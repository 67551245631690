import rotationToAzimuth from "./rotationToAzimuth";
import { federalCredit } from "../config/numbers";

export function getPanelCount(panels) {
  return panels.reduce((acc, panel) => (panel.active ? (acc += 1) : acc), 0);
}

export function getSystemSize(panelCount, panel) {
  return (panelCount * panel.watts) / 1000;
}

export function getAnnualUtility(billInputType, annual, monthly) {
  if (billInputType === "Annual") {
    return annual;
  } else if (billInputType === "12 Month") {
    return monthly.reduce((a, b) => +a + +b, 0);
  } else {
    return null;
  }
}

export function getMonthProduction(panels, azimuthData, monthIndex) {
  return panels.reduce(
    (acc, panel) =>
      panel.active
        ? (acc +=
            azimuthData[rotationToAzimuth(panel.rotation)].monthly[monthIndex])
        : acc,
    0
  );
}

export function getYearProduction(panels, azimuthData) {
  return panels.reduce(
    (acc, panel) =>
      panel.active
        ? (acc += azimuthData[rotationToAzimuth(panel.rotation)].annual)
        : acc,
    0
  );
}

export function getYearProdWShading(panels, azimuthData) {
  return panels.reduce(
    (acc, panel) =>
      panel.active
        ? (acc +=
            azimuthData[rotationToAzimuth(panel.rotation)].annual * panel.prod)
        : acc,
    0
  );
}

export function getCost(
  ppw,
  systemSize,
  isInUtah,
  federal,
  financier,
  loan,
  paydown
) {
  const cost = {
    gross: 0,
    state: 0,
    federal: 0,
    adjusted: 0,
    noPayDown: 0,
  };

  cost.gross = Number((systemSize * 1000 * ppw).toFixed(2));
  if (isInUtah) {
    cost.state = Number(Math.min(cost.gross * 0.25, 800).toFixed(2));
  } else {
    cost.state = 0;
  }
  if (federal) {
    cost.federal = Number((cost.gross * federalCredit).toFixed(2));
  } else {
    cost.federal = 0;
  }
  cost.adjusted = Number(
    (cost.gross - cost.state - cost.federal - paydown).toFixed(2)
  );
  if (financier.name !== "Cash") {
    cost.today = Number((cost.gross * loan.initPayFac).toFixed(2));
    if (loan.amortizations.length !== 0) {
      if (
        cost.adjusted * loan.amortizations[0].payFac < cost.today * 1.01 &&
        cost.adjusted * loan.amortizations[0].payFac > cost.today * 0.99
      ) {
        cost.amortized = cost.today;
      } else {
        cost.amortized = Number(
          (cost.adjusted * loan.amortizations[0].payFac).toFixed(2)
        );
      }
      cost.noPayDown = Number(
        (cost.gross * loan.amortizations[0].payFac).toFixed(2)
      );
    }
  }

  return cost;
}
