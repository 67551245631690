import { useSelector } from "react-redux";
import PropTypes from "prop-types";

/**
 * Bread and butter. This is the basis of permission handling in the app.
 */
export default function RequiresRole(props) {
  const user = useSelector((state) => state.user);
  const hasAccess = props.roles.includes(user.role);
  return hasAccess ? props.children : null;
}

RequiresRole.propTypes = {
  roles: PropTypes.array.isRequired,
};

RequiresRole.defaultProps = {
  roles: ["System Admin"],
};
