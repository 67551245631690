export const setDealership = (payload) => ({
  type: "SET_DEALERSHIP",
  payload,
});

export const setDealershipTheme = (payload) => ({
  type: "SET_DEALERSHIP_THEME",
  payload,
});

export const setDealershipOwner = (payload) => ({
  type: "SET_DEALERSHIP_OWNER",
  payload,
});

export const setDealershipAddress = (payload) => ({
  type: "SET_DEALERSHIP_ADDRESS",
  payload,
});

export const setDealershipProposalDefaults = (payload) => ({
  type: "SET_DEALERSHIP_PROPOSAL_DEFAULTS",
  payload,
});

export const editDealership = (payload) => ({
  type: "EDIT_DEALERSHIP",
  payload,
});
