const initialState = false;

const authenticatedReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_AUTHENTICATED":
      return payload;

    default:
      return state;
  }
};
export default authenticatedReducer;
