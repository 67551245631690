import React, { useState } from "react";
import PropTypes from "prop-types";
import RequiresRole from "../RequiresRole";
import roles from "../../../config/roles";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  List,
} from "@material-ui/core";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import MoneyIcon from "@material-ui/icons/Money";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}22 !important`,
  },
  selectedFaded: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}11 !important`,
  },
  highlightedText: {
    color: theme.palette.secondary.main,
  },
}));

/**
 * Colapsable nav bar element that contains multiple links for the commissions pages.
 * @todo make a nav component to clean this up.
 */
export default function RepCommissionsLinks({ location, toggleDrawer }) {
  const classes = useStyles();
  // This is the bool that determins whether the colapsable is open or closed.
  // Its initial state is true if the current page is contained in the dropdown
  const [repCommissionsOpen, setRepCommissionsOpen] = useState(
    location.pathname.split("/")[1] === "rep-commissions"
  );

  return (
    <>
      <Link to="/rep-commissions/user" className="nostyle-link">
        <ListItem
          button
          onClick={() => {
            toggleDrawer(false);
          }}
          selected={location.pathname.split("/")[1] === "rep-commissions"}
          classes={{ selected: classes.selected }}
        >
          <ListItemIcon
            className={
              location.pathname.split("/")[1] === "rep-commissions"
                ? classes.highlightedText
                : null
            }
          >
            <EmojiPeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Rep Commissions" />
          <ListItemSecondaryAction>
            <IconButton
              className={
                location.pathname.split("/")[1] === "rep-commissions"
                  ? classes.highlightedText
                  : null
              }
              onClick={(e) => {
                e.preventDefault();
                setRepCommissionsOpen(!repCommissionsOpen);
              }}
            >
              {repCommissionsOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </Link>

      <Collapse in={repCommissionsOpen} timeout="auto">
        <List component="div" disablePadding>
          <RequiresRole roles={[roles.dealerOwner, roles.systemAdmin]}>
            <Link to="/rep-commissions/user" className="nostyle-link">
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  toggleDrawer(false);
                }}
                selected={
                  location.pathname.split("/")[1] === "rep-commissions" &&
                  location.pathname.split("/")[2] === "user"
                }
                classes={{ selected: classes.selectedFaded }}
              >
                <ListItemIcon
                  className={
                    location.pathname.split("/")[1] === "rep-commissions" &&
                    location.pathname.split("/")[2] === "user"
                      ? classes.highlightedText
                      : null
                  }
                >
                  <EmojiPeopleIcon />
                </ListItemIcon>
                <ListItemText primary="User" />
              </ListItem>
            </Link>
          </RequiresRole>
          <RequiresRole roles={[roles.dealerOwner, roles.systemAdmin]}>
            <Link to="/rep-commissions/pending" className="nostyle-link">
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  toggleDrawer(false);
                }}
                selected={location.pathname.split("/")[2] === "pending"}
                classes={{ selected: classes.selectedFaded }}
              >
                <ListItemIcon
                  className={
                    location.pathname.split("/")[2] === "pending"
                      ? classes.highlightedText
                      : null
                  }
                >
                  <EmojiPeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Pending" />
              </ListItem>
            </Link>
          </RequiresRole>
          <RequiresRole roles={[roles.dealerOwner, roles.systemAdmin]}>
            <Link to="/rep-commissions/payroll" className="nostyle-link">
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  toggleDrawer(false);
                }}
                selected={location.pathname.split("/")[2] === "payroll"}
                classes={{ selected: classes.selectedFaded }}
              >
                <ListItemIcon
                  className={
                    location.pathname.split("/")[2] === "payroll"
                      ? classes.highlightedText
                      : null
                  }
                >
                  <MoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Payroll" />
              </ListItem>
            </Link>
          </RequiresRole>
        </List>
      </Collapse>
    </>
  );
}

RepCommissionsLinks.propTypes = {
  /** Location object obtained by using react routers useLocation hook */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  /** Function to close navbar on link click */
  toggleDrawer: PropTypes.func.isRequired,
};
