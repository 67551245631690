import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Drawer,
  Typography,
  IconButton,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import NavList from "./header/NavList";

// Function to extract the page name from the url
const urlPathToFriendly = (path) => {
  path = path.substring(1).split("/")[0];
  path = path.split("-").join(" ");
  return path.charAt(0).toUpperCase() + path.slice(1);
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

/**
 * Provides a header that tells the user what page they are on. Also facilitates the nav bar.
 */
export default function Header() {
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNavDrawerOpen(open);
  };

  return (
    <>
      {/* Hiding the navbar on all the pages we dont want it to show up on */}
      {location.pathname.split("/")[1] === "proposal" ||
      location.pathname.split("/")[1] === "customer-proposal" ||
      location.pathname.split("/")[1] === "" ||
      location.pathname.split("/")[1] === "scheduler" ||
      location.pathname.split("/")[1] === "invite" ? null : (
        <>
          <AppBar position="sticky">
            <Toolbar>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              {/* Displaying friendly page name */}
              <Typography variant="h6" className={classes.title}>
                {urlPathToFriendly(location.pathname)}
              </Typography>
            </Toolbar>
          </AppBar>
          {/* Creating a drawer for the navbar to live in */}
          <Drawer
            anchor={"left"}
            open={navDrawerOpen}
            onClose={toggleDrawer(false)}
          >
            <NavList toggleDrawer={setNavDrawerOpen} />
          </Drawer>
        </>
      )}
    </>
  );
}
