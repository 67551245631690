import React, { useEffect, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import allActions from "./redux/actions";
import checkApi from "./api/checkApi.instance";

import {
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from "@material-ui/core";
import { getTheme } from "./theme";

import { SnackbarProvider } from "notistack";

import "./styles/common.scss";

import ProtectedRoute from "./components/common/ProtectedRoute";
import roles from "./config/roles";

import Header from "./components/common/Header";
import Loading from "./components/common/Loading";

const Home = lazy(() =>
  import(/* webpackChunkName: "home-page" */ "./pages/Home")
);
const Invite = lazy(() =>
  import(/* webpackChunkName: "invite-page" */ "./pages/Invite")
);
const ProposalsTable = lazy(() =>
  import(/* webpackChunkName: "proposal-table-page" */ "./pages/ProposalsTable")
);
const ProposalsPending = lazy(() =>
  import(
    /* webpackChunkName: "proposal-pending-page" */ "./pages/ProposalsPending"
  )
);
const ProposalsArchived = lazy(() =>
  import(
    /* webpackChunkName: "proposal-archived-page" */ "./pages/ProposalsArchived"
  )
);
const BuildProposal = lazy(() =>
  import(/* webpackChunkName: "build-proposal-page" */ "./pages/BuildProposal")
);
const Users = lazy(() =>
  import(/* webpackChunkName: "users-page" */ "./pages/Users")
);
const Dealerships = lazy(() =>
  import(/* webpackChunkName: "dealerships-page" */ "./pages/Dealerships")
);
const DealershipId = lazy(() =>
  import(/* webpackChunkName: "dealership-id-page" */ "./pages/DealershipId")
);
const Dealership = lazy(() =>
  import(/* webpackChunkName: "dealership-page" */ "./pages/Dealership")
);
const Profile = lazy(() =>
  import(/* webpackChunkName: "profile-page" */ "./pages/Profile")
);
const Proposal = lazy(() =>
  import(/* webpackChunkName: "proposal-page" */ "./pages/Proposal")
);
const CustomerProposal = lazy(() =>
  import(
    /* webpackChunkName: "customer-proposal-page" */ "./pages/CustomerProposal"
  )
);
const Customers = lazy(() =>
  import(/* webpackChunkName: "customers-page" */ "./pages/Customers")
);
const CustomersPP = lazy(() =>
  import(/* webpackChunkName: "customers-pp-page" */ "./pages/CustomersPP")
);
const Customer = lazy(() =>
  import(/* webpackChunkName: "customer-page" */ "./pages/Customer")
);
const InstallCalendar = lazy(() =>
  import(/* webpackChunkName: "install-calendar" */ "./pages/InstallCalendar")
);

const Commissions = lazy(() =>
  import(/* webpackChunkName: "commissions-page" */ "./pages/Commissions")
);
const Paychecks = lazy(() =>
  import(/* webpackChunkName: "paychecks-page" */ "./pages/Paychecks")
);

const PaychecksExtended = lazy(() =>
  import(
    /* webpackChunkName: "paychecks-extended-page" */ "./pages/PaychecksExtended"
  )
);

const RepCommissionAll = lazy(() =>
  import(/* webpackChunkName: "rep-com-all" */ "./pages/RepCommissionAll")
);
const RepCommissionPending = lazy(() =>
  import(
    /* webpackChunkName: "rep-com-pending" */ "./pages/RepCommissionPending"
  )
);
const RepCommissionPayroll = lazy(() =>
  import(
    /* webpackChunkName: "rep-com-checks" */ "./pages/RepCommissionPayroll"
  )
);

const Scheduler = lazy(() =>
  import(/* webpackChunkName: "scheduler-page" */ "./pages/Scheduler")
);

const InstallerId = lazy(() =>
  import(/* webpackChunkName: "installer-id-page" */ "./pages/InstallerId")
);
const Installers = lazy(() =>
  import(/* webpackChunkName: "intallers-page" */ "./pages/Installers")
);
const Admin = lazy(() =>
  import(/* webpackChunkName: "admin-page" */ "./pages/Admin")
);

const CaseStudy = lazy(() =>
  import(/* webpackChunkName: "cast-study-page" */ "./pages/CaseStudy")
);

const InstallationAgreement = lazy(() =>
  import(
    /* webpackChunkName: "installation-agreement-page" */ "./pages/InstallationAgreement"
  )
);

function App() {
  const loading = useSelector((state) => state.loading);
  const authenticated = useSelector((state) => state.authenticated);
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUser = () => {
      dispatch(allActions.setLoading(true));
      checkApi
        .get("/auth/login")
        .then((response) => {
          if (response.status === 200) {
            dispatch(allActions.setUser(response.data.user));
            if (response.data.userDealership) {
              dispatch(
                allActions.setUserDealership(response.data.userDealership)
              );
            }

            if (response.data.user.profileSettings) {
              dispatch(
                allActions.setTheme(
                  response.data.user?.profileSettings?.useDealershipTheme
                    ? response.data.userDealership?.theme
                    : response.data.user.profileSettings?.theme
                )
              );
            }

            if (response.data.user.needsInvite) {
              dispatch(allActions.setAuthenticated("needs-invite"));
              dispatch(allActions.setLoading(false));
            } else {
              dispatch(allActions.setAuthenticated("rep"));
              dispatch(allActions.setLoading(false));
            }
          } else {
            dispatch(allActions.setAuthenticated("public"));
            dispatch(allActions.setLoading(false));
          }
        })
        .catch((err) => {
          dispatch(allActions.setAuthenticated("public"));
          dispatch(allActions.setLoading(false));
          console.log(err);
        });
    };

    fetchUser();

    // eslint-disable-next-line
  }, []);

  if (loading || !authenticated) {
    return <Loading />;
  }

  if (authenticated === "public") {
    return (
      <ThemeProvider theme={getTheme(theme)}>
        <CssBaseline />
        <div id="updatehelper" />
        <Suspense
          fallback={
            <div className="loadingContainer">
              <CircularProgress />
            </div>
          }
        >
          <Switch>
            <Route path="/customer-proposal/:id" component={CustomerProposal} />
            <Redirect from="/proposal/:id" to="/customer-proposal/:id" />
            <Route path="/scheduler" component={Scheduler} />
            <Route path="/" component={Home} />
          </Switch>
        </Suspense>
      </ThemeProvider>
    );
  }

  if (authenticated === "needs-invite") {
    return (
      <ThemeProvider theme={getTheme(theme)}>
        <CssBaseline />
        <div id="updatehelper" />
        <Suspense
          fallback={
            <div className="loadingContainer">
              <CircularProgress />
            </div>
          }
        >
          <Switch>
            <Route path="/customer-proposal/:id" component={CustomerProposal} />
            <Redirect from="/proposal/:id" to="/customer-proposal/:id" />
            <Route path="/scheduler" component={Scheduler} />
            <Route path="/invite" component={Invite} />
            <Redirect to="/invite" />
          </Switch>
        </Suspense>
      </ThemeProvider>
    );
  }

  return (
    <>
      <ThemeProvider theme={getTheme(theme)}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <div id="updatehelper" />
          <Header />
          <Suspense
            fallback={
              <div className="loadingContainer">
                <CircularProgress />
              </div>
            }
          >
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/invite" component={Invite} />
              <Route path="/proposals/grid" component={ProposalsArchived} />
              <Route path="/proposals/table" component={ProposalsTable} />
              <Route path="/scheduler" component={Scheduler} />
              <ProtectedRoute
                path="/proposals/pending"
                component={ProposalsPending}
                roles={[
                  roles.salesSupport,
                  roles.supportManager,
                  roles.systemAdmin,
                ]}
              />
              <Route path="/proposal/:id" component={Proposal} />
              <Route
                path="/customer-proposal/:id"
                component={CustomerProposal}
              />
              <Route path="/customers/all" component={Customers} />
              <Route path="/customers/perfect-packet" component={CustomersPP} />

              <Route
                path="/installation-agreement"
                component={InstallationAgreement}
              />

              <Route
                path="/customers/install-calendar"
                component={InstallCalendar}
              />

              <ProtectedRoute
                path="/commissions/all"
                component={Commissions}
                roles={[roles.dealerOwner, roles.systemAdmin]}
              />
              <ProtectedRoute
                path="/commissions/paychecks"
                component={Paychecks}
                roles={[roles.dealerOwner, roles.systemAdmin]}
              />
              <ProtectedRoute
                path="/commissions/paychecks-extended"
                component={PaychecksExtended}
                roles={[roles.dealerOwner, roles.systemAdmin]}
              />
              <ProtectedRoute
                path="/rep-commissions/user"
                component={RepCommissionAll}
                roles={[roles.dealerOwner, roles.systemAdmin]}
              />
              <ProtectedRoute
                path="/rep-commissions/pending"
                component={RepCommissionPending}
                roles={[roles.dealerOwner, roles.systemAdmin]}
              />
              <ProtectedRoute
                path="/rep-commissions/payroll"
                component={RepCommissionPayroll}
                roles={[roles.dealerOwner, roles.systemAdmin]}
              />
              <Route path="/customer/:id" component={Customer} />
              <ProtectedRoute
                path="/build-proposal/:id"
                component={BuildProposal}
                roles={[
                  roles.salesSupport,
                  roles.supportManager,
                  roles.systemAdmin,
                ]}
              />

              <ProtectedRoute
                path="/users"
                component={Users}
                roles={[
                  roles.userManager,
                  roles.dealerAdmin,
                  roles.dealerOwner,
                  roles.salesSupport,
                  roles.supportManager,
                  roles.systemAdmin,
                ]}
              />
              <ProtectedRoute
                path="/dealership/:id"
                component={DealershipId}
                roles={[
                  roles.salesSupport,
                  roles.supportManager,
                  roles.systemAdmin,
                ]}
              />
              <ProtectedRoute
                path="/dealership"
                component={Dealership}
                roles={[roles.dealerOwner]}
              />
              <ProtectedRoute
                path="/dealerships"
                component={Dealerships}
                roles={[
                  roles.salesSupport,
                  roles.supportManager,
                  roles.systemAdmin,
                ]}
              />
              <ProtectedRoute
                path="/installer/:id"
                component={InstallerId}
                roles={[roles.systemAdmin]}
              />
              <ProtectedRoute
                path="/installers"
                component={Installers}
                roles={[roles.systemAdmin]}
              />

              <ProtectedRoute
                path="/admin"
                component={Admin}
                roles={[roles.systemAdmin]}
              />
              <ProtectedRoute
                path="/case-study"
                component={CaseStudy}
                roles={[roles.systemAdmin]}
              />

              <Route path="/profile" component={Profile} />
              <Redirect to="/proposals/grid" />
            </Switch>
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
