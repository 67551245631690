import React from "react";
import ReactLoading from "react-loading";

import onesolar from "../../images/1solar-logo_2.png";

/**
 * Provides a loading screen for the entire page. Only really used durring user authentication
 */
export default function Loading() {
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={onesolar} alt="1Solar" style={{ maxWidth: 200 }} />
      <div style={{ marginLeft: 30 }}>
        <ReactLoading type={"bubbles"} color={"#000000"} />
      </div>
    </div>
  );
}
