const initialState = {};

const userDealershipReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_USER_DEALERSHIP":
      return { ...payload };

    case "EDIT_USER_DEALERSHIP":
      return { ...state, ...payload };

    default:
      return state;
  }
};
export default userDealershipReducer;
