const initialState = {};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_USER":
      return payload;

    case "SET_USER_THEME":
      return {
        ...state,
        profileSettings: {
          ...state.profileSettings,
          useDealershipTheme: payload.useDealershipTheme,
          theme: payload.theme,
        },
      };

    case "SET_USER_PROPOSAL_DEFAULTS":
      return {
        ...state,
        profileSettings: {
          ...state.profileSettings,
          useDealershipProposal: payload.useDealershipProposal,
          proposalDefaults: { ...payload.proposalDefaults },
        },
      };
    default:
      return state;
  }
};

export default userReducer;
