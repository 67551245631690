import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import RequiresRole from "../RequiresRole";
import roles from "../../../config/roles";

import { makeStyles } from "@material-ui/core/styles";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import WidgetsIcon from "@material-ui/icons/Widgets";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import RowingIcon from "@material-ui/icons/Rowing";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}22 !important`,
  },
  selectedFaded: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}11 !important`,
  },
  highlightedText: {
    color: theme.palette.secondary.main,
  },
}));

/**
 * Contains all the links that are not colapsable
 */
export default function SingleLinks({ location, toggleDrawer }) {
  const classes = useStyles();

  return (
    <>
      <RequiresRole
        roles={[
          roles.userManager,
          roles.dealerAdmin,
          roles.dealerOwner,
          roles.salesSupport,
          roles.supportManager,
          roles.systemAdmin,
        ]}
      >
        <Link to="/users" className="nostyle-link">
          <ListItem
            button
            onClick={() => {
              toggleDrawer(false);
            }}
            selected={location.pathname.split("/")[1] === "users"}
            classes={{ selected: classes.selected }}
          >
            <ListItemIcon
              className={
                location.pathname.split("/")[1] === "users"
                  ? classes.highlightedText
                  : null
              }
            >
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </Link>
      </RequiresRole>

      <RequiresRole roles={[roles.dealerOwner]}>
        <Link to="/dealership" className="nostyle-link">
          <ListItem
            button
            onClick={() => {
              toggleDrawer(false);
            }}
            selected={location.pathname.split("/")[1] === "dealership"}
            classes={{ selected: classes.selected }}
          >
            <ListItemIcon
              className={
                location.pathname.split("/")[1] === "dealership"
                  ? classes.highlightedText
                  : null
              }
            >
              <WidgetsIcon />
            </ListItemIcon>
            <ListItemText primary="Dealership" />
          </ListItem>
        </Link>
      </RequiresRole>

      <RequiresRole
        roles={[roles.salesSupport, roles.supportManager, roles.systemAdmin]}
      >
        <Link to="/dealerships" className="nostyle-link">
          <ListItem
            button
            onClick={() => {
              toggleDrawer(false);
            }}
            selected={location.pathname.split("/")[1] === "dealerships"}
            classes={{ selected: classes.selected }}
          >
            <ListItemIcon
              className={
                location.pathname.split("/")[1] === "dealerships"
                  ? classes.highlightedText
                  : null
              }
            >
              <WidgetsIcon />
            </ListItemIcon>
            <ListItemText primary="Dealerships" />
          </ListItem>
        </Link>
      </RequiresRole>
      <RequiresRole roles={[roles.systemAdmin]}>
        <Link to="/installers" className="nostyle-link">
          <ListItem
            button
            onClick={() => {
              toggleDrawer(false);
            }}
            selected={location.pathname.split("/")[1] === "installers"}
            classes={{ selected: classes.selected }}
          >
            <ListItemIcon
              className={
                location.pathname.split("/")[1] === "installers"
                  ? classes.highlightedText
                  : null
              }
            >
              <RowingIcon />
            </ListItemIcon>
            <ListItemText primary="Installers" />
          </ListItem>
        </Link>
      </RequiresRole>
      <Link to="/profile" className="nostyle-link">
        <ListItem
          button
          onClick={() => {
            toggleDrawer(false);
          }}
          selected={location.pathname.split("/")[1] === "profile"}
          classes={{ selected: classes.selected }}
        >
          <ListItemIcon
            className={
              location.pathname.split("/")[1] === "profile"
                ? classes.highlightedText
                : null
            }
          >
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItem>
      </Link>
    </>
  );
}

SingleLinks.propTypes = {
  /** Location object obtained by using react routers useLocation hook */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  /** Function to close navbar on link click */
  toggleDrawer: PropTypes.func.isRequired,
};
