import axios from "axios";
import UpdateNotification from "./UpdateNotification";

const checkApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
    "App-Version": process.env.REACT_APP_APP_VERSION,
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300;
  },
});

checkApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 418) {
        UpdateNotification.send();
      }
    }

    return Promise.reject(error);
  }
);

export default checkApi;
