import oneSolarTheme from "../../config/theme";

const initialState = oneSolarTheme;

const themeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_THEME":
      return { ...state, ...payload };

    default:
      return state;
  }
};
export default themeReducer;
