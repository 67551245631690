import { createSlice } from "@reduxjs/toolkit";

import { allInverters, allPanels } from "../../../config/systems";

import getStateFromAddress from "../../../utils/getStateFromAddress";
import {
  getPanelCount,
  getSystemSize,
  getCost,
  getAnnualUtility,
  getYearProduction,
  getYearProdWShading,
} from "../../../utils/getSystemInfo";

const initialState = {
  isLoading: true,

  homeowner: {},
  proposal: {},
  loan: {},
  financier: {},

  panel: {},
  inverter: {},

  forCustomer: false,
  forDemo: false,
  isInUtah: false,

  panelCount: 0,
  systemSize: 0,
  annualUsage: 0,
  annualBill: 0,
  yearProduction: 0,
  yearProdWShading: 0,

  paydown: 0,
  cost: {
    gross: 0,
    state: 0,
    federal: 0,
    adjusted: 0,
    noPayDown: 0,
  },

  detailedAddress: {
    isLoading: true,
    full: "",
    street: "",
    city: "",
    county: "",
    state: {
      long: "",
      short: "",
    },
    zip: "",
    country: "",
    lat: 0,
    lng: 0,
  },
};

const homeownerModalSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    initData(state, action) {
      const { proposal, homeowner, loan, financier } = action.payload;
      const panel = allPanels.find((panel) => panel.id === proposal.panel);
      const inverter = allInverters.find(
        (inverter) => inverter.id === proposal.inverter
      );

      const isInUtah = getStateFromAddress(homeowner.address) === "UT";

      const panelCount = getPanelCount(proposal.builderSettings.panels);

      const systemSize = getSystemSize(panelCount, panel);

      const yearProduction = getYearProduction(
        proposal.builderSettings.panels,
        proposal.builderSettings.azimuthData
      );

      let yearProdWShading = state.yearProdWShading;
      if (proposal.shading?.enabled) {
        yearProdWShading = getYearProdWShading(
          proposal.builderSettings.panels,
          proposal.builderSettings.azimuthData
        );
      }

      const annualUsage = getAnnualUtility(
        proposal.billInputType,
        proposal.annualUsage,
        proposal.usageMonthly
      );

      const annualBill = getAnnualUtility(
        proposal.billInputType,
        proposal.annualBill,
        proposal.billMonthly
      );

      const cost = getCost(
        proposal.ppw,
        systemSize,
        isInUtah,
        proposal.federal,
        financier,
        loan,
        state.paydown
      );

      return {
        ...state,
        ...action.payload,
        isLoading: false,

        panel,
        inverter,

        isInUtah,

        panelCount,
        systemSize,
        annualUsage,
        annualBill,
        yearProduction,
        yearProdWShading,

        cost,
      };
    },

    setPanels(state, action) {
      const panels = action.payload;

      const panelCount = getPanelCount(panels);

      const systemSize = getSystemSize(panelCount, state.panel);

      const yearProduction = getYearProduction(
        panels,
        state.proposal.builderSettings.azimuthData
      );

      let yearProdWShading = state.yearProdWShading;
      if (state.proposal.shading?.enabled) {
        yearProdWShading = getYearProdWShading(
          panels,
          state.proposal.builderSettings.azimuthData
        );
      }

      const cost = getCost(
        state.proposal.ppw,
        systemSize,
        state.isInUtah,
        state.proposal.federal,
        state.financier,
        state.loan,
        state.paydown
      );
      return {
        ...state,
        panelCount,
        systemSize,
        yearProduction,
        yearProdWShading,
        cost,
        proposal: {
          ...state.proposal,
          builderSettings: {
            ...state.proposal.builderSettings,
            panels: panels,
          },
        },
      };
    },

    setPaydown(state, action) {
      const paydown = action.payload;

      const cost = getCost(
        state.proposal.ppw,
        state.systemSize,
        state.isInUtah,
        state.proposal.federal,
        state.financier,
        state.loan,
        paydown
      );

      return { ...state, paydown, cost };
    },

    setState(state, action) {
      return { ...state, ...action.payload };
    },

    editProposalSettings(state, action) {
      const cost = getCost(
        action.payload.ppw,
        state.systemSize,
        state.isInUtah,
        state.proposal.federal,
        state.financier,
        state.loan,
        state.paydown
      );

      return {
        ...state,
        cost,
        proposal: { ...state.proposal, ...action.payload },
      };
    },

    editHomeowner(state, action) {
      return {
        ...state,
        homeowner: { ...state.homeowner, ...action.payload },
      };
    },

    cleanState() {
      return initialState;
    },
  },
});

export const {
  initData,
  setState,
  setPanels,
  setPaydown,
  editProposalSettings,
  editHomeowner,
  cleanState,
} = homeownerModalSlice.actions;
export default homeownerModalSlice.reducer;
