import React, { useState } from "react";
import PropTypes from "prop-types";
import RequiresRole from "../RequiresRole";
import roles from "../../../config/roles";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  List,
} from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyIcon from "@material-ui/icons/Money";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}22 !important`,
  },
  selectedFaded: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}11 !important`,
  },
  highlightedText: {
    color: theme.palette.secondary.main,
  },
}));

/**
 * Colapsable nav bar element that contains multiple links for the commissions pages.
 * @todo make a nav component to clean this up.
 */
export default function CommissionsLinks({ location, toggleDrawer }) {
  const classes = useStyles();
  // This is the bool that determins whether the colapsable is open or closed.
  // Its initial state is true if the current page is contained in the dropdown
  const [commissionsOpen, setCommissionsOpen] = useState(
    location.pathname.split("/")[1] === "commissions"
  );

  return (
    <>
      <Link to="/commissions/all" className="nostyle-link">
        <ListItem
          button
          onClick={() => {
            toggleDrawer(false);
          }}
          selected={location.pathname.split("/")[1] === "commissions"}
          classes={{ selected: classes.selected }}
        >
          <ListItemIcon
            className={
              location.pathname.split("/")[1] === "commissions"
                ? classes.highlightedText
                : null
            }
          >
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Commissions" />
          <ListItemSecondaryAction>
            <IconButton
              className={
                location.pathname.split("/")[1] === "commissions"
                  ? classes.highlightedText
                  : null
              }
              onClick={(e) => {
                e.preventDefault();
                setCommissionsOpen(!commissionsOpen);
              }}
            >
              {commissionsOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </Link>

      <Collapse in={commissionsOpen} timeout="auto">
        <List component="div" disablePadding>
          <RequiresRole roles={[roles.dealerOwner, roles.systemAdmin]}>
            <Link to="/commissions/all" className="nostyle-link">
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  toggleDrawer(false);
                }}
                selected={
                  location.pathname.split("/")[1] === "commissions" &&
                  location.pathname.split("/")[2] === "all"
                }
                classes={{ selected: classes.selectedFaded }}
              >
                <ListItemIcon
                  className={
                    location.pathname.split("/")[1] === "commissions" &&
                    location.pathname.split("/")[2] === "all"
                      ? classes.highlightedText
                      : null
                  }
                >
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="All" />
              </ListItem>
            </Link>
          </RequiresRole>
          <RequiresRole roles={[roles.dealerOwner, roles.systemAdmin]}>
            <Link to="/commissions/paychecks" className="nostyle-link">
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  toggleDrawer(false);
                }}
                selected={location.pathname.split("/")[2] === "paychecks"}
                classes={{ selected: classes.selectedFaded }}
              >
                <ListItemIcon
                  className={
                    location.pathname.split("/")[2] === "paychecks"
                      ? classes.highlightedText
                      : null
                  }
                >
                  <MoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Paychecks" />
              </ListItem>
            </Link>
          </RequiresRole>
          <RequiresRole roles={[roles.dealerOwner, roles.systemAdmin]}>
            <Link to="/commissions/paychecks-extended" className="nostyle-link">
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  toggleDrawer(false);
                }}
                selected={
                  location.pathname.split("/")[2] === "paychecks-extended"
                }
                classes={{ selected: classes.selectedFaded }}
              >
                <ListItemIcon
                  className={
                    location.pathname.split("/")[2] === "paychecks-extended"
                      ? classes.highlightedText
                      : null
                  }
                >
                  <MoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Paychecks Ext." />
              </ListItem>
            </Link>
          </RequiresRole>
        </List>
      </Collapse>
    </>
  );
}

CommissionsLinks.propTypes = {
  /** Location object obtained by using react routers useLocation hook */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  /** Function to close navbar on link click */
  toggleDrawer: PropTypes.func.isRequired,
};
