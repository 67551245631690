const initialState = {};

const loanReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_LOAN":
      return { ...payload };

    default:
      return state;
  }
};

export default loanReducer;
