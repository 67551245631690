import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

/**
 * Provides a react-router-dom route component that is protected by a role check
 */
export default function ProtectedRoute({ roles, ...rest }) {
  const user = useSelector((state) => state.user);
  if (roles.includes(user.role)) {
    return <Route {...rest} />;
  } else {
    return <Redirect to="/" />;
  }
}

ProtectedRoute.propTypes = {
  /** Array containing the roles that are allowed to access the route */
  roles: PropTypes.array.isRequired,
};

ProtectedRoute.defaultProps = {
  roles: ["System Admin"],
};
