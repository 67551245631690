export const federalCredit = 0.3;

export const costToUsage = { UT: 0.118, CO: 0.124, NV: 0.135 };

export const utilityProviders = [
  {
    eiaid: 213,
    name: "Alaska Electric Light&Power Co",
    type: "Bundled",
    state: "AK",
    thenumber: 0.14181819451427294,
  },
  {
    eiaid: 219,
    name: "Alaska Power and Telephone Co",
    type: "Bundled",
    state: "AK",
    thenumber: 0.30257897624833424,
  },
  {
    eiaid: 221,
    name: "Alaska Village Elec Coop, Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.5196488070627722,
  },
  {
    eiaid: 599,
    name: "Anchorage Municipal Light and Power",
    type: "Bundled",
    state: "AK",
    thenumber: 0.2318551801607189,
  },
  {
    eiaid: 1276,
    name: "Barrow Utils & Elec Coop, Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.1548812906774223,
  },
  {
    eiaid: 3522,
    name: "Chugach Electric Assn Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.2115784879242764,
  },
  {
    eiaid: 17271,
    name: "City & Borough of Sitka - (AK)",
    type: "Bundled",
    state: "AK",
    thenumber: 0.18579732120293152,
  },
  {
    eiaid: 19454,
    name: "City of Unalaska - (AK)",
    type: "Bundled",
    state: "AK",
    thenumber: 0.412055768179333,
  },
  {
    eiaid: 21015,
    name: "City of Wrangell - (AK)",
    type: "Bundled",
    state: "AK",
    thenumber: 0.11428571428571428,
  },
  {
    eiaid: 4329,
    name: "Copper Valley Elec Assn, Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.2547704714640198,
  },
  {
    eiaid: 40215,
    name: "Cordova Electric Coop, Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.3633811870580673,
  },
  {
    eiaid: 7353,
    name: "Golden Valley Elec Assn Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.2627489816510333,
  },
  {
    eiaid: 19558,
    name: "Homer Electric Assn Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.2696137056562533,
  },
  {
    eiaid: 18963,
    name: "Inside Passage Elec Coop, Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.6288343558282209,
  },
  {
    eiaid: 10210,
    name: "Ketchikan Public Utilities",
    type: "Bundled",
    state: "AK",
    thenumber: 0.11293533059048502,
  },
  {
    eiaid: 10433,
    name: "Kodiak Electric Assn Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.17358566771485445,
  },
  {
    eiaid: 10451,
    name: "Kotzebue Electric Assn Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.3933469665985003,
  },
  {
    eiaid: 11824,
    name: "Matanuska Electric Assn Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.21528861266752253,
  },
  {
    eiaid: 12385,
    name: "Metlakatla Power & Light",
    type: "Bundled",
    state: "AK",
    thenumber: 0.14681253376553213,
  },
  {
    eiaid: 13642,
    name: "Nome Joint Utility Systems",
    type: "Bundled",
    state: "AK",
    thenumber: 0.3815867028173995,
  },
  {
    eiaid: 26616,
    name: "North Slope Borough Power & Light",
    type: "Bundled",
    state: "AK",
    thenumber: 0.16930602262305103,
  },
  {
    eiaid: 13870,
    name: "Nushagak Electric Coop, Inc",
    type: "Bundled",
    state: "AK",
    thenumber: 0.45388877894320206,
  },
  {
    eiaid: 14856,
    name: "Petersburg Borough - (AK)",
    type: "Bundled",
    state: "AK",
    thenumber: 0.10203313253012049,
  },
  {
    eiaid: 195,
    name: "Alabama Power Co",
    type: "Bundled",
    state: "AL",
    thenumber: 0.13406987318928856,
  },
  {
    eiaid: 241,
    name: "Albertville Municipal Utilities Board",
    type: "Bundled",
    state: "AL",
    thenumber: 0.09831181727904667,
  },
  {
    eiaid: 750,
    name: "Arab Electric Coop Inc",
    type: "Bundled",
    state: "AL",
    thenumber: 0.1096981682143913,
  },
  {
    eiaid: 1149,
    name: "Baldwin County El Member Corp",
    type: "Bundled",
    state: "AL",
    thenumber: 0.1176909834279688,
  },
  {
    eiaid: 1780,
    name: "Black Warrior Elec Member Corp",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10936657089245926,
  },
  {
    eiaid: 3222,
    name: "Central Alabama Electric Coop",
    type: "Bundled",
    state: "AL",
    thenumber: 0.1329496378978141,
  },
  {
    eiaid: 3426,
    name: "Cherokee Electric Coop",
    type: "Bundled",
    state: "AL",
    thenumber: 0.13332383008186371,
  },
  {
    eiaid: 289,
    name: "City of Alexander City",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10221206548713646,
  },
  {
    eiaid: 604,
    name: "City of Andalusia",
    type: "Bundled",
    state: "AL",
    thenumber: 0.13046200946284442,
  },
  {
    eiaid: 944,
    name: "City of Athens - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10110879530645871,
  },
  {
    eiaid: 1640,
    name: "City of Bessemer Utilities",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10882392495713276,
  },
  {
    eiaid: 2423,
    name: "City of Brundidge - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.1192518404382811,
  },
  {
    eiaid: 5309,
    name: "City of Dothan - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.094899876043091,
  },
  {
    eiaid: 5728,
    name: "City of Elba - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.147782585181179,
  },
  {
    eiaid: 6052,
    name: "City of Evergreen - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10227151541004585,
  },
  {
    eiaid: 6145,
    name: "City of Fairhope - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11195367434165172,
  },
  {
    eiaid: 6422,
    name: "City of Florence - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10607008859851251,
  },
  {
    eiaid: 8209,
    name: "City of Hartford - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11217580904291943,
  },
  {
    eiaid: 8226,
    name: "City of Hartselle",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10665861296116737,
  },
  {
    eiaid: 9094,
    name: "City of Huntsville - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10242511991647707,
  },
  {
    eiaid: 10687,
    name: "City of Lanett - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.09608718421905783,
  },
  {
    eiaid: 11333,
    name: "City of Luverne - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.0756813718446137,
  },
  {
    eiaid: 13144,
    name: "City of Muscle Shoals",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10357160402849423,
  },
  {
    eiaid: 14146,
    name: "City of Opelika - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11980837354606566,
  },
  {
    eiaid: 14151,
    name: "City of Opp - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.08584250144759699,
  },
  {
    eiaid: 15022,
    name: "City of Piedmont - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.13122680210978707,
  },
  {
    eiaid: 16140,
    name: "City of Robertsdale",
    type: "Bundled",
    state: "AL",
    thenumber: 0.12133260570151504,
  },
  {
    eiaid: 16458,
    name: "City of Russellville - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11998216743230408,
  },
  {
    eiaid: 16829,
    name: "City of Scottsboro",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11445550196389687,
  },
  {
    eiaid: 18472,
    name: "City of Tarrant",
    type: "Bundled",
    state: "AL",
    thenumber: 0.1244522060034927,
  },
  {
    eiaid: 19225,
    name: "City of Troy - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.0961214681482842,
  },
  {
    eiaid: 19307,
    name: "City of Tuscumbia",
    type: "Bundled",
    state: "AL",
    thenumber: 0.1087219359758933,
  },
  {
    eiaid: 19308,
    name: "City of Tuskegee",
    type: "Bundled",
    state: "AL",
    thenumber: 0.13335917187474766,
  },
  {
    eiaid: 40127,
    name: "Clarke-Washington E M C",
    type: "Bundled",
    state: "AL",
    thenumber: 0.12740650784012572,
  },
  {
    eiaid: 4327,
    name: "Coosa Valley Electric Coop Inc",
    type: "Bundled",
    state: "AL",
    thenumber: 0.13033814669331953,
  },
  {
    eiaid: 4430,
    name: "Covington Electric Coop, Inc",
    type: "Bundled",
    state: "AL",
    thenumber: 0.14178637942151653,
  },
  {
    eiaid: 4618,
    name: "Cullman Electric Coop, Inc",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11767180493324364,
  },
  {
    eiaid: 4617,
    name: "Cullman Power Board",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11185037661745235,
  },
  {
    eiaid: 4958,
    name: "Decatur Utilities",
    type: "Bundled",
    state: "AL",
    thenumber: 0.0995072402430545,
  },
  {
    eiaid: 19219,
    name: "Diverse Power Incorporated",
    type: "Bundled",
    state: "AL",
    thenumber: 0.15950662642697808,
  },
  {
    eiaid: 5204,
    name: "Dixie Electric Coop",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11958571498363378,
  },
  {
    eiaid: 6491,
    name: "Foley Board of Utilities",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11804114276496008,
  },
  {
    eiaid: 6612,
    name: "Fort Payne Improvement Authority",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10846865908489818,
  },
  {
    eiaid: 6717,
    name: "Franklin Electric Coop - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.1215565573875174,
  },
  {
    eiaid: 7827,
    name: "Guntersville Electric Board",
    type: "Bundled",
    state: "AL",
    thenumber: 0.10718603649300296,
  },
  {
    eiaid: 9739,
    name: "Joe Wheeler Elec Member Corp",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11920809259287392,
  },
  {
    eiaid: 11714,
    name: "Marshall-De Kalb Electric Coop",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11116548405513417,
  },
  {
    eiaid: 13669,
    name: "North Alabama Electric Coop",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11415636604099903,
  },
  {
    eiaid: 14602,
    name: "Pea River Electric Coop",
    type: "Bundled",
    state: "AL",
    thenumber: 0.12299039015770726,
  },
  {
    eiaid: 30517,
    name: "Pioneer Electric Coop, Inc - (AL)",
    type: "Bundled",
    state: "AL",
    thenumber: 0.16637295081967213,
  },
  {
    eiaid: 16629,
    name: "Sand Mountain Electric Coop",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11503327589361545,
  },
  {
    eiaid: 17033,
    name: "Sheffield Utilities",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11099589030034382,
  },
  {
    eiaid: 17534,
    name: "South Alabama Elec Coop, Inc",
    type: "Bundled",
    state: "AL",
    thenumber: 0.13199673652814745,
  },
  {
    eiaid: 17646,
    name: "Southern Pine Elec Coop, Inc",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11534760181692201,
  },
  {
    eiaid: 18395,
    name: "Sylacauga Utilities Board",
    type: "Bundled",
    state: "AL",
    thenumber: 0.09806760085825542,
  },
  {
    eiaid: 18203,
    name: "Tallapoosa River Elec Coop Inc",
    type: "Bundled",
    state: "AL",
    thenumber: 0.11461452614193712,
  },
  {
    eiaid: 19027,
    name: "Tombigbee Electric Coop, Inc",
    type: "Bundled",
    state: "AL",
    thenumber: 0.13635797212818543,
  },
  {
    eiaid: 20841,
    name: "Wiregrass Electric Coop, Inc",
    type: "Bundled",
    state: "AL",
    thenumber: 0.12833227005036435,
  },
  {
    eiaid: 817,
    name: "Arkansas Valley Elec Coop Corp",
    type: "Bundled",
    state: "AR",
    thenumber: 0.10574535684420264,
  },
  {
    eiaid: 925,
    name: "Ashley Chicot Elec Coop, Inc",
    type: "Bundled",
    state: "AR",
    thenumber: 0.1152214005622303,
  },
  {
    eiaid: 2678,
    name: "C & L Electric Coop Corp",
    type: "Bundled",
    state: "AR",
    thenumber: 0.11084369877543149,
  },
  {
    eiaid: 3093,
    name: "Carroll Electric Coop Corp - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.09546488817211894,
  },
  {
    eiaid: 1581,
    name: "City of Benton - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.10376431198273485,
  },
  {
    eiaid: 1586,
    name: "City of Bentonville - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.11724082902794677,
  },
  {
    eiaid: 8840,
    name: "City of Hope",
    type: "Bundled",
    state: "AR",
    thenumber: 0.10139419404125286,
  },
  {
    eiaid: 13718,
    name: "City of North Little Rock - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.10971625384256353,
  },
  {
    eiaid: 14216,
    name: "City of Osceola - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.10579542015535155,
  },
  {
    eiaid: 14460,
    name: "City of Paris - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.09460272873194221,
  },
  {
    eiaid: 15043,
    name: "City of Piggott - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.117795998349835,
  },
  {
    eiaid: 15337,
    name: "City of Prescott - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.10183325726456717,
  },
  {
    eiaid: 17184,
    name: "City of Siloam Springs - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.1054644356085708,
  },
  {
    eiaid: 20382,
    name: "City of West Memphis - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.07029240642245362,
  },
  {
    eiaid: 9879,
    name: "City Water and Light Plant",
    type: "Bundled",
    state: "AR",
    thenumber: 0.06263410016922223,
  },
  {
    eiaid: 3705,
    name: "Clarksville Light & Water Co",
    type: "Bundled",
    state: "AR",
    thenumber: 0.0848723072531237,
  },
  {
    eiaid: 3712,
    name: "Clay County Electric Coop Corp",
    type: "Bundled",
    state: "AR",
    thenumber: 0.14464282492196098,
  },
  {
    eiaid: 4280,
    name: "Conway Corporation",
    type: "Bundled",
    state: "AR",
    thenumber: 0.06557479242364335,
  },
  {
    eiaid: 4509,
    name: "Craighead Electric Coop Corp",
    type: "Bundled",
    state: "AR",
    thenumber: 0.09805008851400604,
  },
  {
    eiaid: 5860,
    name: "Empire District Electric Co",
    type: "Bundled",
    state: "AR",
    thenumber: 0.11657859218081577,
  },
  {
    eiaid: 814,
    name: "Entergy Arkansas LLC",
    type: "Bundled",
    state: "AR",
    thenumber: 0.0985213560778662,
  },
  {
    eiaid: 6206,
    name: "Farmers Electric Coop Corp - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.11762023827033387,
  },
  {
    eiaid: 6342,
    name: "First Electric Coop Corp",
    type: "Bundled",
    state: "AR",
    thenumber: 0.10392018049574771,
  },
  {
    eiaid: 12681,
    name: "Mississippi County Electric Co",
    type: "Bundled",
    state: "AR",
    thenumber: 0.07299232384427386,
  },
  {
    eiaid: 13676,
    name: "North Arkansas Elec Coop, Inc",
    type: "Bundled",
    state: "AR",
    thenumber: 0.11401513783229902,
  },
  {
    eiaid: 14063,
    name: "Oklahoma Gas & Electric Co",
    type: "Bundled",
    state: "AR",
    thenumber: 0.08298754793550754,
  },
  {
    eiaid: 14238,
    name: "Ouachita Electric Coop Corp",
    type: "Bundled",
    state: "AR",
    thenumber: 0.13240893449799443,
  },
  {
    eiaid: 14289,
    name: "Ozarks Electric Coop Corp - (AR)",
    type: "Bundled",
    state: "AR",
    thenumber: 0.10522883721742123,
  },
  {
    eiaid: 14446,
    name: "Paragould Light & Water Comm",
    type: "Bundled",
    state: "AR",
    thenumber: 0.07659437468902743,
  },
  {
    eiaid: 14864,
    name: "Petit Jean Electric Coop Corp",
    type: "Bundled",
    state: "AR",
    thenumber: 0.1129967334907295,
  },
  {
    eiaid: 15811,
    name: "Rich Mountain Elec Coop, Inc",
    type: "Bundled",
    state: "AR",
    thenumber: 0.10792726483592995,
  },
  {
    eiaid: 17540,
    name: "South Central Ark El Coop, Inc",
    type: "Bundled",
    state: "AR",
    thenumber: 0.11977442574847554,
  },
  {
    eiaid: 17671,
    name: "Southwest Arkansas E C C",
    type: "Bundled",
    state: "AR",
    thenumber: 0.1051241450414455,
  },
  {
    eiaid: 17698,
    name: "Southwestern Electric Power Co",
    type: "Bundled",
    state: "AR",
    thenumber: 0.08625225597786271,
  },
  {
    eiaid: 20963,
    name: "Woodruff Electric Coop Corp",
    type: "Bundled",
    state: "AR",
    thenumber: 0.10423031857886174,
  },
  {
    eiaid: 803,
    name: "Arizona Public Service Co",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.13598849910377656,
  },
  {
    eiaid: 12351,
    name: "City of Mesa - (AZ)",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.10443904747324324,
  },
  {
    eiaid: 16538,
    name: "City of Safford - (AZ)",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.10503676286700345,
  },
  {
    eiaid: 56535,
    name: "City of Williams - AZ",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.11226876461832873,
  },
  {
    eiaid: 3990,
    name: "Colorado River Indian Irr Proj",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.05891504605936541,
  },
  {
    eiaid: 40165,
    name: "Dixie Escalante R E A, Inc",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.08429825208348699,
  },
  {
    eiaid: 5438,
    name: "Duncan Valley Elec Coop, Inc",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.13385630681110697,
  },
  {
    eiaid: 15048,
    name: "Electrical Dist No2 Pinal County",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.12340399659519273,
  },
  {
    eiaid: 30518,
    name: "Electrical Dist No3 Pinal County",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.13514129291223556,
  },
  {
    eiaid: 15049,
    name: "Electrical Dist No4 Pinal County",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.12458013736401465,
  },
  {
    eiaid: 6957,
    name: "Garkane Energy Coop, Inc",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.11122751430907604,
  },
  {
    eiaid: 7456,
    name: "Graham County Electric Coop Inc",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.12813584952242812,
  },
  {
    eiaid: 8700,
    name: "Hohokam Irr & Drain Dist",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.11916515112150342,
  },
  {
    eiaid: 21538,
    name: "Mohave Electric Cooperative, I",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.1098647128050543,
  },
  {
    eiaid: 12919,
    name: "Morenci Water and Electric",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.08410510445832436,
  },
  {
    eiaid: 13314,
    name: "Navajo Tribal Utility Authority",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.13258802565003056,
  },
  {
    eiaid: 13318,
    name: "Navopache Electric Coop, Inc",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.13150967243336364,
  },
  {
    eiaid: 14373,
    name: "Page Utility Enterprises",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.07712413662598165,
  },
  {
    eiaid: 16572,
    name: "Salt River Project",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.11561729634130052,
  },
  {
    eiaid: 59943,
    name: "Spruce Finance",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.127933015285756,
  },
  {
    eiaid: 18280,
    name: "Sulphur Springs Valley E C Inc",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.14127569556726502,
  },
  {
    eiaid: 14543,
    name: "Tohono O'Odham Utility Authority",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.12575315840621962,
  },
  {
    eiaid: 18805,
    name: "Town of Thatcher- (AZ)",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.10608190422640802,
  },
  {
    eiaid: 20618,
    name: "Town of Wickenburg",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.09986784972626014,
  },
  {
    eiaid: 19189,
    name: "Trico Electric Cooperative Inc",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.14346941159832008,
  },
  {
    eiaid: 24211,
    name: "Tucson Electric Power Co",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.12185460296498311,
  },
  {
    eiaid: 19728,
    name: "UNS Electric, Inc",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.09305257672031911,
  },
  {
    eiaid: 19604,
    name: "USBIA-San Carlos Project",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.1358187883856232,
  },
  {
    eiaid: 25060,
    name: "Wellton-Mohawk Irr & Drain Dist",
    type: "Bundled",
    state: "AZ",
    thenumber: 0.11591719700678368,
  },
  {
    eiaid: 694,
    name: "Anza Electric Coop Inc",
    type: "Bundled",
    state: "CA",
    thenumber: 0.18677612958911258,
  },
  {
    eiaid: 60758,
    name: "Apple Valley Choice Energy",
    type: "Energy",
    state: "CA",
    thenumber: 0.07272273987970956,
  },
  {
    eiaid: 17612,
    name: "Bear Valley Electric Service",
    type: "Bundled",
    state: "CA",
    thenumber: 0.2640476645638183,
  },
  {
    eiaid: 16612,
    name: "City & County of San Francisco",
    type: "Bundled",
    state: "CA",
    thenumber: 0.18938143373829175,
  },
  {
    eiaid: 207,
    name: "City of Alameda",
    type: "Bundled",
    state: "CA",
    thenumber: 0.19845921089302065,
  },
  {
    eiaid: 590,
    name: "City of Anaheim - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.16984626434324607,
  },
  {
    eiaid: 1050,
    name: "City of Azusa",
    type: "Bundled",
    state: "CA",
    thenumber: 0.13332570120206066,
  },
  {
    eiaid: 1192,
    name: "City of Banning - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.18956903816058746,
  },
  {
    eiaid: 2507,
    name: "City of Burbank Water and Power",
    type: "Bundled",
    state: "CA",
    thenumber: 0.15805759020835006,
  },
  {
    eiaid: 4003,
    name: "City of Colton - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.15647832068069495,
  },
  {
    eiaid: 4390,
    name: "City of Corona - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.14879710749548047,
  },
  {
    eiaid: 7294,
    name: "City of Glendale - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.2005634353907855,
  },
  {
    eiaid: 7677,
    name: "City of Gridley - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.21399149166047673,
  },
  {
    eiaid: 8348,
    name: "City of Healdsburg - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.18392659330920907,
  },
  {
    eiaid: 11124,
    name: "City of Lodi - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.18747585719242882,
  },
  {
    eiaid: 11148,
    name: "City of Lompoc - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.15396532093995557,
  },
  {
    eiaid: 55787,
    name: "City of Moreno Valley - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.21225819186735095,
  },
  {
    eiaid: 13149,
    name: "City of Needles - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.1092997108898169,
  },
  {
    eiaid: 14401,
    name: "City of Palo Alto - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.15958433605988467,
  },
  {
    eiaid: 14534,
    name: "City of Pasadena - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.2010107218246409,
  },
  {
    eiaid: 15783,
    name: "City of Redding - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.16658881837480735,
  },
  {
    eiaid: 16088,
    name: "City of Riverside - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.1626831915657284,
  },
  {
    eiaid: 16295,
    name: "City of Roseville - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.15690691905301193,
  },
  {
    eiaid: 16655,
    name: "City of Santa Clara - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.12630534967188398,
  },
  {
    eiaid: 17896,
    name: "City of Shasta Lake - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.16307819525556877,
  },
  {
    eiaid: 61503,
    name: "City of Solana Beach",
    type: "Energy",
    state: "CA",
    thenumber: 0.06782626249032736,
  },
  {
    eiaid: 19397,
    name: "City of Ukiah - (CA)",
    type: "Bundled",
    state: "CA",
    thenumber: 0.15003557939679238,
  },
  {
    eiaid: 61526,
    name: "Clean Power Alliance of Southern Califor",
    type: "Energy",
    state: "CA",
    thenumber: 0.06959576952004289,
  },
  {
    eiaid: 60181,
    name: "CleanPowerSF",
    type: "Energy",
    state: "CA",
    thenumber: 0.07862185776226864,
  },
  {
    eiaid: 3990,
    name: "Colorado River Indian Irr Proj",
    type: "Bundled",
    state: "CA",
    thenumber: 0.07527453271028038,
  },
  {
    eiaid: 4100,
    name: "Commerce Energy, Inc.",
    type: "Energy",
    state: "CA",
    thenumber: 0.13035300618451534,
  },
  {
    eiaid: 61475,
    name: "East Bay Community Energy",
    type: "Energy",
    state: "CA",
    thenumber: 0.07846643564758624,
  },
  {
    eiaid: 60025,
    name: "Greenbacker Renewable Energy Corporation",
    type: "Bundled",
    state: "CA",
    thenumber: 0.18674761854914687,
  },
  {
    eiaid: 9216,
    name: "Imperial Irrigation District",
    type: "Bundled",
    state: "CA",
    thenumber: 0.12320712956041217,
  },
  {
    eiaid: 59625,
    name: "Lancaster Choice Energy",
    type: "Energy",
    state: "CA",
    thenumber: 0.0747760672141487,
  },
  {
    eiaid: 10724,
    name: "Lassen Municipal Utility District",
    type: "Bundled",
    state: "CA",
    thenumber: 0.16751076221828312,
  },
  {
    eiaid: 62779,
    name: "Lathrop Irrigation District",
    type: "Bundled",
    state: "CA",
    thenumber: 0.19285714285714287,
  },
  {
    eiaid: 57483,
    name: "Liberty Utilities",
    type: "Bundled",
    state: "CA",
    thenumber: 0.13423611377615277,
  },
  {
    eiaid: 11208,
    name: "Los Angeles Department of Water & Power",
    type: "Bundled",
    state: "CA",
    thenumber: 0.19990204570495698,
  },
  {
    eiaid: 56692,
    name: "Marin Clean Energy",
    type: "Energy",
    state: "CA",
    thenumber: 0.07560992373345246,
  },
  {
    eiaid: 12312,
    name: "Merced Irrigation District",
    type: "Bundled",
    state: "CA",
    thenumber: 0.16652266415474315,
  },
  {
    eiaid: 12745,
    name: "Modesto Irrigation District",
    type: "Bundled",
    state: "CA",
    thenumber: 0.17375556861522484,
  },
  {
    eiaid: 61432,
    name: "Monterey Bay Community Power",
    type: "Energy",
    state: "CA",
    thenumber: 0.08116677290400116,
  },
  {
    eiaid: 14328,
    name: "Pacific Gas & Electric Co.",
    type: "Delivery",
    state: "CA",
    thenumber: 0.1454820270704478,
  },
  {
    eiaid: 14328,
    name: "Pacific Gas & Electric Co.",
    type: "Bundled",
    state: "CA",
    thenumber: 0.22349103734200584,
  },
  {
    eiaid: 14354,
    name: "PacifiCorp",
    type: "Bundled",
    state: "CA",
    thenumber: 0.12873534759990865,
  },
  {
    eiaid: 60402,
    name: "Peninsula Clean Energy Authority",
    type: "Energy",
    state: "CA",
    thenumber: 0.07442004613957037,
  },
  {
    eiaid: 61083,
    name: "Pico Rivera Innovative Municipal Energy",
    type: "Energy",
    state: "CA",
    thenumber: 0.07453509125453393,
  },
  {
    eiaid: 61431,
    name: "Pioneer Community Energy",
    type: "Energy",
    state: "CA",
    thenumber: 0.06668243482961937,
  },
  {
    eiaid: 15308,
    name: "Plumas-Sierra Rural Elec Coop",
    type: "Bundled",
    state: "CA",
    thenumber: 0.18938434033985385,
  },
  {
    eiaid: 61471,
    name: "Rancho Mirage Energy Authority",
    type: "Energy",
    state: "CA",
    thenumber: 0.07478456895611388,
  },
  {
    eiaid: 60868,
    name: "Redwood Coast Energy Authority",
    type: "Energy",
    state: "CA",
    thenumber: 0.07743852668095093,
  },
  {
    eiaid: 16534,
    name: "Sacramento Municipal Util Dist",
    type: "Bundled",
    state: "CA",
    thenumber: 0.14914679205911105,
  },
  {
    eiaid: 16609,
    name: "San Diego Gas & Electric Co",
    type: "Delivery",
    state: "CA",
    thenumber: 0.20116161347045344,
  },
  {
    eiaid: 16609,
    name: "San Diego Gas & Electric Co",
    type: "Bundled",
    state: "CA",
    thenumber: 0.25779984740828116,
  },
  {
    eiaid: 61433,
    name: "San Jacinto Power",
    type: "Energy",
    state: "CA",
    thenumber: 0.0741830488015175,
  },
  {
    eiaid: 61858,
    name: "San Jose Clean Energy",
    type: "Energy",
    state: "CA",
    thenumber: 0.08606831298735233,
  },
  {
    eiaid: 60759,
    name: "Silicon Valley Clean Energy Authority",
    type: "Energy",
    state: "CA",
    thenumber: 0.07321476920794466,
  },
  {
    eiaid: 59126,
    name: "Sonoma Clean Power Authority",
    type: "Energy",
    state: "CA",
    thenumber: 0.07257750215229018,
  },
  {
    eiaid: 17609,
    name: "Southern California Edison Co",
    type: "Delivery",
    state: "CA",
    thenumber: 0.1027491342340612,
  },
  {
    eiaid: 17609,
    name: "Southern California Edison Co",
    type: "Bundled",
    state: "CA",
    thenumber: 0.16208757847340743,
  },
  {
    eiaid: 59943,
    name: "Spruce Finance",
    type: "Bundled",
    state: "CA",
    thenumber: 0.20225346687211093,
  },
  {
    eiaid: 18260,
    name: "Surprise Valley Electrification",
    type: "Bundled",
    state: "CA",
    thenumber: 0.0981844186928982,
  },
  {
    eiaid: 19131,
    name: "Trinity Public Utilities Dist",
    type: "Bundled",
    state: "CA",
    thenumber: 0.09379036419646906,
  },
  {
    eiaid: 19229,
    name: "Truckee Donner P U D",
    type: "Bundled",
    state: "CA",
    thenumber: 0.16563334739209898,
  },
  {
    eiaid: 19281,
    name: "Turlock Irrigation District",
    type: "Bundled",
    state: "CA",
    thenumber: 0.16053145261231935,
  },
  {
    eiaid: 61462,
    name: "Valley Clean Energy Alliance",
    type: "Energy",
    state: "CA",
    thenumber: 0.08247677574485593,
  },
  {
    eiaid: 56146,
    name: "Black Hills Colorado Electric, LLC",
    type: "Bundled",
    state: "CO",
    thenumber: 0.16378930307941653,
  },
  {
    eiaid: 918,
    name: "City of Aspen- (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.1378371690175699,
  },
  {
    eiaid: 2550,
    name: "City of Burlington - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.158247080049378,
  },
  {
    eiaid: 3227,
    name: "City of Center - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.14924879807692307,
  },
  {
    eiaid: 3989,
    name: "City of Colorado Springs - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.12811071231824783,
  },
  {
    eiaid: 5036,
    name: "City of Delta - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.13106924788185734,
  },
  {
    eiaid: 6604,
    name: "City of Fort Collins - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.10864164034959971,
  },
  {
    eiaid: 6610,
    name: "City of Fort Morgan",
    type: "Bundled",
    state: "CO",
    thenumber: 0.062277550338374135,
  },
  {
    eiaid: 6638,
    name: "City of Fountain",
    type: "Bundled",
    state: "CO",
    thenumber: 0.09433618156641561,
  },
  {
    eiaid: 7300,
    name: "City of Glenwood Springs - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.10905981186176367,
  },
  {
    eiaid: 7826,
    name: "City of Gunnison - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.10587555105875551,
  },
  {
    eiaid: 8777,
    name: "City of Holyoke - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.12372865540729681,
  },
  {
    eiaid: 10536,
    name: "City of La Junta - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.15220783989718167,
  },
  {
    eiaid: 10633,
    name: "City of Lamar - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.18716212017647424,
  },
  {
    eiaid: 10730,
    name: "City of Las Animas - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.16732629727352683,
  },
  {
    eiaid: 11187,
    name: "City of Longmont",
    type: "Bundled",
    state: "CO",
    thenumber: 0.09734064621574051,
  },
  {
    eiaid: 11256,
    name: "City of Loveland - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.11289756763888673,
  },
  {
    eiaid: 19204,
    name: "City of Trinidad - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.17142508639967471,
  },
  {
    eiaid: 21021,
    name: "City of Wray",
    type: "Bundled",
    state: "CO",
    thenumber: 0.13435148295706065,
  },
  {
    eiaid: 21143,
    name: "City of Yuma - (CO)",
    type: "Bundled",
    state: "CO",
    thenumber: 0.104959630911188,
  },
  {
    eiaid: 5086,
    name: "Delta Montrose Electric Assn",
    type: "Bundled",
    state: "CO",
    thenumber: 0.14287033275473648,
  },
  {
    eiaid: 5862,
    name: "Empire Electric Assn, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.14979216183106997,
  },
  {
    eiaid: 7563,
    name: "Grand Valley Power",
    type: "Bundled",
    state: "CO",
    thenumber: 0.13620203552689802,
  },
  {
    eiaid: 7787,
    name: "Gunnison County Elec Assn.",
    type: "Bundled",
    state: "CO",
    thenumber: 0.17214881334188584,
  },
  {
    eiaid: 8570,
    name: "Highline Electric Assn",
    type: "Bundled",
    state: "CO",
    thenumber: 0.13269705233086612,
  },
  {
    eiaid: 8773,
    name: "Holy Cross Electric Assn, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.11700136260738107,
  },
  {
    eiaid: 9336,
    name: "Intermountain Rural Elec Assn",
    type: "Bundled",
    state: "CO",
    thenumber: 0.1312209277459307,
  },
  {
    eiaid: 10066,
    name: "K C Electric Association",
    type: "Bundled",
    state: "CO",
    thenumber: 0.13156231832773047,
  },
  {
    eiaid: 10539,
    name: "La Plata Electric Assn, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.15323749461396843,
  },
  {
    eiaid: 12866,
    name: "Moon Lake Electric Assn Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.08029665133752187,
  },
  {
    eiaid: 12860,
    name: "Morgan County Rural Elec Assn",
    type: "Bundled",
    state: "CO",
    thenumber: 0.13758653003234486,
  },
  {
    eiaid: 13050,
    name: "Mountain Parks Electric, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.14048967917359734,
  },
  {
    eiaid: 13058,
    name: "Mountain View Elec Assn, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.14569834709233698,
  },
  {
    eiaid: 15257,
    name: "Poudre Valley REA, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.12016194405527714,
  },
  {
    eiaid: 15466,
    name: "Public Service Co of Colorado",
    type: "Bundled",
    state: "CO",
    thenumber: 0.11093844511257246,
  },
  {
    eiaid: 16616,
    name: "San Isabel Electric Assn, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.1643470711870602,
  },
  {
    eiaid: 16603,
    name: "San Luis Valley R E C, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.17900596877869604,
  },
  {
    eiaid: 16622,
    name: "San Miguel Power Assn, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.15630234713063504,
  },
  {
    eiaid: 16649,
    name: "Sangre De Cristo Elec Assn Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.17334881901895022,
  },
  {
    eiaid: 17592,
    name: "Southeast Colorado Power Assn",
    type: "Bundled",
    state: "CO",
    thenumber: 0.16781610869216992,
  },
  {
    eiaid: 5997,
    name: "Town of Estes Park",
    type: "Bundled",
    state: "CO",
    thenumber: 0.1481279083246394,
  },
  {
    eiaid: 19499,
    name: "United Power, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.1304988163248979,
  },
  {
    eiaid: 20576,
    name: "White River Electric Assn, Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.14441496147369495,
  },
  {
    eiaid: 21075,
    name: "Y-W Electric Assn Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.14931362659532477,
  },
  {
    eiaid: 21081,
    name: "Yampa Valley Electric Assn Inc",
    type: "Bundled",
    state: "CO",
    thenumber: 0.12430530433685273,
  },
  {
    eiaid: 59472,
    name: "Agera Energy LLC",
    type: "Energy",
    state: "CT",
    thenumber: 0.09522398672671249,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "CT",
    thenumber: 0.10001855518783664,
  },
  {
    eiaid: 2089,
    name: "Bozrah Light & Power Company",
    type: "Bundled",
    state: "CT",
    thenumber: 0.1833629576964095,
  },
  {
    eiaid: 59055,
    name: "Choice Energy, LLC d/b/a 4 Choice Energy",
    type: "Energy",
    state: "CT",
    thenumber: 0.14642619680851063,
  },
  {
    eiaid: 9734,
    name: "City of Jewett City - (CT)",
    type: "Bundled",
    state: "CT",
    thenumber: 0.17835243553008598,
  },
  {
    eiaid: 13831,
    name: "City of Norwich - (CT)",
    type: "Bundled",
    state: "CT",
    thenumber: 0.19888324128830456,
  },
  {
    eiaid: 17569,
    name: "City of South Norwalk - (CT)",
    type: "Bundled",
    state: "CT",
    thenumber: 0.15332718312252677,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "CT",
    thenumber: 0.10343018417339074,
  },
  {
    eiaid: 4176,
    name: "Connecticut Light & Power Co",
    type: "Delivery",
    state: "CT",
    thenumber: 0.12115404268957224,
  },
  {
    eiaid: 4176,
    name: "Connecticut Light & Power Co",
    type: "Bundled",
    state: "CT",
    thenumber: 0.21221578015598425,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "CT",
    thenumber: 0.09859069105666651,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "CT",
    thenumber: 0.09857704876946871,
  },
  {
    eiaid: 59993,
    name: "Discount Power Inc - (CT)",
    type: "Energy",
    state: "CT",
    thenumber: 0.09963994503896416,
  },
  {
    eiaid: 56265,
    name: "Energy Plus Holdings LLC",
    type: "Energy",
    state: "CT",
    thenumber: 0.14135973682513062,
  },
  {
    eiaid: 55874,
    name: "Energy Services Providers, Inc",
    type: "Energy",
    state: "CT",
    thenumber: 0.11394306209370096,
  },
  {
    eiaid: 57067,
    name: "ENGIE Retail, LLC",
    type: "Energy",
    state: "CT",
    thenumber: 0.0937886920079406,
  },
  {
    eiaid: 7716,
    name: "Groton Dept of Utilities - (CT)",
    type: "Bundled",
    state: "CT",
    thenumber: 0.15946572249793628,
  },
  {
    eiaid: 55781,
    name: "Liberty Power Corp.",
    type: "Energy",
    state: "CT",
    thenumber: 0.11505000650735331,
  },
  {
    eiaid: 56504,
    name: "Major Energy Electric Services",
    type: "Energy",
    state: "CT",
    thenumber: 0.09827571562048755,
  },
  {
    eiaid: 58799,
    name: "Mega Energy of New England, LLC",
    type: "Energy",
    state: "CT",
    thenumber: 0.10423178648713632,
  },
  {
    eiaid: 58667,
    name: "North American Power and Gas, LLC",
    type: "Energy",
    state: "CT",
    thenumber: 0.11883312782572955,
  },
  {
    eiaid: 13825,
    name: "Norwalk Third Taxing District",
    type: "Bundled",
    state: "CT",
    thenumber: 0.1669735803675448,
  },
  {
    eiaid: 58119,
    name: "Public Power LLC (CT)",
    type: "Energy",
    state: "CT",
    thenumber: 0.10290826428100022,
  },
  {
    eiaid: 57037,
    name: "Reliant Energy Northeast LLC",
    type: "Energy",
    state: "CT",
    thenumber: 0.12663272867867384,
  },
  {
    eiaid: 17710,
    name: "Spark Energy, LP",
    type: "Energy",
    state: "CT",
    thenumber: 0.10678875223492441,
  },
  {
    eiaid: 59794,
    name: "Starion Energy, Inc.",
    type: "Energy",
    state: "CT",
    thenumber: 0.09835010783608913,
  },
  {
    eiaid: 59127,
    name: "Sunwave Gas & Power Connecticut, Inc.",
    type: "Energy",
    state: "CT",
    thenumber: 0.10183807211299967,
  },
  {
    eiaid: 20038,
    name: "Town of Wallingford - (CT)",
    type: "Bundled",
    state: "CT",
    thenumber: 0.1411164184247808,
  },
  {
    eiaid: 59059,
    name: "Town Square Energy",
    type: "Energy",
    state: "CT",
    thenumber: 0.09156988938370925,
  },
  {
    eiaid: 19497,
    name: "United Illuminating Co",
    type: "Delivery",
    state: "CT",
    thenumber: 0.15559072195317714,
  },
  {
    eiaid: 19497,
    name: "United Illuminating Co",
    type: "Bundled",
    state: "CT",
    thenumber: 0.25225584836808884,
  },
  {
    eiaid: 59620,
    name: "Verde Energy USA",
    type: "Energy",
    state: "CT",
    thenumber: 0.1120531457921122,
  },
  {
    eiaid: 58161,
    name: "Viridian Energy LLC",
    type: "Energy",
    state: "CT",
    thenumber: 0.12793378657995863,
  },
  {
    eiaid: 57487,
    name: "XOOM Energy Connecticut, LLC",
    type: "Energy",
    state: "CT",
    thenumber: 0.09840796590409571,
  },
  {
    eiaid: 58951,
    name: "CleanChoice Energy, Inc.",
    type: "Energy",
    state: "DC",
    thenumber: 0.1295101661903635,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "DC",
    thenumber: 0.07720966819221968,
  },
  {
    eiaid: 55815,
    name: "IDT Energy, Inc.",
    type: "Energy",
    state: "DC",
    thenumber: 0.1372390838316597,
  },
  {
    eiaid: 56504,
    name: "Major Energy Electric Services",
    type: "Energy",
    state: "DC",
    thenumber: 0.1219208211143695,
  },
  {
    eiaid: 15270,
    name: "Potomac Electric Power Co",
    type: "Delivery",
    state: "DC",
    thenumber: 0.04635038532209343,
  },
  {
    eiaid: 15270,
    name: "Potomac Electric Power Co",
    type: "Bundled",
    state: "DC",
    thenumber: 0.1217077571887078,
  },
  {
    eiaid: 58119,
    name: "Public Power LLC (CT)",
    type: "Energy",
    state: "DC",
    thenumber: 0.15154046997389034,
  },
  {
    eiaid: 58953,
    name: "Star Energy Partners, LLC",
    type: "Energy",
    state: "DC",
    thenumber: 0.10278754752851711,
  },
  {
    eiaid: 20659,
    name: "WGL Energy Services, Inc.",
    type: "Energy",
    state: "DC",
    thenumber: 0.09183392906241408,
  },
  {
    eiaid: 59312,
    name: "XOOM Energy Washington DC, LLC",
    type: "Energy",
    state: "DC",
    thenumber: 0.11418361269463116,
  },
  {
    eiaid: 5335,
    name: "City of Dover - (DE)",
    type: "Bundled",
    state: "DE",
    thenumber: 0.1252994538337123,
  },
  {
    eiaid: 10935,
    name: "City of Lewes - (DE)",
    type: "Bundled",
    state: "DE",
    thenumber: 0.12358181237849288,
  },
  {
    eiaid: 12540,
    name: "City of Milford - (DE)",
    type: "Bundled",
    state: "DE",
    thenumber: 0.13334721364674124,
  },
  {
    eiaid: 13519,
    name: "City of Newark - (DE)",
    type: "Bundled",
    state: "DE",
    thenumber: 0.15501519756838905,
  },
  {
    eiaid: 16852,
    name: "City of Seaford- (DE)",
    type: "Bundled",
    state: "DE",
    thenumber: 0.12757150675461587,
  },
  {
    eiaid: 58951,
    name: "CleanChoice Energy, Inc.",
    type: "Energy",
    state: "DE",
    thenumber: 0.11538393095471126,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "DE",
    thenumber: 0.14304827739993312,
  },
  {
    eiaid: 4100,
    name: "Commerce Energy, Inc.",
    type: "Energy",
    state: "DE",
    thenumber: 0.10352616388505459,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "DE",
    thenumber: 0.061930564647125846,
  },
  {
    eiaid: 5070,
    name: "Delaware Electric Cooperative",
    type: "Bundled",
    state: "DE",
    thenumber: 0.12020810238839061,
  },
  {
    eiaid: 5027,
    name: "Delmarva Power",
    type: "Delivery",
    state: "DE",
    thenumber: 0.05517088686751916,
  },
  {
    eiaid: 5027,
    name: "Delmarva Power",
    type: "Bundled",
    state: "DE",
    thenumber: 0.12356492556614797,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "DE",
    thenumber: 0.09262326567613663,
  },
  {
    eiaid: 60219,
    name: "LifeEnergy, LLC",
    type: "Energy",
    state: "DE",
    thenumber: 0.09486613624484581,
  },
  {
    eiaid: 13424,
    name: "New Castle Municipal Serv Comm",
    type: "Bundled",
    state: "DE",
    thenumber: 0.11889363976483165,
  },
  {
    eiaid: 57037,
    name: "Reliant Energy Northeast LLC",
    type: "Energy",
    state: "DE",
    thenumber: 0.10150952159777055,
  },
  {
    eiaid: 58956,
    name: "SmartEnergy Holdings, LLC",
    type: "Energy",
    state: "DE",
    thenumber: 0.11110521974784966,
  },
  {
    eiaid: 3732,
    name: "Town of Clayton",
    type: "Bundled",
    state: "DE",
    thenumber: 0.14377657886385273,
  },
  {
    eiaid: 12478,
    name: "Town of Middletown - (DE)",
    type: "Bundled",
    state: "DE",
    thenumber: 0.1386134205927855,
  },
  {
    eiaid: 17457,
    name: "Town of Smyrna - (DE)",
    type: "Bundled",
    state: "DE",
    thenumber: 0.1393744940470314,
  },
  {
    eiaid: 20659,
    name: "WGL Energy Services, Inc.",
    type: "Energy",
    state: "DE",
    thenumber: 0.08587968488899499,
  },
  {
    eiaid: 9616,
    name: "Beaches Energy Services",
    type: "Bundled",
    state: "FL",
    thenumber: 0.10637449576982116,
  },
  {
    eiaid: 3245,
    name: "Central Florida Elec Coop, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.1278288640493365,
  },
  {
    eiaid: 3502,
    name: "Choctawhatche Elec Coop, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11985245540629817,
  },
  {
    eiaid: 201,
    name: "City of Alachua - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11594138146725047,
  },
  {
    eiaid: 1300,
    name: "City of Bartow - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.10430374734599972,
  },
  {
    eiaid: 1876,
    name: "City of Blountstown - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.12248738345736036,
  },
  {
    eiaid: 2633,
    name: "City of Bushnell - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.1280320855614973,
  },
  {
    eiaid: 3406,
    name: "City of Chattahoochee - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.12599539904441692,
  },
  {
    eiaid: 3774,
    name: "City of Clewiston",
    type: "Bundled",
    state: "FL",
    thenumber: 0.10418459282696352,
  },
  {
    eiaid: 6609,
    name: "City of Fort Meade - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.12916479801290234,
  },
  {
    eiaid: 7593,
    name: "City of Green Cove Springs",
    type: "Bundled",
    state: "FL",
    thenumber: 0.10504264551847972,
  },
  {
    eiaid: 8795,
    name: "City of Homestead - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.13898770129740912,
  },
  {
    eiaid: 10620,
    name: "City of Lake Worth - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.09165730806445972,
  },
  {
    eiaid: 10623,
    name: "City of Lakeland - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.10619113247766103,
  },
  {
    eiaid: 10868,
    name: "City of Leesburg - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11995477294371701,
  },
  {
    eiaid: 13033,
    name: "City of Mount Dora - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11682093238071899,
  },
  {
    eiaid: 13521,
    name: "City of Newberry - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11453186729496881,
  },
  {
    eiaid: 13955,
    name: "City of Ocala",
    type: "Bundled",
    state: "FL",
    thenumber: 0.09687229985400589,
  },
  {
    eiaid: 15566,
    name: "City of Quincy - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11669387755102041,
  },
  {
    eiaid: 18004,
    name: "City of Starke - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11407645738961093,
  },
  {
    eiaid: 18445,
    name: "City of Tallahassee - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11199322050969472,
  },
  {
    eiaid: 20209,
    name: "City of Wauchula - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.10347881536063483,
  },
  {
    eiaid: 20735,
    name: "City of Williston - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.12053145415840857,
  },
  {
    eiaid: 58124,
    name: "City of Winter Park - (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.1166160317831269,
  },
  {
    eiaid: 3757,
    name: "Clay Electric Cooperative, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11554155699292726,
  },
  {
    eiaid: 6455,
    name: "Duke Energy Florida, LLC",
    type: "Bundled",
    state: "FL",
    thenumber: 0.13624619496050075,
  },
  {
    eiaid: 5964,
    name: "Escambia River Elec Coop, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.13642821741285313,
  },
  {
    eiaid: 6443,
    name: "Florida Keys El Coop Assn, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11587279406365981,
  },
  {
    eiaid: 6452,
    name: "Florida Power & Light Co",
    type: "Bundled",
    state: "FL",
    thenumber: 0.1103014464631342,
  },
  {
    eiaid: 6457,
    name: "Florida Public Utilities Co",
    type: "Bundled",
    state: "FL",
    thenumber: 0.14816639799507908,
  },
  {
    eiaid: 6616,
    name: "Fort Pierce Utilities Authority",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11434006172737626,
  },
  {
    eiaid: 6909,
    name: "Gainesville Regional Utilities",
    type: "Bundled",
    state: "FL",
    thenumber: 0.13177618302745173,
  },
  {
    eiaid: 7264,
    name: "Glades Electric Coop, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.14564201807043278,
  },
  {
    eiaid: 7785,
    name: "Gulf Coast Electric Coop, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.12815843643848968,
  },
  {
    eiaid: 7801,
    name: "Gulf Power Co",
    type: "Bundled",
    state: "FL",
    thenumber: 0.13254282751939986,
  },
  {
    eiaid: 8276,
    name: "Havana Power & Light Company",
    type: "Bundled",
    state: "FL",
    thenumber: 0.10916937880288666,
  },
  {
    eiaid: 9617,
    name: "JEA",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11418090638082655,
  },
  {
    eiaid: 10376,
    name: "Kissimmee Utility Authority",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11777933589330258,
  },
  {
    eiaid: 10857,
    name: "Lee County Electric Coop, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.10459602694150734,
  },
  {
    eiaid: 13485,
    name: "New Smyrna Beach City of",
    type: "Bundled",
    state: "FL",
    thenumber: 0.10446794582313754,
  },
  {
    eiaid: 31833,
    name: "Okefenoke Rural El Member Corp",
    type: "Bundled",
    state: "FL",
    thenumber: 0.12423648443211002,
  },
  {
    eiaid: 14610,
    name: "Orlando Utilities Comm",
    type: "Bundled",
    state: "FL",
    thenumber: 0.12057629737473674,
  },
  {
    eiaid: 14606,
    name: "Peace River Electric Coop, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.12723499729846058,
  },
  {
    eiaid: 18304,
    name: "Sumter Electric Coop, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11924495038174808,
  },
  {
    eiaid: 18360,
    name: "Suwannee Valley Elec Coop Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.12809261424086796,
  },
  {
    eiaid: 18449,
    name: "Talquin Electric Coop, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.13467122765920278,
  },
  {
    eiaid: 18454,
    name: "Tampa Electric Co",
    type: "Bundled",
    state: "FL",
    thenumber: 0.10931701807008926,
  },
  {
    eiaid: 19161,
    name: "Tri-County Electric Coop, Inc (FL)",
    type: "Bundled",
    state: "FL",
    thenumber: 0.1433516249182565,
  },
  {
    eiaid: 10226,
    name: "Utility Board of the City of Key West, F",
    type: "Bundled",
    state: "FL",
    thenumber: 0.12848163358128636,
  },
  {
    eiaid: 20371,
    name: "West Florida El Coop Assn, Inc",
    type: "Bundled",
    state: "FL",
    thenumber: 0.13358295180015695,
  },
  {
    eiaid: 20885,
    name: "Withlacoochee River Elec Coop",
    type: "Bundled",
    state: "FL",
    thenumber: 0.11796694263000393,
  },
  {
    eiaid: 230,
    name: "Albany Utility Board",
    type: "Bundled",
    state: "GA",
    thenumber: 0.10762847040189177,
  },
  {
    eiaid: 407,
    name: "Altamaha Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.13566293664497278,
  },
  {
    eiaid: 562,
    name: "Amicalola Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11226826616682285,
  },
  {
    eiaid: 1891,
    name: "Blue Ridge Mountain EMC - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1291515774225606,
  },
  {
    eiaid: 2903,
    name: "Canoochee Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12381365926516655,
  },
  {
    eiaid: 3081,
    name: "Carroll Electric Member Corp - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11975132418523411,
  },
  {
    eiaid: 3248,
    name: "Central Georgia El Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.10189575732004928,
  },
  {
    eiaid: 308,
    name: "City of Acworth - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1430354190512527,
  },
  {
    eiaid: 123,
    name: "City of Adel- (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1429134563698262,
  },
  {
    eiaid: 1232,
    name: "City of Barnesville - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11362323028026582,
  },
  {
    eiaid: 1826,
    name: "City of Blakely - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.10826050183253454,
  },
  {
    eiaid: 2487,
    name: "City of Buford",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11682310678486586,
  },
  {
    eiaid: 2773,
    name: "City of Cairo - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12876791421796063,
  },
  {
    eiaid: 2812,
    name: "City of Calhoun - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11632947976878613,
  },
  {
    eiaid: 2910,
    name: "City of Camilla",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12150216400474932,
  },
  {
    eiaid: 3108,
    name: "City of Cartersville - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.10956267396329669,
  },
  {
    eiaid: 3408,
    name: "City of Chattanooga - (TN)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1088677562745485,
  },
  {
    eiaid: 3475,
    name: "City of Chickamauga",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11256335022672713,
  },
  {
    eiaid: 3939,
    name: "City of College Park - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11406648529161707,
  },
  {
    eiaid: 4091,
    name: "City of Commerce - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12241874576380596,
  },
  {
    eiaid: 4433,
    name: "City of Covington - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.114930740170701,
  },
  {
    eiaid: 5325,
    name: "City of Douglas",
    type: "Bundled",
    state: "GA",
    thenumber: 0.09988036343647945,
  },
  {
    eiaid: 5582,
    name: "City of East Point - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.13592869472508504,
  },
  {
    eiaid: 5730,
    name: "City of Elberton",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12258992451995182,
  },
  {
    eiaid: 5796,
    name: "City of Ellaville",
    type: "Bundled",
    state: "GA",
    thenumber: 0.15731114889482634,
  },
  {
    eiaid: 6130,
    name: "City of Fairburn - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12517020144005828,
  },
  {
    eiaid: 6598,
    name: "City of Forsyth - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12697422214571588,
  },
  {
    eiaid: 7515,
    name: "City of Grantville - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1265035118525022,
  },
  {
    eiaid: 7679,
    name: "City of Griffin - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12327484939286006,
  },
  {
    eiaid: 8698,
    name: "City of Hogansville - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.13037449632614362,
  },
  {
    eiaid: 9573,
    name: "City of Jackson - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1265597147950089,
  },
  {
    eiaid: 10585,
    name: "City of La Grange - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.10937799890747493,
  },
  {
    eiaid: 10571,
    name: "City of LaFayette - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12063049274217438,
  },
  {
    eiaid: 10800,
    name: "City of Lawrenceville - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11961110052016667,
  },
  {
    eiaid: 11646,
    name: "City of Marietta - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11714054667842562,
  },
  {
    eiaid: 12800,
    name: "City of Monroe - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1300212705423311,
  },
  {
    eiaid: 12851,
    name: "City of Monticello - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1354101234387554,
  },
  {
    eiaid: 13026,
    name: "City of Moultrie - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11836974426831574,
  },
  {
    eiaid: 13646,
    name: "City of Norcross- (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12221845702021913,
  },
  {
    eiaid: 40369,
    name: "City of Oxford - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.14085035431429763,
  },
  {
    eiaid: 15573,
    name: "City of Quitman",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1359535350888685,
  },
  {
    eiaid: 16637,
    name: "City of Sandersville - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.13384683915512824,
  },
  {
    eiaid: 18397,
    name: "City of Sylvania - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.13081135809287128,
  },
  {
    eiaid: 18403,
    name: "City of Sylvester - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.28647250952640174,
  },
  {
    eiaid: 18847,
    name: "City of Thomaston - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.10996533249290892,
  },
  {
    eiaid: 18848,
    name: "City of Thomasville - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11867817010172019,
  },
  {
    eiaid: 20140,
    name: "City of Washington - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.14541783621411336,
  },
  {
    eiaid: 20393,
    name: "City of West Point - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1239885612488866,
  },
  {
    eiaid: 3843,
    name: "Coastal Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.13922345913657344,
  },
  {
    eiaid: 3916,
    name: "Cobb Electric Membership Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11099248868578292,
  },
  {
    eiaid: 40212,
    name: "Colquitt Electric Membership Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1071545468719679,
  },
  {
    eiaid: 4432,
    name: "Coweta-Fayette El Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1123980898175513,
  },
  {
    eiaid: 4538,
    name: "Crisp County Power Comm",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11389566417934978,
  },
  {
    eiaid: 4744,
    name: "Dalton Utilities",
    type: "Bundled",
    state: "GA",
    thenumber: 0.09362895517883194,
  },
  {
    eiaid: 19219,
    name: "Diverse Power Incorporated",
    type: "Bundled",
    state: "GA",
    thenumber: 0.14409278433910372,
  },
  {
    eiaid: 5905,
    name: "Excelsior Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.10474235591642823,
  },
  {
    eiaid: 6380,
    name: "Fitzgerald Wtr Lgt & Bond Comm",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12924618197738805,
  },
  {
    eiaid: 6411,
    name: "Flint Electric Membership Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12623476023567537,
  },
  {
    eiaid: 6617,
    name: "Fort Valley Utility Comm",
    type: "Bundled",
    state: "GA",
    thenumber: 0.09885576250715149,
  },
  {
    eiaid: 7140,
    name: "Georgia Power Co",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12097164009321629,
  },
  {
    eiaid: 7450,
    name: "Grady Electric Membership Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1273526163678658,
  },
  {
    eiaid: 7090,
    name: "GreyStone Power Corporation",
    type: "Bundled",
    state: "GA",
    thenumber: 0.10084175590445701,
  },
  {
    eiaid: 7887,
    name: "Habersham Electric Membership Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.13091078327805153,
  },
  {
    eiaid: 8210,
    name: "Hart Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1314695519186978,
  },
  {
    eiaid: 9431,
    name: "Irwin Electric Membership Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.14075081729819866,
  },
  {
    eiaid: 9601,
    name: "Jackson Electric Member Corp - (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11121862268782105,
  },
  {
    eiaid: 9689,
    name: "Jefferson Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11991481485611069,
  },
  {
    eiaid: 26218,
    name: "Little Ocmulgee El Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.13698889518325663,
  },
  {
    eiaid: 12472,
    name: "Middle Georgia El Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12715853497289437,
  },
  {
    eiaid: 12706,
    name: "Mitchell Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.13929192159699408,
  },
  {
    eiaid: 13547,
    name: "Newnan Wtr, Sewer & Light Comm",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11928304577820825,
  },
  {
    eiaid: 13716,
    name: "North Georgia Elec Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.10901856967625415,
  },
  {
    eiaid: 13934,
    name: "Ocmulgee Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12712238315481986,
  },
  {
    eiaid: 13962,
    name: "Oconee Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.129455369845892,
  },
  {
    eiaid: 31833,
    name: "Okefenoke Rural El Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12643970363926516,
  },
  {
    eiaid: 14649,
    name: "Planters Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.13025261483882805,
  },
  {
    eiaid: 15700,
    name: "Rayle Electric Membership Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1283663658435139,
  },
  {
    eiaid: 16674,
    name: "Satilla Rural Elec Member Corporation",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11377753060445046,
  },
  {
    eiaid: 16865,
    name: "Sawnee Electric Membership Corporation",
    type: "Bundled",
    state: "GA",
    thenumber: 0.1038211803532735,
  },
  {
    eiaid: 17290,
    name: "Slash Pine Elec Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12553594639620672,
  },
  {
    eiaid: 17832,
    name: "Snapping Shoals El Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.10096755576906337,
  },
  {
    eiaid: 10624,
    name: "Southern Rivers Energy",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12626271311777068,
  },
  {
    eiaid: 18305,
    name: "Sumter Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12804489674002215,
  },
  {
    eiaid: 18499,
    name: "Three Notch Elec Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.134623356824777,
  },
  {
    eiaid: 18956,
    name: "Tri-County Elec Member Corp (GA)",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12643516420615802,
  },
  {
    eiaid: 19154,
    name: "Tri-State Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.12595591854845978,
  },
  {
    eiaid: 19581,
    name: "Upson Elec Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11283720686675801,
  },
  {
    eiaid: 20065,
    name: "Walton Electric Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11448638336679352,
  },
  {
    eiaid: 20146,
    name: "Washington Elec Member Corp",
    type: "Bundled",
    state: "GA",
    thenumber: 0.11945971853537268,
  },
  {
    eiaid: 8287,
    name: "Hawaii Electric Light Co Inc",
    type: "Bundled",
    state: "HI",
    thenumber: 0.3653123678556501,
  },
  {
    eiaid: 19547,
    name: "Hawaiian Electric Co Inc",
    type: "Bundled",
    state: "HI",
    thenumber: 0.3104342879860548,
  },
  {
    eiaid: 10071,
    name: "Kauai Island Utility Cooperative",
    type: "Bundled",
    state: "HI",
    thenumber: 0.3486654823903257,
  },
  {
    eiaid: 11843,
    name: "Maui Electric Co Ltd",
    type: "Bundled",
    state: "HI",
    thenumber: 0.3400736373298244,
  },
  {
    eiaid: 16611,
    name: "Access Energy Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1282796629392837,
  },
  {
    eiaid: 329,
    name: "Allamakee-Clayton El Coop, Inc",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1461670639566715,
  },
  {
    eiaid: 942,
    name: "Atchison-Holt Electric Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13705987666600358,
  },
  {
    eiaid: 965,
    name: "Atlantic Municipal Utilities",
    type: "Bundled",
    state: "IA",
    thenumber: 0.08433955970647099,
  },
  {
    eiaid: 13143,
    name: "Board of Water Electric & Communications",
    type: "Bundled",
    state: "IA",
    thenumber: 0.11812027474428027,
  },
  {
    eiaid: 2652,
    name: "Butler County Rural Elec Coop - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12979753878645114,
  },
  {
    eiaid: 4040,
    name: "Calhoun County Elec Coop Assn",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1405643410852713,
  },
  {
    eiaid: 3203,
    name: "Cedar Falls Utilities",
    type: "Bundled",
    state: "IA",
    thenumber: 0.09464147491379916,
  },
  {
    eiaid: 3314,
    name: "Chariton Valley Elec Coop, Inc",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12971718536222096,
  },
  {
    eiaid: 309,
    name: "City of Algona - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1420539463383389,
  },
  {
    eiaid: 405,
    name: "City of Alta - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10387250521298778,
  },
  {
    eiaid: 554,
    name: "City of Ames - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.11642962787670827,
  },
  {
    eiaid: 1515,
    name: "City of Bellevue - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10348249729339588,
  },
  {
    eiaid: 3029,
    name: "City of Carlisle- (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.14026536181424673,
  },
  {
    eiaid: 5056,
    name: "City of Denison - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.07904157536780398,
  },
  {
    eiaid: 5529,
    name: "City of Durant - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.15501604576531325,
  },
  {
    eiaid: 5998,
    name: "City of Estherville - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13350125944584382,
  },
  {
    eiaid: 6579,
    name: "City of Forest City- (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10206554878048779,
  },
  {
    eiaid: 7626,
    name: "City of Greenfield - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10473182853592876,
  },
  {
    eiaid: 8288,
    name: "City of Hawarden - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.08973770491803278,
  },
  {
    eiaid: 9230,
    name: "City of Independence - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1465723665154002,
  },
  {
    eiaid: 10606,
    name: "City of Lake Mills",
    type: "Bundled",
    state: "IA",
    thenumber: 0.11617357001972387,
  },
  {
    eiaid: 10617,
    name: "City of Lake View - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.09370523935167735,
  },
  {
    eiaid: 11611,
    name: "City of Maquoketa - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.11997132495572235,
  },
  {
    eiaid: 12541,
    name: "City of Milford - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10795069754842206,
  },
  {
    eiaid: 13038,
    name: "City of Mt Pleasant - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12809488510007414,
  },
  {
    eiaid: 13444,
    name: "City of New Hampton - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.09357903829542183,
  },
  {
    eiaid: 13990,
    name: "City of Ogden - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1509602711353794,
  },
  {
    eiaid: 14132,
    name: "City of Onawa - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10894602502645832,
  },
  {
    eiaid: 14159,
    name: "City of Orange City - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.09539116640227102,
  },
  {
    eiaid: 14201,
    name: "City of Osage - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1034335320031096,
  },
  {
    eiaid: 14645,
    name: "City of Pella - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1177936330709948,
  },
  {
    eiaid: 16932,
    name: "City of Sergeant Bluff - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10383573757806887,
  },
  {
    eiaid: 17141,
    name: "City of Sibley - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.08821837243495494,
  },
  {
    eiaid: 17264,
    name: "City of Sioux Center",
    type: "Bundled",
    state: "IA",
    thenumber: 0.0941380154594103,
  },
  {
    eiaid: 17783,
    name: "City of Spencer - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.08052636293067415,
  },
  {
    eiaid: 18014,
    name: "City of State Center - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.11956640753312164,
  },
  {
    eiaid: 18177,
    name: "City of Story City - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12243911283012027,
  },
  {
    eiaid: 18231,
    name: "City of Stuart - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.14792586054721976,
  },
  {
    eiaid: 18301,
    name: "City of Sumner - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.15865934568748571,
  },
  {
    eiaid: 18947,
    name: "City of Tipton - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.11036893203883495,
  },
  {
    eiaid: 19062,
    name: "City of Traer - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.11559082135963972,
  },
  {
    eiaid: 19865,
    name: "City of Vinton - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12188544806796384,
  },
  {
    eiaid: 20259,
    name: "City of Webster City - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1306247822920295,
  },
  {
    eiaid: 20380,
    name: "City of West Liberty - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.11925795053003534,
  },
  {
    eiaid: 20789,
    name: "City of Wilton",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10153208058235991,
  },
  {
    eiaid: 20835,
    name: "City of Winterset - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13525031611483163,
  },
  {
    eiaid: 3722,
    name: "Clarke Electric Coop Inc - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13265023179238336,
  },
  {
    eiaid: 11788,
    name: "Consumers Energy",
    type: "Bundled",
    state: "IA",
    thenumber: 0.14216727834653273,
  },
  {
    eiaid: 5588,
    name: "East-Central Iowa Rural Elec Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10990332078351198,
  },
  {
    eiaid: 5605,
    name: "Eastern Iowa Light & Power Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1234589986107675,
  },
  {
    eiaid: 5742,
    name: "Eldridge City Utilities",
    type: "Bundled",
    state: "IA",
    thenumber: 0.07878651685393258,
  },
  {
    eiaid: 6168,
    name: "Farmers Electric Coop - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1287915262397689,
  },
  {
    eiaid: 6112,
    name: "Farmers Electric Coop, Inc - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1429313158285121,
  },
  {
    eiaid: 6722,
    name: "Franklin Rural Electric Coop - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12961626445854948,
  },
  {
    eiaid: 7743,
    name: "Grundy Center Mun Light & Power",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10461661983139302,
  },
  {
    eiaid: 7864,
    name: "Grundy County Rural Elec Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13948399392934036,
  },
  {
    eiaid: 7750,
    name: "Guthrie County Rural E C A",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10480180292283656,
  },
  {
    eiaid: 8122,
    name: "Harlan Municipal Utilities - (IA)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12565705061876578,
  },
  {
    eiaid: 8283,
    name: "Harrison County Rrl Elec Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.14169395328530887,
  },
  {
    eiaid: 8319,
    name: "Heartland Power Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.11776081710533699,
  },
  {
    eiaid: 8966,
    name: "Hudson Municipal Electric Utility",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1466613140639636,
  },
  {
    eiaid: 9275,
    name: "Indianola Municipal Utilities",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12776699029126212,
  },
  {
    eiaid: 9417,
    name: "Interstate Power and Light Co",
    type: "Bundled",
    state: "IA",
    thenumber: 0.16703236537355937,
  },
  {
    eiaid: 9425,
    name: "Iowa Lakes Electric Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13001906687693346,
  },
  {
    eiaid: 10542,
    name: "La Porte City Utilities",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1203862660944206,
  },
  {
    eiaid: 11053,
    name: "Linn County REC",
    type: "Bundled",
    state: "IA",
    thenumber: 0.11546792424377438,
  },
  {
    eiaid: 11298,
    name: "Lyon Rural Electric Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.09476505834121728,
  },
  {
    eiaid: 12642,
    name: "Maquoketa Valley Rrl Elec Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12028113899558149,
  },
  {
    eiaid: 12341,
    name: "MidAmerican Energy Co",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10280991892898607,
  },
  {
    eiaid: 12450,
    name: "Midland Power Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12179075434983848,
  },
  {
    eiaid: 19157,
    name: "MiEnergy Cooperative",
    type: "Bundled",
    state: "IA",
    thenumber: 0.15330537552420892,
  },
  {
    eiaid: 13468,
    name: "New London Municipal Utilities",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13096392030156165,
  },
  {
    eiaid: 13675,
    name: "Nishnabotna Valley R E C",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13507489495962388,
  },
  {
    eiaid: 17260,
    name: "North West Rural Electric Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.08956043556376703,
  },
  {
    eiaid: 14202,
    name: "Osceola Electric Coop, Inc",
    type: "Bundled",
    state: "IA",
    thenumber: 0.10744019422074846,
  },
  {
    eiaid: 26765,
    name: "Pella Cooperative Elec Assn",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12918179769090135,
  },
  {
    eiaid: 15291,
    name: "Prairie Energy Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13339496375803786,
  },
  {
    eiaid: 7303,
    name: "Raccoon Valley Electric Cooperative",
    type: "Bundled",
    state: "IA",
    thenumber: 0.1391680039501296,
  },
  {
    eiaid: 16206,
    name: "Rock Rapids Municipal Utility",
    type: "Bundled",
    state: "IA",
    thenumber: 0.09001599268905643,
  },
  {
    eiaid: 17643,
    name: "Southern Iowa Elec Coop, Inc",
    type: "Bundled",
    state: "IA",
    thenumber: 0.156589504901557,
  },
  {
    eiaid: 49986,
    name: "Southwest Iowa Rural Elec Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12310322435294274,
  },
  {
    eiaid: 18446,
    name: "T I P Rural Electric Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.12083709358764848,
  },
  {
    eiaid: 22646,
    name: "United Electric Coop, Inc - (MO)",
    type: "Bundled",
    state: "IA",
    thenumber: 0.15512621633055987,
  },
  {
    eiaid: 20214,
    name: "Waverly Municipal Elec Utility",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13922813873961895,
  },
  {
    eiaid: 19437,
    name: "Western Iowa Power Coop",
    type: "Bundled",
    state: "IA",
    thenumber: 0.13944827586206895,
  },
  {
    eiaid: 20951,
    name: "Woodbury County Rural E C A",
    type: "Bundled",
    state: "IA",
    thenumber: 0.14304496450170917,
  },
  {
    eiaid: 20169,
    name: "Avista Corp",
    type: "Bundled",
    state: "ID",
    thenumber: 0.09673215563081405,
  },
  {
    eiaid: 1956,
    name: "City of Bonners Ferry",
    type: "Bundled",
    state: "ID",
    thenumber: 0.07156613512056081,
  },
  {
    eiaid: 2545,
    name: "City of Burley - (ID)",
    type: "Bundled",
    state: "ID",
    thenumber: 0.08912100812151122,
  },
  {
    eiaid: 8532,
    name: "City of Heyburn - (ID)",
    type: "Bundled",
    state: "ID",
    thenumber: 0.09537362379948465,
  },
  {
    eiaid: 9187,
    name: "City of Idaho Falls - (ID)",
    type: "Bundled",
    state: "ID",
    thenumber: 0.0776158117291039,
  },
  {
    eiaid: 16416,
    name: "City of Rupert - (ID)",
    type: "Bundled",
    state: "ID",
    thenumber: 0.07714355508509078,
  },
  {
    eiaid: 17493,
    name: "City of Soda Springs - (ID)",
    type: "Bundled",
    state: "ID",
    thenumber: 0.09230769230769231,
  },
  {
    eiaid: 20297,
    name: "City of Weiser - (ID)",
    type: "Bundled",
    state: "ID",
    thenumber: 0.0745258883248731,
  },
  {
    eiaid: 3739,
    name: "Clearwater Power Company",
    type: "Bundled",
    state: "ID",
    thenumber: 0.11643831144985842,
  },
  {
    eiaid: 5584,
    name: "East End Mutual Elec Co Ltd",
    type: "Bundled",
    state: "ID",
    thenumber: 0.08047834518422754,
  },
  {
    eiaid: 6169,
    name: "Fall River Rural Elec Coop Inc",
    type: "Bundled",
    state: "ID",
    thenumber: 0.10903596912771225,
  },
  {
    eiaid: 9186,
    name: "Idaho County L&P Coop Assn, Inc",
    type: "Bundled",
    state: "ID",
    thenumber: 0.11247510290798035,
  },
  {
    eiaid: 9191,
    name: "Idaho Power Co",
    type: "Bundled",
    state: "ID",
    thenumber: 0.10043903435220931,
  },
  {
    eiaid: 8699,
    name: "Inland Power & Light Company",
    type: "Bundled",
    state: "ID",
    thenumber: 0.08520541439088103,
  },
  {
    eiaid: 10454,
    name: "Kootenai Electric Cooperative",
    type: "Bundled",
    state: "ID",
    thenumber: 0.09935317781452681,
  },
  {
    eiaid: 11211,
    name: "Lost River Electric Coop Inc",
    type: "Bundled",
    state: "ID",
    thenumber: 0.09345173491202105,
  },
  {
    eiaid: 11273,
    name: "Lower Valley Energy Inc",
    type: "Bundled",
    state: "ID",
    thenumber: 0.07188577055637617,
  },
  {
    eiaid: 13758,
    name: "Northern Lights, Inc",
    type: "Bundled",
    state: "ID",
    thenumber: 0.11511428951978935,
  },
  {
    eiaid: 14354,
    name: "PacifiCorp",
    type: "Bundled",
    state: "ID",
    thenumber: 0.102048672464052,
  },
  {
    eiaid: 22814,
    name: "Raft Rural Elec Coop Inc",
    type: "Bundled",
    state: "ID",
    thenumber: 0.0905368330535195,
  },
  {
    eiaid: 56536,
    name: "Riverside Electric Cooperative",
    type: "Bundled",
    state: "ID",
    thenumber: 0.06546772885560273,
  },
  {
    eiaid: 16565,
    name: "Salmon River Electric Coop Inc",
    type: "Bundled",
    state: "ID",
    thenumber: 0.090755254720342,
  },
  {
    eiaid: 17576,
    name: "South Side Electric, Inc",
    type: "Bundled",
    state: "ID",
    thenumber: 0.060179155900086136,
  },
  {
    eiaid: 19502,
    name: "United Electric Co-op, Inc - (ID)",
    type: "Bundled",
    state: "ID",
    thenumber: 0.07095974313598745,
  },
  {
    eiaid: 97,
    name: "Adams Electric Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14919749131739557,
  },
  {
    eiaid: 55722,
    name: "AEP Energy",
    type: "Energy",
    state: "IL",
    thenumber: 0.0648331675212367,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.07273576629992495,
  },
  {
    eiaid: 56697,
    name: "Ameren Illinois Company",
    type: "Delivery",
    state: "IL",
    thenumber: 0.05728814689487069,
  },
  {
    eiaid: 56697,
    name: "Ameren Illinois Company",
    type: "Bundled",
    state: "IL",
    thenumber: 0.09423453748114652,
  },
  {
    eiaid: 59799,
    name: "American Power & Gas",
    type: "Energy",
    state: "IL",
    thenumber: 0.11217784916405052,
  },
  {
    eiaid: 2776,
    name: "Cairo Public Utility Company",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13676039517967647,
  },
  {
    eiaid: 54862,
    name: "Champion Energy Services",
    type: "Energy",
    state: "IL",
    thenumber: 0.0829483107132544,
  },
  {
    eiaid: 406,
    name: "City of Altamont - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1261372670258811,
  },
  {
    eiaid: 1325,
    name: "City of Batavia - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14077347560277517,
  },
  {
    eiaid: 2188,
    name: "City of Breese - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1119130878943853,
  },
  {
    eiaid: 2634,
    name: "City of Bushnell - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13480837613591465,
  },
  {
    eiaid: 3037,
    name: "City of Carlyle - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.12485160269093787,
  },
  {
    eiaid: 3040,
    name: "City of Carmi - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1595564363115065,
  },
  {
    eiaid: 3153,
    name: "City of Casey - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.11470289685328336,
  },
  {
    eiaid: 6141,
    name: "City of Fairfield- (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.09851351351351352,
  },
  {
    eiaid: 6192,
    name: "City of Farmer City - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14669864916045955,
  },
  {
    eiaid: 6417,
    name: "City of Flora - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14905161203533474,
  },
  {
    eiaid: 7095,
    name: "City of Geneseo - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.11438258386866523,
  },
  {
    eiaid: 7096,
    name: "City of Geneva- (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.11750012809345699,
  },
  {
    eiaid: 8573,
    name: "City of Highland",
    type: "Bundled",
    state: "IL",
    thenumber: 0.11975818952154593,
  },
  {
    eiaid: 11736,
    name: "City of Marshall - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1229307326831708,
  },
  {
    eiaid: 11790,
    name: "City of Mascoutah - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14864106019766396,
  },
  {
    eiaid: 12167,
    name: "City of McLeansboro - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1468882541331256,
  },
  {
    eiaid: 12388,
    name: "City of Metropolis - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.12092134685105183,
  },
  {
    eiaid: 13208,
    name: "City of Naperville - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13206017848263799,
  },
  {
    eiaid: 13560,
    name: "City of Newton - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.10681431005110732,
  },
  {
    eiaid: 13993,
    name: "City of Oglesby - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13679508654383027,
  },
  {
    eiaid: 14840,
    name: "City of Peru - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1111636956407425,
  },
  {
    eiaid: 15388,
    name: "City of Princeton - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.11155481964871271,
  },
  {
    eiaid: 15772,
    name: "City of Red Bud - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.12395557367026697,
  },
  {
    eiaid: 16198,
    name: "City of Rock Falls - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1309805397269111,
  },
  {
    eiaid: 17828,
    name: "City of Springfield - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.11922012211063777,
  },
  {
    eiaid: 17860,
    name: "City of St Charles - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14622051321228788,
  },
  {
    eiaid: 18277,
    name: "City of Sullivan - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1378225927220087,
  },
  {
    eiaid: 20180,
    name: "City of Waterloo - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13341555266809083,
  },
  {
    eiaid: 3726,
    name: "Clay Electric Coop Inc",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14833247680614536,
  },
  {
    eiaid: 58951,
    name: "CleanChoice Energy, Inc.",
    type: "Energy",
    state: "IL",
    thenumber: 0.1274929885992134,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "IL",
    thenumber: 0.09952128392340544,
  },
  {
    eiaid: 3806,
    name: "Clinton County Elec Coop, Inc",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13113345140118762,
  },
  {
    eiaid: 3931,
    name: "Coles-Moultrie Electric Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14835010724302922,
  },
  {
    eiaid: 4100,
    name: "Commerce Energy, Inc.",
    type: "Energy",
    state: "IL",
    thenumber: 0.08233628379581498,
  },
  {
    eiaid: 4110,
    name: "Commonwealth Edison Co",
    type: "Delivery",
    state: "IL",
    thenumber: 0.062217999943023156,
  },
  {
    eiaid: 4110,
    name: "Commonwealth Edison Co",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13302939015542445,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "IL",
    thenumber: 0.069663063773833,
  },
  {
    eiaid: 4362,
    name: "Corn Belt Energy Corporation",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13316365449333167,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "IL",
    thenumber: 0.109058643599032,
  },
  {
    eiaid: 59385,
    name: "Dynegy Energy Services, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.07070515100304253,
  },
  {
    eiaid: 5585,
    name: "Eastern Illinois Elec Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14807292072431025,
  },
  {
    eiaid: 5535,
    name: "Egyptian Electric Coop Assn",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1405112585015453,
  },
  {
    eiaid: 59054,
    name: "Eligo Energy, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.07556906898036005,
  },
  {
    eiaid: 6458,
    name: "Energy Harbor Corp.",
    type: "Energy",
    state: "IL",
    thenumber: 0.06466160427098186,
  },
  {
    eiaid: 56265,
    name: "Energy Plus Holdings LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.13413141297360748,
  },
  {
    eiaid: 55874,
    name: "Energy Services Providers, Inc",
    type: "Energy",
    state: "IL",
    thenumber: 0.09026829307208732,
  },
  {
    eiaid: 58965,
    name: "Energy.Me Midwest LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.07754485699058447,
  },
  {
    eiaid: 5531,
    name: "Enerstar Power Corp",
    type: "Bundled",
    state: "IL",
    thenumber: 0.15997955532839253,
  },
  {
    eiaid: 57067,
    name: "ENGIE Retail, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.09352596641936743,
  },
  {
    eiaid: 56286,
    name: "Frontier Utilities, Inc.",
    type: "Energy",
    state: "IL",
    thenumber: 0.10979758888548598,
  },
  {
    eiaid: 59064,
    name: "Great American Power, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.10847916966988612,
  },
  {
    eiaid: 7554,
    name: "Green Mountain Energy Company",
    type: "Energy",
    state: "IL",
    thenumber: 0.13351516066843755,
  },
  {
    eiaid: 970,
    name: "Homefield Energy",
    type: "Energy",
    state: "IL",
    thenumber: 0.056020591131556906,
  },
  {
    eiaid: 55815,
    name: "IDT Energy, Inc.",
    type: "Energy",
    state: "IL",
    thenumber: 0.13060910427458472,
  },
  {
    eiaid: 9209,
    name: "Illinois Rural Electric Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.18288617115408926,
  },
  {
    eiaid: 59310,
    name: "Inspire Energy Holdings, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.0978537608800431,
  },
  {
    eiaid: 58957,
    name: "Interstate Gas Supply, Inc.",
    type: "Energy",
    state: "IL",
    thenumber: 0.1004055322732068,
  },
  {
    eiaid: 9750,
    name: "Jo-Carroll Energy, Inc",
    type: "Bundled",
    state: "IL",
    thenumber: 0.16473938167973357,
  },
  {
    eiaid: 55781,
    name: "Liberty Power Corp.",
    type: "Energy",
    state: "IL",
    thenumber: 0.09947894051237516,
  },
  {
    eiaid: 60219,
    name: "LifeEnergy, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.08621590552826837,
  },
  {
    eiaid: 8824,
    name: "M J M Electric Cooperative Inc",
    type: "Bundled",
    state: "IL",
    thenumber: 0.15399049353649327,
  },
  {
    eiaid: 56504,
    name: "Major Energy Electric Services",
    type: "Energy",
    state: "IL",
    thenumber: 0.14735632183908046,
  },
  {
    eiaid: 56379,
    name: "MC Squared Energy Services, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.06811259271378708,
  },
  {
    eiaid: 12070,
    name: "McDonough Power Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1554511176459976,
  },
  {
    eiaid: 59958,
    name: "Mega Energy of Illinois, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.11564068021632597,
  },
  {
    eiaid: 12395,
    name: "Menard Electric Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.15659708713229695,
  },
  {
    eiaid: 12341,
    name: "MidAmerican Energy Co",
    type: "Bundled",
    state: "IL",
    thenumber: 0.0989684449928323,
  },
  {
    eiaid: 59931,
    name: "MidAmerican Energy Services, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.04957654795659338,
  },
  {
    eiaid: 12803,
    name: "Monroe County Elec Coop, Inc",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14982686544001397,
  },
  {
    eiaid: 13032,
    name: "Mt Carmel Public Utility Co",
    type: "Bundled",
    state: "IL",
    thenumber: 0.15094146541137946,
  },
  {
    eiaid: 59088,
    name: "Nordic Energy Services, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.05928760222292505,
  },
  {
    eiaid: 59088,
    name: "Nordic Energy Services, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.0707119731283582,
  },
  {
    eiaid: 13292,
    name: "Norris Electric Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.10797238161592597,
  },
  {
    eiaid: 58667,
    name: "North American Power and Gas, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.14144502330682754,
  },
  {
    eiaid: 58314,
    name: "Palmco Power IL, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.08899048600267229,
  },
  {
    eiaid: 58119,
    name: "Public Power LLC (CT)",
    type: "Energy",
    state: "IL",
    thenumber: 0.10570270127955347,
  },
  {
    eiaid: 58972,
    name: "Realgy, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.053151100095149714,
  },
  {
    eiaid: 57037,
    name: "Reliant Energy Northeast LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.09642946567640118,
  },
  {
    eiaid: 59809,
    name: "Residents Energy, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.09772681915873503,
  },
  {
    eiaid: 16179,
    name: "Rochelle Municipal Utilities",
    type: "Bundled",
    state: "IL",
    thenumber: 0.11674480523238988,
  },
  {
    eiaid: 16196,
    name: "Rock Energy Cooperative",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13680211390231725,
  },
  {
    eiaid: 16420,
    name: "Rural Electric Conven Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.12593872033643738,
  },
  {
    eiaid: 59811,
    name: "Santanna Energy Services",
    type: "Energy",
    state: "IL",
    thenumber: 0.08297118847539016,
  },
  {
    eiaid: 17040,
    name: "Shelby Electric Coop, Inc",
    type: "Bundled",
    state: "IL",
    thenumber: 0.16823248849204148,
  },
  {
    eiaid: 58956,
    name: "SmartEnergy Holdings, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.08346466938284255,
  },
  {
    eiaid: 17585,
    name: "Southeastern IL Elec Coop, Inc",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13282049246160565,
  },
  {
    eiaid: 17631,
    name: "Southern Illinois Elec Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1602447005637838,
  },
  {
    eiaid: 17697,
    name: "Southwestern Electric Coop Inc - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.1313716499619952,
  },
  {
    eiaid: 17710,
    name: "Spark Energy, LP",
    type: "Energy",
    state: "IL",
    thenumber: 0.1425549077950897,
  },
  {
    eiaid: 17838,
    name: "Spoon River Electric Coop, Inc",
    type: "Bundled",
    state: "IL",
    thenumber: 0.17747186110342772,
  },
  {
    eiaid: 58953,
    name: "Star Energy Partners, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.09416689584901917,
  },
  {
    eiaid: 61092,
    name: "Stream Energy Illinois, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.08216866427242439,
  },
  {
    eiaid: 58952,
    name: "Tomorow Energy Corp.",
    type: "Energy",
    state: "IL",
    thenumber: 0.14507100545797874,
  },
  {
    eiaid: 18955,
    name: "Tri-County Electric Coop, Inc (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14464245271148765,
  },
  {
    eiaid: 3405,
    name: "Village of Chatham - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.11391972672929121,
  },
  {
    eiaid: 6764,
    name: "Village of Freeburg - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13331253342841862,
  },
  {
    eiaid: 15686,
    name: "Village of Rantoul - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.13911635655458832,
  },
  {
    eiaid: 16092,
    name: "Village of Riverton - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.128419175911252,
  },
  {
    eiaid: 20824,
    name: "Village of Winnetka - (IL)",
    type: "Bundled",
    state: "IL",
    thenumber: 0.12165765406563832,
  },
  {
    eiaid: 58162,
    name: "Viridian Energy PA LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.11365919457607865,
  },
  {
    eiaid: 60696,
    name: "Vista Energy Marketing, L.P.",
    type: "Energy",
    state: "IL",
    thenumber: 0.09877506510110648,
  },
  {
    eiaid: 20222,
    name: "Wayne-White Counties Elec Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.14970017774478972,
  },
  {
    eiaid: 29296,
    name: "Western Illinois Elec Coop",
    type: "Bundled",
    state: "IL",
    thenumber: 0.15252011622708986,
  },
  {
    eiaid: 57488,
    name: "XOOM Energy Illinois, LLC",
    type: "Energy",
    state: "IL",
    thenumber: 0.0920656479909451,
  },
  {
    eiaid: 1283,
    name: "Bartholomew County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1385015347992232,
  },
  {
    eiaid: 24753,
    name: "Boone County Rural EMC",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13593533110149425,
  },
  {
    eiaid: 2192,
    name: "Bremen Electric Light & Power Co",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10519349637194303,
  },
  {
    eiaid: 27599,
    name: "Carroll-White REMC",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13710094820090266,
  },
  {
    eiaid: 636,
    name: "City of Anderson - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11102851745260518,
  },
  {
    eiaid: 994,
    name: "City of Auburn - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.08861307393472209,
  },
  {
    eiaid: 1896,
    name: "City of Bluffton - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.08874942168044576,
  },
  {
    eiaid: 4007,
    name: "City of Columbia City - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10325778739313995,
  },
  {
    eiaid: 4429,
    name: "City of Covington - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10842647747808044,
  },
  {
    eiaid: 6707,
    name: "City of Frankfort - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1024689745264533,
  },
  {
    eiaid: 6970,
    name: "City of Garrett",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10644667405042674,
  },
  {
    eiaid: 6993,
    name: "City of Gas City - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1067043774506653,
  },
  {
    eiaid: 6907,
    name: "City of Greendale",
    type: "Bundled",
    state: "IN",
    thenumber: 0.115216509710269,
  },
  {
    eiaid: 7627,
    name: "City of Greenfield - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10828391805326934,
  },
  {
    eiaid: 7907,
    name: "City of Hagerstown - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1045958795562599,
  },
  {
    eiaid: 9088,
    name: "City of Huntingburg - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10986736901514439,
  },
  {
    eiaid: 9667,
    name: "City of Jasper - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.09343071694803771,
  },
  {
    eiaid: 9613,
    name: "City of Lebanon - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10721364701569722,
  },
  {
    eiaid: 11055,
    name: "City of Linton - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12507508726357658,
  },
  {
    eiaid: 11142,
    name: "City of Logansport - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.105266262880516,
  },
  {
    eiaid: 12674,
    name: "City of Mishawaka",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11900274762935018,
  },
  {
    eiaid: 14839,
    name: "City of Peru - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10870882116462723,
  },
  {
    eiaid: 15860,
    name: "City of Rensselaer - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11387810304960516,
  },
  {
    eiaid: 15989,
    name: "City of Richmond - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10190682405531885,
  },
  {
    eiaid: 16068,
    name: "City of Rising Sun - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.09741096532333646,
  },
  {
    eiaid: 16830,
    name: "City of Scottsburg - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.09890016440841318,
  },
  {
    eiaid: 18538,
    name: "City of Tell City - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12948225059632384,
  },
  {
    eiaid: 20139,
    name: "City of Washington - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10519089316987741,
  },
  {
    eiaid: 20730,
    name: "City of Williamsport - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11715564395822974,
  },
  {
    eiaid: 22822,
    name: "Clark County Rural E M C - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1278371802886502,
  },
  {
    eiaid: 4508,
    name: "Crawfordsville Elec, Lgt & Pwr",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10754754520451752,
  },
  {
    eiaid: 4799,
    name: "Darlington Light & Power Co",
    type: "Bundled",
    state: "IN",
    thenumber: 0.09999999999999999,
  },
  {
    eiaid: 4848,
    name: "Daviess Martin County R E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12220571239887733,
  },
  {
    eiaid: 4960,
    name: "Decatur County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13886892892593927,
  },
  {
    eiaid: 5394,
    name: "Dubois Rural Electric Coop Inc",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12242649260434757,
  },
  {
    eiaid: 15470,
    name: "Duke Energy Indiana, LLC",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1173407756607214,
  },
  {
    eiaid: 5655,
    name: "Edinburgh Municipal Utilities",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11092532161061518,
  },
  {
    eiaid: 6848,
    name: "Fulton County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12926159878022217,
  },
  {
    eiaid: 8179,
    name: "Harrison County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11656480011749487,
  },
  {
    eiaid: 59505,
    name: "Heartland REMC",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12610078599405905,
  },
  {
    eiaid: 8447,
    name: "Hendricks County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1195711774756917,
  },
  {
    eiaid: 8466,
    name: "Henry County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1352121421666514,
  },
  {
    eiaid: 9324,
    name: "Indiana Michigan Power Co",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13696780983525175,
  },
  {
    eiaid: 9273,
    name: "Indianapolis Power & Light Co",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11245630706905378,
  },
  {
    eiaid: 9576,
    name: "Jackson County Rural E M C - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12633316437959924,
  },
  {
    eiaid: 9665,
    name: "Jasper County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12821572660390793,
  },
  {
    eiaid: 9666,
    name: "Jay County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1265861440771669,
  },
  {
    eiaid: 9778,
    name: "Johnson County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1303146656952405,
  },
  {
    eiaid: 9999,
    name: "Kankakee Valley Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12921876236909136,
  },
  {
    eiaid: 10448,
    name: "Kosciusko County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1161033984546768,
  },
  {
    eiaid: 10562,
    name: "Lagrange County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1431785765797529,
  },
  {
    eiaid: 10798,
    name: "Lawrenceburg Municipal Utils",
    type: "Bundled",
    state: "IN",
    thenumber: 0.0995432580922751,
  },
  {
    eiaid: 11767,
    name: "Marshall County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.14640663223702094,
  },
  {
    eiaid: 12406,
    name: "Miami-Cass County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1347323914612767,
  },
  {
    eiaid: 13566,
    name: "Newton County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1275320454955768,
  },
  {
    eiaid: 8000,
    name: "NineStar Connect",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13435708859885367,
  },
  {
    eiaid: 13647,
    name: "Noble County R E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1239068126699493,
  },
  {
    eiaid: 20603,
    name: "Northeastern Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.132955349137255,
  },
  {
    eiaid: 13756,
    name: "Northern Indiana Pub Serv Co",
    type: "Bundled",
    state: "IN",
    thenumber: 0.14293479389933728,
  },
  {
    eiaid: 14160,
    name: "Orange County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13977326216061933,
  },
  {
    eiaid: 14471,
    name: "Parke County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.14122035669954922,
  },
  {
    eiaid: 14599,
    name: "Paulding-Putman Elec Coop, Inc",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13383054459161683,
  },
  {
    eiaid: 17038,
    name: "RushShelby Energy",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13902851550251327,
  },
  {
    eiaid: 12929,
    name: "South Central Indiana REMC",
    type: "Bundled",
    state: "IN",
    thenumber: 0.15290961707765663,
  },
  {
    eiaid: 17599,
    name: "Southeastern Indiana R E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.14081596430531623,
  },
  {
    eiaid: 17633,
    name: "Southern Indiana Gas & Elec Co",
    type: "Bundled",
    state: "IN",
    thenumber: 0.15338316733039456,
  },
  {
    eiaid: 19445,
    name: "Southern Indiana R E C, Inc",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12892929329882294,
  },
  {
    eiaid: 18100,
    name: "Steuben County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12286686005801742,
  },
  {
    eiaid: 18940,
    name: "Tipmont Rural Elec Member Corp",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13720649080981312,
  },
  {
    eiaid: 18942,
    name: "Tipton Municipal Electric Util",
    type: "Bundled",
    state: "IN",
    thenumber: 0.09829944732037912,
  },
  {
    eiaid: 1208,
    name: "Town of Bargersville - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10714380736996142,
  },
  {
    eiaid: 3230,
    name: "Town of Centerville - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10620736698499318,
  },
  {
    eiaid: 6735,
    name: "Town of Frankton - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11258385193566638,
  },
  {
    eiaid: 10407,
    name: "Town of Knightstown - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.1350055330136481,
  },
  {
    eiaid: 12476,
    name: "Town of Middletown - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10470204471318323,
  },
  {
    eiaid: 14435,
    name: "Town of Paoli",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10847818375310393,
  },
  {
    eiaid: 14659,
    name: "Town of Pendleton - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11770521572451827,
  },
  {
    eiaid: 19771,
    name: "Town of Veedersburg - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11228170626968222,
  },
  {
    eiaid: 20021,
    name: "Town of Walkerton - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.10455535562079743,
  },
  {
    eiaid: 20792,
    name: "Town of Winamac - (IN)",
    type: "Bundled",
    state: "IN",
    thenumber: 0.12985126253891388,
  },
  {
    eiaid: 19667,
    name: "Utilities Dist-Western IN REMC",
    type: "Bundled",
    state: "IN",
    thenumber: 0.14642247706610637,
  },
  {
    eiaid: 20111,
    name: "Warren County Rural E M C",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13726115838184805,
  },
  {
    eiaid: 25295,
    name: "Western Indiana Energy REMC",
    type: "Bundled",
    state: "IN",
    thenumber: 0.11835203793609574,
  },
  {
    eiaid: 20216,
    name: "Whitewater Valley Rural EMC",
    type: "Bundled",
    state: "IN",
    thenumber: 0.13191850191529986,
  },
  {
    eiaid: 40208,
    name: "Ark Valley Elec Coop Assn, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.15591777364221696,
  },
  {
    eiaid: 23826,
    name: "Bluestem Electric Coop Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.16810968859113748,
  },
  {
    eiaid: 2354,
    name: "Brown-Atchison E C A Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13792948285282677,
  },
  {
    eiaid: 2641,
    name: "Butler Rural El Coop Assn, Inc - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13904649909353717,
  },
  {
    eiaid: 2961,
    name: "Caney Valley El Coop Assn, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.163483587246741,
  },
  {
    eiaid: 701,
    name: "City of Anthony - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13536694510739858,
  },
  {
    eiaid: 832,
    name: "City of Arma - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13827433628318583,
  },
  {
    eiaid: 998,
    name: "City of Augusta - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13674258587582677,
  },
  {
    eiaid: 1148,
    name: "City of Baldwin City- (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.14354819909080313,
  },
  {
    eiaid: 1512,
    name: "City of Belleville - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.15705669481302775,
  },
  {
    eiaid: 1525,
    name: "City of Beloit - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.11954037582529202,
  },
  {
    eiaid: 2551,
    name: "City of Burlington - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12484620116887112,
  },
  {
    eiaid: 3355,
    name: "City of Chanute",
    type: "Bundled",
    state: "KS",
    thenumber: 0.09652856058340427,
  },
  {
    eiaid: 3710,
    name: "City of Clay Center - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1223021582733813,
  },
  {
    eiaid: 3892,
    name: "City of Coffeyville - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.11609318489483361,
  },
  {
    eiaid: 3913,
    name: "City of Colby - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.10609942055504727,
  },
  {
    eiaid: 5802,
    name: "City of Ellinwood - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.15552319309600862,
  },
  {
    eiaid: 6019,
    name: "City of Eudora - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12989098532494758,
  },
  {
    eiaid: 6762,
    name: "City of Fredonia - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.10454380883417813,
  },
  {
    eiaid: 6941,
    name: "City of Garden City",
    type: "Bundled",
    state: "KS",
    thenumber: 0.11546623392834852,
  },
  {
    eiaid: 6949,
    name: "City of Gardner - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.10498274984446582,
  },
  {
    eiaid: 6965,
    name: "City of Garnett - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.11345924313697957,
  },
  {
    eiaid: 7257,
    name: "City of Girard - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1373097006692653,
  },
  {
    eiaid: 7374,
    name: "City of Goodland - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.14973418239177427,
  },
  {
    eiaid: 8490,
    name: "City of Herington - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.17941731293229932,
  },
  {
    eiaid: 8629,
    name: "City of Hillsboro - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13172736832042825,
  },
  {
    eiaid: 8703,
    name: "City of Hoisington - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13303886925795053,
  },
  {
    eiaid: 8770,
    name: "City of Holton - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.09430140199370969,
  },
  {
    eiaid: 9011,
    name: "City of Hugoton - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.14289738713993944,
  },
  {
    eiaid: 9418,
    name: "City of Iola - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.11954114185592912,
  },
  {
    eiaid: 9996,
    name: "City of Kansas City - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13148737169678318,
  },
  {
    eiaid: 10321,
    name: "City of Kingman - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.14354217435736183,
  },
  {
    eiaid: 10351,
    name: "City of Kiowa - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.08080379123584441,
  },
  {
    eiaid: 10628,
    name: "City of Lakin - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1513027618551329,
  },
  {
    eiaid: 10713,
    name: "City of Larned - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13491354391128396,
  },
  {
    eiaid: 11040,
    name: "City of Lindsborg - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12491402648715885,
  },
  {
    eiaid: 11658,
    name: "City of Marion - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1470360824742268,
  },
  {
    eiaid: 12208,
    name: "City of McPherson - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.0755129854554177,
  },
  {
    eiaid: 21533,
    name: "City of Minneapolis - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1461705726020778,
  },
  {
    eiaid: 13029,
    name: "City of Moundridge - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12542331881954524,
  },
  {
    eiaid: 13095,
    name: "City of Mulvane - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12766661154291384,
  },
  {
    eiaid: 13380,
    name: "City of Neodesha - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.11616579358514842,
  },
  {
    eiaid: 13819,
    name: "City of Norton",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1409928577726832,
  },
  {
    eiaid: 14199,
    name: "City of Osage City - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13646004603748768,
  },
  {
    eiaid: 14203,
    name: "City of Osawatomie - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12515999777394402,
  },
  {
    eiaid: 14229,
    name: "City of Ottawa - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.10168427008675972,
  },
  {
    eiaid: 15321,
    name: "City of Pratt- (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1322978591800969,
  },
  {
    eiaid: 16440,
    name: "City of Russell - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1110204081632653,
  },
  {
    eiaid: 16518,
    name: "City of Sabetha - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.10988762350101818,
  },
  {
    eiaid: 17894,
    name: "City of St Marys - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12764190649527926,
  },
  {
    eiaid: 18086,
    name: "City of Sterling - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1100717181166199,
  },
  {
    eiaid: 20069,
    name: "City of Wamego - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1199224382240342,
  },
  {
    eiaid: 20315,
    name: "City of Wellington - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.16290341171046563,
  },
  {
    eiaid: 20813,
    name: "City of Winfield - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12284190998278542,
  },
  {
    eiaid: 2774,
    name: "CMS Electric Coop Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.16433167091788176,
  },
  {
    eiaid: 5334,
    name: "Doniphan Elec Coop Assn, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.17151793693348652,
  },
  {
    eiaid: 4704,
    name: "DS&O Electric Cooperative, Inc.",
    type: "Bundled",
    state: "KS",
    thenumber: 0.15389761361642393,
  },
  {
    eiaid: 5860,
    name: "Empire District Electric Co",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1209812770572718,
  },
  {
    eiaid: 22500,
    name: "Evergy Kansas Central, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1328665971012301,
  },
  {
    eiaid: 10005,
    name: "Evergy Kansas South, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1188340708000579,
  },
  {
    eiaid: 10000,
    name: "Evergy Metro",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12212225052291861,
  },
  {
    eiaid: 6431,
    name: "Flint Hills Rural E C A, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.15397766985071415,
  },
  {
    eiaid: 10019,
    name: "FreeState Electric Coop",
    type: "Bundled",
    state: "KS",
    thenumber: 0.14770557776038018,
  },
  {
    eiaid: 16854,
    name: "Heartland Rural Elec Coop, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.1447909001445125,
  },
  {
    eiaid: 10728,
    name: "Lane-Scott Electric Coop, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.11841294615353021,
  },
  {
    eiaid: 11334,
    name: "Lyon-Coffey Electric Cooperative, Inc.",
    type: "Bundled",
    state: "KS",
    thenumber: 0.15330748619073994,
  },
  {
    eiaid: 12524,
    name: "Midwest Energy Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12149194254969788,
  },
  {
    eiaid: 13386,
    name: "Nemaha-Marshall E C A, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.14084174642716665,
  },
  {
    eiaid: 13570,
    name: "Ninnescah Rural E C A Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.16435579339982162,
  },
  {
    eiaid: 15073,
    name: "Pioneer Electric Coop, Inc - (KS)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.10872081805903917,
  },
  {
    eiaid: 13799,
    name: "Prairie Land Electric Coop Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12185511100487047,
  },
  {
    eiaid: 15621,
    name: "Radiant Electric Coop, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.14621553621972952,
  },
  {
    eiaid: 16267,
    name: "Rolling Hills Electric Coop",
    type: "Bundled",
    state: "KS",
    thenumber: 0.16921611028517367,
  },
  {
    eiaid: 16920,
    name: "Sedgwick County El Coop Assn Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13309842921592518,
  },
  {
    eiaid: 60839,
    name: "Southern Pioneer Electric Company",
    type: "Bundled",
    state: "KS",
    thenumber: 0.14406114222896926,
  },
  {
    eiaid: 18316,
    name: "Sumner-Cowley Elec Coop, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.15647109488751718,
  },
  {
    eiaid: 19160,
    name: "Tri-County Electric Coop, Inc (OK)",
    type: "Bundled",
    state: "KS",
    thenumber: 0.13190354483831537,
  },
  {
    eiaid: 18962,
    name: "Twin Valley Electric Coop Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.16855725342329828,
  },
  {
    eiaid: 19820,
    name: "Victory Electric Coop Assn Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12405125216728177,
  },
  {
    eiaid: 20476,
    name: "Western Coop Electric Assn Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.11515886529379639,
  },
  {
    eiaid: 20510,
    name: "Wheatland Electric Coop, Inc",
    type: "Bundled",
    state: "KS",
    thenumber: 0.12981329668767475,
  },
  {
    eiaid: 1201,
    name: "Barbourville Utility Comm",
    type: "Bundled",
    state: "KY",
    thenumber: 0.0941188556166213,
  },
  {
    eiaid: 1708,
    name: "Big Sandy Rural Elec Coop Corp",
    type: "Bundled",
    state: "KY",
    thenumber: 0.11441583962625601,
  },
  {
    eiaid: 1886,
    name: "Blue Grass Energy Coop Corp",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10426862636856014,
  },
  {
    eiaid: 690,
    name: "City of Bardstown - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.08525209069558155,
  },
  {
    eiaid: 1582,
    name: "City of Benton - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.12236410988826346,
  },
  {
    eiaid: 49998,
    name: "City of Berea Municipal Utility",
    type: "Bundled",
    state: "KY",
    thenumber: 0.09423294509151414,
  },
  {
    eiaid: 2056,
    name: "City of Bowling Green - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10546158442202803,
  },
  {
    eiaid: 6178,
    name: "City of Falmouth - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10753036437246964,
  },
  {
    eiaid: 6708,
    name: "City of Frankfort - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10467432914286785,
  },
  {
    eiaid: 6718,
    name: "City of Franklin - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.1162412902215611,
  },
  {
    eiaid: 6840,
    name: "City of Fulton - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.11687061735184931,
  },
  {
    eiaid: 7270,
    name: "City of Glasgow - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.13050501211458976,
  },
  {
    eiaid: 8548,
    name: "City of Hickman",
    type: "Bundled",
    state: "KY",
    thenumber: 0.12909692292272226,
  },
  {
    eiaid: 8846,
    name: "City of Hopkinsville",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10873298874640147,
  },
  {
    eiaid: 9697,
    name: "City of Jellico",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10505624537775875,
  },
  {
    eiaid: 11871,
    name: "City of Mayfield Plant Board",
    type: "Bundled",
    state: "KY",
    thenumber: 0.11988916256157635,
  },
  {
    eiaid: 13138,
    name: "City of Murray - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.12174503736131029,
  },
  {
    eiaid: 13577,
    name: "City of Nicholasville - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.0908957243604757,
  },
  {
    eiaid: 14103,
    name: "City of Olive Hill - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.1293919974992185,
  },
  {
    eiaid: 14268,
    name: "City of Owensboro - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.14937056502767285,
  },
  {
    eiaid: 14371,
    name: "City of Paducah - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.13724000349343374,
  },
  {
    eiaid: 14459,
    name: "City of Paris - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.1008125,
  },
  {
    eiaid: 15386,
    name: "City of Princeton - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.12893258426966292,
  },
  {
    eiaid: 15443,
    name: "City of Providence - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.12216742202079446,
  },
  {
    eiaid: 16459,
    name: "City of Russellville - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10604506849675265,
  },
  {
    eiaid: 19716,
    name: "City of Vanceburg",
    type: "Bundled",
    state: "KY",
    thenumber: 0.13484982843042756,
  },
  {
    eiaid: 3687,
    name: "Clark Energy Coop Inc - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10874748283547753,
  },
  {
    eiaid: 4341,
    name: "Corbin City Utilities Comm",
    type: "Bundled",
    state: "KY",
    thenumber: 0.09449109154208478,
  },
  {
    eiaid: 4622,
    name: "Cumberland Valley Electric, Inc.",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10344008785729127,
  },
  {
    eiaid: 19446,
    name: "Duke Energy Kentucky",
    type: "Bundled",
    state: "KY",
    thenumber: 0.09195172053787412,
  },
  {
    eiaid: 6194,
    name: "Farmers Rural Electric Coop Corp - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10601966584059477,
  },
  {
    eiaid: 6442,
    name: "Fleming-Mason Energy Coop Inc",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10348986976877313,
  },
  {
    eiaid: 7174,
    name: "Gibson Electric Members Corp",
    type: "Bundled",
    state: "KY",
    thenumber: 0.11933481986943556,
  },
  {
    eiaid: 7558,
    name: "Grayson Rural Electric Coop Corp",
    type: "Bundled",
    state: "KY",
    thenumber: 0.13721743731444194,
  },
  {
    eiaid: 8449,
    name: "Henderson City Utility Comm",
    type: "Bundled",
    state: "KY",
    thenumber: 0.08125618717253258,
  },
  {
    eiaid: 9292,
    name: "Inter County Energy Coop Corp",
    type: "Bundled",
    state: "KY",
    thenumber: 0.11471048687677934,
  },
  {
    eiaid: 9575,
    name: "Jackson Energy Coop Corp - (KY)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.1162446973914045,
  },
  {
    eiaid: 9605,
    name: "Jackson Purchase Energy Corporation",
    type: "Bundled",
    state: "KY",
    thenumber: 0.11831792341871405,
  },
  {
    eiaid: 9964,
    name: "Kenergy Corp",
    type: "Bundled",
    state: "KY",
    thenumber: 0.12246464594578783,
  },
  {
    eiaid: 22053,
    name: "Kentucky Power Co",
    type: "Bundled",
    state: "KY",
    thenumber: 0.1201256331747238,
  },
  {
    eiaid: 10171,
    name: "Kentucky Utilities Co",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10210138944626092,
  },
  {
    eiaid: 11011,
    name: "Licking Valley Rural E C C",
    type: "Bundled",
    state: "KY",
    thenumber: 0.11505012068568737,
  },
  {
    eiaid: 11249,
    name: "Louisville Gas & Electric Co",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10891233677177464,
  },
  {
    eiaid: 11488,
    name: "Madisonville Municipal Utils",
    type: "Bundled",
    state: "KY",
    thenumber: 0.11489069278014001,
  },
  {
    eiaid: 12243,
    name: "Meade County Rural E C C",
    type: "Bundled",
    state: "KY",
    thenumber: 0.120381152504779,
  },
  {
    eiaid: 13651,
    name: "Nolin Rural Electric Coop Corp",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10877943546921016,
  },
  {
    eiaid: 14251,
    name: "Owen Electric Coop Inc",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10726133208252886,
  },
  {
    eiaid: 14724,
    name: "Pennyrile Rural Electric Coop",
    type: "Bundled",
    state: "KY",
    thenumber: 0.11728537949841766,
  },
  {
    eiaid: 16587,
    name: "Salt River Electric Coop Corp",
    type: "Bundled",
    state: "KY",
    thenumber: 0.09020415390568129,
  },
  {
    eiaid: 17044,
    name: "Shelby Energy Co-op, Inc",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10953925915595789,
  },
  {
    eiaid: 17564,
    name: "South Kentucky Rural E C C",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10334195853079221,
  },
  {
    eiaid: 18498,
    name: "Taylor County Rural E C C",
    type: "Bundled",
    state: "KY",
    thenumber: 0.09522293200590067,
  },
  {
    eiaid: 19162,
    name: "Tri-County Elec Member Corp (TN)",
    type: "Bundled",
    state: "KY",
    thenumber: 0.11313723117227044,
  },
  {
    eiaid: 20130,
    name: "Warren Rural Elec Coop Corp",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10690186699162446,
  },
  {
    eiaid: 20377,
    name: "West Kentucky Rural E C C",
    type: "Bundled",
    state: "KY",
    thenumber: 0.12071236832013715,
  },
  {
    eiaid: 20731,
    name: "Williamstown Utility Comm",
    type: "Bundled",
    state: "KY",
    thenumber: 0.10217129834778756,
  },
  {
    eiaid: 1458,
    name: "Beauregard Electric Coop, Inc",
    type: "Bundled",
    state: "LA",
    thenumber: 0.10920414467404234,
  },
  {
    eiaid: 59,
    name: "City of Abbeville - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.09973494057266342,
  },
  {
    eiaid: 298,
    name: "City of Alexandria - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.1000973716511352,
  },
  {
    eiaid: 5945,
    name: "City of Erath - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.10758594563879155,
  },
  {
    eiaid: 9880,
    name: "City of Jonesville - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.11964560454135496,
  },
  {
    eiaid: 10025,
    name: "City of Kaplan - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.11888519881518715,
  },
  {
    eiaid: 9096,
    name: "City of Lafayette - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.09322751348245445,
  },
  {
    eiaid: 12625,
    name: "City of Minden - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.12481983280484289,
  },
  {
    eiaid: 13228,
    name: "City of Natchitoches",
    type: "Bundled",
    state: "LA",
    thenumber: 0.08633444780555113,
  },
  {
    eiaid: 15137,
    name: "City of Plaquemine - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.11063723478291904,
  },
  {
    eiaid: 15728,
    name: "City of Rayne - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.07748521700323552,
  },
  {
    eiaid: 16463,
    name: "City of Ruston - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.03949107407042115,
  },
  {
    eiaid: 17889,
    name: "City of St Martinville - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.08343944775819315,
  },
  {
    eiaid: 30856,
    name: "City of Winnfield - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.08601864181091877,
  },
  {
    eiaid: 3641,
    name: "Claiborne Electric Coop, Inc",
    type: "Bundled",
    state: "LA",
    thenumber: 0.09946699654347423,
  },
  {
    eiaid: 3265,
    name: "Cleco Power LLC",
    type: "Bundled",
    state: "LA",
    thenumber: 0.12090893617689474,
  },
  {
    eiaid: 4153,
    name: "Concordia Electric Coop, Inc",
    type: "Bundled",
    state: "LA",
    thenumber: 0.10370838479878207,
  },
  {
    eiaid: 5202,
    name: "Dixie Electric Membership Corp",
    type: "Bundled",
    state: "LA",
    thenumber: 0.10102393617021277,
  },
  {
    eiaid: 11241,
    name: "Entergy Louisiana LLC",
    type: "Bundled",
    state: "LA",
    thenumber: 0.08978802057368637,
  },
  {
    eiaid: 13478,
    name: "Entergy New Orleans, LLC",
    type: "Bundled",
    state: "LA",
    thenumber: 0.10147045950000212,
  },
  {
    eiaid: 9682,
    name: "Jefferson Davis Elec Coop, Inc",
    type: "Bundled",
    state: "LA",
    thenumber: 0.10909659035956344,
  },
  {
    eiaid: 12927,
    name: "Morgan City - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.11920501063168096,
  },
  {
    eiaid: 13783,
    name: "Northeast Louisiana Power Coop Inc.",
    type: "Bundled",
    state: "LA",
    thenumber: 0.0858789683439391,
  },
  {
    eiaid: 14424,
    name: "Panola-Harrison Elec Coop, Inc",
    type: "Bundled",
    state: "LA",
    thenumber: 0.09047081720902406,
  },
  {
    eiaid: 15175,
    name: "Pointe Coupee Elec Member Corp",
    type: "Bundled",
    state: "LA",
    thenumber: 0.10828415590005049,
  },
  {
    eiaid: 17565,
    name: "South Louisiana Elec Coop Assn",
    type: "Bundled",
    state: "LA",
    thenumber: 0.09364582442546579,
  },
  {
    eiaid: 17684,
    name: "Southwest Louisiana E M C",
    type: "Bundled",
    state: "LA",
    thenumber: 0.09490039404682203,
  },
  {
    eiaid: 17698,
    name: "Southwestern Electric Power Co",
    type: "Bundled",
    state: "LA",
    thenumber: 0.10800429858530367,
  },
  {
    eiaid: 8884,
    name: "Terrebonne Parish Consol Gov't",
    type: "Bundled",
    state: "LA",
    thenumber: 0.08974294453484535,
  },
  {
    eiaid: 19828,
    name: "Town of Vidalia - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.1401755241345685,
  },
  {
    eiaid: 19866,
    name: "Town of Vinton - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.08932710966591494,
  },
  {
    eiaid: 20343,
    name: "Town of Welsh - (LA)",
    type: "Bundled",
    state: "LA",
    thenumber: 0.06050207020030587,
  },
  {
    eiaid: 21567,
    name: "Washington-St Tammany E C, Inc",
    type: "Bundled",
    state: "LA",
    thenumber: 0.09560041454281794,
  },
  {
    eiaid: 59472,
    name: "Agera Energy LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.10461405125806496,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.1354758082542521,
  },
  {
    eiaid: 3477,
    name: "City of Chicopee - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.1350279265333445,
  },
  {
    eiaid: 8796,
    name: "City of Hingham - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.16748774090546242,
  },
  {
    eiaid: 8774,
    name: "City of Holyoke - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.13372590174129353,
  },
  {
    eiaid: 11624,
    name: "City of Marblehead - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.17066361428292248,
  },
  {
    eiaid: 13839,
    name: "City of Norwood - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.1773310016561085,
  },
  {
    eiaid: 14605,
    name: "City of Peabody - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.11085174771168604,
  },
  {
    eiaid: 18488,
    name: "City of Taunton",
    type: "Bundled",
    state: "MA",
    thenumber: 0.15395700148219124,
  },
  {
    eiaid: 20481,
    name: "City of Westfield - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.1412505729685302,
  },
  {
    eiaid: 58951,
    name: "CleanChoice Energy, Inc.",
    type: "Energy",
    state: "MA",
    thenumber: 0.18448255609068484,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "MA",
    thenumber: 0.13776721245390847,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "MA",
    thenumber: 0.11362420625236466,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "MA",
    thenumber: 0.11867503842661555,
  },
  {
    eiaid: 59993,
    name: "Discount Power Inc - (CT)",
    type: "Energy",
    state: "MA",
    thenumber: 0.1781007393715342,
  },
  {
    eiaid: 59385,
    name: "Dynegy Energy Services, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.10748919676295735,
  },
  {
    eiaid: 59054,
    name: "Eligo Energy, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.16751690331304936,
  },
  {
    eiaid: 56265,
    name: "Energy Plus Holdings LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.168724509749148,
  },
  {
    eiaid: 55874,
    name: "Energy Services Providers, Inc",
    type: "Energy",
    state: "MA",
    thenumber: 0.1743033496424539,
  },
  {
    eiaid: 57067,
    name: "ENGIE Retail, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.12896746058369674,
  },
  {
    eiaid: 59793,
    name: "First Point Power, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.11266089080914787,
  },
  {
    eiaid: 6374,
    name: "Fitchburg Gas & Elec Light Co",
    type: "Delivery",
    state: "MA",
    thenumber: 0.13247639034627492,
  },
  {
    eiaid: 6374,
    name: "Fitchburg Gas & Elec Light Co",
    type: "Bundled",
    state: "MA",
    thenumber: 0.2500975332032891,
  },
  {
    eiaid: 7554,
    name: "Green Mountain Energy Company",
    type: "Energy",
    state: "MA",
    thenumber: 0.16688528978717707,
  },
  {
    eiaid: 8797,
    name: "Hull Municipal Light Plant",
    type: "Bundled",
    state: "MA",
    thenumber: 0.16616610835162296,
  },
  {
    eiaid: 59310,
    name: "Inspire Energy Holdings, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.1585010383793891,
  },
  {
    eiaid: 9949,
    name: "Just Energy",
    type: "Energy",
    state: "MA",
    thenumber: 0.1678876514502509,
  },
  {
    eiaid: 55781,
    name: "Liberty Power Corp.",
    type: "Energy",
    state: "MA",
    thenumber: 0.16690156940423587,
  },
  {
    eiaid: 56504,
    name: "Major Energy Electric Services",
    type: "Energy",
    state: "MA",
    thenumber: 0.1736689871556035,
  },
  {
    eiaid: 11804,
    name: "Massachusetts Electric Co",
    type: "Delivery",
    state: "MA",
    thenumber: 0.11249189030636941,
  },
  {
    eiaid: 11804,
    name: "Massachusetts Electric Co",
    type: "Bundled",
    state: "MA",
    thenumber: 0.23832324562393048,
  },
  {
    eiaid: 58799,
    name: "Mega Energy of New England, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.14167783204391235,
  },
  {
    eiaid: 13206,
    name: "Nantucket Electric Co",
    type: "Delivery",
    state: "MA",
    thenumber: 0.12834000621938896,
  },
  {
    eiaid: 13206,
    name: "Nantucket Electric Co",
    type: "Bundled",
    state: "MA",
    thenumber: 0.24776091954022988,
  },
  {
    eiaid: 63232,
    name: "National Gas & Electric, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.1449446172314072,
  },
  {
    eiaid: 7279,
    name: "NextEra Energy Services, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.10556366295164046,
  },
  {
    eiaid: 54913,
    name: "NSTAR Electric Company",
    type: "Delivery",
    state: "MA",
    thenumber: 0.11312531207635436,
  },
  {
    eiaid: 54913,
    name: "NSTAR Electric Company",
    type: "Bundled",
    state: "MA",
    thenumber: 0.2252804867562947,
  },
  {
    eiaid: 59313,
    name: "Palmco Power MA, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.16539453238392843,
  },
  {
    eiaid: 58747,
    name: "Provider Power MASS, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.1316889671920385,
  },
  {
    eiaid: 58119,
    name: "Public Power LLC (CT)",
    type: "Energy",
    state: "MA",
    thenumber: 0.10477697952553007,
  },
  {
    eiaid: 57037,
    name: "Reliant Energy Northeast LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.15460405812912004,
  },
  {
    eiaid: 61059,
    name: "Renaissance Power & Gas, Inc.",
    type: "Energy",
    state: "MA",
    thenumber: 0.12644307052579745,
  },
  {
    eiaid: 59809,
    name: "Residents Energy, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.16454299506657472,
  },
  {
    eiaid: 59734,
    name: "SFE Energy Massachusetts, Inc.",
    type: "Energy",
    state: "MA",
    thenumber: 0.11159183998091224,
  },
  {
    eiaid: 58956,
    name: "SmartEnergy Holdings, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.16519890520372504,
  },
  {
    eiaid: 59794,
    name: "Starion Energy, Inc.",
    type: "Energy",
    state: "MA",
    thenumber: 0.14642715225522412,
  },
  {
    eiaid: 61200,
    name: "Summer Energy Northeast, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.12588166144200627,
  },
  {
    eiaid: 902,
    name: "Town of Ashburnham - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.1354903758020165,
  },
  {
    eiaid: 1523,
    name: "Town of Belmont - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.2093124570559771,
  },
  {
    eiaid: 2086,
    name: "Town of Boylston - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.11889545974818772,
  },
  {
    eiaid: 2144,
    name: "Town of Braintree - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.14319505736981464,
  },
  {
    eiaid: 4147,
    name: "Town of Concord - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.16722481113780427,
  },
  {
    eiaid: 5480,
    name: "Town of Danvers",
    type: "Bundled",
    state: "MA",
    thenumber: 0.14126620074864227,
  },
  {
    eiaid: 7130,
    name: "Town of Georgetown - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.1792679576981184,
  },
  {
    eiaid: 7715,
    name: "Town of Groton - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.13104620334911965,
  },
  {
    eiaid: 7723,
    name: "Town of Groveland - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.16717902350813743,
  },
  {
    eiaid: 8715,
    name: "Town of Holden - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.14103277674706247,
  },
  {
    eiaid: 8973,
    name: "Town of Hudson - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.11016537732870224,
  },
  {
    eiaid: 9442,
    name: "Town of Ipswich - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.1529774644340929,
  },
  {
    eiaid: 11085,
    name: "Town of Littleton - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.12769820931658465,
  },
  {
    eiaid: 11586,
    name: "Town of Mansfield - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.12366541043308336,
  },
  {
    eiaid: 12296,
    name: "Town of Merrimac - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.17509040812812124,
  },
  {
    eiaid: 12473,
    name: "Town of Middleborough - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.14900228072824367,
  },
  {
    eiaid: 12477,
    name: "Town of Middleton",
    type: "Bundled",
    state: "MA",
    thenumber: 0.1368852237393538,
  },
  {
    eiaid: 13679,
    name: "Town of North Attleborough - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.15945786356171462,
  },
  {
    eiaid: 14585,
    name: "Town of Paxton - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.14816180991024222,
  },
  {
    eiaid: 15371,
    name: "Town of Princeton - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.2621786197564276,
  },
  {
    eiaid: 15748,
    name: "Town of Reading - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.15201184194660314,
  },
  {
    eiaid: 16377,
    name: "Town of Rowley - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.18081056334634477,
  },
  {
    eiaid: 17127,
    name: "Town of Shrewsbury - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.12042849911988457,
  },
  {
    eiaid: 17560,
    name: "Town of South Hadley - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.12784573077117745,
  },
  {
    eiaid: 18087,
    name: "Town of Sterling - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.1487153482082488,
  },
  {
    eiaid: 18546,
    name: "Town of Templeton - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.13165691569156915,
  },
  {
    eiaid: 19979,
    name: "Town of Wakefield - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.16240257111043627,
  },
  {
    eiaid: 20310,
    name: "Town of Wellesley - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.14849055302037237,
  },
  {
    eiaid: 20326,
    name: "Town of West Boylston - (MA)",
    type: "Bundled",
    state: "MA",
    thenumber: 0.13067619989351184,
  },
  {
    eiaid: 59059,
    name: "Town Square Energy",
    type: "Energy",
    state: "MA",
    thenumber: 0.13243622436887956,
  },
  {
    eiaid: 59620,
    name: "Verde Energy USA",
    type: "Energy",
    state: "MA",
    thenumber: 0.1516583006554353,
  },
  {
    eiaid: 58161,
    name: "Viridian Energy LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.14671823131058292,
  },
  {
    eiaid: 57489,
    name: "XOOM Energy Massachusetts, LLC",
    type: "Energy",
    state: "MA",
    thenumber: 0.12398551629416905,
  },
  {
    eiaid: 55722,
    name: "AEP Energy",
    type: "Energy",
    state: "MD",
    thenumber: 0.068826139923425,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.09723412543825477,
  },
  {
    eiaid: 1167,
    name: "Baltimore Gas & Electric Co",
    type: "Delivery",
    state: "MD",
    thenumber: 0.04897031821426141,
  },
  {
    eiaid: 1167,
    name: "Baltimore Gas & Electric Co",
    type: "Bundled",
    state: "MD",
    thenumber: 0.1225124879916155,
  },
  {
    eiaid: 3503,
    name: "Choptank Electric Cooperative, Inc",
    type: "Bundled",
    state: "MD",
    thenumber: 0.14697964500467098,
  },
  {
    eiaid: 58951,
    name: "CleanChoice Energy, Inc.",
    type: "Energy",
    state: "MD",
    thenumber: 0.11786632390745502,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "MD",
    thenumber: 0.09949974917786077,
  },
  {
    eiaid: 4100,
    name: "Commerce Energy, Inc.",
    type: "Energy",
    state: "MD",
    thenumber: 0.09615136564444875,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "MD",
    thenumber: 0.07496298216144807,
  },
  {
    eiaid: 5027,
    name: "Delmarva Power",
    type: "Delivery",
    state: "MD",
    thenumber: 0.06984492016037916,
  },
  {
    eiaid: 5027,
    name: "Delmarva Power",
    type: "Bundled",
    state: "MD",
    thenumber: 0.14393005923632218,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "MD",
    thenumber: 0.09745243119907931,
  },
  {
    eiaid: 5625,
    name: "Easton Utilities Comm",
    type: "Bundled",
    state: "MD",
    thenumber: 0.09878925360080898,
  },
  {
    eiaid: 59054,
    name: "Eligo Energy, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.09877494768005717,
  },
  {
    eiaid: 56265,
    name: "Energy Plus Holdings LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.1488146551724138,
  },
  {
    eiaid: 55874,
    name: "Energy Services Providers, Inc",
    type: "Energy",
    state: "MD",
    thenumber: 0.12280559713218885,
  },
  {
    eiaid: 57067,
    name: "ENGIE Retail, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.08708250852882955,
  },
  {
    eiaid: 59064,
    name: "Great American Power, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.10155925155925156,
  },
  {
    eiaid: 7554,
    name: "Green Mountain Energy Company",
    type: "Energy",
    state: "MD",
    thenumber: 0.13472599456782233,
  },
  {
    eiaid: 7908,
    name: "Hagerstown Light Department",
    type: "Bundled",
    state: "MD",
    thenumber: 0.07883132236212494,
  },
  {
    eiaid: 59062,
    name: "Horizon Power and Light, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.15485756026296568,
  },
  {
    eiaid: 55815,
    name: "IDT Energy, Inc.",
    type: "Energy",
    state: "MD",
    thenumber: 0.1253550433618532,
  },
  {
    eiaid: 59310,
    name: "Inspire Energy Holdings, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.11697114866705954,
  },
  {
    eiaid: 58957,
    name: "Interstate Gas Supply, Inc.",
    type: "Energy",
    state: "MD",
    thenumber: 0.09451284228401613,
  },
  {
    eiaid: 63028,
    name: "Josco Energy USA LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.11151478067994952,
  },
  {
    eiaid: 55781,
    name: "Liberty Power Corp.",
    type: "Energy",
    state: "MD",
    thenumber: 0.1077431168661304,
  },
  {
    eiaid: 60219,
    name: "LifeEnergy, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.08497095226067189,
  },
  {
    eiaid: 56504,
    name: "Major Energy Electric Services",
    type: "Energy",
    state: "MD",
    thenumber: 0.16283593218141149,
  },
  {
    eiaid: 60218,
    name: "MPower Energy NJ LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.12062043795620438,
  },
  {
    eiaid: 63232,
    name: "National Gas & Electric, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.10576392133542879,
  },
  {
    eiaid: 58667,
    name: "North American Power and Gas, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.1289718501884674,
  },
  {
    eiaid: 57185,
    name: "Palmco Power MD, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.15775533852011256,
  },
  {
    eiaid: 15270,
    name: "Potomac Electric Power Co",
    type: "Delivery",
    state: "MD",
    thenumber: 0.07007969836762705,
  },
  {
    eiaid: 15270,
    name: "Potomac Electric Power Co",
    type: "Bundled",
    state: "MD",
    thenumber: 0.1470431896741727,
  },
  {
    eiaid: 58116,
    name: "Public Power & Utility of Maryland, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.14395730793922534,
  },
  {
    eiaid: 57037,
    name: "Reliant Energy Northeast LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.11402560581225357,
  },
  {
    eiaid: 59084,
    name: "SFE Energy Maryland, Inc",
    type: "Energy",
    state: "MD",
    thenumber: 0.07927730085845477,
  },
  {
    eiaid: 58956,
    name: "SmartEnergy Holdings, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.10000742917519645,
  },
  {
    eiaid: 17637,
    name: "Southern Maryland Elec Coop Inc",
    type: "Delivery",
    state: "MD",
    thenumber: 0.051482096714654854,
  },
  {
    eiaid: 17637,
    name: "Southern Maryland Elec Coop Inc",
    type: "Bundled",
    state: "MD",
    thenumber: 0.12444420508097567,
  },
  {
    eiaid: 17710,
    name: "Spark Energy, LP",
    type: "Energy",
    state: "MD",
    thenumber: 0.15204565704240613,
  },
  {
    eiaid: 60124,
    name: "Spring Energy RRH LLC dba Spring Power &",
    type: "Energy",
    state: "MD",
    thenumber: 0.09866666666666667,
  },
  {
    eiaid: 58953,
    name: "Star Energy Partners, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.094277126186126,
  },
  {
    eiaid: 59795,
    name: "Starion Energy PA, Inc.",
    type: "Energy",
    state: "MD",
    thenumber: 0.1402115878979632,
  },
  {
    eiaid: 62154,
    name: "StateWise Energy Maryland LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.1725736657737482,
  },
  {
    eiaid: 56876,
    name: "Stream Energy Maryland, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.11228363910767344,
  },
  {
    eiaid: 15263,
    name: "The Potomac Edison Company",
    type: "Delivery",
    state: "MD",
    thenumber: 0.03683039293197914,
  },
  {
    eiaid: 15263,
    name: "The Potomac Edison Company",
    type: "Bundled",
    state: "MD",
    thenumber: 0.10280935221063224,
  },
  {
    eiaid: 18901,
    name: "Thurmont Municipal Light Co",
    type: "Bundled",
    state: "MD",
    thenumber: 0.09108369438867166,
  },
  {
    eiaid: 59065,
    name: "Titan Gas LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.11285413037498855,
  },
  {
    eiaid: 58952,
    name: "Tomorow Energy Corp.",
    type: "Energy",
    state: "MD",
    thenumber: 0.11611562836302762,
  },
  {
    eiaid: 1615,
    name: "Town of Berlin - (MD)",
    type: "Bundled",
    state: "MD",
    thenumber: 0.11867072175654625,
  },
  {
    eiaid: 58162,
    name: "Viridian Energy PA LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.14510415565189808,
  },
  {
    eiaid: 20659,
    name: "WGL Energy Services, Inc.",
    type: "Energy",
    state: "MD",
    thenumber: 0.08461538461538462,
  },
  {
    eiaid: 57490,
    name: "XOOM Energy Maryland, LLC",
    type: "Energy",
    state: "MD",
    thenumber: 0.09566845882167682,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "ME",
    thenumber: 0.099456505943558,
  },
  {
    eiaid: 58196,
    name: "C. N. Brown Electricity, LLC",
    type: "Energy",
    state: "ME",
    thenumber: 0.08404379717231848,
  },
  {
    eiaid: 3266,
    name: "Central Maine Power Co",
    type: "Delivery",
    state: "ME",
    thenumber: 0.08310497361087822,
  },
  {
    eiaid: 3266,
    name: "Central Maine Power Co",
    type: "Bundled",
    state: "ME",
    thenumber: 0.17321459516645238,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "ME",
    thenumber: 0.17378108132023715,
  },
  {
    eiaid: 5609,
    name: "Eastern Maine Electric Coop",
    type: "Bundled",
    state: "ME",
    thenumber: 0.17289221673481672,
  },
  {
    eiaid: 58085,
    name: "Electricity Maine, LLC",
    type: "Energy",
    state: "ME",
    thenumber: 0.12088744340368052,
  },
  {
    eiaid: 57067,
    name: "ENGIE Retail, LLC",
    type: "Energy",
    state: "ME",
    thenumber: 0.0954416479345543,
  },
  {
    eiaid: 58158,
    name: "FairPoint Energy LLC",
    type: "Energy",
    state: "ME",
    thenumber: 0.14333280440071935,
  },
  {
    eiaid: 8780,
    name: "Fox Islands Electric Coop, Inc",
    type: "Bundled",
    state: "ME",
    thenumber: 0.3117485944932968,
  },
  {
    eiaid: 8883,
    name: "Houlton Water Company",
    type: "Bundled",
    state: "ME",
    thenumber: 0.10554709317085555,
  },
  {
    eiaid: 10144,
    name: "Kennebunk Light & Power Dist",
    type: "Bundled",
    state: "ME",
    thenumber: 0.14141674260132325,
  },
  {
    eiaid: 58667,
    name: "North American Power and Gas, LLC",
    type: "Energy",
    state: "ME",
    thenumber: 0.12019513991163476,
  },
  {
    eiaid: 11477,
    name: "Town of Madison - (ME)",
    type: "Bundled",
    state: "ME",
    thenumber: 0.1608768775735081,
  },
  {
    eiaid: 1179,
    name: "Versant Power",
    type: "Delivery",
    state: "ME",
    thenumber: 0.10435789516080311,
  },
  {
    eiaid: 1179,
    name: "Versant Power",
    type: "Bundled",
    state: "ME",
    thenumber: 0.15462393069168082,
  },
  {
    eiaid: 1179,
    name: "Versant Power",
    type: "Bundled",
    state: "ME",
    thenumber: 0.20550840797008196,
  },
  {
    eiaid: 305,
    name: "Alger-Delta Coop Electric Assn",
    type: "Bundled",
    state: "MI",
    thenumber: 0.20622094085639453,
  },
  {
    eiaid: 392,
    name: "Alpena Power Co",
    type: "Bundled",
    state: "MI",
    thenumber: 0.14551434440418215,
  },
  {
    eiaid: 3436,
    name: "Cherryland Electric Coop Inc",
    type: "Bundled",
    state: "MI",
    thenumber: 0.14598173615817972,
  },
  {
    eiaid: 1366,
    name: "City of Bay City - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1304514244201942,
  },
  {
    eiaid: 3384,
    name: "City of Charlevoix - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1111331832428376,
  },
  {
    eiaid: 4604,
    name: "City of Crystal Falls",
    type: "Bundled",
    state: "MI",
    thenumber: 0.15734621250635486,
  },
  {
    eiaid: 5343,
    name: "City of Dowagiac",
    type: "Bundled",
    state: "MI",
    thenumber: 0.14637596446107085,
  },
  {
    eiaid: 5630,
    name: "City of Eaton Rapids - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.12354015608568107,
  },
  {
    eiaid: 24558,
    name: "City of Escanaba",
    type: "Bundled",
    state: "MI",
    thenumber: 0.12422306877094492,
  },
  {
    eiaid: 7265,
    name: "City of Gladstone",
    type: "Bundled",
    state: "MI",
    thenumber: 0.13342748372835564,
  },
  {
    eiaid: 7483,
    name: "City of Grand Haven - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.14157726669297838,
  },
  {
    eiaid: 8083,
    name: "City of Harbor Springs - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.08918522721899087,
  },
  {
    eiaid: 8723,
    name: "City of Holland",
    type: "Bundled",
    state: "MI",
    thenumber: 0.12141811455224304,
  },
  {
    eiaid: 10704,
    name: "City of Lansing - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.15824539300386495,
  },
  {
    eiaid: 11268,
    name: "City of Lowell - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.12502680677675315,
  },
  {
    eiaid: 11701,
    name: "City of Marquette - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1674020564883509,
  },
  {
    eiaid: 11713,
    name: "City of Marshall - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.13303980274744628,
  },
  {
    eiaid: 13352,
    name: "City of Negaunee",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1654209815398469,
  },
  {
    eiaid: 13604,
    name: "City of Niles - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1133924993989903,
  },
  {
    eiaid: 13826,
    name: "City of Norway",
    type: "Bundled",
    state: "MI",
    thenumber: 0.15158799533799533,
  },
  {
    eiaid: 14867,
    name: "City of Petoskey - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.11404849436160105,
  },
  {
    eiaid: 15246,
    name: "City of Portland - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.11674103049935823,
  },
  {
    eiaid: 17562,
    name: "City of South Haven - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.11000020408579766,
  },
  {
    eiaid: 17886,
    name: "City of St Louis - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1313485394157663,
  },
  {
    eiaid: 18252,
    name: "City of Sturgis",
    type: "Bundled",
    state: "MI",
    thenumber: 0.14186692277178112,
  },
  {
    eiaid: 19125,
    name: "City of Traverse City - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.10492002937330969,
  },
  {
    eiaid: 21158,
    name: "City of Zeeland - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.08582070047046524,
  },
  {
    eiaid: 3828,
    name: "Cloverland Electric Co-op",
    type: "Bundled",
    state: "MI",
    thenumber: 0.12907259949892877,
  },
  {
    eiaid: 3915,
    name: "Coldwater Board of Public Util",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1143015708322274,
  },
  {
    eiaid: 4254,
    name: "Consumers Energy Co",
    type: "Bundled",
    state: "MI",
    thenumber: 0.15850346424023004,
  },
  {
    eiaid: 5109,
    name: "DTE Electric Company",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1610863448846418,
  },
  {
    eiaid: 38084,
    name: "Great Lakes Energy Coop",
    type: "Bundled",
    state: "MI",
    thenumber: 0.157240125949284,
  },
  {
    eiaid: 8631,
    name: "Hillsdale Board of Public Wks",
    type: "Bundled",
    state: "MI",
    thenumber: 0.12000929824108272,
  },
  {
    eiaid: 9324,
    name: "Indiana Michigan Power Co",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1352244463581936,
  },
  {
    eiaid: 12377,
    name: "Midwest Energy Cooperative",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1541271607729599,
  },
  {
    eiaid: 13780,
    name: "Northern States Power Co",
    type: "Bundled",
    state: "MI",
    thenumber: 0.12291233901121727,
  },
  {
    eiaid: 14156,
    name: "Ontonagon County R E A",
    type: "Bundled",
    state: "MI",
    thenumber: 0.2684348496530455,
  },
  {
    eiaid: 15340,
    name: "Presque Isle Elec & Gas Coop",
    type: "Bundled",
    state: "MI",
    thenumber: 0.15283889365451045,
  },
  {
    eiaid: 18895,
    name: "Thumb Electric Coop of Mich",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1347723291781543,
  },
  {
    eiaid: 19396,
    name: "Tri-County Electric Coop (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.14865606243040405,
  },
  {
    eiaid: 60631,
    name: "Upper Michigan Energy Resources Corp.",
    type: "Bundled",
    state: "MI",
    thenumber: 0.14102314934784488,
  },
  {
    eiaid: 19578,
    name: "Upper Peninsula Power Company",
    type: "Bundled",
    state: "MI",
    thenumber: 0.21970069874678208,
  },
  {
    eiaid: 3415,
    name: "Village of Chelsea - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.09835521638147604,
  },
  {
    eiaid: 3813,
    name: "Village of Clinton - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.1398975029985825,
  },
  {
    eiaid: 14581,
    name: "Village of Paw Paw - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.12982456140350876,
  },
  {
    eiaid: 19432,
    name: "Village of Union City - (MI)",
    type: "Bundled",
    state: "MI",
    thenumber: 0.11928811928811929,
  },
  {
    eiaid: 21048,
    name: "Wyandotte Municipal Serv Comm",
    type: "Bundled",
    state: "MI",
    thenumber: 0.14659734518831077,
  },
  {
    eiaid: 155,
    name: "Agralite Electric Coop",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12179838378304342,
  },
  {
    eiaid: 174,
    name: "Aitkin Public Utilities Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10842814023682378,
  },
  {
    eiaid: 12647,
    name: "ALLETE, Inc.",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11080660774229077,
  },
  {
    eiaid: 887,
    name: "Arrowhead Electric Coop, Inc",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1486236166326421,
  },
  {
    eiaid: 1529,
    name: "Beltrami Electric Coop, Inc",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1355390228684589,
  },
  {
    eiaid: 1884,
    name: "BENCO Electric Cooperative",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13727568692641012,
  },
  {
    eiaid: 1883,
    name: "Blue Earth Light & Water",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11615591018217766,
  },
  {
    eiaid: 2138,
    name: "Brainerd Public Utilities",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12098929177475667,
  },
  {
    eiaid: 2316,
    name: "Brown County Rural Elec Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11863926605504588,
  },
  {
    eiaid: 295,
    name: "City of Alexandria - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10356114367993417,
  },
  {
    eiaid: 691,
    name: "City of Anoka",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13514180024660913,
  },
  {
    eiaid: 1009,
    name: "City of Austin - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13873799509977333,
  },
  {
    eiaid: 1233,
    name: "City of Barnesville - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.09261780411947655,
  },
  {
    eiaid: 1573,
    name: "City of Benson - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.09215227700903007,
  },
  {
    eiaid: 2182,
    name: "City of Breckenridge- (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.0788083884530585,
  },
  {
    eiaid: 2485,
    name: "City of Buffalo - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13711005979683485,
  },
  {
    eiaid: 2810,
    name: "City of Caledonia - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13794942921156514,
  },
  {
    eiaid: 3400,
    name: "City of Chaska - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1420734660582732,
  },
  {
    eiaid: 5111,
    name: "City of Detroit Lakes - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10399196798506724,
  },
  {
    eiaid: 5575,
    name: "City of East Grand Forks - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10934331617532367,
  },
  {
    eiaid: 5773,
    name: "City of Elk River",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13699299899093925,
  },
  {
    eiaid: 5841,
    name: "City of Ely - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10201304856967602,
  },
  {
    eiaid: 6627,
    name: "City of Fosston - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13159473203807537,
  },
  {
    eiaid: 7487,
    name: "City of Grand Marais - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12449756716733658,
  },
  {
    eiaid: 7494,
    name: "City of Granite Falls - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.14839513631900914,
  },
  {
    eiaid: 9599,
    name: "City of Jackson - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1339807992855548,
  },
  {
    eiaid: 9650,
    name: "City of Janesville - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12741116751269035,
  },
  {
    eiaid: 10040,
    name: "City of Kasson",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13006923837784373,
  },
  {
    eiaid: 10595,
    name: "City of Lake City - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11227255129694154,
  },
  {
    eiaid: 10596,
    name: "City of Lake Crystal - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1892599053077498,
  },
  {
    eiaid: 10814,
    name: "City of Le Sueur - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13387827998351423,
  },
  {
    eiaid: 11332,
    name: "City of Luverne - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10403273185060848,
  },
  {
    eiaid: 29305,
    name: "City of Madelia - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.15902485399972838,
  },
  {
    eiaid: 11731,
    name: "City of Marshall - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.0901169752233582,
  },
  {
    eiaid: 12894,
    name: "City of Moorhead - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10612236994251634,
  },
  {
    eiaid: 12900,
    name: "City of Mora - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11300476106506789,
  },
  {
    eiaid: 13730,
    name: "City of North St Paul - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.14618783969116675,
  },
  {
    eiaid: 14107,
    name: "City of Olivia - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.09126891734575088,
  },
  {
    eiaid: 14196,
    name: "City of Ortonville - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.09701434664598682,
  },
  {
    eiaid: 14246,
    name: "City of Owatonna - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12903186543002507,
  },
  {
    eiaid: 16285,
    name: "City of Roseau - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11454842982934621,
  },
  {
    eiaid: 17900,
    name: "City of Saint Peter",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13826040554962646,
  },
  {
    eiaid: 16679,
    name: "City of Sauk Centre - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.09634961439588689,
  },
  {
    eiaid: 17862,
    name: "City of St Charles - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.15009162085623856,
  },
  {
    eiaid: 17876,
    name: "City of St James - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.09907790320777915,
  },
  {
    eiaid: 17983,
    name: "City of Staples- (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13616071428571427,
  },
  {
    eiaid: 18820,
    name: "City of Thief River Falls",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12333995203589572,
  },
  {
    eiaid: 19321,
    name: "City of Two Harbors- (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.16207596404755,
  },
  {
    eiaid: 19883,
    name: "City of Virginia - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12724018509356538,
  },
  {
    eiaid: 19947,
    name: "City of Wadena - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.09695207812854872,
  },
  {
    eiaid: 20136,
    name: "City of Waseca - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1298666359199047,
  },
  {
    eiaid: 29295,
    name: "City of Wells - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13403223988276405,
  },
  {
    eiaid: 20806,
    name: "City of Windom",
    type: "Bundled",
    state: "MN",
    thenumber: 0.0944686522786692,
  },
  {
    eiaid: 21013,
    name: "City of Worthington - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10989065197730824,
  },
  {
    eiaid: 3764,
    name: "Clearwater-Polk Elec Coop Inc",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13226596285932332,
  },
  {
    eiaid: 689,
    name: "Connexus Energy",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13441983904783547,
  },
  {
    eiaid: 4346,
    name: "Cooperative L&P Assn Lake County",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1346464680058534,
  },
  {
    eiaid: 4577,
    name: "Crow Wing Cooperative Power & Light Comp",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12052538435764994,
  },
  {
    eiaid: 25177,
    name: "Dakota Electric Association",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13175430243448832,
  },
  {
    eiaid: 5015,
    name: "Delano Municipal Utilities",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11235651895550373,
  },
  {
    eiaid: 5574,
    name: "East Central Energy",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1546090253725177,
  },
  {
    eiaid: 6151,
    name: "Fairmont Public Utilities Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11797773903714631,
  },
  {
    eiaid: 6258,
    name: "Federated Rural Electric Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10073824774725872,
  },
  {
    eiaid: 6782,
    name: "Freeborn-Mower Coop Services",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1324796503800667,
  },
  {
    eiaid: 7292,
    name: "Glencoe Light & Power Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11022632839614802,
  },
  {
    eiaid: 7460,
    name: "Goodhue County Coop Elec Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13099975381034443,
  },
  {
    eiaid: 7489,
    name: "Grand Rapids Public Util Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10894510088299279,
  },
  {
    eiaid: 8307,
    name: "Hawley Public Utilities Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13408484129338474,
  },
  {
    eiaid: 8543,
    name: "Hibbing Public Utilities Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.17326846167562474,
  },
  {
    eiaid: 9130,
    name: "Hutchinson Utilities Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10566561088516971,
  },
  {
    eiaid: 9475,
    name: "Itasca-Mantrap Co-op Electrical Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12759719566602934,
  },
  {
    eiaid: 9991,
    name: "Kandiyohi Power Coop",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1300533382379989,
  },
  {
    eiaid: 10697,
    name: "Lake Country Power",
    type: "Bundled",
    state: "MN",
    thenumber: 0.14601024948993527,
  },
  {
    eiaid: 10618,
    name: "Lake Region Electric Cooperative - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11927721455435863,
  },
  {
    eiaid: 11064,
    name: "Litchfield Public Utilities",
    type: "Bundled",
    state: "MN",
    thenumber: 0.08858489132086943,
  },
  {
    eiaid: 11345,
    name: "Lyon-Lincoln Electric Cooperative, Inc.",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11533874419332951,
  },
  {
    eiaid: 11910,
    name: "McLeod Cooperative Power Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13101930808995713,
  },
  {
    eiaid: 12227,
    name: "Meeker Coop Light & Power Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1151874807382803,
  },
  {
    eiaid: 12286,
    name: "Melrose Public Utilities",
    type: "Bundled",
    state: "MN",
    thenumber: 0.09501571134467439,
  },
  {
    eiaid: 19157,
    name: "MiEnergy Cooperative",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1493339435445645,
  },
  {
    eiaid: 12546,
    name: "Mille Lacs Energy Cooperative",
    type: "Bundled",
    state: "MN",
    thenumber: 0.14449385753260474,
  },
  {
    eiaid: 40304,
    name: "Minnesota Valley Coop L&P Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1075279785497785,
  },
  {
    eiaid: 12651,
    name: "Minnesota Valley Electric Coop",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1174481874374071,
  },
  {
    eiaid: 13480,
    name: "New Prague Utilities Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1269922273690822,
  },
  {
    eiaid: 13488,
    name: "New Ulm Public Utilities Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.14189455261430758,
  },
  {
    eiaid: 13684,
    name: "Nobles Cooperative Electric",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11721854304635762,
  },
  {
    eiaid: 13681,
    name: "North Branch Water & Light Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.16683362171022675,
  },
  {
    eiaid: 13700,
    name: "North Itasca Electric Coop Inc",
    type: "Bundled",
    state: "MN",
    thenumber: 0.17187861941162844,
  },
  {
    eiaid: 13731,
    name: "North Star Electric Coop, Inc",
    type: "Bundled",
    state: "MN",
    thenumber: 0.145388795815797,
  },
  {
    eiaid: 13781,
    name: "Northern States Power Co - Minnesota",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13624895097797443,
  },
  {
    eiaid: 14232,
    name: "Otter Tail Power Co",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10721898779567074,
  },
  {
    eiaid: 14178,
    name: "P K M Electric Coop, Inc",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13299153485358536,
  },
  {
    eiaid: 14468,
    name: "People's Cooperative Services",
    type: "Bundled",
    state: "MN",
    thenumber: 0.14643690583408386,
  },
  {
    eiaid: 15387,
    name: "Princeton Public Utils Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.15065102488075285,
  },
  {
    eiaid: 15460,
    name: "Proctor Public Utilities Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.09438095931290487,
  },
  {
    eiaid: 26934,
    name: "Red Lake Electric Coop, Inc",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12272868516077877,
  },
  {
    eiaid: 26939,
    name: "Red River Valley Coop Pwr Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12675225214739635,
  },
  {
    eiaid: 15750,
    name: "Redwood Electric Coop",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12166986761780643,
  },
  {
    eiaid: 15793,
    name: "Redwood Falls Public Util Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.10616984789740531,
  },
  {
    eiaid: 15845,
    name: "Renville-Sibley Coop Pwr Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1266400792270014,
  },
  {
    eiaid: 16181,
    name: "Rochester Public Utilities",
    type: "Bundled",
    state: "MN",
    thenumber: 0.15186761015019065,
  },
  {
    eiaid: 16284,
    name: "Roseau Electric Coop, Inc",
    type: "Bundled",
    state: "MN",
    thenumber: 0.14202274526462128,
  },
  {
    eiaid: 16368,
    name: "Runestone Electric Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12158264110544993,
  },
  {
    eiaid: 16971,
    name: "Shakopee Public Utilities Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12768315358869467,
  },
  {
    eiaid: 17267,
    name: "Sioux Valley SW Elec Coop",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1102235469448584,
  },
  {
    eiaid: 17320,
    name: "Sleepy Eye Public Utility Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11311698545741099,
  },
  {
    eiaid: 17550,
    name: "South Central Electric Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.13338702002269484,
  },
  {
    eiaid: 17824,
    name: "Spring Valley Pub Utils Comm",
    type: "Bundled",
    state: "MN",
    thenumber: 0.17417345471969334,
  },
  {
    eiaid: 17836,
    name: "Springfield Public Utils Comm - (MN)",
    type: "Bundled",
    state: "MN",
    thenumber: 0.1505,
  },
  {
    eiaid: 18019,
    name: "Stearns Cooperative Elec Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11531861842723332,
  },
  {
    eiaid: 18047,
    name: "Steele-Waseca Cooperative Electric",
    type: "Bundled",
    state: "MN",
    thenumber: 0.14259657346048166,
  },
  {
    eiaid: 27422,
    name: "Todd-Wadena Electric Coop",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11075645598070814,
  },
  {
    eiaid: 19060,
    name: "Traverse Electric Coop, Inc",
    type: "Bundled",
    state: "MN",
    thenumber: 0.11601629220864539,
  },
  {
    eiaid: 20639,
    name: "Wild Rice Electric Coop, Inc",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12805815170488974,
  },
  {
    eiaid: 20737,
    name: "Willmar Municipal Utilities",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12476466545139407,
  },
  {
    eiaid: 20996,
    name: "Wright-Hennepin Coop Elec Assn",
    type: "Bundled",
    state: "MN",
    thenumber: 0.12334625350664925,
  },
  {
    eiaid: 942,
    name: "Atchison-Holt Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1370116489339333,
  },
  {
    eiaid: 1279,
    name: "Barry Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12439943964995416,
  },
  {
    eiaid: 1301,
    name: "Barton County Elec Coop, Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13779346324996164,
  },
  {
    eiaid: 1775,
    name: "Black River Electric Coop - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10664878290438141,
  },
  {
    eiaid: 2001,
    name: "Boone Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11061156517330083,
  },
  {
    eiaid: 4160,
    name: "Callaway Electric Cooperative",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13706388986167045,
  },
  {
    eiaid: 3093,
    name: "Carroll Electric Coop Corp - (AR)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09890917311931273,
  },
  {
    eiaid: 3082,
    name: "Carrollton Board of Public Wks",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12414409050312593,
  },
  {
    eiaid: 3268,
    name: "Central Missouri Elec Coop Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10586666528305999,
  },
  {
    eiaid: 3486,
    name: "Chillicothe Municipal Utils",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11227629959284159,
  },
  {
    eiaid: 3600,
    name: "Citizens Electric Corporation - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13890889752065003,
  },
  {
    eiaid: 228,
    name: "City of Albany - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.14474753337202553,
  },
  {
    eiaid: 1024,
    name: "City of Ava - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12143602225312934,
  },
  {
    eiaid: 1647,
    name: "City of Bethany - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11587591240875912,
  },
  {
    eiaid: 2647,
    name: "City of Butler - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12891431761094055,
  },
  {
    eiaid: 2851,
    name: "City of California - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10471121177802944,
  },
  {
    eiaid: 2900,
    name: "City of Cameron",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11313202472061225,
  },
  {
    eiaid: 3113,
    name: "City of Carthage - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11119431399923849,
  },
  {
    eiaid: 3296,
    name: "City of Centralia - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11339620360295007,
  },
  {
    eiaid: 4045,
    name: "City of Columbia - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12575998503414088,
  },
  {
    eiaid: 4608,
    name: "City of Cuba - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09529299714271391,
  },
  {
    eiaid: 5686,
    name: "City of El Dorado Springs - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11972696245733788,
  },
  {
    eiaid: 6205,
    name: "City of Farmington - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09803628331818652,
  },
  {
    eiaid: 6231,
    name: "City of Fayette - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12614265552057324,
  },
  {
    eiaid: 6761,
    name: "City of Fredericktown - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.06725519393036943,
  },
  {
    eiaid: 6839,
    name: "City of Fulton - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10344683080146673,
  },
  {
    eiaid: 6924,
    name: "City of Gallatin - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13333333333333333,
  },
  {
    eiaid: 8055,
    name: "City of Hannibal - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11369910885899003,
  },
  {
    eiaid: 8199,
    name: "City of Harrisonville - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12021148762316751,
  },
  {
    eiaid: 8493,
    name: "City of Hermann",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12579903756374344,
  },
  {
    eiaid: 8567,
    name: "City of Higginsville - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1352489357764205,
  },
  {
    eiaid: 9231,
    name: "City of Independence - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13664750692261288,
  },
  {
    eiaid: 9603,
    name: "City of Jackson - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12400600150037508,
  },
  {
    eiaid: 9973,
    name: "City of Kahoka",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11076741788186772,
  },
  {
    eiaid: 10152,
    name: "City of Kennett - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.08241821175459947,
  },
  {
    eiaid: 10370,
    name: "City of Kirkwood - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10758940649518522,
  },
  {
    eiaid: 10057,
    name: "City of Lamar - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11672574318257008,
  },
  {
    eiaid: 10832,
    name: "City of Lebanon - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09888918770389252,
  },
  {
    eiaid: 11460,
    name: "City of Macon - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1383342526199669,
  },
  {
    eiaid: 11540,
    name: "City of Malden - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.08959132987130278,
  },
  {
    eiaid: 11626,
    name: "City of Marceline - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12209501025290499,
  },
  {
    eiaid: 11732,
    name: "City of Marshall - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12054044294655752,
  },
  {
    eiaid: 12294,
    name: "City of Memphis - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1424831712789828,
  },
  {
    eiaid: 12782,
    name: "City of Monett - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11520807287015537,
  },
  {
    eiaid: 12802,
    name: "City of Monroe City - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09874845275752991,
  },
  {
    eiaid: 13040,
    name: "City of Mount Vernon - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12220356427062457,
  },
  {
    eiaid: 13470,
    name: "City of New Madrid - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.07157041540020263,
  },
  {
    eiaid: 3634,
    name: "City of Nixa - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1381393218208354,
  },
  {
    eiaid: 13971,
    name: "City of Odessa - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.111870989530564,
  },
  {
    eiaid: 14400,
    name: "City of Palmyra - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1228480092996222,
  },
  {
    eiaid: 14456,
    name: "City of Paris - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11427504434693306,
  },
  {
    eiaid: 15229,
    name: "City of Poplar Bluff - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09363504856375285,
  },
  {
    eiaid: 15981,
    name: "City of Richland - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1298616668572082,
  },
  {
    eiaid: 40382,
    name: "City of Rockport - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13490957803081044,
  },
  {
    eiaid: 16259,
    name: "City of Rolla - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10391298530002102,
  },
  {
    eiaid: 16560,
    name: "City of Salisbury - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12186489290291085,
  },
  {
    eiaid: 17035,
    name: "City of Shelbina - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1297845804988662,
  },
  {
    eiaid: 17177,
    name: "City of Sikeston - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.07790595849990481,
  },
  {
    eiaid: 17312,
    name: "City of Slater - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13552676831365357,
  },
  {
    eiaid: 17877,
    name: "City of St James - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11234963540940969,
  },
  {
    eiaid: 17918,
    name: "City of St Robert - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10379726973168053,
  },
  {
    eiaid: 18273,
    name: "City of Sullivan - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09270912569439642,
  },
  {
    eiaid: 18806,
    name: "City of Thayer - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.20090909090909093,
  },
  {
    eiaid: 19474,
    name: "City of Unionville - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13678474114441416,
  },
  {
    eiaid: 19722,
    name: "City of Vandalia - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13723820875191556,
  },
  {
    eiaid: 20224,
    name: "City of Waynesville - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12105849255655843,
  },
  {
    eiaid: 20392,
    name: "City of West Plains - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09923919804775348,
  },
  {
    eiaid: 17833,
    name: "City Utilities of Springfield - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09915850731484303,
  },
  {
    eiaid: 4063,
    name: "Co-Mo Electric Coop Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.126536084205224,
  },
  {
    eiaid: 4237,
    name: "Consolidated Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10515791901629695,
  },
  {
    eiaid: 4524,
    name: "Crawford Electric Coop, Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11176976422664785,
  },
  {
    eiaid: 4675,
    name: "Cuivre River Electric Coop Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09187832140149604,
  },
  {
    eiaid: 5860,
    name: "Empire District Electric Co",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13450012293498495,
  },
  {
    eiaid: 10000,
    name: "Evergy Metro",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13310430487487845,
  },
  {
    eiaid: 12698,
    name: "Evergy Missouri West",
    type: "Bundled",
    state: "MO",
    thenumber: 0.108952704388567,
  },
  {
    eiaid: 6181,
    name: "Farmers Electric Coop, Inc - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12329739105122078,
  },
  {
    eiaid: 7024,
    name: "Gascosage Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09701220058281723,
  },
  {
    eiaid: 7720,
    name: "Grundy Electric Coop, Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1280355989780735,
  },
  {
    eiaid: 8911,
    name: "Howard Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.15084275413415826,
  },
  {
    eiaid: 8934,
    name: "Howell-Oregon Elec Coop, Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11326943915847341,
  },
  {
    eiaid: 9331,
    name: "Intercounty Electric Coop Assn",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11198827722846919,
  },
  {
    eiaid: 10603,
    name: "Laclede Electric Coop, Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10267291915126665,
  },
  {
    eiaid: 10962,
    name: "Lewis County Rural E C A",
    type: "Bundled",
    state: "MO",
    thenumber: 0.14079985588182312,
  },
  {
    eiaid: 11463,
    name: "Macon Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12351345569020586,
  },
  {
    eiaid: 12700,
    name: "Missouri Rural Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11379330961705181,
  },
  {
    eiaid: 13520,
    name: "New-Mac Electric Coop, Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11754662830727548,
  },
  {
    eiaid: 13690,
    name: "North Central MO Elec Coop Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13421108970241918,
  },
  {
    eiaid: 14192,
    name: "Osage Valley Elec Coop Assn",
    type: "Bundled",
    state: "MO",
    thenumber: 0.12760818666236073,
  },
  {
    eiaid: 14285,
    name: "Ozark Border Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10250601806633786,
  },
  {
    eiaid: 14288,
    name: "Ozark Electric Coop Inc - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11914916774296536,
  },
  {
    eiaid: 14712,
    name: "Pemiscot-Dunklin Elec Coop Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11934766404578134,
  },
  {
    eiaid: 15138,
    name: "Platte-Clay Electric Coop, Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11136428848718079,
  },
  {
    eiaid: 15672,
    name: "Ralls County Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.14762362637362636,
  },
  {
    eiaid: 16511,
    name: "Sac-Osage Electric Coop Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13165337009665437,
  },
  {
    eiaid: 16851,
    name: "SE-MA-NO Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.09460139432629117,
  },
  {
    eiaid: 16805,
    name: "SEMO Electric Cooperative",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10553773883192556,
  },
  {
    eiaid: 27238,
    name: "Southwest Electric Coop, Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10894516594803189,
  },
  {
    eiaid: 16751,
    name: "Three Rivers Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10709906613909159,
  },
  {
    eiaid: 19150,
    name: "Trenton Municipal Utilities - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.11194652755135312,
  },
  {
    eiaid: 19158,
    name: "Tri-County Electric Coop Assn (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.14181049858683423,
  },
  {
    eiaid: 19436,
    name: "Union Electric Co - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.10378072879666886,
  },
  {
    eiaid: 22646,
    name: "United Electric Coop, Inc - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1566567816450757,
  },
  {
    eiaid: 20318,
    name: "Webster Electric Coop",
    type: "Bundled",
    state: "MO",
    thenumber: 0.08698031011059648,
  },
  {
    eiaid: 20363,
    name: "West Central Electric Coop Inc - (MO)",
    type: "Bundled",
    state: "MO",
    thenumber: 0.1067147493129202,
  },
  {
    eiaid: 20574,
    name: "White River Valley El Coop Inc",
    type: "Bundled",
    state: "MO",
    thenumber: 0.13292074967268974,
  },
  {
    eiaid: 6641,
    name: "4-County Electric Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1165418959600779,
  },
  {
    eiaid: 276,
    name: "Alcorn County Elec Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11463102245476346,
  },
  {
    eiaid: 2974,
    name: "Canton Municipal Utilities",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10047115225657133,
  },
  {
    eiaid: 2849,
    name: "Central Electric Power Assn - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11037221640216116,
  },
  {
    eiaid: 55,
    name: "City of Aberdeen - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1129927259135735,
  },
  {
    eiaid: 571,
    name: "City of Amory",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10636232389107383,
  },
  {
    eiaid: 3957,
    name: "City of Collins - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10013952369497234,
  },
  {
    eiaid: 4068,
    name: "City of Columbus - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1081641701238243,
  },
  {
    eiaid: 8748,
    name: "City of Holly Springs",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11189720109297689,
  },
  {
    eiaid: 10447,
    name: "City of Kosciusko - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.09409190371991247,
  },
  {
    eiaid: 10892,
    name: "City of Leland - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11203798767967146,
  },
  {
    eiaid: 11458,
    name: "City of Macon - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1166680675800622,
  },
  {
    eiaid: 13412,
    name: "City of New Albany - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10543067435846429,
  },
  {
    eiaid: 14078,
    name: "City of Okolona",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11152579940676033,
  },
  {
    eiaid: 14275,
    name: "City of Oxford - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10722745521068494,
  },
  {
    eiaid: 14947,
    name: "City of Philadelphia - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10421449872008022,
  },
  {
    eiaid: 18006,
    name: "City of Starkville",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10767501356694317,
  },
  {
    eiaid: 19273,
    name: "City of Tupelo - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.09687646282820546,
  },
  {
    eiaid: 20176,
    name: "City of Water Valley - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10556122922662706,
  },
  {
    eiaid: 20394,
    name: "City of West Point - (MS)",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11413285777975925,
  },
  {
    eiaid: 3702,
    name: "Clarksdale Public Utilities",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1128059829341416,
  },
  {
    eiaid: 3839,
    name: "Coahoma Electric Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11038108611174607,
  },
  {
    eiaid: 3841,
    name: "Coast Electric Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11614610220849911,
  },
  {
    eiaid: 22815,
    name: "Delta Electric Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.12288150935079656,
  },
  {
    eiaid: 5175,
    name: "Dixie Electric Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11995521609184497,
  },
  {
    eiaid: 5578,
    name: "East Mississippi Elec Pwr Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.13013751307450933,
  },
  {
    eiaid: 12685,
    name: "Entergy Mississippi LLC",
    type: "Bundled",
    state: "MS",
    thenumber: 0.09934233392297108,
  },
  {
    eiaid: 7651,
    name: "Greenwood Utilities Comm",
    type: "Bundled",
    state: "MS",
    thenumber: 0.09956030150753768,
  },
  {
    eiaid: 11247,
    name: "Louisville Electric System",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10222630395541059,
  },
  {
    eiaid: 11519,
    name: "Magnolia Electric Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.12500620242738197,
  },
  {
    eiaid: 12686,
    name: "Mississippi Power Co",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1340096063677825,
  },
  {
    eiaid: 40303,
    name: "Monroe County Elec Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11228217842918398,
  },
  {
    eiaid: 13227,
    name: "Natchez Trace Elec Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11367138852083321,
  },
  {
    eiaid: 40302,
    name: "North East Mississippi EPA",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11924539631705364,
  },
  {
    eiaid: 13735,
    name: "Northcentral Mississippi E P A",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1043204158416711,
  },
  {
    eiaid: 14563,
    name: "Pearl River Valley El Pwr Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1283698293409589,
  },
  {
    eiaid: 15211,
    name: "Pontotoc Electric Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11500866697670485,
  },
  {
    eiaid: 15334,
    name: "Prentiss County Elec Pwr Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.09651269887331407,
  },
  {
    eiaid: 21095,
    name: "Public Serv Comm of Yazoo City",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10344827586206896,
  },
  {
    eiaid: 17252,
    name: "Singing River Elec Cooperative",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11023505981857133,
  },
  {
    eiaid: 17647,
    name: "Southern Pine Electric Cooperative",
    type: "Bundled",
    state: "MS",
    thenumber: 0.12192516565528204,
  },
  {
    eiaid: 17683,
    name: "Southwest Mississippi E P A",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1238883502360243,
  },
  {
    eiaid: 18447,
    name: "Tallahatchie Valley E P A",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11172170376041646,
  },
  {
    eiaid: 18943,
    name: "Tippah Electric Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1135722943251304,
  },
  {
    eiaid: 18951,
    name: "Tishomingo County E P A",
    type: "Bundled",
    state: "MS",
    thenumber: 0.11019881651958476,
  },
  {
    eiaid: 19007,
    name: "Tombigbee Electric Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.10776652874372744,
  },
  {
    eiaid: 18961,
    name: "Twin County Electric Pwr Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.14825591114248035,
  },
  {
    eiaid: 21114,
    name: "Yazoo Valley Elec Power Assn",
    type: "Bundled",
    state: "MS",
    thenumber: 0.1456156669933392,
  },
  {
    eiaid: 1417,
    name: "Beartooth Electric Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.129588693957115,
  },
  {
    eiaid: 1671,
    name: "Big Flat Electric Coop Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.12290617958674911,
  },
  {
    eiaid: 1683,
    name: "Big Horn County Elec Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.118630892026103,
  },
  {
    eiaid: 6169,
    name: "Fall River Rural Elec Coop Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.10903426791277258,
  },
  {
    eiaid: 21513,
    name: "Fergus Electric Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.15373747792520356,
  },
  {
    eiaid: 6395,
    name: "Flathead Electric Coop Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.09573763090332381,
  },
  {
    eiaid: 7262,
    name: "Glacier Electric Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.10028242067102065,
  },
  {
    eiaid: 8632,
    name: "Hill County Electric Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.14094747231056037,
  },
  {
    eiaid: 11022,
    name: "Lincoln Electric Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.10185120093731694,
  },
  {
    eiaid: 11272,
    name: "Lower Yellowstone R E A, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.0871434153966393,
  },
  {
    eiaid: 11643,
    name: "Marias River Electric Coop Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.0888810988240543,
  },
  {
    eiaid: 11989,
    name: "McCone Electric Coop Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.13598927875243663,
  },
  {
    eiaid: 12463,
    name: "Mid-Yellowstone Elec Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.13322642281970792,
  },
  {
    eiaid: 12692,
    name: "Missoula Electric Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.1003686275439449,
  },
  {
    eiaid: 12199,
    name: "Montana-Dakota Utilities Co",
    type: "Bundled",
    state: "MT",
    thenumber: 0.10760897265584757,
  },
  {
    eiaid: 13758,
    name: "Northern Lights, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.1239341042010804,
  },
  {
    eiaid: 12825,
    name: "NorthWestern Energy LLC - (MT)",
    type: "Bundled",
    state: "MT",
    thenumber: 0.12013811211111623,
  },
  {
    eiaid: 13749,
    name: "NorVal Electric Cooperative, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.1341768491070783,
  },
  {
    eiaid: 14500,
    name: "Park Electric Coop Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.09527751507633353,
  },
  {
    eiaid: 26916,
    name: "Ravalli County Elec Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.09948119878614729,
  },
  {
    eiaid: 16759,
    name: "Sheridan Electric Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.08341191161854278,
  },
  {
    eiaid: 17593,
    name: "Southeast Electric Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.13235994286621172,
  },
  {
    eiaid: 18401,
    name: "Sun River Electric Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.13452751374621713,
  },
  {
    eiaid: 19022,
    name: "Tongue River Electric Coop Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.14089602555003547,
  },
  {
    eiaid: 19603,
    name: "USBIA-Mission Valley Power",
    type: "Bundled",
    state: "MT",
    thenumber: 0.06845653841495418,
  },
  {
    eiaid: 23586,
    name: "Vigilante Electric Coop, Inc",
    type: "Bundled",
    state: "MT",
    thenumber: 0.08366587530097153,
  },
  {
    eiaid: 20997,
    name: "Yellowstone Valley Elec Co-op",
    type: "Bundled",
    state: "MT",
    thenumber: 0.12476101884270015,
  },
  {
    eiaid: 240,
    name: "Albemarle Electric Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.14102802946904902,
  },
  {
    eiaid: 1889,
    name: "Blue Ridge Elec Member Corp - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11895362577638674,
  },
  {
    eiaid: 1891,
    name: "Blue Ridge Mountain EMC - (GA)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12867570925657487,
  },
  {
    eiaid: 24889,
    name: "Brunswick Electric Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12782587249686417,
  },
  {
    eiaid: 2982,
    name: "Cape Hatteras Elec Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.15594641470084378,
  },
  {
    eiaid: 3107,
    name: "Carteret-Craven El Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1185062867532422,
  },
  {
    eiaid: 3250,
    name: "Central Electric Membership Corp. - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1321061113936772,
  },
  {
    eiaid: 232,
    name: "City of Albemarle - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12734066167974215,
  },
  {
    eiaid: 3437,
    name: "City of Cherryville - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1349743501314825,
  },
  {
    eiaid: 4150,
    name: "City of Concord - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10205679298817961,
  },
  {
    eiaid: 5760,
    name: "City of Elizabeth City - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10727673998669468,
  },
  {
    eiaid: 7027,
    name: "City of Gastonia - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12334267108621044,
  },
  {
    eiaid: 8517,
    name: "City of Hertford - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.2610335337886046,
  },
  {
    eiaid: 8579,
    name: "City of Highlands - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13808391439280965,
  },
  {
    eiaid: 10324,
    name: "City of Kings Mountain - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1056819569376043,
  },
  {
    eiaid: 10345,
    name: "City of Kinston - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13004225449675588,
  },
  {
    eiaid: 10770,
    name: "City of Laurinburg - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11787964569445712,
  },
  {
    eiaid: 10966,
    name: "City of Lexington - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1275234096658411,
  },
  {
    eiaid: 11035,
    name: "City of Lincolnton - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13075695394436845,
  },
  {
    eiaid: 11318,
    name: "City of Lumberton - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12398933425081714,
  },
  {
    eiaid: 12801,
    name: "City of Monroe - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13015794806957526,
  },
  {
    eiaid: 12944,
    name: "City of Morganton - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12105761044366663,
  },
  {
    eiaid: 13416,
    name: "City of New Bern - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10048244339780917,
  },
  {
    eiaid: 13561,
    name: "City of Newton - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12246761078877869,
  },
  {
    eiaid: 16113,
    name: "City of Robersonville - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.140691889390258,
  },
  {
    eiaid: 16226,
    name: "City of Rocky Mount - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11976946919798527,
  },
  {
    eiaid: 17037,
    name: "City of Shelby - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1123898434670637,
  },
  {
    eiaid: 17668,
    name: "City of Southport - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1164650447801096,
  },
  {
    eiaid: 18022,
    name: "City of Statesville - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10813617430045463,
  },
  {
    eiaid: 20142,
    name: "City of Washington - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1290999016602071,
  },
  {
    eiaid: 20785,
    name: "City of Wilson",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1138989657440067,
  },
  {
    eiaid: 20836,
    name: "City of Winterville - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12346796297253969,
  },
  {
    eiaid: 5416,
    name: "Duke Energy Carolinas, LLC",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10361366336459947,
  },
  {
    eiaid: 3046,
    name: "Duke Energy Progress - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11831881109111847,
  },
  {
    eiaid: 5656,
    name: "Edgecombe-Martin County E M C",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1428033056071496,
  },
  {
    eiaid: 21632,
    name: "EnergyUnited Elec Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11283808554462706,
  },
  {
    eiaid: 6235,
    name: "Fayetteville Public Works Commission",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12185717977813494,
  },
  {
    eiaid: 6640,
    name: "Four County Elec Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12233450748115285,
  },
  {
    eiaid: 6784,
    name: "French Broad Elec Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1353274332844361,
  },
  {
    eiaid: 7639,
    name: "Greenville Utilities Comm",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11354754530059608,
  },
  {
    eiaid: 7978,
    name: "Halifax Electric Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1454528410955052,
  },
  {
    eiaid: 8333,
    name: "Haywood Electric Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.14921229438566685,
  },
  {
    eiaid: 9837,
    name: "Jones-Onslow Elec Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11753542483495068,
  },
  {
    eiaid: 11291,
    name: "Lumbee River Elec Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11590086248047328,
  },
  {
    eiaid: 13027,
    name: "Mountain Electric Coop, Inc",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10923626695287345,
  },
  {
    eiaid: 13482,
    name: "New River Light & Power Co",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10823102678571428,
  },
  {
    eiaid: 14717,
    name: "Pee Dee Electric Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12643320372928438,
  },
  {
    eiaid: 15023,
    name: "Piedmont Electric Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12889210019267822,
  },
  {
    eiaid: 14857,
    name: "Pitt & Greene Elec Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12758976386751825,
  },
  {
    eiaid: 15671,
    name: "Randolph Electric Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13749526224476177,
  },
  {
    eiaid: 16101,
    name: "Roanoke Electric Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.16063826706437695,
  },
  {
    eiaid: 16496,
    name: "Rutherford Elec Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10873665192771506,
  },
  {
    eiaid: 17572,
    name: "South River Elec Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12674392612573968,
  },
  {
    eiaid: 18339,
    name: "Surry-Yadkin Elec Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.15123749403091116,
  },
  {
    eiaid: 19108,
    name: "Tideland Electric Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.14626301337051642,
  },
  {
    eiaid: 719,
    name: "Town of Apex- (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10163240249498136,
  },
  {
    eiaid: 1036,
    name: "Town of Ayden - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1148151818452086,
  },
  {
    eiaid: 1494,
    name: "Town of Belhaven - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13622359154929578,
  },
  {
    eiaid: 1571,
    name: "Town of Benson - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1382263341983527,
  },
  {
    eiaid: 202,
    name: "Town of Black Creek - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12756238859180036,
  },
  {
    eiaid: 3731,
    name: "Town of Clayton",
    type: "Bundled",
    state: "NC",
    thenumber: 0.14312270143764627,
  },
  {
    eiaid: 4365,
    name: "Town of Cornelius - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10950527743381094,
  },
  {
    eiaid: 4739,
    name: "Town of Dallas - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.10044101433296582,
  },
  {
    eiaid: 5372,
    name: "Town of Drexel - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13137397996417435,
  },
  {
    eiaid: 6710,
    name: "Town of Edenton - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12182190625376552,
  },
  {
    eiaid: 5896,
    name: "Town of Enfield - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.15624404194470926,
  },
  {
    eiaid: 6209,
    name: "Town of Farmville - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13105551320257813,
  },
  {
    eiaid: 6580,
    name: "Town of Forest City",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12990817418736972,
  },
  {
    eiaid: 6780,
    name: "Town of Fremont - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.14610271903323263,
  },
  {
    eiaid: 7496,
    name: "Town of Granite Falls - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12332275878275174,
  },
  {
    eiaid: 8571,
    name: "Town of High Point",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12879618441971383,
  },
  {
    eiaid: 9087,
    name: "Town of Huntersville - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1179995619478718,
  },
  {
    eiaid: 10586,
    name: "Town of La Grange - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1251096940267946,
  },
  {
    eiaid: 10669,
    name: "Town of Landis - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13057779432756159,
  },
  {
    eiaid: 11228,
    name: "Town of Louisburg - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1365933774834437,
  },
  {
    eiaid: 11205,
    name: "Town of Lucama - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11480853468984033,
  },
  {
    eiaid: 11515,
    name: "Town of Maiden - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12869071188775072,
  },
  {
    eiaid: 40441,
    name: "Town of Murphy - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1203074523783001,
  },
  {
    eiaid: 15058,
    name: "Town of Pinetops - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.15034266133637922,
  },
  {
    eiaid: 15062,
    name: "Town of Pineville - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1174671153257877,
  },
  {
    eiaid: 15778,
    name: "Town of Red Springs - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13789871028625356,
  },
  {
    eiaid: 16788,
    name: "Town of Scotland Neck - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.14751327860936744,
  },
  {
    eiaid: 16914,
    name: "Town of Selma - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12390609806917627,
  },
  {
    eiaid: 17004,
    name: "Town of Sharpsburg - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12011044638748274,
  },
  {
    eiaid: 17451,
    name: "Town of Smithfield - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11019194125575127,
  },
  {
    eiaid: 17981,
    name: "Town of Stantonsburg - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11835657745947983,
  },
  {
    eiaid: 18466,
    name: "Town of Tarboro - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1168043297546826,
  },
  {
    eiaid: 19974,
    name: "Town of Wake Forest - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.14284348012276069,
  },
  {
    eiaid: 20225,
    name: "Town of Waynesville - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12168859218535245,
  },
  {
    eiaid: 20811,
    name: "Town of Windsor- (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11734494015233951,
  },
  {
    eiaid: 18957,
    name: "Tri-County Elec Member Corp (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.11026764714638722,
  },
  {
    eiaid: 19154,
    name: "Tri-State Electric Member Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.13189781585243499,
  },
  {
    eiaid: 19435,
    name: "Union Electric Membership Corp - (NC)",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12197829198432295,
  },
  {
    eiaid: 19876,
    name: "Virginia Electric & Power Co",
    type: "Bundled",
    state: "NC",
    thenumber: 0.1173615325415376,
  },
  {
    eiaid: 19981,
    name: "Wake Electric Membership Corp",
    type: "Bundled",
    state: "NC",
    thenumber: 0.12212443047419472,
  },
  {
    eiaid: 2394,
    name: "Burke-Divide Electric Coop Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.07951247823563552,
  },
  {
    eiaid: 2985,
    name: "Capital Electric Coop, Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.10214093187824301,
  },
  {
    eiaid: 24949,
    name: "Cass County Elec Coop Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.11644889566889965,
  },
  {
    eiaid: 7444,
    name: "City of Grafton - (ND)",
    type: "Bundled",
    state: "ND",
    thenumber: 0.14991083812165643,
  },
  {
    eiaid: 8628,
    name: "City of Hillsboro - (ND)",
    type: "Bundled",
    state: "ND",
    thenumber: 0.08161161731207289,
  },
  {
    eiaid: 14474,
    name: "City of Park River - (ND)",
    type: "Bundled",
    state: "ND",
    thenumber: 0.11280137772675086,
  },
  {
    eiaid: 19687,
    name: "City of Valley City",
    type: "Bundled",
    state: "ND",
    thenumber: 0.06789203510514986,
  },
  {
    eiaid: 4717,
    name: "Dakota Valley Elec Coop Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.1034748417388018,
  },
  {
    eiaid: 10153,
    name: "KEM Electric Coop Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.12372017604818161,
  },
  {
    eiaid: 11272,
    name: "Lower Yellowstone R E A, Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.08858514961211673,
  },
  {
    eiaid: 12087,
    name: "McKenzie Electric Coop Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.07438802521189546,
  },
  {
    eiaid: 12090,
    name: "McLean Electric Coop, Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.12887780728856127,
  },
  {
    eiaid: 12199,
    name: "Montana-Dakota Utilities Co",
    type: "Bundled",
    state: "ND",
    thenumber: 0.10593266931761516,
  },
  {
    eiaid: 12899,
    name: "Mor-Gran-Sou Electric Coop Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.13664731101101643,
  },
  {
    eiaid: 20413,
    name: "Mountrail-Williams Elec Coop",
    type: "Bundled",
    state: "ND",
    thenumber: 0.07189303314940398,
  },
  {
    eiaid: 12301,
    name: "Nodak Electric Coop Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.11589907508841435,
  },
  {
    eiaid: 13694,
    name: "North Central Elec Coop, Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.09475820895522388,
  },
  {
    eiaid: 13196,
    name: "Northern Plains Electric Coop",
    type: "Bundled",
    state: "ND",
    thenumber: 0.09993873360710027,
  },
  {
    eiaid: 13781,
    name: "Northern States Power Co - Minnesota",
    type: "Bundled",
    state: "ND",
    thenumber: 0.1004873907462244,
  },
  {
    eiaid: 14232,
    name: "Otter Tail Power Co",
    type: "Bundled",
    state: "ND",
    thenumber: 0.09534379772747263,
  },
  {
    eiaid: 55959,
    name: "Roughrider Electric Cooperativ",
    type: "Bundled",
    state: "ND",
    thenumber: 0.09949217171273436,
  },
  {
    eiaid: 17261,
    name: "Slope Electric Coop Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.10879545729128912,
  },
  {
    eiaid: 19790,
    name: "Verendrye Electric Coop Inc",
    type: "Bundled",
    state: "ND",
    thenumber: 0.10155198558336813,
  },
  {
    eiaid: 993,
    name: "Auburn Board of Public Works",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09271675414735067,
  },
  {
    eiaid: 2599,
    name: "Burt County Public Power Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.13291403937717153,
  },
  {
    eiaid: 2643,
    name: "Butler Public Power District - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10861279947861323,
  },
  {
    eiaid: 3205,
    name: "Cedar-Knox Public Power Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.08468834074624297,
  },
  {
    eiaid: 3435,
    name: "Cherry-Todd Electric Coop, Inc",
    type: "Bundled",
    state: "NE",
    thenumber: 0.12528666264333133,
  },
  {
    eiaid: 3495,
    name: "Chimney Rock Public Power Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.14043715846994537,
  },
  {
    eiaid: 367,
    name: "City of Alliance- (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.12972648568582884,
  },
  {
    eiaid: 1427,
    name: "City of Beatrice - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09558772113838193,
  },
  {
    eiaid: 2277,
    name: "City of Broken Bow - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.12516297262059975,
  },
  {
    eiaid: 2609,
    name: "City of Burwell - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.1579605880512981,
  },
  {
    eiaid: 3235,
    name: "City of Central City",
    type: "Bundled",
    state: "NE",
    thenumber: 0.1048587570621469,
  },
  {
    eiaid: 4527,
    name: "City of Crete",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11412941661358612,
  },
  {
    eiaid: 4836,
    name: "City of David City",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11358560398187735,
  },
  {
    eiaid: 6132,
    name: "City of Fairbury",
    type: "Bundled",
    state: "NE",
    thenumber: 0.12025509581349933,
  },
  {
    eiaid: 6175,
    name: "City of Falls City - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10544231636796128,
  },
  {
    eiaid: 6779,
    name: "City of Fremont - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10397116400472314,
  },
  {
    eiaid: 7149,
    name: "City of Gering - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.15978982657468893,
  },
  {
    eiaid: 7411,
    name: "City of Gothenburg - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.08953121204760749,
  },
  {
    eiaid: 40606,
    name: "City of Grand Island - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09195329728559638,
  },
  {
    eiaid: 8245,
    name: "City of Hastings - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10269881480141138,
  },
  {
    eiaid: 10229,
    name: "City of Hickman - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.0966225626740947,
  },
  {
    eiaid: 8717,
    name: "City of Holdrege",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09735901440222107,
  },
  {
    eiaid: 9217,
    name: "City of Imperial",
    type: "Bundled",
    state: "NE",
    thenumber: 0.12460118996292145,
  },
  {
    eiaid: 10260,
    name: "City of Kimball - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.1611269389047167,
  },
  {
    eiaid: 10967,
    name: "City of Lexington - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09701763989193579,
  },
  {
    eiaid: 12626,
    name: "City of Minden - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.14167237834132967,
  },
  {
    eiaid: 13334,
    name: "City of Nebraska City",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10337249628423809,
  },
  {
    eiaid: 13233,
    name: "City of Neligh - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10068310068310068,
  },
  {
    eiaid: 13725,
    name: "City of North Platte",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10420712515904373,
  },
  {
    eiaid: 14172,
    name: "City of Ord - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.08890573077595756,
  },
  {
    eiaid: 15039,
    name: "City of Pierce - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09577897160399079,
  },
  {
    eiaid: 16778,
    name: "City of Schuyler - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.0931104716950632,
  },
  {
    eiaid: 16953,
    name: "City of Seward - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11485712415305555,
  },
  {
    eiaid: 17149,
    name: "City of Sidney - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.13096213096213097,
  },
  {
    eiaid: 17577,
    name: "City of South Sioux City",
    type: "Bundled",
    state: "NE",
    thenumber: 0.12935263777768755,
  },
  {
    eiaid: 17899,
    name: "City of St Paul - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10262064286320075,
  },
  {
    eiaid: 18330,
    name: "City of Superior - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11374803972817564,
  },
  {
    eiaid: 18408,
    name: "City of Syracuse - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11357254290171608,
  },
  {
    eiaid: 18525,
    name: "City of Tecumseh",
    type: "Bundled",
    state: "NE",
    thenumber: 0.13027264946815015,
  },
  {
    eiaid: 19677,
    name: "City of Valentine - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.08988564668769716,
  },
  {
    eiaid: 19968,
    name: "City of Wahoo - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.08507776376628835,
  },
  {
    eiaid: 20219,
    name: "City of Wayne",
    type: "Bundled",
    state: "NE",
    thenumber: 0.13306531665674012,
  },
  {
    eiaid: 20397,
    name: "City of West Point - (NE)",
    type: "Bundled",
    state: "NE",
    thenumber: 0.1481703260146374,
  },
  {
    eiaid: 4373,
    name: "Cornhusker Public Power Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10650164688511557,
  },
  {
    eiaid: 4459,
    name: "Cozad Board of Public Works",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10228352210449397,
  },
  {
    eiaid: 4632,
    name: "Cuming County Public Pwr Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10098206252273292,
  },
  {
    eiaid: 4671,
    name: "Custer Public Power District",
    type: "Bundled",
    state: "NE",
    thenumber: 0.12329886742517611,
  },
  {
    eiaid: 4911,
    name: "Dawson Power District",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10744684957309825,
  },
  {
    eiaid: 5780,
    name: "Elkhorn Rural Public Pwr Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09615450643776824,
  },
  {
    eiaid: 27058,
    name: "High West Energy, Inc",
    type: "Bundled",
    state: "NE",
    thenumber: 0.14496879448155042,
  },
  {
    eiaid: 8570,
    name: "Highline Electric Assn",
    type: "Bundled",
    state: "NE",
    thenumber: 0.13029025653378296,
  },
  {
    eiaid: 8924,
    name: "Howard Greeley Rural P P D",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11291647323501053,
  },
  {
    eiaid: 10065,
    name: "KBR Rural Public Power District",
    type: "Bundled",
    state: "NE",
    thenumber: 0.12957980234868108,
  },
  {
    eiaid: 11018,
    name: "Lincoln Electric System",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09753956111259673,
  },
  {
    eiaid: 11251,
    name: "Loup River Public Power Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09839462151081509,
  },
  {
    eiaid: 11250,
    name: "Loup Valleys Rural P P D",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11924715251412168,
  },
  {
    eiaid: 10550,
    name: "McCook Public Power District",
    type: "Bundled",
    state: "NE",
    thenumber: 0.13863053279198945,
  },
  {
    eiaid: 12539,
    name: "Midwest Electric Member Corp",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11881168794959014,
  },
  {
    eiaid: 13337,
    name: "Nebraska Public Power District",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10447823307527533,
  },
  {
    eiaid: 13610,
    name: "Niobrara Valley El Member Corp",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11293179805137289,
  },
  {
    eiaid: 13664,
    name: "Norris Public Power District",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09383053078336324,
  },
  {
    eiaid: 13698,
    name: "North Central Public Pwr Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11610827514717675,
  },
  {
    eiaid: 13739,
    name: "Northeast Power",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09776973714216215,
  },
  {
    eiaid: 13805,
    name: "Northwest Rural Pub Pwr Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.14463162797919416,
  },
  {
    eiaid: 14127,
    name: "Omaha Public Power District",
    type: "Bundled",
    state: "NE",
    thenumber: 0.1130044625701496,
  },
  {
    eiaid: 14426,
    name: "Panhandle Rural El Member Assn",
    type: "Bundled",
    state: "NE",
    thenumber: 0.14246819338422392,
  },
  {
    eiaid: 21111,
    name: "Perennial Public Power Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.0878995262089193,
  },
  {
    eiaid: 15188,
    name: "Polk County Rural Pub Pwr Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.10630013784376201,
  },
  {
    eiaid: 16164,
    name: "Roosevelt Public Power Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.1268546692179805,
  },
  {
    eiaid: 17548,
    name: "South Central Public Pwr Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09346555066621534,
  },
  {
    eiaid: 17642,
    name: "Southern Public Power District",
    type: "Bundled",
    state: "NE",
    thenumber: 0.09425043569119923,
  },
  {
    eiaid: 17692,
    name: "Southwest Public Power Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.0965540019817241,
  },
  {
    eiaid: 17979,
    name: "Stanton County Public Pwr Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.11022448716294672,
  },
  {
    eiaid: 20456,
    name: "Twin Valleys Public Power Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.1194517452285623,
  },
  {
    eiaid: 20509,
    name: "Wheat Belt Public Power Dist",
    type: "Bundled",
    state: "NE",
    thenumber: 0.1397254372612654,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "NH",
    thenumber: 0.1124784595789316,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "NH",
    thenumber: 0.18207193066266295,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "NH",
    thenumber: 0.07683829444891392,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "NH",
    thenumber: 0.10115129807174404,
  },
  {
    eiaid: 58086,
    name: "Electricity NH, LLC d/b/a ENH Power, LLC",
    type: "Energy",
    state: "NH",
    thenumber: 0.12218687029377452,
  },
  {
    eiaid: 57067,
    name: "ENGIE Retail, LLC",
    type: "Energy",
    state: "NH",
    thenumber: 0.10109863864342011,
  },
  {
    eiaid: 58158,
    name: "FairPoint Energy LLC",
    type: "Energy",
    state: "NH",
    thenumber: 0.15002691065662002,
  },
  {
    eiaid: 59793,
    name: "First Point Power, LLC",
    type: "Energy",
    state: "NH",
    thenumber: 0.09930602086723461,
  },
  {
    eiaid: 26510,
    name: "Liberty Utilities (Granite State Electri",
    type: "Delivery",
    state: "NH",
    thenumber: 0.1014041514041514,
  },
  {
    eiaid: 26510,
    name: "Liberty Utilities (Granite State Electri",
    type: "Bundled",
    state: "NH",
    thenumber: 0.17993258383927374,
  },
  {
    eiaid: 13441,
    name: "New Hampshire Elec Coop Inc",
    type: "Bundled",
    state: "NH",
    thenumber: 0.20956349641732847,
  },
  {
    eiaid: 58667,
    name: "North American Power and Gas, LLC",
    type: "Energy",
    state: "NH",
    thenumber: 0.1250680433749514,
  },
  {
    eiaid: 15472,
    name: "Public Service Co of NH",
    type: "Delivery",
    state: "NH",
    thenumber: 0.10411186757025102,
  },
  {
    eiaid: 15472,
    name: "Public Service Co of NH",
    type: "Bundled",
    state: "NH",
    thenumber: 0.1989300262522042,
  },
  {
    eiaid: 58956,
    name: "SmartEnergy Holdings, LLC",
    type: "Energy",
    state: "NH",
    thenumber: 0.1381612242438848,
  },
  {
    eiaid: 906,
    name: "Town of Ashland",
    type: "Bundled",
    state: "NH",
    thenumber: 0.16129032258064516,
  },
  {
    eiaid: 11196,
    name: "Town of Littleton - (NH)",
    type: "Bundled",
    state: "NH",
    thenumber: 0.11565361019348748,
  },
  {
    eiaid: 20913,
    name: "Town of Wolfeboro - (NH)",
    type: "Bundled",
    state: "NH",
    thenumber: 0.1453300639188891,
  },
  {
    eiaid: 59059,
    name: "Town Square Energy",
    type: "Energy",
    state: "NH",
    thenumber: 0.10770457892153425,
  },
  {
    eiaid: 24590,
    name: "Unitil Energy Systems",
    type: "Delivery",
    state: "NH",
    thenumber: 0.08962691565220816,
  },
  {
    eiaid: 24590,
    name: "Unitil Energy Systems",
    type: "Bundled",
    state: "NH",
    thenumber: 0.1870085556904807,
  },
  {
    eiaid: 58633,
    name: "XOOM Energy New Hampshire, LLC",
    type: "Energy",
    state: "NH",
    thenumber: 0.09989344698987747,
  },
  {
    eiaid: 55722,
    name: "AEP Energy",
    type: "Energy",
    state: "NJ",
    thenumber: 0.09468678555098309,
  },
  {
    eiaid: 58964,
    name: "Alpha Gas and Electric, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.15775075987841947,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.12780204724296484,
  },
  {
    eiaid: 59799,
    name: "American Power & Gas",
    type: "Energy",
    state: "NJ",
    thenumber: 0.1497109179753068,
  },
  {
    eiaid: 59052,
    name: "Astral Energy LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.18692002442002442,
  },
  {
    eiaid: 963,
    name: "Atlantic City Electric Co",
    type: "Delivery",
    state: "NJ",
    thenumber: 0.08460901897399539,
  },
  {
    eiaid: 963,
    name: "Atlantic City Electric Co",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.17652461115073528,
  },
  {
    eiaid: 2650,
    name: "Borough of Butler - (NJ)",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.13024493269220913,
  },
  {
    eiaid: 10776,
    name: "Borough of Lavallette - (NJ)",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.17226491035492134,
  },
  {
    eiaid: 11476,
    name: "Borough of Madison - (NJ)",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.1838153796367461,
  },
  {
    eiaid: 14472,
    name: "Borough of Park Ridge - (NJ)",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.1531839473351057,
  },
  {
    eiaid: 14648,
    name: "Borough of Pemberton - (NJ)",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.22658359293873312,
  },
  {
    eiaid: 17571,
    name: "Borough of South River - (NJ)",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.2209805203294548,
  },
  {
    eiaid: 59055,
    name: "Choice Energy, LLC d/b/a 4 Choice Energy",
    type: "Energy",
    state: "NJ",
    thenumber: 0.1521707406056184,
  },
  {
    eiaid: 19856,
    name: "City of Vineland - (NJ)",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.16808843595835837,
  },
  {
    eiaid: 58951,
    name: "CleanChoice Energy, Inc.",
    type: "Energy",
    state: "NJ",
    thenumber: 0.15852386260858592,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "NJ",
    thenumber: 0.1315548416850877,
  },
  {
    eiaid: 4100,
    name: "Commerce Energy, Inc.",
    type: "Energy",
    state: "NJ",
    thenumber: 0.1252187018773959,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "NJ",
    thenumber: 0.09506913995141815,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "NJ",
    thenumber: 0.12128468854118342,
  },
  {
    eiaid: 56265,
    name: "Energy Plus Holdings LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.15498101484294097,
  },
  {
    eiaid: 55874,
    name: "Energy Services Providers, Inc",
    type: "Energy",
    state: "NJ",
    thenumber: 0.16354659142900715,
  },
  {
    eiaid: 57067,
    name: "ENGIE Retail, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.11235361177324074,
  },
  {
    eiaid: 56286,
    name: "Frontier Utilities, Inc.",
    type: "Energy",
    state: "NJ",
    thenumber: 0.14086901763224183,
  },
  {
    eiaid: 7554,
    name: "Green Mountain Energy Company",
    type: "Energy",
    state: "NJ",
    thenumber: 0.15530861354214648,
  },
  {
    eiaid: 55815,
    name: "IDT Energy, Inc.",
    type: "Energy",
    state: "NJ",
    thenumber: 0.09095348745618526,
  },
  {
    eiaid: 59310,
    name: "Inspire Energy Holdings, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.1517344718044123,
  },
  {
    eiaid: 9726,
    name: "Jersey Central Power & Lt Co",
    type: "Delivery",
    state: "NJ",
    thenumber: 0.04271314835883429,
  },
  {
    eiaid: 9726,
    name: "Jersey Central Power & Lt Co",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.13135181299526721,
  },
  {
    eiaid: 63028,
    name: "Josco Energy USA LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.15697541452258434,
  },
  {
    eiaid: 55781,
    name: "Liberty Power Corp.",
    type: "Energy",
    state: "NJ",
    thenumber: 0.13751467382190172,
  },
  {
    eiaid: 60218,
    name: "MPower Energy NJ LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.14276635885214972,
  },
  {
    eiaid: 58667,
    name: "North American Power and Gas, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.12203548835056319,
  },
  {
    eiaid: 56501,
    name: "Palmco Power NJ, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.22078497386373705,
  },
  {
    eiaid: 57307,
    name: "Plymouth Rock Energy, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.1697425129848602,
  },
  {
    eiaid: 15477,
    name: "Public Service Elec & Gas Co",
    type: "Delivery",
    state: "NJ",
    thenumber: 0.04120776125425531,
  },
  {
    eiaid: 15477,
    name: "Public Service Elec & Gas Co",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.16703939230105336,
  },
  {
    eiaid: 61922,
    name: "Pure Energy USA, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.17989148864021703,
  },
  {
    eiaid: 57037,
    name: "Reliant Energy Northeast LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.10997951653204718,
  },
  {
    eiaid: 59809,
    name: "Residents Energy, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.1360866182572614,
  },
  {
    eiaid: 58663,
    name: "Respond Power LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.12585210860774118,
  },
  {
    eiaid: 16213,
    name: "Rockland Electric Co",
    type: "Delivery",
    state: "NJ",
    thenumber: 0.05643634103387548,
  },
  {
    eiaid: 16213,
    name: "Rockland Electric Co",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.16355224499983462,
  },
  {
    eiaid: 59085,
    name: "SFE Energy New Jersey, Inc.",
    type: "Energy",
    state: "NJ",
    thenumber: 0.10279955874899377,
  },
  {
    eiaid: 58956,
    name: "SmartEnergy Holdings, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.13965126741335981,
  },
  {
    eiaid: 17549,
    name: "South Jersey Energy Company",
    type: "Energy",
    state: "NJ",
    thenumber: 0.08859106112414691,
  },
  {
    eiaid: 60124,
    name: "Spring Energy RRH LLC dba Spring Power &",
    type: "Energy",
    state: "NJ",
    thenumber: 0.13183369705108836,
  },
  {
    eiaid: 59943,
    name: "Spruce Finance",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.14863028021748223,
  },
  {
    eiaid: 58953,
    name: "Star Energy Partners, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.14112222351436213,
  },
  {
    eiaid: 59795,
    name: "Starion Energy PA, Inc.",
    type: "Energy",
    state: "NJ",
    thenumber: 0.14692106209234246,
  },
  {
    eiaid: 57074,
    name: "Stream Energy New Jersey, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.11885284062944784,
  },
  {
    eiaid: 40299,
    name: "Sussex Rural Electric Coop Inc",
    type: "Bundled",
    state: "NJ",
    thenumber: 0.12521370812475935,
  },
  {
    eiaid: 58952,
    name: "Tomorow Energy Corp.",
    type: "Energy",
    state: "NJ",
    thenumber: 0.14610451306413302,
  },
  {
    eiaid: 19126,
    name: "TriEagle Energy, L.P.",
    type: "Energy",
    state: "NJ",
    thenumber: 0.09267438953246104,
  },
  {
    eiaid: 59620,
    name: "Verde Energy USA",
    type: "Energy",
    state: "NJ",
    thenumber: 0.15999220200900874,
  },
  {
    eiaid: 58162,
    name: "Viridian Energy PA LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.16571921558217476,
  },
  {
    eiaid: 57491,
    name: "XOOM Energy New Jersey, LLC",
    type: "Energy",
    state: "NJ",
    thenumber: 0.1621749516551018,
  },
  {
    eiaid: 3273,
    name: "Central New Mexico El Coop, Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.1743332412077662,
  },
  {
    eiaid: 3287,
    name: "Central Valley Elec Coop, Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.08057447582111259,
  },
  {
    eiaid: 1046,
    name: "City of Aztec - (NM)",
    type: "Bundled",
    state: "NM",
    thenumber: 0.13268892794376097,
  },
  {
    eiaid: 6204,
    name: "City of Farmington - (NM)",
    type: "Bundled",
    state: "NM",
    thenumber: 0.11138145863682856,
  },
  {
    eiaid: 6930,
    name: "City of Gallup - (NM)",
    type: "Bundled",
    state: "NM",
    thenumber: 0.12258449223803364,
  },
  {
    eiaid: 19246,
    name: "City of Truth or Consequences - (NM)",
    type: "Bundled",
    state: "NM",
    thenumber: 0.1446551724137931,
  },
  {
    eiaid: 4071,
    name: "Columbus Electric Coop, Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.16380082847239832,
  },
  {
    eiaid: 4265,
    name: "Continental Divide El Coop Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.14332984902081589,
  },
  {
    eiaid: 5701,
    name: "El Paso Electric Co",
    type: "Bundled",
    state: "NM",
    thenumber: 0.10466589949708285,
  },
  {
    eiaid: 6198,
    name: "Farmers Electric Coop, Inc - (NM)",
    type: "Bundled",
    state: "NM",
    thenumber: 0.11196008762276095,
  },
  {
    eiaid: 9699,
    name: "Jemez Mountains Elec Coop, Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.1373753263270634,
  },
  {
    eiaid: 10378,
    name: "Kit Carson Electric Coop, Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.18551133095594158,
  },
  {
    eiaid: 10817,
    name: "Lea County Electric Coop, Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.0880960691069325,
  },
  {
    eiaid: 11204,
    name: "Los Alamos County",
    type: "Bundled",
    state: "NM",
    thenumber: 0.1316148375655862,
  },
  {
    eiaid: 12901,
    name: "Mora-San Miguel Elec Coop",
    type: "Bundled",
    state: "NM",
    thenumber: 0.17208922651473504,
  },
  {
    eiaid: 13314,
    name: "Navajo Tribal Utility Authority",
    type: "Bundled",
    state: "NM",
    thenumber: 0.13123092573753814,
  },
  {
    eiaid: 13777,
    name: "Northern Rio Arriba E Coop Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.18164234581497798,
  },
  {
    eiaid: 14224,
    name: "Otero County Electric Coop Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.18435259507481297,
  },
  {
    eiaid: 15473,
    name: "Public Service Co of NM",
    type: "Bundled",
    state: "NM",
    thenumber: 0.13258069033984046,
  },
  {
    eiaid: 15698,
    name: "Raton Public Service Company",
    type: "Bundled",
    state: "NM",
    thenumber: 0.15571428571428572,
  },
  {
    eiaid: 22690,
    name: "Roosevelt County Elec Coop Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.09544598870509072,
  },
  {
    eiaid: 23326,
    name: "Sierra Electric Coop, Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.19300523935336528,
  },
  {
    eiaid: 17492,
    name: "Socorro Electric Coop, Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.15650424413311012,
  },
  {
    eiaid: 17715,
    name: "Southwestern Electric Coop Inc - (NM)",
    type: "Bundled",
    state: "NM",
    thenumber: 0.2477225785411458,
  },
  {
    eiaid: 17718,
    name: "Southwestern Public Service Co",
    type: "Bundled",
    state: "NM",
    thenumber: 0.09646538513034676,
  },
  {
    eiaid: 17826,
    name: "Springer Electric Coop, Inc",
    type: "Bundled",
    state: "NM",
    thenumber: 0.17571638758495062,
  },
  {
    eiaid: 2008,
    name: "City of Boulder City - (NV)",
    type: "Bundled",
    state: "NV",
    thenumber: 0.0967081627537806,
  },
  {
    eiaid: 6172,
    name: "City of Fallon - (NV)",
    type: "Bundled",
    state: "NV",
    thenumber: 0.14824911777252103,
  },
  {
    eiaid: 11021,
    name: "Lincoln County Power Dist No 1",
    type: "Bundled",
    state: "NV",
    thenumber: 0.08276077451592755,
  },
  {
    eiaid: 13073,
    name: "Mt Wheeler Power, Inc",
    type: "Bundled",
    state: "NV",
    thenumber: 0.0747969992747851,
  },
  {
    eiaid: 13407,
    name: "Nevada Power Co",
    type: "Bundled",
    state: "NV",
    thenumber: 0.12331112863774257,
  },
  {
    eiaid: 14245,
    name: "Overton Power District No 5",
    type: "Bundled",
    state: "NV",
    thenumber: 0.0997430859906302,
  },
  {
    eiaid: 22814,
    name: "Raft Rural Elec Coop Inc",
    type: "Bundled",
    state: "NV",
    thenumber: 0.0991695163654128,
  },
  {
    eiaid: 17166,
    name: "Sierra Pacific Power Co",
    type: "Bundled",
    state: "NV",
    thenumber: 0.11062517338270818,
  },
  {
    eiaid: 19840,
    name: "Valley Electric Assn, Inc",
    type: "Bundled",
    state: "NV",
    thenumber: 0.13331514286997156,
  },
  {
    eiaid: 20332,
    name: "Wells Rural Electric Co",
    type: "Bundled",
    state: "NV",
    thenumber: 0.10068834726417082,
  },
  {
    eiaid: 59053,
    name: "Abest Power & Gas, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.08364167478091528,
  },
  {
    eiaid: 54872,
    name: "Accent Energy Holdings, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.09941874879479905,
  },
  {
    eiaid: 59472,
    name: "Agera Energy LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.09777614409115971,
  },
  {
    eiaid: 63008,
    name: "Agressive Energy LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.06981531390023313,
  },
  {
    eiaid: 113,
    name: "Agway Energy Services, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.08002916385455514,
  },
  {
    eiaid: 58964,
    name: "Alpha Gas and Electric, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.12320479129743934,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.056620268125357504,
  },
  {
    eiaid: 59799,
    name: "American Power & Gas",
    type: "Energy",
    state: "NY",
    thenumber: 0.1153721238735648,
  },
  {
    eiaid: 63122,
    name: "Approved Energy II, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.07119770156221943,
  },
  {
    eiaid: 59052,
    name: "Astral Energy LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.16914049586776858,
  },
  {
    eiaid: 60583,
    name: "Atlantic Energy LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.1312200956937799,
  },
  {
    eiaid: 1343,
    name: "Bath Electric Gas & Water",
    type: "Bundled",
    state: "NY",
    thenumber: 0.05931552445165145,
  },
  {
    eiaid: 3249,
    name: "Central Hudson Gas & Elec Corp",
    type: "Delivery",
    state: "NY",
    thenumber: 0.11399796296647752,
  },
  {
    eiaid: 3249,
    name: "Central Hudson Gas & Elec Corp",
    type: "Bundled",
    state: "NY",
    thenumber: 0.17191797648074425,
  },
  {
    eiaid: 15145,
    name: "City of Plattsburgh - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.04616706833002551,
  },
  {
    eiaid: 16549,
    name: "City of Salamanca - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.050298177671125474,
  },
  {
    eiaid: 17085,
    name: "City of Sherrill - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.058279370952821465,
  },
  {
    eiaid: 58951,
    name: "CleanChoice Energy, Inc.",
    type: "Energy",
    state: "NY",
    thenumber: 0.14512709906023724,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "NY",
    thenumber: 0.085034869257753,
  },
  {
    eiaid: 55814,
    name: "Columbia Utilities Power, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.19120789661137683,
  },
  {
    eiaid: 4226,
    name: "Consolidated Edison Co-NY Inc",
    type: "Delivery",
    state: "NY",
    thenumber: 0.16707190980468067,
  },
  {
    eiaid: 4226,
    name: "Consolidated Edison Co-NY Inc",
    type: "Bundled",
    state: "NY",
    thenumber: 0.2529764194459687,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "NY",
    thenumber: 0.07304884408134886,
  },
  {
    eiaid: 5021,
    name: "Delaware County Elec Coop Inc",
    type: "Bundled",
    state: "NY",
    thenumber: 0.13885813696030366,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "NY",
    thenumber: 0.1183622387897641,
  },
  {
    eiaid: 60053,
    name: "East Coast Power & Gas, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.0813616295032093,
  },
  {
    eiaid: 59054,
    name: "Eligo Energy, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.09119812458804362,
  },
  {
    eiaid: 5880,
    name: "Energy Coop of New York, Inc",
    type: "Energy",
    state: "NY",
    thenumber: 0.04332738739265628,
  },
  {
    eiaid: 56265,
    name: "Energy Plus Holdings LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.11891232703953553,
  },
  {
    eiaid: 55874,
    name: "Energy Services Providers, Inc",
    type: "Energy",
    state: "NY",
    thenumber: 0.11712634238787112,
  },
  {
    eiaid: 59082,
    name: "Family Energy, Inc. New York",
    type: "Energy",
    state: "NY",
    thenumber: 0.08342859357138706,
  },
  {
    eiaid: 6369,
    name: "Fishers Island Utility Co Inc",
    type: "Bundled",
    state: "NY",
    thenumber: 0.3181176470588235,
  },
  {
    eiaid: 61921,
    name: "Flanders Energy, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.1615036547163244,
  },
  {
    eiaid: 7554,
    name: "Green Mountain Energy Company",
    type: "Energy",
    state: "NY",
    thenumber: 0.14189365671641793,
  },
  {
    eiaid: 59810,
    name: "Greenlight Energy Inc.",
    type: "Energy",
    state: "NY",
    thenumber: 0.1194417959608217,
  },
  {
    eiaid: 55878,
    name: "Hudson Energy Services",
    type: "Energy",
    state: "NY",
    thenumber: 0.08249106078665078,
  },
  {
    eiaid: 55815,
    name: "IDT Energy, Inc.",
    type: "Energy",
    state: "NY",
    thenumber: 0.14369709881901155,
  },
  {
    eiaid: 59310,
    name: "Inspire Energy Holdings, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.12174132704614347,
  },
  {
    eiaid: 9645,
    name: "Jamestown Board of Public Util",
    type: "Bundled",
    state: "NY",
    thenumber: 0.08254300599017537,
  },
  {
    eiaid: 63027,
    name: "Josco Energy Corp-  NY",
    type: "Energy",
    state: "NY",
    thenumber: 0.1757947344015663,
  },
  {
    eiaid: 56196,
    name: "Just Energy New York Corp.",
    type: "Energy",
    state: "NY",
    thenumber: 0.09195382021014398,
  },
  {
    eiaid: 58853,
    name: "Kiwi Energy NY LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.11533606816030294,
  },
  {
    eiaid: 10610,
    name: "Lake Placid Village, Inc - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.054933067080837214,
  },
  {
    eiaid: 55781,
    name: "Liberty Power Corp.",
    type: "Energy",
    state: "NY",
    thenumber: 0.14603795386871696,
  },
  {
    eiaid: 11171,
    name: "Long Island Power Authority",
    type: "Bundled",
    state: "NY",
    thenumber: 0.20385963551388275,
  },
  {
    eiaid: 56504,
    name: "Major Energy Electric Services",
    type: "Energy",
    state: "NY",
    thenumber: 0.09823163423503761,
  },
  {
    eiaid: 58966,
    name: "Marathon Power LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.07229767845519636,
  },
  {
    eiaid: 63168,
    name: "Median Energy Corp.",
    type: "Energy",
    state: "NY",
    thenumber: 0.16822856447392442,
  },
  {
    eiaid: 59128,
    name: "MPower Energy LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.13311426841526713,
  },
  {
    eiaid: 59812,
    name: "New Wave Energy Corporation",
    type: "Energy",
    state: "NY",
    thenumber: 0.05506647947807416,
  },
  {
    eiaid: 13511,
    name: "New York State Elec & Gas Corp",
    type: "Delivery",
    state: "NY",
    thenumber: 0.06809005676467099,
  },
  {
    eiaid: 13511,
    name: "New York State Elec & Gas Corp",
    type: "Bundled",
    state: "NY",
    thenumber: 0.11386247487813289,
  },
  {
    eiaid: 13573,
    name: "Niagara Mohawk Power Corp.",
    type: "Delivery",
    state: "NY",
    thenumber: 0.07691153905743715,
  },
  {
    eiaid: 13573,
    name: "Niagara Mohawk Power Corp.",
    type: "Bundled",
    state: "NY",
    thenumber: 0.12542832125724232,
  },
  {
    eiaid: 54893,
    name: "NOCO Electric",
    type: "Energy",
    state: "NY",
    thenumber: 0.04273657587199656,
  },
  {
    eiaid: 58667,
    name: "North American Power and Gas, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.11394435701954074,
  },
  {
    eiaid: 13726,
    name: "North Shore Towers Apts Inc",
    type: "Bundled",
    state: "NY",
    thenumber: 0.27526769779892923,
  },
  {
    eiaid: 14154,
    name: "Orange & Rockland Utils Inc",
    type: "Delivery",
    state: "NY",
    thenumber: 0.11874507971936017,
  },
  {
    eiaid: 14154,
    name: "Orange & Rockland Utils Inc",
    type: "Bundled",
    state: "NY",
    thenumber: 0.19405488054554895,
  },
  {
    eiaid: 14267,
    name: "Otsego Electric Coop, Inc",
    type: "Bundled",
    state: "NY",
    thenumber: 0.12428990742831339,
  },
  {
    eiaid: 14711,
    name: "Pennsylvania Electric Co",
    type: "Bundled",
    state: "NY",
    thenumber: 0.10539908490086426,
  },
  {
    eiaid: 57307,
    name: "Plymouth Rock Energy, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.12259528130671507,
  },
  {
    eiaid: 58119,
    name: "Public Power LLC (CT)",
    type: "Energy",
    state: "NY",
    thenumber: 0.08874240890688259,
  },
  {
    eiaid: 61922,
    name: "Pure Energy USA, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.15517553566039632,
  },
  {
    eiaid: 57037,
    name: "Reliant Energy Northeast LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.07315419598349011,
  },
  {
    eiaid: 61059,
    name: "Renaissance Power & Gas, Inc.",
    type: "Energy",
    state: "NY",
    thenumber: 0.14328522954907813,
  },
  {
    eiaid: 59809,
    name: "Residents Energy, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.1419177401611129,
  },
  {
    eiaid: 16177,
    name: "Robison Energy, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.08596204947789962,
  },
  {
    eiaid: 16183,
    name: "Rochester Gas & Electric Corp",
    type: "Delivery",
    state: "NY",
    thenumber: 0.08226592609385627,
  },
  {
    eiaid: 16183,
    name: "Rochester Gas & Electric Corp",
    type: "Bundled",
    state: "NY",
    thenumber: 0.12850188503838536,
  },
  {
    eiaid: 61367,
    name: "South Bay Energy Corp.",
    type: "Energy",
    state: "NY",
    thenumber: 0.13900892305965543,
  },
  {
    eiaid: 17710,
    name: "Spark Energy, LP",
    type: "Energy",
    state: "NY",
    thenumber: 0.1376122556788167,
  },
  {
    eiaid: 59796,
    name: "Starion Energy NY, Inc.",
    type: "Energy",
    state: "NY",
    thenumber: 0.1069182389937107,
  },
  {
    eiaid: 18102,
    name: "Steuben Rural Elec Coop, Inc",
    type: "Bundled",
    state: "NY",
    thenumber: 0.12349982939963507,
  },
  {
    eiaid: 58181,
    name: "Stream Energy New York, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.06694523628251209,
  },
  {
    eiaid: 11811,
    name: "Town of Massena - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.0606414973873011,
  },
  {
    eiaid: 59620,
    name: "Verde Energy USA",
    type: "Energy",
    state: "NY",
    thenumber: 0.1440842621944984,
  },
  {
    eiaid: 122,
    name: "Village of Arcade - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.052340336464247406,
  },
  {
    eiaid: 1970,
    name: "Village of Boonville - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.05182921990318229,
  },
  {
    eiaid: 5875,
    name: "Village of Endicott - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.065439672801636,
  },
  {
    eiaid: 5930,
    name: "Village of Fairport - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.05552840979984142,
  },
  {
    eiaid: 6775,
    name: "Village of Freeport - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.12715020700885202,
  },
  {
    eiaid: 7600,
    name: "Village of Green Island - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.1089831672964617,
  },
  {
    eiaid: 7995,
    name: "Village of Hamilton - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.0493433222345542,
  },
  {
    eiaid: 9200,
    name: "Village of Ilion - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.05356714484479202,
  },
  {
    eiaid: 11083,
    name: "Village of Little Valley - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.07633308984660336,
  },
  {
    eiaid: 14700,
    name: "Village of Penn Yan - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.03748520799830082,
  },
  {
    eiaid: 16217,
    name: "Village of Rockville Centre - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.12342884634749433,
  },
  {
    eiaid: 17079,
    name: "Village of Sherburne - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.05516354721042875,
  },
  {
    eiaid: 17512,
    name: "Village of Solvay - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.057222639384268824,
  },
  {
    eiaid: 17789,
    name: "Village of Spencerport - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.045747927484740825,
  },
  {
    eiaid: 17846,
    name: "Village of Springville - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.05393678270484615,
  },
  {
    eiaid: 19274,
    name: "Village of Tupper Lake - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.05375064765596515,
  },
  {
    eiaid: 20337,
    name: "Village of Wellsville - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.06074570815450644,
  },
  {
    eiaid: 20480,
    name: "Village of Westfield - (NY)",
    type: "Bundled",
    state: "NY",
    thenumber: 0.05693967239312825,
  },
  {
    eiaid: 58160,
    name: "Viridian Energy NY LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.10658078801246126,
  },
  {
    eiaid: 58367,
    name: "XOOM Energy New York, LLC",
    type: "Energy",
    state: "NY",
    thenumber: 0.09167242612733864,
  },
  {
    eiaid: 118,
    name: "Adams Rural Electric Coop, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1407947290794191,
  },
  {
    eiaid: 55722,
    name: "AEP Energy",
    type: "Energy",
    state: "OH",
    thenumber: 0.057462053548418456,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.07038387857353375,
  },
  {
    eiaid: 59799,
    name: "American Power & Gas",
    type: "Energy",
    state: "OH",
    thenumber: 0.09529161015307111,
  },
  {
    eiaid: 56571,
    name: "AP Holdings LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.06994892469044119,
  },
  {
    eiaid: 2502,
    name: "Buckeye Rural Elec Coop, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.15677127887082976,
  },
  {
    eiaid: 2651,
    name: "Butler Rural Electric Coop Inc - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.15799152344111295,
  },
  {
    eiaid: 59800,
    name: "Capital Energy LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.054235089394529534,
  },
  {
    eiaid: 3076,
    name: "Carroll Electric Coop, Inc - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.14196512162998462,
  },
  {
    eiaid: 58142,
    name: "Cincinnati Bell Energy LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.05541259765624999,
  },
  {
    eiaid: 560,
    name: "City of Amherst- (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11811467842855677,
  },
  {
    eiaid: 768,
    name: "City of Arcanum - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11791666666666667,
  },
  {
    eiaid: 2054,
    name: "City of Bowling Green - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.14363878196731222,
  },
  {
    eiaid: 2439,
    name: "City of Bryan - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11612854360528024,
  },
  {
    eiaid: 3216,
    name: "City of Celina - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12623129077651274,
  },
  {
    eiaid: 3762,
    name: "City of Cleveland - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.14654208978340488,
  },
  {
    eiaid: 4061,
    name: "City of Columbiana - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13185873151655872,
  },
  {
    eiaid: 4065,
    name: "City of Columbus - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11330337301833622,
  },
  {
    eiaid: 4683,
    name: "City of Cuyahoga Falls - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13380603390657964,
  },
  {
    eiaid: 5336,
    name: "City of Dover - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1527496408707503,
  },
  {
    eiaid: 6914,
    name: "City of Galion",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1433463597551334,
  },
  {
    eiaid: 7977,
    name: "City of Hamilton - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.14979054577220116,
  },
  {
    eiaid: 8949,
    name: "City of Hubbard",
    type: "Bundled",
    state: "OH",
    thenumber: 0.08105246647321779,
  },
  {
    eiaid: 8972,
    name: "City of Hudson - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13042839336088696,
  },
  {
    eiaid: 9598,
    name: "City of Jackson - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.14253224320589591,
  },
  {
    eiaid: 10830,
    name: "City of Lebanon - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11125462952313037,
  },
  {
    eiaid: 13209,
    name: "City of Napoleon - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12604502271788517,
  },
  {
    eiaid: 13563,
    name: "City of Newton Falls - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.15839909808342728,
  },
  {
    eiaid: 13602,
    name: "City of Niles - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.08044442660136097,
  },
  {
    eiaid: 13949,
    name: "City of Oberlin - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13042179174343907,
  },
  {
    eiaid: 14194,
    name: "City of Orrville - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.10326534953599063,
  },
  {
    eiaid: 14381,
    name: "City of Painesville",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1452286898496469,
  },
  {
    eiaid: 15095,
    name: "City of Piqua - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11618586289435173,
  },
  {
    eiaid: 17043,
    name: "City of Shelby - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12184079820667224,
  },
  {
    eiaid: 17865,
    name: "City of St Clairsville - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.14498045290255956,
  },
  {
    eiaid: 17891,
    name: "City of St Marys - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12179667079378181,
  },
  {
    eiaid: 18941,
    name: "City of Tipp City - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12563451776649745,
  },
  {
    eiaid: 19951,
    name: "City of Wadsworth - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12374726848014468,
  },
  {
    eiaid: 20077,
    name: "City of Wapakoneta - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11619596070983683,
  },
  {
    eiaid: 20477,
    name: "City of Westerville - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11611890959612897,
  },
  {
    eiaid: 20977,
    name: "City of Woodsfield - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11387554919791561,
  },
  {
    eiaid: 58951,
    name: "CleanChoice Energy, Inc.",
    type: "Energy",
    state: "OH",
    thenumber: 0.09244715155858114,
  },
  {
    eiaid: 3755,
    name: "Cleveland Electric Illum Co",
    type: "Delivery",
    state: "OH",
    thenumber: 0.07042769143294961,
  },
  {
    eiaid: 3755,
    name: "Cleveland Electric Illum Co",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12451238893587932,
  },
  {
    eiaid: 3824,
    name: "Clyde Light & Power",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12722687057127988,
  },
  {
    eiaid: 4100,
    name: "Commerce Energy, Inc.",
    type: "Energy",
    state: "OH",
    thenumber: 0.0679565211205486,
  },
  {
    eiaid: 12990,
    name: "Consolidated Electric Coop Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1381653784453843,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "OH",
    thenumber: 0.055920753979587234,
  },
  {
    eiaid: 4796,
    name: "Darke Rural Electric Coop, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12464865594725057,
  },
  {
    eiaid: 4922,
    name: "Dayton Power & Light Co",
    type: "Delivery",
    state: "OH",
    thenumber: 0.04589732175546301,
  },
  {
    eiaid: 4922,
    name: "Dayton Power & Light Co",
    type: "Bundled",
    state: "OH",
    thenumber: 0.10743089514181703,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "OH",
    thenumber: 0.07924378474131084,
  },
  {
    eiaid: 3542,
    name: "Duke Energy Ohio Inc",
    type: "Delivery",
    state: "OH",
    thenumber: 0.05783430760539932,
  },
  {
    eiaid: 3542,
    name: "Duke Energy Ohio Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11054023368310235,
  },
  {
    eiaid: 59385,
    name: "Dynegy Energy Services, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.05388196124522724,
  },
  {
    eiaid: 59054,
    name: "Eligo Energy, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.08041388850908447,
  },
  {
    eiaid: 6458,
    name: "Energy Harbor Corp.",
    type: "Energy",
    state: "OH",
    thenumber: 0.05110125443579259,
  },
  {
    eiaid: 55874,
    name: "Energy Services Providers, Inc",
    type: "Energy",
    state: "OH",
    thenumber: 0.10173614408254994,
  },
  {
    eiaid: 57067,
    name: "ENGIE Retail, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.07711801308301866,
  },
  {
    eiaid: 6335,
    name: "Firelands Electric Coop, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.132342306147047,
  },
  {
    eiaid: 6804,
    name: "Frontier Power Company",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13726152460492919,
  },
  {
    eiaid: 56286,
    name: "Frontier Utilities, Inc.",
    type: "Energy",
    state: "OH",
    thenumber: 0.09550916767545543,
  },
  {
    eiaid: 7891,
    name: "Guernsey-Muskingum El Coop Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.14228415886691695,
  },
  {
    eiaid: 8034,
    name: "Hancock-Wood Electric Coop Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.14428974476740372,
  },
  {
    eiaid: 8761,
    name: "Holmes-Wayne Electric Coop Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13059515628006707,
  },
  {
    eiaid: 55815,
    name: "IDT Energy, Inc.",
    type: "Energy",
    state: "OH",
    thenumber: 0.09303226321673855,
  },
  {
    eiaid: 59310,
    name: "Inspire Energy Holdings, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.09513860797144558,
  },
  {
    eiaid: 58957,
    name: "Interstate Gas Supply, Inc.",
    type: "Energy",
    state: "OH",
    thenumber: 0.06764653172042623,
  },
  {
    eiaid: 63028,
    name: "Josco Energy USA LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.10094784715433332,
  },
  {
    eiaid: 58853,
    name: "Kiwi Energy NY LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.07619482893705928,
  },
  {
    eiaid: 55781,
    name: "Liberty Power Corp.",
    type: "Energy",
    state: "OH",
    thenumber: 0.08879220046732737,
  },
  {
    eiaid: 10668,
    name: "Licking Rural Electric Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13904223394509901,
  },
  {
    eiaid: 60219,
    name: "LifeEnergy, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.07451360154943217,
  },
  {
    eiaid: 11203,
    name: "Logan County Coop Power & Light",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1538282987322869,
  },
  {
    eiaid: 11200,
    name: "Lorain-Medina R E C, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13426987369210888,
  },
  {
    eiaid: 59361,
    name: "Lykins Energy Solutions",
    type: "Energy",
    state: "OH",
    thenumber: 0.055067854694996574,
  },
  {
    eiaid: 63168,
    name: "Median Energy Corp.",
    type: "Energy",
    state: "OH",
    thenumber: 0.11403016354178362,
  },
  {
    eiaid: 36189,
    name: "Mid-Ohio Energy Coop, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1319637833410491,
  },
  {
    eiaid: 59931,
    name: "MidAmerican Energy Services, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.05187344653766638,
  },
  {
    eiaid: 12515,
    name: "Midwest Electric, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12273853954344499,
  },
  {
    eiaid: 12377,
    name: "Midwest Energy Cooperative",
    type: "Bundled",
    state: "OH",
    thenumber: 0.15130942604252212,
  },
  {
    eiaid: 63232,
    name: "National Gas & Electric, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.09480576167612396,
  },
  {
    eiaid: 59088,
    name: "Nordic Energy Services, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.06501562270639798,
  },
  {
    eiaid: 58667,
    name: "North American Power and Gas, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.0912038516118966,
  },
  {
    eiaid: 13693,
    name: "North Central Elec Coop, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13141182217879824,
  },
  {
    eiaid: 13704,
    name: "North Western Elec Coop, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12698353011957583,
  },
  {
    eiaid: 13998,
    name: "Ohio Edison Co",
    type: "Delivery",
    state: "OH",
    thenumber: 0.06735107511022172,
  },
  {
    eiaid: 13998,
    name: "Ohio Edison Co",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12068548160290561,
  },
  {
    eiaid: 14006,
    name: "Ohio Power Co",
    type: "Delivery",
    state: "OH",
    thenumber: 0.06876158888562009,
  },
  {
    eiaid: 14006,
    name: "Ohio Power Co",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1219462692353854,
  },
  {
    eiaid: 57467,
    name: "Palmco Power OH, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.11407191093265194,
  },
  {
    eiaid: 59797,
    name: "Park Power LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.06555754576641672,
  },
  {
    eiaid: 14599,
    name: "Paulding-Putman Elec Coop, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1266335355442342,
  },
  {
    eiaid: 15054,
    name: "Pioneer Rural Elec Coop, Inc - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13751343012204192,
  },
  {
    eiaid: 60041,
    name: "Planet Energy",
    type: "Energy",
    state: "OH",
    thenumber: 0.060242488139167105,
  },
  {
    eiaid: 63546,
    name: "Provision Power & Gas, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.07789263826330946,
  },
  {
    eiaid: 58119,
    name: "Public Power LLC (CT)",
    type: "Energy",
    state: "OH",
    thenumber: 0.09071434456620252,
  },
  {
    eiaid: 57037,
    name: "Reliant Energy Northeast LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.08333898071849537,
  },
  {
    eiaid: 59807,
    name: "Rushmore Energy, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.07107405788024532,
  },
  {
    eiaid: 59811,
    name: "Santanna Energy Services",
    type: "Energy",
    state: "OH",
    thenumber: 0.05703458760083889,
  },
  {
    eiaid: 16951,
    name: "Seville Board of Public Affairs",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1357282904307128,
  },
  {
    eiaid: 60579,
    name: "SFE Energy Ohio, Inc.",
    type: "Energy",
    state: "OH",
    thenumber: 0.07557173573103987,
  },
  {
    eiaid: 58956,
    name: "SmartEnergy Holdings, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.07733952885238816,
  },
  {
    eiaid: 18085,
    name: "South Central Power Company",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11921815983680166,
  },
  {
    eiaid: 58953,
    name: "Star Energy Partners, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.07671518885693011,
  },
  {
    eiaid: 60977,
    name: "StateWise Energy Ohio LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.0790386632363092,
  },
  {
    eiaid: 61479,
    name: "Stream Ohio Gas & Electric, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.05557704322989498,
  },
  {
    eiaid: 59932,
    name: "Switch Energy",
    type: "Energy",
    state: "OH",
    thenumber: 0.0626361068587076,
  },
  {
    eiaid: 18997,
    name: "The Toledo Edison Co",
    type: "Delivery",
    state: "OH",
    thenumber: 0.07470120156194368,
  },
  {
    eiaid: 18997,
    name: "The Toledo Edison Co",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1267806030112054,
  },
  {
    eiaid: 59065,
    name: "Titan Gas LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.11364878040211156,
  },
  {
    eiaid: 58952,
    name: "Tomorow Energy Corp.",
    type: "Energy",
    state: "OH",
    thenumber: 0.09193209054593875,
  },
  {
    eiaid: 19176,
    name: "Tricounty Rural Elec Coop, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1280438985334609,
  },
  {
    eiaid: 19501,
    name: "Union Rural Electric Coop, Inc",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13530092831889512,
  },
  {
    eiaid: 59620,
    name: "Verde Energy USA",
    type: "Energy",
    state: "OH",
    thenumber: 0.11698861715316128,
  },
  {
    eiaid: 1386,
    name: "Village of Beach City - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.15906962222916016,
  },
  {
    eiaid: 1650,
    name: "Village of Bethel - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.10636671262062916,
  },
  {
    eiaid: 2205,
    name: "Village of Brewster - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1237162461266047,
  },
  {
    eiaid: 3008,
    name: "Village of Carey - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.10675965665236052,
  },
  {
    eiaid: 5096,
    name: "Village of Deshler - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1623866179762045,
  },
  {
    eiaid: 5653,
    name: "Village of Edgerton - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12494056110318592,
  },
  {
    eiaid: 7100,
    name: "Village of Genoa - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1588022835838285,
  },
  {
    eiaid: 7131,
    name: "Village of Georgetown - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.09693818153595406,
  },
  {
    eiaid: 7445,
    name: "Village of Grafton - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.10898728627794826,
  },
  {
    eiaid: 10616,
    name: "Village of Lakeview - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.14813455657492355,
  },
  {
    eiaid: 11126,
    name: "Village of Lodi - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.10931161053512782,
  },
  {
    eiaid: 12660,
    name: "Village of Minster - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12176252943155062,
  },
  {
    eiaid: 12861,
    name: "Village of Montpelier - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.12112894564114804,
  },
  {
    eiaid: 13420,
    name: "Village of New Bremen - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.10837849103793247,
  },
  {
    eiaid: 13932,
    name: "Village of Oak Harbor - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13829975256274302,
  },
  {
    eiaid: 16062,
    name: "Village of Ripley - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11128792701039678,
  },
  {
    eiaid: 19805,
    name: "Village of Versailles - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.1347001016604541,
  },
  {
    eiaid: 20313,
    name: "Village of Wellington - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11498483115332969,
  },
  {
    eiaid: 20979,
    name: "Village of Woodville - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.13691128148959475,
  },
  {
    eiaid: 21101,
    name: "Village of Yellow Springs - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.11006831826651024,
  },
  {
    eiaid: 60696,
    name: "Vista Energy Marketing, L.P.",
    type: "Energy",
    state: "OH",
    thenumber: 0.09973868253704515,
  },
  {
    eiaid: 20150,
    name: "Washington Electric Coop - (OH)",
    type: "Bundled",
    state: "OH",
    thenumber: 0.15781783985871234,
  },
  {
    eiaid: 59311,
    name: "XOOM Energy Ohio, LLC",
    type: "Energy",
    state: "OH",
    thenumber: 0.0765399434697433,
  },
  {
    eiaid: 296,
    name: "Alfalfa Electric Coop, Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.09674848891221115,
  },
  {
    eiaid: 588,
    name: "Anadarko Public Works Authority",
    type: "Bundled",
    state: "OK",
    thenumber: 0.0975897920604915,
  },
  {
    eiaid: 817,
    name: "Arkansas Valley Elec Coop Corp",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10965046604527297,
  },
  {
    eiaid: 2911,
    name: "Canadian Valley Elec Coop, Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.09949882034700883,
  },
  {
    eiaid: 3226,
    name: "Central Rural Electric Cooperative, Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.09557711915058187,
  },
  {
    eiaid: 3527,
    name: "Choctaw Electric Coop Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.12786953055668365,
  },
  {
    eiaid: 3478,
    name: "Cimarron Electric Coop",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10957818416649544,
  },
  {
    eiaid: 416,
    name: "City of Altus - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.14660875600282092,
  },
  {
    eiaid: 1800,
    name: "City of Blackwell - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.14080854879689134,
  },
  {
    eiaid: 3647,
    name: "City of Claremore",
    type: "Bundled",
    state: "OK",
    thenumber: 0.13361804955404713,
  },
  {
    eiaid: 3986,
    name: "City of Collinsville - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.1354682397556094,
  },
  {
    eiaid: 4078,
    name: "City of Comanche - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.13453518094981837,
  },
  {
    eiaid: 4667,
    name: "City of Cushing - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10019252783848476,
  },
  {
    eiaid: 5429,
    name: "City of Duncan - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11251869356256491,
  },
  {
    eiaid: 5661,
    name: "City of Edmond - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11186806586626402,
  },
  {
    eiaid: 6152,
    name: "City of Fairview - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.07709547288211564,
  },
  {
    eiaid: 6750,
    name: "City of Frederick - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11910618395981293,
  },
  {
    eiaid: 10320,
    name: "City of Kingfisher - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10094784697203636,
  },
  {
    eiaid: 11058,
    name: "City of Lindsay - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.08712204407350464,
  },
  {
    eiaid: 11566,
    name: "City of Mangum - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.12195640125623498,
  },
  {
    eiaid: 11691,
    name: "City of Marlow - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.09998673915926269,
  },
  {
    eiaid: 12408,
    name: "City of Miami - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.09809646152049516,
  },
  {
    eiaid: 4344,
    name: "City of New Cordell - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10583640836408365,
  },
  {
    eiaid: 13530,
    name: "City of Newkirk - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.1046090108751942,
  },
  {
    eiaid: 14582,
    name: "City of Pawhuska - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.15436729396495782,
  },
  {
    eiaid: 14829,
    name: "City of Perry - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.12080948487326247,
  },
  {
    eiaid: 15202,
    name: "City of Ponca City - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.12239778759832125,
  },
  {
    eiaid: 15462,
    name: "City of Pryor - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.08518518518518518,
  },
  {
    eiaid: 15515,
    name: "City of Purcell - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11686522710349963,
  },
  {
    eiaid: 16561,
    name: "City of Sallisaw - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10170266028251843,
  },
  {
    eiaid: 17286,
    name: "City of Skiatook - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10495956873315364,
  },
  {
    eiaid: 18126,
    name: "City of Stilwell - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.09688941806816491,
  },
  {
    eiaid: 18224,
    name: "City of Stroud - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.12751729438893158,
  },
  {
    eiaid: 19025,
    name: "City of Tonkawa - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11483008009954117,
  },
  {
    eiaid: 20196,
    name: "City of Watonga - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.12281460838955706,
  },
  {
    eiaid: 60482,
    name: "CKenergy Electric Cooperative",
    type: "Bundled",
    state: "OK",
    thenumber: 0.1284718803256959,
  },
  {
    eiaid: 4296,
    name: "Cookson Hills Elec Coop, Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11650819298572701,
  },
  {
    eiaid: 4401,
    name: "Cotton Electric Coop, Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.1109757809054092,
  },
  {
    eiaid: 5598,
    name: "East Central Oklahoma Elec Coop Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11414415278482819,
  },
  {
    eiaid: 5860,
    name: "Empire District Electric Co",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10387691710347505,
  },
  {
    eiaid: 9246,
    name: "Indian Electric Coop, Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10720386410207841,
  },
  {
    eiaid: 10012,
    name: "Kay Electric Coop",
    type: "Bundled",
    state: "OK",
    thenumber: 0.12507331100894867,
  },
  {
    eiaid: 10170,
    name: "Kiamichi Electric Coop, Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11199279919857189,
  },
  {
    eiaid: 10599,
    name: "Lake Region Electric Coop, Inc - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.1066795687538227,
  },
  {
    eiaid: 13734,
    name: "Northeast Oklahoma Electric Co",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11922806373673425,
  },
  {
    eiaid: 13838,
    name: "Northfork Electric Coop, Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.14175157232704402,
  },
  {
    eiaid: 13807,
    name: "Northwestern Electric Coop Inc - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.13837051387978766,
  },
  {
    eiaid: 14062,
    name: "Oklahoma Electric Coop Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11247984686674546,
  },
  {
    eiaid: 14063,
    name: "Oklahoma Gas & Electric Co",
    type: "Bundled",
    state: "OK",
    thenumber: 0.09236387332070366,
  },
  {
    eiaid: 14289,
    name: "Ozarks Electric Coop Corp - (AR)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.1165990524553888,
  },
  {
    eiaid: 14775,
    name: "People's Electric Cooperative",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10510605227585579,
  },
  {
    eiaid: 15311,
    name: "Prague Public Works Authority",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11403944595001506,
  },
  {
    eiaid: 15474,
    name: "Public Service Co of Oklahoma",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10140309506075848,
  },
  {
    eiaid: 15746,
    name: "Red River Valley Rrl Elec Assn",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10276924638931899,
  },
  {
    eiaid: 16382,
    name: "Rural Electric Coop, Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11016358509308809,
  },
  {
    eiaid: 17603,
    name: "Southeastern Electric Coop Inc - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11153481773474061,
  },
  {
    eiaid: 17681,
    name: "Southwest Rural Elec Assn Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.1288084598026636,
  },
  {
    eiaid: 18125,
    name: "Stillwater Utilities Authority",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11068874081466913,
  },
  {
    eiaid: 18433,
    name: "Tahlequah Public Works Authority",
    type: "Bundled",
    state: "OK",
    thenumber: 0.09006484784586721,
  },
  {
    eiaid: 18524,
    name: "Tecumseh Utility Authority",
    type: "Bundled",
    state: "OK",
    thenumber: 0.11541225506635366,
  },
  {
    eiaid: 11578,
    name: "Town of Mannford - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10028287252486541,
  },
  {
    eiaid: 17802,
    name: "Town of Spiro - (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.09936575052854123,
  },
  {
    eiaid: 19160,
    name: "Tri-County Electric Coop, Inc (OK)",
    type: "Bundled",
    state: "OK",
    thenumber: 0.12897863285752284,
  },
  {
    eiaid: 19785,
    name: "Verdigris Valley Elec Coop Inc",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10593615543508655,
  },
  {
    eiaid: 20163,
    name: "Wagoner Public Works Authority",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10722630173564753,
  },
  {
    eiaid: 20061,
    name: "Walters Public Works Authority",
    type: "Bundled",
    state: "OK",
    thenumber: 0.10918747092572491,
  },
  {
    eiaid: 1736,
    name: "Blachly-Lane County Coop El Assn",
    type: "Bundled",
    state: "OR",
    thenumber: 0.1354517671028916,
  },
  {
    eiaid: 2955,
    name: "Canby Utility Board",
    type: "Bundled",
    state: "OR",
    thenumber: 0.0814993412721761,
  },
  {
    eiaid: 3240,
    name: "Central Electric Coop Inc - (OR)",
    type: "Bundled",
    state: "OR",
    thenumber: 0.0865760512874169,
  },
  {
    eiaid: 3264,
    name: "Central Lincoln People's Ut Dt",
    type: "Bundled",
    state: "OR",
    thenumber: 0.09814150100697025,
  },
  {
    eiaid: 907,
    name: "City of Ashland - (OR)",
    type: "Bundled",
    state: "OR",
    thenumber: 0.0945456982042348,
  },
  {
    eiaid: 1176,
    name: "City of Bandon - (OR)",
    type: "Bundled",
    state: "OR",
    thenumber: 0.08840789227103579,
  },
  {
    eiaid: 6022,
    name: "City of Eugene - (OR)",
    type: "Bundled",
    state: "OR",
    thenumber: 0.11354055133839393,
  },
  {
    eiaid: 6582,
    name: "City of Forest Grove",
    type: "Bundled",
    state: "OR",
    thenumber: 0.0846045834344555,
  },
  {
    eiaid: 8515,
    name: "City of Hermiston",
    type: "Bundled",
    state: "OR",
    thenumber: 0.08545114486510996,
  },
  {
    eiaid: 12187,
    name: "City of McMinnville - (OR)",
    type: "Bundled",
    state: "OR",
    thenumber: 0.06966909074392762,
  },
  {
    eiaid: 12615,
    name: "City of Milton-Freewater- (OR)",
    type: "Bundled",
    state: "OR",
    thenumber: 0.07245273022285506,
  },
  {
    eiaid: 12789,
    name: "City of Monmouth - (OR)",
    type: "Bundled",
    state: "OR",
    thenumber: 0.08434987766515203,
  },
  {
    eiaid: 17839,
    name: "City of Springfield - (OR)",
    type: "Bundled",
    state: "OR",
    thenumber: 0.06880104024236575,
  },
  {
    eiaid: 28541,
    name: "Clatskanie Peoples Util Dist",
    type: "Bundled",
    state: "OR",
    thenumber: 0.07163188710403842,
  },
  {
    eiaid: 4005,
    name: "Columbia Basin Elec Cooperative, Inc",
    type: "Bundled",
    state: "OR",
    thenumber: 0.10573742664532712,
  },
  {
    eiaid: 4008,
    name: "Columbia Power Coop Assn Inc",
    type: "Bundled",
    state: "OR",
    thenumber: 0.1156646557104223,
  },
  {
    eiaid: 40438,
    name: "Columbia River Peoples Ut Dist",
    type: "Bundled",
    state: "OR",
    thenumber: 0.08250470956534493,
  },
  {
    eiaid: 4743,
    name: "Consumers Power, Inc",
    type: "Bundled",
    state: "OR",
    thenumber: 0.1083175353570607,
  },
  {
    eiaid: 4317,
    name: "Coos-Curry Electric Coop, Inc",
    type: "Bundled",
    state: "OR",
    thenumber: 0.12276134863241868,
  },
  {
    eiaid: 5327,
    name: "Douglas Electric Coop - (OR)",
    type: "Bundled",
    state: "OR",
    thenumber: 0.10698649012237976,
  },
  {
    eiaid: 40437,
    name: "Emerald People's Utility Dist",
    type: "Bundled",
    state: "OR",
    thenumber: 0.1042362866831008,
  },
  {
    eiaid: 11589,
    name: "Harney Electric Coop, Inc",
    type: "Bundled",
    state: "OR",
    thenumber: 0.08241821815504365,
  },
  {
    eiaid: 8830,
    name: "Hood River Electric Coop",
    type: "Bundled",
    state: "OR",
    thenumber: 0.08287037878099737,
  },
  {
    eiaid: 9191,
    name: "Idaho Power Co",
    type: "Bundled",
    state: "OR",
    thenumber: 0.094839551410171,
  },
  {
    eiaid: 10681,
    name: "Lane Electric Coop Inc",
    type: "Bundled",
    state: "OR",
    thenumber: 0.11633516128515516,
  },
  {
    eiaid: 12439,
    name: "Midstate Electric Coop, Inc",
    type: "Bundled",
    state: "OR",
    thenumber: 0.08990954051971178,
  },
  {
    eiaid: 13788,
    name: "Northern Wasco County PUD",
    type: "Bundled",
    state: "OR",
    thenumber: 0.07000718858042652,
  },
  {
    eiaid: 14109,
    name: "Oregon Trail El Cons Coop, Inc",
    type: "Bundled",
    state: "OR",
    thenumber: 0.09968818283428042,
  },
  {
    eiaid: 14354,
    name: "PacifiCorp",
    type: "Bundled",
    state: "OR",
    thenumber: 0.10793401107842698,
  },
  {
    eiaid: 15248,
    name: "Portland General Electric Co",
    type: "Bundled",
    state: "OR",
    thenumber: 0.12284618171777024,
  },
  {
    eiaid: 16555,
    name: "Salem Electric - (OR)",
    type: "Bundled",
    state: "OR",
    thenumber: 0.09523011611535517,
  },
  {
    eiaid: 18260,
    name: "Surprise Valley Electrification",
    type: "Bundled",
    state: "OR",
    thenumber: 0.096045197740113,
  },
  {
    eiaid: 18917,
    name: "Tillamook Peoples Utility Dist",
    type: "Bundled",
    state: "OR",
    thenumber: 0.09732887594333378,
  },
  {
    eiaid: 19325,
    name: "Umatilla Electric Coop Assn",
    type: "Bundled",
    state: "OR",
    thenumber: 0.08357359000413145,
  },
  {
    eiaid: 20138,
    name: "Wasco Electric Coop, Inc",
    type: "Bundled",
    state: "OR",
    thenumber: 0.12112294288480155,
  },
  {
    eiaid: 20385,
    name: "West Oregon Electric Coop Inc",
    type: "Bundled",
    state: "OR",
    thenumber: 0.19154589371980676,
  },
  {
    eiaid: 40220,
    name: "Adams Electric Cooperative Inc",
    type: "Bundled",
    state: "PA",
    thenumber: 0.12443311998594205,
  },
  {
    eiaid: 55722,
    name: "AEP Energy",
    type: "Energy",
    state: "PA",
    thenumber: 0.0705476418776673,
  },
  {
    eiaid: 113,
    name: "Agway Energy Services, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.09792344073307714,
  },
  {
    eiaid: 58964,
    name: "Alpha Gas and Electric, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.11664890564800394,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.07781812726323621,
  },
  {
    eiaid: 59799,
    name: "American Power & Gas",
    type: "Energy",
    state: "PA",
    thenumber: 0.12542421886545146,
  },
  {
    eiaid: 56571,
    name: "AP Holdings LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.06583411254541918,
  },
  {
    eiaid: 40221,
    name: "Bedford Rural Elec Coop, Inc",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1215007215007215,
  },
  {
    eiaid: 1823,
    name: "Borough of Blakely - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1745126534703082,
  },
  {
    eiaid: 3170,
    name: "Borough of Catawissa",
    type: "Bundled",
    state: "PA",
    thenumber: 0.17263230005137867,
  },
  {
    eiaid: 3329,
    name: "Borough of Chambersburg",
    type: "Bundled",
    state: "PA",
    thenumber: 0.10120949458521823,
  },
  {
    eiaid: 5828,
    name: "Borough of Ellwood City - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.15361828184511214,
  },
  {
    eiaid: 5935,
    name: "Borough of Ephrata - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.13027788792971967,
  },
  {
    eiaid: 7256,
    name: "Borough of Girard - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.12127779115498837,
  },
  {
    eiaid: 7719,
    name: "Borough of Grove City - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.14662155286455683,
  },
  {
    eiaid: 8254,
    name: "Borough of Hatfield- (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1901508682038144,
  },
  {
    eiaid: 10494,
    name: "Borough of Kutztown - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1704687891702181,
  },
  {
    eiaid: 10702,
    name: "Borough of Lansdale",
    type: "Bundled",
    state: "PA",
    thenumber: 0.18862107623318386,
  },
  {
    eiaid: 10882,
    name: "Borough of Lehighton - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1385395642498577,
  },
  {
    eiaid: 12474,
    name: "Borough of Middletown - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1293475653633965,
  },
  {
    eiaid: 12523,
    name: "Borough of Mifflinburg - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1303990803087535,
  },
  {
    eiaid: 14124,
    name: "Borough of Olyphant - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.16824282235687185,
  },
  {
    eiaid: 14792,
    name: "Borough of Perkasie - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.15727680610408104,
  },
  {
    eiaid: 15104,
    name: "Borough of Pitcairn - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1836713138994508,
  },
  {
    eiaid: 15541,
    name: "Borough of Quakertown - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.12596659207992922,
  },
  {
    eiaid: 16780,
    name: "Borough of Schuylkill Haven - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.13695310927752063,
  },
  {
    eiaid: 16853,
    name: "Borough of St Clair - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.18845801246791344,
  },
  {
    eiaid: 18456,
    name: "Borough of Tarentum - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1695558317524694,
  },
  {
    eiaid: 20206,
    name: "Borough of Watsontown - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1745578231292517,
  },
  {
    eiaid: 20232,
    name: "Borough of Weatherly - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.13505778756191525,
  },
  {
    eiaid: 21160,
    name: "Borough of Zelienople - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1818276220145379,
  },
  {
    eiaid: 40224,
    name: "Central Electric Coop, Inc - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.14037545012874028,
  },
  {
    eiaid: 59055,
    name: "Choice Energy, LLC d/b/a 4 Choice Energy",
    type: "Energy",
    state: "PA",
    thenumber: 0.11278082227358906,
  },
  {
    eiaid: 3597,
    name: "Citizens Electric Co - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.10249164283006514,
  },
  {
    eiaid: 40289,
    name: "Claverack Rural Elec Coop Inc",
    type: "Bundled",
    state: "PA",
    thenumber: 0.13752728251519922,
  },
  {
    eiaid: 58951,
    name: "CleanChoice Energy, Inc.",
    type: "Energy",
    state: "PA",
    thenumber: 0.10460319164162016,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "PA",
    thenumber: 0.1004141866601215,
  },
  {
    eiaid: 4100,
    name: "Commerce Energy, Inc.",
    type: "Energy",
    state: "PA",
    thenumber: 0.09410089922959985,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Energy",
    state: "PA",
    thenumber: 0.07394169977352173,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "PA",
    thenumber: 0.0855414975326203,
  },
  {
    eiaid: 58973,
    name: "Discount Energy Group, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.07490576419285697,
  },
  {
    eiaid: 59993,
    name: "Discount Power Inc - (CT)",
    type: "Energy",
    state: "PA",
    thenumber: 0.1252596909044844,
  },
  {
    eiaid: 5487,
    name: "Duquesne Light Co",
    type: "Delivery",
    state: "PA",
    thenumber: 0.08754652823056544,
  },
  {
    eiaid: 5487,
    name: "Duquesne Light Co",
    type: "Bundled",
    state: "PA",
    thenumber: 0.16024547175445913,
  },
  {
    eiaid: 59385,
    name: "Dynegy Energy Services, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.0571553738317757,
  },
  {
    eiaid: 59054,
    name: "Eligo Energy, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.08934065413925309,
  },
  {
    eiaid: 6458,
    name: "Energy Harbor Corp.",
    type: "Energy",
    state: "PA",
    thenumber: 0.06400201294777913,
  },
  {
    eiaid: 56265,
    name: "Energy Plus Holdings LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.11336861905072718,
  },
  {
    eiaid: 55874,
    name: "Energy Services Providers, Inc",
    type: "Energy",
    state: "PA",
    thenumber: 0.10555400669109447,
  },
  {
    eiaid: 57067,
    name: "ENGIE Retail, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.094138964972648,
  },
  {
    eiaid: 58683,
    name: "Everyday Energy LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.12009672219236969,
  },
  {
    eiaid: 56286,
    name: "Frontier Utilities, Inc.",
    type: "Energy",
    state: "PA",
    thenumber: 0.09815072369241394,
  },
  {
    eiaid: 59064,
    name: "Great American Power, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.08875919702061949,
  },
  {
    eiaid: 7554,
    name: "Green Mountain Energy Company",
    type: "Energy",
    state: "PA",
    thenumber: 0.11188481066278466,
  },
  {
    eiaid: 55815,
    name: "IDT Energy, Inc.",
    type: "Energy",
    state: "PA",
    thenumber: 0.11179652266109391,
  },
  {
    eiaid: 59310,
    name: "Inspire Energy Holdings, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.10531946297630153,
  },
  {
    eiaid: 58957,
    name: "Interstate Gas Supply, Inc.",
    type: "Energy",
    state: "PA",
    thenumber: 0.0876465424675722,
  },
  {
    eiaid: 63028,
    name: "Josco Energy USA LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.11623023715415019,
  },
  {
    eiaid: 9949,
    name: "Just Energy",
    type: "Energy",
    state: "PA",
    thenumber: 0.08303064699205448,
  },
  {
    eiaid: 55781,
    name: "Liberty Power Corp.",
    type: "Energy",
    state: "PA",
    thenumber: 0.09453077205432522,
  },
  {
    eiaid: 60219,
    name: "LifeEnergy, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.08696838267647243,
  },
  {
    eiaid: 12390,
    name: "Metropolitan Edison Co",
    type: "Delivery",
    state: "PA",
    thenumber: 0.06497959838157973,
  },
  {
    eiaid: 12390,
    name: "Metropolitan Edison Co",
    type: "Bundled",
    state: "PA",
    thenumber: 0.12578362062553877,
  },
  {
    eiaid: 60218,
    name: "MPower Energy NJ LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.10696058784235138,
  },
  {
    eiaid: 63232,
    name: "National Gas & Electric, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.08592842262156807,
  },
  {
    eiaid: 13205,
    name: "New Enterprise R E C, Inc",
    type: "Bundled",
    state: "PA",
    thenumber: 0.11882100924645463,
  },
  {
    eiaid: 7279,
    name: "NextEra Energy Services, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.09469976905311778,
  },
  {
    eiaid: 60101,
    name: "Nittany Energy, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.06780300987108258,
  },
  {
    eiaid: 59088,
    name: "Nordic Energy Services, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.06432563916778802,
  },
  {
    eiaid: 58667,
    name: "North American Power and Gas, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.10374878680038822,
  },
  {
    eiaid: 40293,
    name: "Northwestern Rural E C A, Inc - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.13525666003568426,
  },
  {
    eiaid: 56573,
    name: "Palmco Power PA, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.13962232113618997,
  },
  {
    eiaid: 59797,
    name: "Park Power LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.09914895529094585,
  },
  {
    eiaid: 14940,
    name: "PECO Energy Co",
    type: "Delivery",
    state: "PA",
    thenumber: 0.06919180679311618,
  },
  {
    eiaid: 14940,
    name: "PECO Energy Co",
    type: "Bundled",
    state: "PA",
    thenumber: 0.12755107186019984,
  },
  {
    eiaid: 14711,
    name: "Pennsylvania Electric Co",
    type: "Delivery",
    state: "PA",
    thenumber: 0.08938407387839237,
  },
  {
    eiaid: 14711,
    name: "Pennsylvania Electric Co",
    type: "Bundled",
    state: "PA",
    thenumber: 0.14694912094871015,
  },
  {
    eiaid: 14716,
    name: "Pennsylvania Power Co",
    type: "Delivery",
    state: "PA",
    thenumber: 0.06010804340310922,
  },
  {
    eiaid: 14716,
    name: "Pennsylvania Power Co",
    type: "Bundled",
    state: "PA",
    thenumber: 0.12694403395642218,
  },
  {
    eiaid: 15045,
    name: "Pike County Light & Power Co",
    type: "Bundled",
    state: "PA",
    thenumber: 0.13527048491077268,
  },
  {
    eiaid: 60041,
    name: "Planet Energy",
    type: "Energy",
    state: "PA",
    thenumber: 0.07470888714471365,
  },
  {
    eiaid: 14715,
    name: "PPL Electric Utilities Corp",
    type: "Delivery",
    state: "PA",
    thenumber: 0.05723728435222039,
  },
  {
    eiaid: 14715,
    name: "PPL Electric Utilities Corp",
    type: "Bundled",
    state: "PA",
    thenumber: 0.13364470516971616,
  },
  {
    eiaid: 58118,
    name: "Public Power LLC (PA)",
    type: "Energy",
    state: "PA",
    thenumber: 0.11600079600126424,
  },
  {
    eiaid: 40292,
    name: "REA Energy Coop Inc",
    type: "Bundled",
    state: "PA",
    thenumber: 0.11972053131447474,
  },
  {
    eiaid: 57037,
    name: "Reliant Energy Northeast LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.0959157344974081,
  },
  {
    eiaid: 59809,
    name: "Residents Energy, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.08180695541632615,
  },
  {
    eiaid: 58663,
    name: "Respond Power LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.15544685046932424,
  },
  {
    eiaid: 59807,
    name: "Rushmore Energy, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.08772567381834277,
  },
  {
    eiaid: 59083,
    name: "SFE Energy Pennsylvania",
    type: "Energy",
    state: "PA",
    thenumber: 0.0800352319436289,
  },
  {
    eiaid: 59410,
    name: "Shipley Choice, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.08764817818817071,
  },
  {
    eiaid: 58956,
    name: "SmartEnergy Holdings, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.09978502412636843,
  },
  {
    eiaid: 40167,
    name: "Somerset Rural Elec Coop, Inc",
    type: "Bundled",
    state: "PA",
    thenumber: 0.11638555934942603,
  },
  {
    eiaid: 60124,
    name: "Spring Energy RRH LLC dba Spring Power &",
    type: "Energy",
    state: "PA",
    thenumber: 0.08535869437595398,
  },
  {
    eiaid: 58953,
    name: "Star Energy Partners, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.10051130356997641,
  },
  {
    eiaid: 59795,
    name: "Starion Energy PA, Inc.",
    type: "Energy",
    state: "PA",
    thenumber: 0.09149381209691476,
  },
  {
    eiaid: 56737,
    name: "Stream Energy Pennsylvania, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.08374790430946218,
  },
  {
    eiaid: 40291,
    name: "Sullivan County R E C, Inc - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1773191874757407,
  },
  {
    eiaid: 59933,
    name: "Texpo Power, L.P.",
    type: "Energy",
    state: "PA",
    thenumber: 0.08896345334179741,
  },
  {
    eiaid: 49746,
    name: "The Energy Coop",
    type: "Energy",
    state: "PA",
    thenumber: 0.12624005182306128,
  },
  {
    eiaid: 59065,
    name: "Titan Gas LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.09456629656678794,
  },
  {
    eiaid: 58952,
    name: "Tomorow Energy Corp.",
    type: "Energy",
    state: "PA",
    thenumber: 0.11122214586594383,
  },
  {
    eiaid: 40290,
    name: "Tri-County Rural Elec Coop Inc (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.19041017897368617,
  },
  {
    eiaid: 19126,
    name: "TriEagle Energy, L.P.",
    type: "Energy",
    state: "PA",
    thenumber: 0.08186764682905488,
  },
  {
    eiaid: 19390,
    name: "UGI Utilities, Inc",
    type: "Bundled",
    state: "PA",
    thenumber: 0.1064783374900795,
  },
  {
    eiaid: 40219,
    name: "United Electric Coop, Inc - (PA)",
    type: "Bundled",
    state: "PA",
    thenumber: 0.17125043701200326,
  },
  {
    eiaid: 40222,
    name: "Valley Rural Electric Coop Inc",
    type: "Bundled",
    state: "PA",
    thenumber: 0.12752332083237433,
  },
  {
    eiaid: 59620,
    name: "Verde Energy USA",
    type: "Energy",
    state: "PA",
    thenumber: 0.10639446982253305,
  },
  {
    eiaid: 58162,
    name: "Viridian Energy PA LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.12374470571053015,
  },
  {
    eiaid: 60696,
    name: "Vista Energy Marketing, L.P.",
    type: "Energy",
    state: "PA",
    thenumber: 0.08888775236219969,
  },
  {
    eiaid: 40223,
    name: "Warren Electric Coop Inc",
    type: "Bundled",
    state: "PA",
    thenumber: 0.16868554960147703,
  },
  {
    eiaid: 20334,
    name: "Wellsborough Electric Co",
    type: "Bundled",
    state: "PA",
    thenumber: 0.13507430120122188,
  },
  {
    eiaid: 20387,
    name: "West Penn Power Company",
    type: "Delivery",
    state: "PA",
    thenumber: 0.05320864014110152,
  },
  {
    eiaid: 20387,
    name: "West Penn Power Company",
    type: "Bundled",
    state: "PA",
    thenumber: 0.11095360366984568,
  },
  {
    eiaid: 20659,
    name: "WGL Energy Services, Inc.",
    type: "Energy",
    state: "PA",
    thenumber: 0.07522180789507522,
  },
  {
    eiaid: 58339,
    name: "XOOM Energy Pennsylvania, LLC",
    type: "Energy",
    state: "PA",
    thenumber: 0.08930026124329335,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Energy",
    state: "RI",
    thenumber: 0.1157391146369527,
  },
  {
    eiaid: 1857,
    name: "Block Island Utility District",
    type: "Bundled",
    state: "RI",
    thenumber: 0.39816031537450725,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Energy",
    state: "RI",
    thenumber: 0.1497769753610875,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Energy",
    state: "RI",
    thenumber: 0.10532240545077516,
  },
  {
    eiaid: 59793,
    name: "First Point Power, LLC",
    type: "Energy",
    state: "RI",
    thenumber: 0.10820486815415822,
  },
  {
    eiaid: 58667,
    name: "North American Power and Gas, LLC",
    type: "Energy",
    state: "RI",
    thenumber: 0.14680772701324052,
  },
  {
    eiaid: 14537,
    name: "Pascoag Utility District",
    type: "Bundled",
    state: "RI",
    thenumber: 0.14298072170044487,
  },
  {
    eiaid: 13214,
    name: "The Narragansett Electric Co",
    type: "Delivery",
    state: "RI",
    thenumber: 0.1085470502670038,
  },
  {
    eiaid: 13214,
    name: "The Narragansett Electric Co",
    type: "Bundled",
    state: "RI",
    thenumber: 0.21519623498602702,
  },
  {
    eiaid: 59059,
    name: "Town Square Energy",
    type: "Energy",
    state: "RI",
    thenumber: 0.1539120013696285,
  },
  {
    eiaid: 58631,
    name: "XOOM Energy Rhode Island, LLC",
    type: "Energy",
    state: "RI",
    thenumber: 0.11513984257243343,
  },
  {
    eiaid: 162,
    name: "Aiken Electric Coop Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.1397959085485547,
  },
  {
    eiaid: 1170,
    name: "Bamberg Board of Public Works",
    type: "Bundled",
    state: "SC",
    thenumber: 0.12371665892792653,
  },
  {
    eiaid: 1613,
    name: "Berkeley Electric Coop Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13321538620313444,
  },
  {
    eiaid: 1763,
    name: "Black River Electric Coop, Inc - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.11925988657068802,
  },
  {
    eiaid: 1890,
    name: "Blue Ridge Electric Coop Inc - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.1486102978541193,
  },
  {
    eiaid: 2212,
    name: "Broad River Electric Coop, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.14639911121438323,
  },
  {
    eiaid: 34,
    name: "City of Abbeville - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13827272727272727,
  },
  {
    eiaid: 1566,
    name: "City of Bennettsville - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13034230204153235,
  },
  {
    eiaid: 2890,
    name: "City of Camden",
    type: "Bundled",
    state: "SC",
    thenumber: 0.12412636796879983,
  },
  {
    eiaid: 6894,
    name: "City of Gaffney - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.11074892349792748,
  },
  {
    eiaid: 7128,
    name: "City of Georgetown - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.09076504763208858,
  },
  {
    eiaid: 21526,
    name: "City of Laurens - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13321514499176904,
  },
  {
    eiaid: 13523,
    name: "City of Newberry - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.1411802030456853,
  },
  {
    eiaid: 14164,
    name: "City of Orangeburg - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.1099997908285282,
  },
  {
    eiaid: 16195,
    name: "City of Rock Hill - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13237698251882238,
  },
  {
    eiaid: 16921,
    name: "City of Seneca - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.12212095453973051,
  },
  {
    eiaid: 19431,
    name: "City of Union - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.12585512657963302,
  },
  {
    eiaid: 20490,
    name: "City of Westminster - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.15909270528728364,
  },
  {
    eiaid: 3804,
    name: "Clinton Combined Utility Sys",
    type: "Bundled",
    state: "SC",
    thenumber: 0.15975271550727987,
  },
  {
    eiaid: 3844,
    name: "Coastal Electric Coop, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.15937785133787424,
  },
  {
    eiaid: 17539,
    name: "Dominion Energy South Carolina, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.14305039017785054,
  },
  {
    eiaid: 5416,
    name: "Duke Energy Carolinas, LLC",
    type: "Bundled",
    state: "SC",
    thenumber: 0.11481346601131669,
  },
  {
    eiaid: 3046,
    name: "Duke Energy Progress - (NC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.12338276531799809,
  },
  {
    eiaid: 6709,
    name: "Easley Combined Utility System",
    type: "Bundled",
    state: "SC",
    thenumber: 0.12729498887995078,
  },
  {
    eiaid: 5644,
    name: "Edisto Electric Coop, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.1507176359812385,
  },
  {
    eiaid: 5929,
    name: "Fairfield Electric Coop, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13188394744028478,
  },
  {
    eiaid: 7646,
    name: "Greenwood CPW",
    type: "Bundled",
    state: "SC",
    thenumber: 0.08417430801017707,
  },
  {
    eiaid: 7654,
    name: "Greer Commission of Public Wks",
    type: "Bundled",
    state: "SC",
    thenumber: 0.12670227825374983,
  },
  {
    eiaid: 8786,
    name: "Horry Electric Coop Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.1383878485398449,
  },
  {
    eiaid: 10768,
    name: "Laurens Electric Coop, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13322429652434714,
  },
  {
    eiaid: 11019,
    name: "Little River Electric Coop Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.1493786927377262,
  },
  {
    eiaid: 11118,
    name: "Lockhart Power Co",
    type: "Bundled",
    state: "SC",
    thenumber: 0.1333743596055324,
  },
  {
    eiaid: 11355,
    name: "Lynches River Elec Coop, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.14746083811521932,
  },
  {
    eiaid: 11693,
    name: "Marlboro Electric Coop, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.12624203364700537,
  },
  {
    eiaid: 12462,
    name: "Mid-Carolina Electric Coop Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.12980688894765857,
  },
  {
    eiaid: 13524,
    name: "Newberry Electric Coop, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13436165466250238,
  },
  {
    eiaid: 14398,
    name: "Palmetto Electric Coop Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.11712865641409714,
  },
  {
    eiaid: 14557,
    name: "Pee Dee Electric Coop, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13449260612862649,
  },
  {
    eiaid: 16606,
    name: "Santee Electric Coop, Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13944466858735038,
  },
  {
    eiaid: 17543,
    name: "South Carolina Public Service Authority",
    type: "Bundled",
    state: "SC",
    thenumber: 0.11522978335920689,
  },
  {
    eiaid: 12006,
    name: "Town of McCormick - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13644842380152958,
  },
  {
    eiaid: 20826,
    name: "Town of Winnsboro - (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.13835248105597653,
  },
  {
    eiaid: 14175,
    name: "Tri-County Electric Coop, Inc (SC)",
    type: "Bundled",
    state: "SC",
    thenumber: 0.1531111187928859,
  },
  {
    eiaid: 21002,
    name: "York Electric Coop Inc",
    type: "Bundled",
    state: "SC",
    thenumber: 0.12167887025880998,
  },
  {
    eiaid: 1769,
    name: "Black Hills Electric Coop, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.13846965743766412,
  },
  {
    eiaid: 19545,
    name: "Black Hills Power, Inc. d/b/a",
    type: "Bundled",
    state: "SD",
    thenumber: 0.13150039966831267,
  },
  {
    eiaid: 1898,
    name: "Bon Homme Yankton El Assn, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11412761151891586,
  },
  {
    eiaid: 2655,
    name: "Butte Electric Coop, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.13512161181921808,
  },
  {
    eiaid: 2986,
    name: "Cam Wal Electric Coop, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.12259045909079574,
  },
  {
    eiaid: 3236,
    name: "Central Electric Coop, Inc - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11941007344049077,
  },
  {
    eiaid: 3315,
    name: "Charles Mix Electric Assn, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.13893633652760487,
  },
  {
    eiaid: 3435,
    name: "Cherry-Todd Electric Coop, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.1105618792140941,
  },
  {
    eiaid: 1601,
    name: "City of Beresford",
    type: "Bundled",
    state: "SD",
    thenumber: 0.13754193351631594,
  },
  {
    eiaid: 2285,
    name: "City of Brookings - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.108497218522291,
  },
  {
    eiaid: 6390,
    name: "City of Flandreau",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11118366596155616,
  },
  {
    eiaid: 6615,
    name: "City of Fort Pierre - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11590582178817473,
  },
  {
    eiaid: 7712,
    name: "City of Groton - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.10468365553602811,
  },
  {
    eiaid: 11475,
    name: "City of Madison - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11622249851343316,
  },
  {
    eiaid: 12580,
    name: "City of Miller - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.09476413516226162,
  },
  {
    eiaid: 15040,
    name: "City of Pierre - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.09917503844550798,
  },
  {
    eiaid: 17265,
    name: "City of Sioux Falls - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.13443830570902393,
  },
  {
    eiaid: 19788,
    name: "City of Vermillion - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11055478287745325,
  },
  {
    eiaid: 19896,
    name: "City of Volga - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.12153913423699168,
  },
  {
    eiaid: 3716,
    name: "Clay-Union Electric Corp",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11611266752479746,
  },
  {
    eiaid: 3850,
    name: "Codington-Clark Elec Coop, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.09323947556395308,
  },
  {
    eiaid: 4720,
    name: "Dakota Energy Coop Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.14275673445688877,
  },
  {
    eiaid: 5328,
    name: "Douglas Electric Coop, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.12327836548433936,
  },
  {
    eiaid: 6272,
    name: "FEM Electric Assn, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.12010992144764472,
  },
  {
    eiaid: 7484,
    name: "Grand Electric Coop, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.1249304769758923,
  },
  {
    eiaid: 7870,
    name: "H-D Electric Coop Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11092035530342614,
  },
  {
    eiaid: 10329,
    name: "Kingsbury Electric Coop, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.12784665510091822,
  },
  {
    eiaid: 10558,
    name: "LaCreek Electric Assn, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11610864275891251,
  },
  {
    eiaid: 10632,
    name: "Lake Region Electric Assn, Inc - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11900083746698448,
  },
  {
    eiaid: 12341,
    name: "MidAmerican Energy Co",
    type: "Bundled",
    state: "SD",
    thenumber: 0.08002768591863416,
  },
  {
    eiaid: 12199,
    name: "Montana-Dakota Utilities Co",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11242586759740075,
  },
  {
    eiaid: 12915,
    name: "Moreau-Grand Electric Coop Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.13193196987425634,
  },
  {
    eiaid: 13337,
    name: "Nebraska Public Power District",
    type: "Bundled",
    state: "SD",
    thenumber: 0.09298120650923238,
  },
  {
    eiaid: 13750,
    name: "Northern Electric Coop, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.09689850232353436,
  },
  {
    eiaid: 13781,
    name: "Northern States Power Co - Minnesota",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11948900703156172,
  },
  {
    eiaid: 13809,
    name: "NorthWestern Energy - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.1060959699940885,
  },
  {
    eiaid: 13853,
    name: "Oahe Electric Coop Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.09742911234456349,
  },
  {
    eiaid: 14232,
    name: "Otter Tail Power Co",
    type: "Bundled",
    state: "SD",
    thenumber: 0.10435171264881557,
  },
  {
    eiaid: 16286,
    name: "Rosebud Electric Coop Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.09782900026802466,
  },
  {
    eiaid: 17267,
    name: "Sioux Valley SW Elec Coop",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11086680577829239,
  },
  {
    eiaid: 19293,
    name: "Southeastern Electric Coop Inc - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.10949372882907626,
  },
  {
    eiaid: 19060,
    name: "Traverse Electric Coop, Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.11247332334377697,
  },
  {
    eiaid: 19493,
    name: "Union County Electric Coop Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.13056817276276994,
  },
  {
    eiaid: 20187,
    name: "Watertown Municipal Utilities",
    type: "Bundled",
    state: "SD",
    thenumber: 0.0897005294506949,
  },
  {
    eiaid: 20368,
    name: "West Central Electric Coop Inc - (SD)",
    type: "Bundled",
    state: "SD",
    thenumber: 0.13352734625841975,
  },
  {
    eiaid: 20401,
    name: "West River Electric Assn Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.13128908749985965,
  },
  {
    eiaid: 20422,
    name: "Whetstone Valley Elec Coop Inc",
    type: "Bundled",
    state: "SD",
    thenumber: 0.1239555346587856,
  },
  {
    eiaid: 20823,
    name: "Winner Municipal Utility",
    type: "Bundled",
    state: "SD",
    thenumber: 0.09817404992129526,
  },
  {
    eiaid: 727,
    name: "Appalachian Electric Coop",
    type: "Bundled",
    state: "TN",
    thenumber: 0.1158518130069063,
  },
  {
    eiaid: 947,
    name: "Athens Utility Board",
    type: "Bundled",
    state: "TN",
    thenumber: 0.103262509429218,
  },
  {
    eiaid: 1578,
    name: "Benton County",
    type: "Bundled",
    state: "TN",
    thenumber: 0.12828075574933745,
  },
  {
    eiaid: 1936,
    name: "Bolivar Energy Authority",
    type: "Bundled",
    state: "TN",
    thenumber: 0.1214324639937719,
  },
  {
    eiaid: 2960,
    name: "Caney Fork Electric Coop, Inc",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11439517779466984,
  },
  {
    eiaid: 3075,
    name: "Carroll County - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10609143792439893,
  },
  {
    eiaid: 40166,
    name: "Chickasaw Electric Coop, Inc",
    type: "Bundled",
    state: "TN",
    thenumber: 0.0987199968953942,
  },
  {
    eiaid: 577,
    name: "City of Alcoa Utilities",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10899219458603202,
  },
  {
    eiaid: 2247,
    name: "City of Bristol - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.09843399950215141,
  },
  {
    eiaid: 2411,
    name: "City of Brownsville",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10045108857739338,
  },
  {
    eiaid: 3408,
    name: "City of Chattanooga - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10909455084133601,
  },
  {
    eiaid: 3704,
    name: "City of Clarksville - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11257199175934508,
  },
  {
    eiaid: 3758,
    name: "City of Cleveland - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11439946705553428,
  },
  {
    eiaid: 3812,
    name: "City of Clinton - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11590635197898472,
  },
  {
    eiaid: 4294,
    name: "City of Cookeville - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10343473994111875,
  },
  {
    eiaid: 4437,
    name: "City of Covington - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10077471463304076,
  },
  {
    eiaid: 4920,
    name: "City of Dayton - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11075963117781505,
  },
  {
    eiaid: 5148,
    name: "City of Dickson",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10299632277741667,
  },
  {
    eiaid: 5509,
    name: "City of Dyersburg",
    type: "Bundled",
    state: "TN",
    thenumber: 0.103389842941923,
  },
  {
    eiaid: 5763,
    name: "City of Elizabethton - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10905526824179737,
  },
  {
    eiaid: 6008,
    name: "City of Etowah",
    type: "Bundled",
    state: "TN",
    thenumber: 0.1262977790328743,
  },
  {
    eiaid: 6234,
    name: "City of Fayetteville",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11081259043686084,
  },
  {
    eiaid: 6923,
    name: "City of Gallatin - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10241362952299941,
  },
  {
    eiaid: 7625,
    name: "City of Greeneville - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10379822933831576,
  },
  {
    eiaid: 8147,
    name: "City of Harriman - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.12014334063308779,
  },
  {
    eiaid: 9023,
    name: "City of Humboldt",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10322123408243981,
  },
  {
    eiaid: 9612,
    name: "City of Jackson - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11100300129482295,
  },
  {
    eiaid: 9697,
    name: "City of Jellico",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10517792801910854,
  },
  {
    eiaid: 10579,
    name: "City of LaFollette",
    type: "Bundled",
    state: "TN",
    thenumber: 0.1207178786265426,
  },
  {
    eiaid: 10799,
    name: "City of Lawrenceburg",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10810810810810811,
  },
  {
    eiaid: 10906,
    name: "City of Lenoir - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10460149483296731,
  },
  {
    eiaid: 10960,
    name: "City of Lewisburg - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10178220200291278,
  },
  {
    eiaid: 10968,
    name: "City of Lexington - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10925560469008858,
  },
  {
    eiaid: 12293,
    name: "City of Memphis - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10034384100651539,
  },
  {
    eiaid: 12532,
    name: "City of Milan",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10496100624092469,
  },
  {
    eiaid: 12988,
    name: "City of Morristown - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11583411992647424,
  },
  {
    eiaid: 13039,
    name: "City of Mt Pleasant - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.12088249754178958,
  },
  {
    eiaid: 13119,
    name: "City of Murfreesboro",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10242744180976823,
  },
  {
    eiaid: 13437,
    name: "City of Newbern",
    type: "Bundled",
    state: "TN",
    thenumber: 0.09498295177788602,
  },
  {
    eiaid: 13550,
    name: "City of Newport",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11047104020552752,
  },
  {
    eiaid: 13933,
    name: "City of Oak Ridge",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11298782720969354,
  },
  {
    eiaid: 14455,
    name: "City of Paris - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10377690549493636,
  },
  {
    eiaid: 15507,
    name: "City of Pulaski - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.1198352100439018,
  },
  {
    eiaid: 27222,
    name: "City of Ripley - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10812213355026017,
  },
  {
    eiaid: 16223,
    name: "City of Rockwood - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11300258446723391,
  },
  {
    eiaid: 17047,
    name: "City of Shelbyville - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10446872389522525,
  },
  {
    eiaid: 17452,
    name: "City of Smithville - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11227426283521709,
  },
  {
    eiaid: 17740,
    name: "City of Sparta",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11034017920862739,
  },
  {
    eiaid: 17829,
    name: "City of Springfield - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10072132211953048,
  },
  {
    eiaid: 18384,
    name: "City of Sweetwater",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10705916027120342,
  },
  {
    eiaid: 19149,
    name: "City of Trenton - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11312065645147786,
  },
  {
    eiaid: 19430,
    name: "City of Union City",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10414043293158241,
  },
  {
    eiaid: 20793,
    name: "City of Winchester - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10670704683114197,
  },
  {
    eiaid: 3855,
    name: "Columbia Power System",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10836151769219839,
  },
  {
    eiaid: 4624,
    name: "Cumberland Elec Member Corp",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11509347889708464,
  },
  {
    eiaid: 5399,
    name: "Duck River Elec Member Corp",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11151147760362042,
  },
  {
    eiaid: 40236,
    name: "Forked Deer Electric Coop, Inc",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11955818807137188,
  },
  {
    eiaid: 6608,
    name: "Fort Loudoun Electric Coop",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10995189807592304,
  },
  {
    eiaid: 6784,
    name: "French Broad Elec Member Corp",
    type: "Bundled",
    state: "TN",
    thenumber: 0.13645554202192448,
  },
  {
    eiaid: 7174,
    name: "Gibson Electric Members Corp",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11603543112363424,
  },
  {
    eiaid: 8764,
    name: "Holston Electric Coop, Inc",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11179908343026923,
  },
  {
    eiaid: 9777,
    name: "Johnson City - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11459159113389682,
  },
  {
    eiaid: 10331,
    name: "Kingsport Power Co",
    type: "Bundled",
    state: "TN",
    thenumber: 0.09041270281807198,
  },
  {
    eiaid: 10421,
    name: "Knoxville Utilities Board",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10670389326868679,
  },
  {
    eiaid: 11222,
    name: "Loudon Utilities Board",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10341577324665689,
  },
  {
    eiaid: 11789,
    name: "Maryville Utilities",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10150150379675241,
  },
  {
    eiaid: 12186,
    name: "McMinnville Electric System",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10361741263028816,
  },
  {
    eiaid: 12330,
    name: "Meriwether Lewis Electric Coop",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11551379628290853,
  },
  {
    eiaid: 12470,
    name: "Middle Tennessee E M C",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10130608694057953,
  },
  {
    eiaid: 13027,
    name: "Mountain Electric Coop, Inc",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10782639581426483,
  },
  {
    eiaid: 13216,
    name: "Nashville Electric Service",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11888707920560507,
  },
  {
    eiaid: 15016,
    name: "Pickwick Electric Coop",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11420510604293903,
  },
  {
    eiaid: 15141,
    name: "Plateau Electric Cooperative",
    type: "Bundled",
    state: "TN",
    thenumber: 0.12033956279735067,
  },
  {
    eiaid: 15293,
    name: "Powell Valley Electric Coop",
    type: "Bundled",
    state: "TN",
    thenumber: 0.10685259028073076,
  },
  {
    eiaid: 16930,
    name: "Sequachee Valley Electric Coop",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11479566528467046,
  },
  {
    eiaid: 16949,
    name: "Sevier County Electric System",
    type: "Bundled",
    state: "TN",
    thenumber: 0.1025541679731422,
  },
  {
    eiaid: 17694,
    name: "Southwest Tennessee E M C",
    type: "Bundled",
    state: "TN",
    thenumber: 0.114773988504977,
  },
  {
    eiaid: 18643,
    name: "Tennessee Valley Electric Coop",
    type: "Bundled",
    state: "TN",
    thenumber: 0.1150739900052239,
  },
  {
    eiaid: 5961,
    name: "Town of Erwin - (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11136585572963105,
  },
  {
    eiaid: 19162,
    name: "Tri-County Elec Member Corp (TN)",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11169860641963573,
  },
  {
    eiaid: 19154,
    name: "Tri-State Electric Member Corp",
    type: "Bundled",
    state: "TN",
    thenumber: 0.12539466352999443,
  },
  {
    eiaid: 19266,
    name: "Tullahoma Board-Public Utils",
    type: "Bundled",
    state: "TN",
    thenumber: 0.09909931282075614,
  },
  {
    eiaid: 19574,
    name: "Upper Cumberland E M C",
    type: "Bundled",
    state: "TN",
    thenumber: 0.11311929682122245,
  },
  {
    eiaid: 19898,
    name: "Volunteer Electric Coop",
    type: "Bundled",
    state: "TN",
    thenumber: 0.1097342791753642,
  },
  {
    eiaid: 20228,
    name: "Weakley County Mun Elec Sys",
    type: "Bundled",
    state: "TN",
    thenumber: 0.1008147083169956,
  },
  {
    eiaid: 49952,
    name: "3000 Energy Corp",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1337681886603111,
  },
  {
    eiaid: 54872,
    name: "Accent Energy Holdings, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11402269475465009,
  },
  {
    eiaid: 59472,
    name: "Agera Energy LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09568025837706903,
  },
  {
    eiaid: 49818,
    name: "Alliance Power Company, LLC.",
    type: "Bundled",
    state: "TX",
    thenumber: 0.06510754115772703,
  },
  {
    eiaid: 56212,
    name: "Ambit Energy Holdings, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1264391204450655,
  },
  {
    eiaid: 56810,
    name: "American Light and Power",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09598947236495801,
  },
  {
    eiaid: 50156,
    name: "AmeriPower LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10277376778717262,
  },
  {
    eiaid: 49853,
    name: "Amigo Energy",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1335168530200703,
  },
  {
    eiaid: 56571,
    name: "AP Holdings LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.08329301937812296,
  },
  {
    eiaid: 62722,
    name: "ATG Clean Energy Holdings Inc.",
    type: "Bundled",
    state: "TX",
    thenumber: 0.045471079623559264,
  },
  {
    eiaid: 1015,
    name: "Austin Energy",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10844059141174067,
  },
  {
    eiaid: 59566,
    name: "Axon Power & Gas LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.07170400974627894,
  },
  {
    eiaid: 1169,
    name: "Bailey County Elec Coop Assn",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10662936848559165,
  },
  {
    eiaid: 1175,
    name: "Bandera Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1130925408300811,
  },
  {
    eiaid: 1273,
    name: "Bartlett Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10790308641602255,
  },
  {
    eiaid: 1591,
    name: "Big Country Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11802900732829097,
  },
  {
    eiaid: 1892,
    name: "Bluebonnet Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1043087786084242,
  },
  {
    eiaid: 56292,
    name: "Bounce Energy, Inc.",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12554445704375666,
  },
  {
    eiaid: 2049,
    name: "Bowie-Cass Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11347856824692387,
  },
  {
    eiaid: 56255,
    name: "Brilliant Energy, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11883733929569593,
  },
  {
    eiaid: 58963,
    name: "Brooklet Energy Distribution",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09554126120182528,
  },
  {
    eiaid: 2409,
    name: "Brownsville Public Utilities Board",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1029749258440826,
  },
  {
    eiaid: 3282,
    name: "Central Texas Elec Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11235274542429284,
  },
  {
    eiaid: 54862,
    name: "Champion Energy Services",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10632339366877121,
  },
  {
    eiaid: 62862,
    name: "Chariot Energy",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09511448155169899,
  },
  {
    eiaid: 3470,
    name: "Cherokee County Elec Coop Assn",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10866387075814407,
  },
  {
    eiaid: 1324,
    name: "City of Bastrop - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10265062047763657,
  },
  {
    eiaid: 1519,
    name: "City of Bellville - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11187507283533388,
  },
  {
    eiaid: 1913,
    name: "City of Boerne",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10018313221491873,
  },
  {
    eiaid: 2050,
    name: "City of Bowie - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11620546944737482,
  },
  {
    eiaid: 2135,
    name: "City of Brady - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.13551467987265653,
  },
  {
    eiaid: 2194,
    name: "City of Brenham - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09054693293103888,
  },
  {
    eiaid: 2210,
    name: "City of Bridgeport - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1056477093206951,
  },
  {
    eiaid: 2442,
    name: "City of Bryan - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10339456312842937,
  },
  {
    eiaid: 2559,
    name: "City of Burnet - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10811541320359484,
  },
  {
    eiaid: 2800,
    name: "City of Caldwell",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11698964100732273,
  },
  {
    eiaid: 3168,
    name: "City of Castroville - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1339927121290994,
  },
  {
    eiaid: 3923,
    name: "City of Coleman - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1267688679245283,
  },
  {
    eiaid: 3940,
    name: "City of College Station - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12889762641052588,
  },
  {
    eiaid: 4610,
    name: "City of Cuero - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1057316582465239,
  },
  {
    eiaid: 5063,
    name: "City of Denton - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10727518783720394,
  },
  {
    eiaid: 5744,
    name: "City of Electra - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.13392293114339862,
  },
  {
    eiaid: 6203,
    name: "City of Farmersville - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1274213995943205,
  },
  {
    eiaid: 6396,
    name: "City of Flatonia - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09922284474968372,
  },
  {
    eiaid: 6427,
    name: "City of Floresville",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10555116580593621,
  },
  {
    eiaid: 6472,
    name: "City of Floydada - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12506457381283528,
  },
  {
    eiaid: 6758,
    name: "City of Fredericksburg - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.08549464628900888,
  },
  {
    eiaid: 6958,
    name: "City of Garland - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10306442658036573,
  },
  {
    eiaid: 7129,
    name: "City of Georgetown - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1406998172259803,
  },
  {
    eiaid: 7184,
    name: "City of Giddings - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09664669131709805,
  },
  {
    eiaid: 7368,
    name: "City of Goldthwaite - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12170550524066348,
  },
  {
    eiaid: 7370,
    name: "City of Gonzales - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12276394451215973,
  },
  {
    eiaid: 7480,
    name: "City of Granbury - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12320686887019651,
  },
  {
    eiaid: 7634,
    name: "City of Greenville - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1200421093626395,
  },
  {
    eiaid: 7958,
    name: "City of Hallettsville - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10142568843174272,
  },
  {
    eiaid: 8359,
    name: "City of Hearne - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.14163940072326503,
  },
  {
    eiaid: 8801,
    name: "City of Hondo - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11445385546144539,
  },
  {
    eiaid: 9664,
    name: "City of Jasper - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.13212054794520547,
  },
  {
    eiaid: 10532,
    name: "City of La Grange - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09254487727439248,
  },
  {
    eiaid: 10656,
    name: "City of Lampasas - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11825521652511271,
  },
  {
    eiaid: 10982,
    name: "City of Liberty - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10873942910993725,
  },
  {
    eiaid: 11097,
    name: "City of Livingston - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12685468615649184,
  },
  {
    eiaid: 11103,
    name: "City of Llano - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09837382051796828,
  },
  {
    eiaid: 11119,
    name: "City of Lockhart - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1055641604094414,
  },
  {
    eiaid: 11292,
    name: "City of Lubbock - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10695122870606712,
  },
  {
    eiaid: 11316,
    name: "City of Luling - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11474148932500948,
  },
  {
    eiaid: 13418,
    name: "City of New Braunfels - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.08829967297948037,
  },
  {
    eiaid: 13603,
    name: "City of Newton - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12414021164021165,
  },
  {
    eiaid: 16604,
    name: "City of San Antonio - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10690153453558898,
  },
  {
    eiaid: 28978,
    name: "City of San Marcos - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.13343270781859393,
  },
  {
    eiaid: 28981,
    name: "City of San Saba - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.05869906411672383,
  },
  {
    eiaid: 16647,
    name: "City of Sanger - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12458470584068289,
  },
  {
    eiaid: 16765,
    name: "City of Schulenburg - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1148477157360406,
  },
  {
    eiaid: 16900,
    name: "City of Seguin - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10623253120719797,
  },
  {
    eiaid: 16961,
    name: "City of Seymour - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.13404272089540473,
  },
  {
    eiaid: 17101,
    name: "City of Shiner - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10029697624190065,
  },
  {
    eiaid: 17453,
    name: "City of Smithville - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.20549131696331904,
  },
  {
    eiaid: 19264,
    name: "City of Tulia",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10756896460783798,
  },
  {
    eiaid: 20331,
    name: "City of Weimar - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12676612903225806,
  },
  {
    eiaid: 20588,
    name: "City of Whitesboro - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1177415510748844,
  },
  {
    eiaid: 21108,
    name: "City of Yoakum - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10360635696821516,
  },
  {
    eiaid: 58974,
    name: "Clearview Electric Inc.",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11666610517804804,
  },
  {
    eiaid: 3938,
    name: "Coleman County Elec Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11427427293811424,
  },
  {
    eiaid: 4295,
    name: "Comanche County Elec Coop Assn",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11862853130759554,
  },
  {
    eiaid: 4146,
    name: "Concho Valley Elec Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.130785401459854,
  },
  {
    eiaid: 61311,
    name: "Conservice Energy",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10686386137106742,
  },
  {
    eiaid: 13374,
    name: "Constellation NewEnergy, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11485413924079449,
  },
  {
    eiaid: 4262,
    name: "Cooke County Elec Coop Assn",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11395855169128156,
  },
  {
    eiaid: 4939,
    name: "Deaf Smith Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.08434547656759527,
  },
  {
    eiaid: 4975,
    name: "Deep East Texas Elec Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10338721664474264,
  },
  {
    eiaid: 5078,
    name: "Denton County Elec Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10395270793994231,
  },
  {
    eiaid: 54820,
    name: "Direct Energy Services",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12813859912030837,
  },
  {
    eiaid: 5701,
    name: "El Paso Electric Co",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11985672396537496,
  },
  {
    eiaid: 57067,
    name: "ENGIE Retail, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09617383316791063,
  },
  {
    eiaid: 55937,
    name: "Entergy Texas Inc.",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10047134406576894,
  },
  {
    eiaid: 56775,
    name: "Entrust Energy",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11452252244543465,
  },
  {
    eiaid: 6173,
    name: "Fannin County Electric Coop",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1356622224709355,
  },
  {
    eiaid: 6182,
    name: "Farmers Electric Coop, Inc - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11496765039126614,
  },
  {
    eiaid: 6183,
    name: "Fayette Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12862182977807632,
  },
  {
    eiaid: 6611,
    name: "Fort Belknap Electric Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10844160836472669,
  },
  {
    eiaid: 56286,
    name: "Frontier Utilities, Inc.",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11509838175393283,
  },
  {
    eiaid: 7559,
    name: "Grayson-Collin Elec Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1224102522629859,
  },
  {
    eiaid: 7554,
    name: "Green Mountain Energy Company",
    type: "Bundled",
    state: "TX",
    thenumber: 0.13545888319329563,
  },
  {
    eiaid: 7561,
    name: "Greenbelt Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.15197637437528397,
  },
  {
    eiaid: 60915,
    name: "Griddy Energy LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12005112696427533,
  },
  {
    eiaid: 61769,
    name: "GridPlus Texas Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.08990189040440297,
  },
  {
    eiaid: 7752,
    name: "Guadalupe Valley Elec Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1019380395058975,
  },
  {
    eiaid: 7979,
    name: "Hamilton County Elec Coop Assn",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10962460906331112,
  },
  {
    eiaid: 55982,
    name: "Heart of Texas Electric Coop",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09252995366518695,
  },
  {
    eiaid: 61264,
    name: "Heritage Power LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.07128718255478819,
  },
  {
    eiaid: 8620,
    name: "HILCO Electric Cooperative, Inc.",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10549275206800506,
  },
  {
    eiaid: 8898,
    name: "Houston County Elec Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11728447744492808,
  },
  {
    eiaid: 55878,
    name: "Hudson Energy Services",
    type: "Bundled",
    state: "TX",
    thenumber: 0.0988211236758593,
  },
  {
    eiaid: 56738,
    name: "Infinite Electric LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10840544372738958,
  },
  {
    eiaid: 59314,
    name: "Infuse Energy LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10184928163472806,
  },
  {
    eiaid: 9422,
    name: "J-A-C Electric Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10685451388267306,
  },
  {
    eiaid: 9590,
    name: "Jackson Electric Coop, Inc - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10901712080124605,
  },
  {
    eiaid: 9668,
    name: "Jasper-Newton Elec Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12314404572665472,
  },
  {
    eiaid: 9949,
    name: "Just Energy",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12960335124473868,
  },
  {
    eiaid: 10009,
    name: "Karnes Electric Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09519662169450625,
  },
  {
    eiaid: 28604,
    name: "Kerrville Public Utility Board",
    type: "Bundled",
    state: "TX",
    thenumber: 0.08373788584981144,
  },
  {
    eiaid: 10359,
    name: "Kirbyville Light & Power Co",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11560151894643289,
  },
  {
    eiaid: 10649,
    name: "Lamar County Elec Coop Assn",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10792045965652458,
  },
  {
    eiaid: 10625,
    name: "Lamb County Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09858509480437647,
  },
  {
    eiaid: 10817,
    name: "Lea County Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.08809123200598243,
  },
  {
    eiaid: 55781,
    name: "Liberty Power Corp.",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1359631773886152,
  },
  {
    eiaid: 60219,
    name: "LifeEnergy, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.08896632963581035,
  },
  {
    eiaid: 11014,
    name: "Lighthouse Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.13285621588610252,
  },
  {
    eiaid: 11364,
    name: "Lyntegar Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09734383836807925,
  },
  {
    eiaid: 11501,
    name: "Magic Valley Electric Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09382656232529452,
  },
  {
    eiaid: 12268,
    name: "Medina Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11535138845164457,
  },
  {
    eiaid: 56247,
    name: "Mega Energy LP DBA Texans Energy",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10078094691922344,
  },
  {
    eiaid: 12452,
    name: "Mid-South Electric Coop Assn",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11709812684612636,
  },
  {
    eiaid: 59931,
    name: "MidAmerican Energy Services, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10118651107111737,
  },
  {
    eiaid: 59808,
    name: "MP2 Energy LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11166267942583731,
  },
  {
    eiaid: 13332,
    name: "Navarro County Elec Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10482695264913226,
  },
  {
    eiaid: 16146,
    name: "Navasota Valley Elec Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10954886107726954,
  },
  {
    eiaid: 7279,
    name: "NextEra Energy Services, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12917914652233373,
  },
  {
    eiaid: 13757,
    name: "North Plains Electric Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11228343278849202,
  },
  {
    eiaid: 13830,
    name: "Nueces Electric Cooperative",
    type: "Bundled",
    state: "TX",
    thenumber: 0.0809492681894303,
  },
  {
    eiaid: 13830,
    name: "Nueces Electric Cooperative",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1399762445322978,
  },
  {
    eiaid: 56441,
    name: "Our Energy LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09031794296315399,
  },
  {
    eiaid: 14424,
    name: "Panola-Harrison Elec Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.0933443703579945,
  },
  {
    eiaid: 14626,
    name: "Pedernales Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10364434409550273,
  },
  {
    eiaid: 61265,
    name: "Pogo Energy LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.06425929930795847,
  },
  {
    eiaid: 58805,
    name: "Pro Power Providers",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10183430853533947,
  },
  {
    eiaid: 61855,
    name: "Pulse Power, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09668228120107253,
  },
  {
    eiaid: 15847,
    name: "Reliant Energy Retail Services",
    type: "Bundled",
    state: "TX",
    thenumber: 0.13615398661191808,
  },
  {
    eiaid: 16057,
    name: "Rio Grande Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.16492442099344767,
  },
  {
    eiaid: 16063,
    name: "Rita Blanca Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.08191796973695292,
  },
  {
    eiaid: 16461,
    name: "Rusk County Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11065676724258242,
  },
  {
    eiaid: 16613,
    name: "Sam Houston Electric Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11604753312664172,
  },
  {
    eiaid: 16638,
    name: "San Bernard Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12880629225728277,
  },
  {
    eiaid: 16627,
    name: "San Patricio Electric Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.12539731706735924,
  },
  {
    eiaid: 17561,
    name: "South Plains Electric Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09370921888805504,
  },
  {
    eiaid: 17561,
    name: "South Plains Electric Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1132388369096308,
  },
  {
    eiaid: 17671,
    name: "Southwest Arkansas E C C",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10116910837295043,
  },
  {
    eiaid: 17681,
    name: "Southwest Rural Elec Assn Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1291551246537396,
  },
  {
    eiaid: 17693,
    name: "Southwest Texas Elec Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11989829700452896,
  },
  {
    eiaid: 17698,
    name: "Southwestern Electric Power Co",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1020319065923215,
  },
  {
    eiaid: 17718,
    name: "Southwestern Public Service Co",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10229295167399403,
  },
  {
    eiaid: 17710,
    name: "Spark Energy, LP",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11997813783964995,
  },
  {
    eiaid: 50041,
    name: "Stream SPE, LTD",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11691139694023787,
  },
  {
    eiaid: 57350,
    name: "Summer Energy LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11277916584945723,
  },
  {
    eiaid: 18199,
    name: "Swisher Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10715359559007769,
  },
  {
    eiaid: 18476,
    name: "Tara Energy, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.13031940975577583,
  },
  {
    eiaid: 27369,
    name: "Taylor Electric Coop Inc - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.14485962360168483,
  },
  {
    eiaid: 59933,
    name: "Texpo Power, L.P.",
    type: "Bundled",
    state: "TX",
    thenumber: 0.061324784790677975,
  },
  {
    eiaid: 19159,
    name: "Tri-County Electric Coop, Inc (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.08983900944693336,
  },
  {
    eiaid: 19126,
    name: "TriEagle Energy, L.P.",
    type: "Bundled",
    state: "TX",
    thenumber: 0.0993959309324458,
  },
  {
    eiaid: 18976,
    name: "Trinity Valley Elec Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11117244706423825,
  },
  {
    eiaid: 19327,
    name: "TXU Energy Retail Co, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.13945768284819446,
  },
  {
    eiaid: 19490,
    name: "United Electric Coop Service Inc - (TX)",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09085123506173115,
  },
  {
    eiaid: 19579,
    name: "Upshur Rural Elec Coop Corp",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10316653865664213,
  },
  {
    eiaid: 57132,
    name: "US Retailers, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10697092333860597,
  },
  {
    eiaid: 58263,
    name: "V247 Power Corporation",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09685205596085773,
  },
  {
    eiaid: 57394,
    name: "Value Based Brands LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10013507137317162,
  },
  {
    eiaid: 56961,
    name: "Veteran Energy LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11163691197866149,
  },
  {
    eiaid: 19806,
    name: "Victoria Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10687531708098583,
  },
  {
    eiaid: 59798,
    name: "Volt Electricity Provider LP",
    type: "Bundled",
    state: "TX",
    thenumber: 0.06545314587153886,
  },
  {
    eiaid: 20230,
    name: "Weatherford Mun Utility System",
    type: "Bundled",
    state: "TX",
    thenumber: 0.09229878342506329,
  },
  {
    eiaid: 20472,
    name: "Wharton County Elec Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11389151752040046,
  },
  {
    eiaid: 61439,
    name: "Windrose Power & Gas LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.05310727930584262,
  },
  {
    eiaid: 20948,
    name: "Wise Electric Coop Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.10354100598255012,
  },
  {
    eiaid: 20927,
    name: "Wood County Electric Coop, Inc",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11290718835304822,
  },
  {
    eiaid: 57486,
    name: "XOOM Energy Texas, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.11962632609335928,
  },
  {
    eiaid: 56248,
    name: "Young Energy, LLC",
    type: "Bundled",
    state: "TX",
    thenumber: 0.1585264187658837,
  },
  {
    eiaid: 1434,
    name: "Beaver City Corporation",
    type: "Bundled",
    state: "UT",
    thenumber: 0.08359375,
  },
  {
    eiaid: 2215,
    name: "Bridger Valley Elec Assn, Inc",
    type: "Bundled",
    state: "UT",
    thenumber: 0.16225531403866938,
  },
  {
    eiaid: 2220,
    name: "Brigham City Corporation",
    type: "Bundled",
    state: "UT",
    thenumber: 0.10610522182960345,
  },
  {
    eiaid: 1839,
    name: "City of Blanding - (UT)",
    type: "Bundled",
    state: "UT",
    thenumber: 0.11004707028079858,
  },
  {
    eiaid: 2010,
    name: "City of Bountiful",
    type: "Bundled",
    state: "UT",
    thenumber: 0.10504020581064881,
  },
  {
    eiaid: 5932,
    name: "City of Ephraim - (UT)",
    type: "Bundled",
    state: "UT",
    thenumber: 0.087417171357321,
  },
  {
    eiaid: 11135,
    name: "City of Logan - (UT)",
    type: "Bundled",
    state: "UT",
    thenumber: 0.10343802585572787,
  },
  {
    eiaid: 11588,
    name: "City of Manti - (UT)",
    type: "Bundled",
    state: "UT",
    thenumber: 0.10143078465323976,
  },
  {
    eiaid: 13037,
    name: "City of Mt Pleasant - (UT)",
    type: "Bundled",
    state: "UT",
    thenumber: 0.08664009703690868,
  },
  {
    eiaid: 13137,
    name: "City of Murray - (UT)",
    type: "Bundled",
    state: "UT",
    thenumber: 0.09236168598233996,
  },
  {
    eiaid: 16648,
    name: "City of Santa Clara - (UT)",
    type: "Bundled",
    state: "UT",
    thenumber: 0.09020161737011188,
  },
  {
    eiaid: 17845,
    name: "City of Springville - (UT)",
    type: "Bundled",
    state: "UT",
    thenumber: 0.10682058492034904,
  },
  {
    eiaid: 17874,
    name: "City of St George",
    type: "Bundled",
    state: "UT",
    thenumber: 0.09489352630125783,
  },
  {
    eiaid: 20135,
    name: "City of Washington - (UT)",
    type: "Bundled",
    state: "UT",
    thenumber: 0.09611646751115442,
  },
  {
    eiaid: 40165,
    name: "Dixie Escalante R E A, Inc",
    type: "Bundled",
    state: "UT",
    thenumber: 0.07845275609471437,
  },
  {
    eiaid: 5862,
    name: "Empire Electric Assn, Inc",
    type: "Bundled",
    state: "UT",
    thenumber: 0.15170969993021632,
  },
  {
    eiaid: 6957,
    name: "Garkane Energy Coop, Inc",
    type: "Bundled",
    state: "UT",
    thenumber: 0.10542181884051062,
  },
  {
    eiaid: 8366,
    name: "Heber Light & Power Company",
    type: "Bundled",
    state: "UT",
    thenumber: 0.10194309151499616,
  },
  {
    eiaid: 9128,
    name: "Hurricane City Power",
    type: "Bundled",
    state: "UT",
    thenumber: 0.0936616926468023,
  },
  {
    eiaid: 9163,
    name: "Hyrum City Corporation",
    type: "Bundled",
    state: "UT",
    thenumber: 0.09954620063725017,
  },
  {
    eiaid: 10063,
    name: "Kaysville City Corporation",
    type: "Bundled",
    state: "UT",
    thenumber: 0.09660369710610246,
  },
  {
    eiaid: 10879,
    name: "Lehi City Corporation",
    type: "Bundled",
    state: "UT",
    thenumber: 0.08770851576479803,
  },
  {
    eiaid: 12866,
    name: "Moon Lake Electric Assn Inc",
    type: "Bundled",
    state: "UT",
    thenumber: 0.07803162012387264,
  },
  {
    eiaid: 13314,
    name: "Navajo Tribal Utility Authority",
    type: "Bundled",
    state: "UT",
    thenumber: 0.1208158485410364,
  },
  {
    eiaid: 13388,
    name: "Nephi City Corporation",
    type: "Bundled",
    state: "UT",
    thenumber: 0.08936974620922657,
  },
  {
    eiaid: 14354,
    name: "PacifiCorp",
    type: "Bundled",
    state: "UT",
    thenumber: 0.117,
  },
  {
    eiaid: 14600,
    name: "Payson City Corporation",
    type: "Bundled",
    state: "UT",
    thenumber: 0.12132517534696315,
  },
  {
    eiaid: 14198,
    name: "Price Municipal Corporation",
    type: "Bundled",
    state: "UT",
    thenumber: 0.12043091959129276,
  },
  {
    eiaid: 15444,
    name: "Provo City Corp",
    type: "Bundled",
    state: "UT",
    thenumber: 0.10726231226594407,
  },
  {
    eiaid: 16557,
    name: "Salem City Corporation - (UT)",
    type: "Bundled",
    state: "UT",
    thenumber: 0.11495346432264736,
  },
  {
    eiaid: 17732,
    name: "Spanish Fork City Corporation",
    type: "Bundled",
    state: "UT",
    thenumber: 0.10248453343347894,
  },
  {
    eiaid: 18206,
    name: "Strawberry Electric Serv Dist",
    type: "Bundled",
    state: "UT",
    thenumber: 0.10806847648989146,
  },
  {
    eiaid: 84,
    name: "A & N Electric Coop",
    type: "Bundled",
    state: "VA",
    thenumber: 0.12319407354436461,
  },
  {
    eiaid: 733,
    name: "Appalachian Power Co",
    type: "Bundled",
    state: "VA",
    thenumber: 0.1097272539408851,
  },
  {
    eiaid: 1062,
    name: "BARC Electric Coop Inc",
    type: "Bundled",
    state: "VA",
    thenumber: 0.14327409876601171,
  },
  {
    eiaid: 2248,
    name: "Bristol Virginia Utilities",
    type: "Bundled",
    state: "VA",
    thenumber: 0.10494517220703463,
  },
  {
    eiaid: 3291,
    name: "Central Virginia Electric Coop",
    type: "Bundled",
    state: "VA",
    thenumber: 0.14696430492763743,
  },
  {
    eiaid: 4794,
    name: "City of Danville - (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.1409106195554302,
  },
  {
    eiaid: 6715,
    name: "City of Franklin - (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.12915024905211508,
  },
  {
    eiaid: 8198,
    name: "City of Harrisonburg - (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.10180972601354767,
  },
  {
    eiaid: 11560,
    name: "City of Manassas - (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.10370844940709427,
  },
  {
    eiaid: 11770,
    name: "City of Martinsville - (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.12149710148596309,
  },
  {
    eiaid: 15619,
    name: "City of Radford - (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.123317525151457,
  },
  {
    eiaid: 16558,
    name: "City of Salem - (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.11604977515893937,
  },
  {
    eiaid: 4117,
    name: "Community Electric Coop",
    type: "Bundled",
    state: "VA",
    thenumber: 0.12259006579983762,
  },
  {
    eiaid: 4471,
    name: "Craig-Botetourt Electric Coop",
    type: "Bundled",
    state: "VA",
    thenumber: 0.1517006413192854,
  },
  {
    eiaid: 10171,
    name: "Kentucky Utilities Co",
    type: "Bundled",
    state: "VA",
    thenumber: 0.10599832239198942,
  },
  {
    eiaid: 12260,
    name: "Mecklenburg Electric Cooperative",
    type: "Bundled",
    state: "VA",
    thenumber: 0.1379831718369543,
  },
  {
    eiaid: 13762,
    name: "Northern Neck Elec Coop, Inc",
    type: "Bundled",
    state: "VA",
    thenumber: 0.14309428005848027,
  },
  {
    eiaid: 13640,
    name: "Northern Virginia Elec Coop",
    type: "Bundled",
    state: "VA",
    thenumber: 0.12529191899217554,
  },
  {
    eiaid: 15293,
    name: "Powell Valley Electric Coop",
    type: "Bundled",
    state: "VA",
    thenumber: 0.10625472714354472,
  },
  {
    eiaid: 15410,
    name: "Prince George Electric Coop",
    type: "Bundled",
    state: "VA",
    thenumber: 0.12409161558989999,
  },
  {
    eiaid: 40228,
    name: "Rappahannock Electric Coop",
    type: "Bundled",
    state: "VA",
    thenumber: 0.13195396314013907,
  },
  {
    eiaid: 17066,
    name: "Shenandoah Valley Elec Coop",
    type: "Bundled",
    state: "VA",
    thenumber: 0.11824649513632565,
  },
  {
    eiaid: 21244,
    name: "Southside Electric Coop, Inc",
    type: "Bundled",
    state: "VA",
    thenumber: 0.13865825468418333,
  },
  {
    eiaid: 1456,
    name: "Town of Bedford - (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.1259160441124155,
  },
  {
    eiaid: 1795,
    name: "Town of Blackstone - (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.11149000663067159,
  },
  {
    eiaid: 4619,
    name: "Town of Culpeper- (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.13196816770186334,
  },
  {
    eiaid: 5794,
    name: "Town of Elkton",
    type: "Bundled",
    state: "VA",
    thenumber: 0.10298545986406263,
  },
  {
    eiaid: 6803,
    name: "Town of Front Royal",
    type: "Bundled",
    state: "VA",
    thenumber: 0.1186608776144403,
  },
  {
    eiaid: 15982,
    name: "Town of Richlands - (VA)",
    type: "Bundled",
    state: "VA",
    thenumber: 0.11253681693752382,
  },
  {
    eiaid: 19876,
    name: "Virginia Electric & Power Co",
    type: "Bundled",
    state: "VA",
    thenumber: 0.12056458043354928,
  },
  {
    eiaid: 19882,
    name: "Virginia Tech Electric Service",
    type: "Bundled",
    state: "VA",
    thenumber: 0.11824703062199232,
  },
  {
    eiaid: 1299,
    name: "Barton Village, Inc",
    type: "Bundled",
    state: "VT",
    thenumber: 0.1973759778195861,
  },
  {
    eiaid: 2548,
    name: "City of Burlington Electric - (VT)",
    type: "Bundled",
    state: "VT",
    thenumber: 0.157549202176215,
  },
  {
    eiaid: 7601,
    name: "Green Mountain Power Corp",
    type: "Bundled",
    state: "VT",
    thenumber: 0.17654378690187994,
  },
  {
    eiaid: 8104,
    name: "Town of Hardwick",
    type: "Bundled",
    state: "VT",
    thenumber: 0.18019535197036038,
  },
  {
    eiaid: 27316,
    name: "Town of Stowe- (VT)",
    type: "Bundled",
    state: "VT",
    thenumber: 0.18352035112417706,
  },
  {
    eiaid: 19791,
    name: "Vermont Electric Cooperative, Inc",
    type: "Bundled",
    state: "VT",
    thenumber: 0.19096907290631981,
  },
  {
    eiaid: 5915,
    name: "Village of Enosburg Falls - (VT)",
    type: "Bundled",
    state: "VT",
    thenumber: 0.16064445258147197,
  },
  {
    eiaid: 9144,
    name: "Village of Hyde Park - (VT)",
    type: "Bundled",
    state: "VT",
    thenumber: 0.1764124466137889,
  },
  {
    eiaid: 11305,
    name: "Village of Ludlow - (VT)",
    type: "Bundled",
    state: "VT",
    thenumber: 0.11719416027280477,
  },
  {
    eiaid: 11359,
    name: "Village of Lyndonville - (VT)",
    type: "Bundled",
    state: "VT",
    thenumber: 0.1544962897748711,
  },
  {
    eiaid: 12989,
    name: "Village of Morrisville - (VT)",
    type: "Bundled",
    state: "VT",
    thenumber: 0.157238712082382,
  },
  {
    eiaid: 13789,
    name: "Village of Northfield - (VT)",
    type: "Bundled",
    state: "VT",
    thenumber: 0.1378914302542456,
  },
  {
    eiaid: 18371,
    name: "Village of Swanton - (VT)",
    type: "Bundled",
    state: "VT",
    thenumber: 0.13011494252873562,
  },
  {
    eiaid: 20151,
    name: "Washington Electric Coop - (VT)",
    type: "Bundled",
    state: "VT",
    thenumber: 0.23156977426220443,
  },
  {
    eiaid: 20169,
    name: "Avista Corp",
    type: "Bundled",
    state: "WA",
    thenumber: 0.09862841913655002,
  },
  {
    eiaid: 1625,
    name: "Benton Rural Electric Assn",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08630779823162778,
  },
  {
    eiaid: 1723,
    name: "Big Bend Electric Coop, Inc",
    type: "Bundled",
    state: "WA",
    thenumber: 0.07733815792940514,
  },
  {
    eiaid: 1818,
    name: "City of Blaine - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.07764662486167466,
  },
  {
    eiaid: 3295,
    name: "City of Centralia - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.09872464264315672,
  },
  {
    eiaid: 3420,
    name: "City of Cheney - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.07765559310856483,
  },
  {
    eiaid: 6149,
    name: "City of Ellensburg - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.10384948200850899,
  },
  {
    eiaid: 11965,
    name: "City of McCleary - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08270737638476088,
  },
  {
    eiaid: 15231,
    name: "City of Port Angeles - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.09743020891107414,
  },
  {
    eiaid: 15979,
    name: "City of Richland - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.09566587842991958,
  },
  {
    eiaid: 16868,
    name: "City of Seattle - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.11366913860357168,
  },
  {
    eiaid: 18429,
    name: "City of Tacoma - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.09599100624730288,
  },
  {
    eiaid: 3739,
    name: "Clearwater Power Company",
    type: "Bundled",
    state: "WA",
    thenumber: 0.11862799263351749,
  },
  {
    eiaid: 4041,
    name: "Columbia Rural Elec Assn, Inc",
    type: "Bundled",
    state: "WA",
    thenumber: 0.12719591033658914,
  },
  {
    eiaid: 5832,
    name: "Elmhurst Mutual Power & Light Co",
    type: "Bundled",
    state: "WA",
    thenumber: 0.07208847789774586,
  },
  {
    eiaid: 8699,
    name: "Inland Power & Light Company",
    type: "Bundled",
    state: "WA",
    thenumber: 0.0856207261278103,
  },
  {
    eiaid: 10627,
    name: "Lakeview Light & Power",
    type: "Bundled",
    state: "WA",
    thenumber: 0.09840427997885973,
  },
  {
    eiaid: 12744,
    name: "Modern Electric Water Company",
    type: "Bundled",
    state: "WA",
    thenumber: 0.0667916683650593,
  },
  {
    eiaid: 13387,
    name: "Nespelem Valley Elec Coop, Inc",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08794437530436665,
  },
  {
    eiaid: 14012,
    name: "Ohop Mutual Light Company, Inc",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08638053141795168,
  },
  {
    eiaid: 14074,
    name: "Okanogan County Elec Coop, Inc",
    type: "Bundled",
    state: "WA",
    thenumber: 0.10625659654893105,
  },
  {
    eiaid: 14170,
    name: "Orcas Power & Light Coop",
    type: "Bundled",
    state: "WA",
    thenumber: 0.1529512530686018,
  },
  {
    eiaid: 14354,
    name: "PacifiCorp",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08703906610252155,
  },
  {
    eiaid: 14505,
    name: "Parkland Light & Water Company",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08163486949281396,
  },
  {
    eiaid: 14668,
    name: "Peninsula Light Company",
    type: "Bundled",
    state: "WA",
    thenumber: 0.1016941942404794,
  },
  {
    eiaid: 17470,
    name: "PUD 1 of Snohomish County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.1017769477098848,
  },
  {
    eiaid: 1579,
    name: "PUD No 1 of Benton County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08517345963245784,
  },
  {
    eiaid: 3413,
    name: "PUD No 1 of Chelan County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.031855595585682496,
  },
  {
    eiaid: 3644,
    name: "PUD No 1 of Clallam County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.10400448807854137,
  },
  {
    eiaid: 3660,
    name: "PUD No 1 of Clark County - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.09262493451273991,
  },
  {
    eiaid: 4442,
    name: "PUD No 1 of Cowlitz County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08664289553696355,
  },
  {
    eiaid: 6297,
    name: "PUD No 1 of Ferry County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.1069463287567157,
  },
  {
    eiaid: 6716,
    name: "PUD No 1 of Franklin County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.09440934776161318,
  },
  {
    eiaid: 7548,
    name: "PUD No 1 of Grays Harbor County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.1250238827592723,
  },
  {
    eiaid: 59013,
    name: "PUD No 1 of Jefferson County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.11431539170160365,
  },
  {
    eiaid: 10326,
    name: "PUD No 1 of Kittitas County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.10797003280722085,
  },
  {
    eiaid: 10393,
    name: "PUD No 1 of Klickitat County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.11363043870614109,
  },
  {
    eiaid: 10944,
    name: "PUD No 1 of Lewis County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08922084280849119,
  },
  {
    eiaid: 22355,
    name: "PUD No 1 of Mason County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.12012761825495907,
  },
  {
    eiaid: 14055,
    name: "PUD No 1 of Okanogan County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08162048684583216,
  },
  {
    eiaid: 14653,
    name: "PUD No 1 of Pend Oreille County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.0706816029262876,
  },
  {
    eiaid: 17279,
    name: "PUD No 1 of Skamania Co",
    type: "Bundled",
    state: "WA",
    thenumber: 0.10202397303034087,
  },
  {
    eiaid: 15327,
    name: "PUD No 1 of Wahkiakum County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.09371949235274976,
  },
  {
    eiaid: 14624,
    name: "PUD No 2 of Grant County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.055871758784380145,
  },
  {
    eiaid: 14324,
    name: "PUD No 2 of Pacific County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08493675904353823,
  },
  {
    eiaid: 15419,
    name: "PUD No 3 of Mason County",
    type: "Bundled",
    state: "WA",
    thenumber: 0.10712946071818816,
  },
  {
    eiaid: 15500,
    name: "Puget Sound Energy Inc",
    type: "Bundled",
    state: "WA",
    thenumber: 0.10592131660591032,
  },
  {
    eiaid: 18448,
    name: "Tanner Electric Coop",
    type: "Bundled",
    state: "WA",
    thenumber: 0.13128568424798226,
  },
  {
    eiaid: 5631,
    name: "Town of Eatonville - (WA)",
    type: "Bundled",
    state: "WA",
    thenumber: 0.08954495547151396,
  },
  {
    eiaid: 18051,
    name: "Town of Steilacoom",
    type: "Bundled",
    state: "WA",
    thenumber: 0.09600776361141786,
  },
  {
    eiaid: 19784,
    name: "Vera Irrigation District #15",
    type: "Bundled",
    state: "WA",
    thenumber: 0.07943825042881647,
  },
  {
    eiaid: 108,
    name: "Adams-Columbia Electric Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.16192787029888608,
  },
  {
    eiaid: 307,
    name: "Algoma Utility Comm",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12344028520499109,
  },
  {
    eiaid: 1251,
    name: "Barron Electric Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.1357593106062117,
  },
  {
    eiaid: 1367,
    name: "Bayfield Electric Coop, Inc",
    type: "Bundled",
    state: "WI",
    thenumber: 0.18360087952517137,
  },
  {
    eiaid: 1866,
    name: "Bloomer Electric & Water Co",
    type: "Bundled",
    state: "WI",
    thenumber: 0.08953849695016687,
  },
  {
    eiaid: 2273,
    name: "Brodhead Water & Lighting Comm",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11785742050843505,
  },
  {
    eiaid: 3208,
    name: "Cedarburg Light & Water Comm",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11887976168983572,
  },
  {
    eiaid: 3293,
    name: "Central Wisconsin Elec Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.18787080861275915,
  },
  {
    eiaid: 3498,
    name: "Chippewa Valley Electric Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.1435449984501108,
  },
  {
    eiaid: 765,
    name: "City of Arcadia - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10744035248150305,
  },
  {
    eiaid: 1278,
    name: "City of Barron - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10278736215411677,
  },
  {
    eiaid: 1776,
    name: "City of Black River Falls",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12131812811424461,
  },
  {
    eiaid: 1997,
    name: "City of Boscobel - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10166709194522412,
  },
  {
    eiaid: 3814,
    name: "City of Clintonville - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10972921024365424,
  },
  {
    eiaid: 4073,
    name: "City of Columbus - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11696717040125065,
  },
  {
    eiaid: 5777,
    name: "City of Elkhorn - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11106410673608852,
  },
  {
    eiaid: 6043,
    name: "City of Evansville",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11828724051660017,
  },
  {
    eiaid: 10056,
    name: "City of Kaukauna",
    type: "Bundled",
    state: "WI",
    thenumber: 0.112337145249608,
  },
  {
    eiaid: 10243,
    name: "City of Kiel - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10009871668311944,
  },
  {
    eiaid: 11125,
    name: "City of Lodi - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12234534508332606,
  },
  {
    eiaid: 11740,
    name: "City of Marshfield - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.09340373696496468,
  },
  {
    eiaid: 12265,
    name: "City of Medford - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.08726907630522088,
  },
  {
    eiaid: 12298,
    name: "City of Menasha - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11577656034685577,
  },
  {
    eiaid: 13448,
    name: "City of New Holstein - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12028939078434514,
  },
  {
    eiaid: 13481,
    name: "City of New Richmond",
    type: "Bundled",
    state: "WI",
    thenumber: 0.1058948441348849,
  },
  {
    eiaid: 15159,
    name: "City of Plymouth - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11334035485700397,
  },
  {
    eiaid: 15978,
    name: "City of Richland Center - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11148282097649186,
  },
  {
    eiaid: 16082,
    name: "City of River Falls",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11566075884023058,
  },
  {
    eiaid: 17028,
    name: "City of Sheboygan Falls - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.09440106224905946,
  },
  {
    eiaid: 17811,
    name: "City of Spooner - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11732342007434943,
  },
  {
    eiaid: 18181,
    name: "City of Stoughton - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11274467361390272,
  },
  {
    eiaid: 18249,
    name: "City of Sturgeon Bay - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11064753563775272,
  },
  {
    eiaid: 3701,
    name: "Clark Electric Coop - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.133474433277089,
  },
  {
    eiaid: 4715,
    name: "Dahlberg Light & Power Co",
    type: "Bundled",
    state: "WI",
    thenumber: 0.13144099220048588,
  },
  {
    eiaid: 5417,
    name: "Dunn County Electric Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.1388716563879867,
  },
  {
    eiaid: 5574,
    name: "East Central Energy",
    type: "Bundled",
    state: "WI",
    thenumber: 0.14408064207097693,
  },
  {
    eiaid: 5632,
    name: "Eau Claire Electric Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.14313201339521767,
  },
  {
    eiaid: 8212,
    name: "Hartford Electric",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11134483998561669,
  },
  {
    eiaid: 9124,
    name: "Hustisford Utilities",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12852938703438996,
  },
  {
    eiaid: 8574,
    name: "Jackson Electric Coop, Inc - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.14279770316039747,
  },
  {
    eiaid: 9690,
    name: "Jefferson Utilities",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10866570826786133,
  },
  {
    eiaid: 9922,
    name: "Jump River Electric Coop Inc",
    type: "Bundled",
    state: "WI",
    thenumber: 0.1599831204775912,
  },
  {
    eiaid: 10605,
    name: "Lake Mills Light & Water",
    type: "Bundled",
    state: "WI",
    thenumber: 0.13166013263474152,
  },
  {
    eiaid: 11479,
    name: "Madison Gas & Electric Co",
    type: "Bundled",
    state: "WI",
    thenumber: 0.16556308073210932,
  },
  {
    eiaid: 11571,
    name: "Manitowoc Public Utilities",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10828789211374963,
  },
  {
    eiaid: 13467,
    name: "New London Electric&Water Util",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11298086106123696,
  },
  {
    eiaid: 13697,
    name: "North Central Power Co Inc",
    type: "Bundled",
    state: "WI",
    thenumber: 0.15391051291378682,
  },
  {
    eiaid: 13780,
    name: "Northern States Power Co",
    type: "Bundled",
    state: "WI",
    thenumber: 0.1337231881051954,
  },
  {
    eiaid: 13815,
    name: "Northwestern Wisconsin Elec Co",
    type: "Bundled",
    state: "WI",
    thenumber: 0.13532266973803284,
  },
  {
    eiaid: 13936,
    name: "Oakdale Electric Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.14227223445591494,
  },
  {
    eiaid: 13963,
    name: "Oconomowoc Utilities",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12109081643600472,
  },
  {
    eiaid: 13964,
    name: "Oconto Electric Cooperative",
    type: "Bundled",
    state: "WI",
    thenumber: 0.14346252788659627,
  },
  {
    eiaid: 13965,
    name: "Oconto Falls Water & Light Comm",
    type: "Bundled",
    state: "WI",
    thenumber: 0.13097492456560192,
  },
  {
    eiaid: 15034,
    name: "Pierce-Pepin Coop Services",
    type: "Bundled",
    state: "WI",
    thenumber: 0.14962650887573964,
  },
  {
    eiaid: 15086,
    name: "Pioneer Power and Light Co",
    type: "Bundled",
    state: "WI",
    thenumber: 0.13443560225604573,
  },
  {
    eiaid: 15344,
    name: "Polk-Burnett Electric Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.14740772163395385,
  },
  {
    eiaid: 15356,
    name: "Price Electric Coop Inc",
    type: "Bundled",
    state: "WI",
    thenumber: 0.2100517077883738,
  },
  {
    eiaid: 15804,
    name: "Reedsburg Utility Comm",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11770544600195348,
  },
  {
    eiaid: 15938,
    name: "Rice Lake Utilities",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10113875801983949,
  },
  {
    eiaid: 15983,
    name: "Richland Electric Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.17533988423744784,
  },
  {
    eiaid: 16060,
    name: "Riverland Energy Cooperative",
    type: "Bundled",
    state: "WI",
    thenumber: 0.13880185681189877,
  },
  {
    eiaid: 16196,
    name: "Rock Energy Cooperative",
    type: "Bundled",
    state: "WI",
    thenumber: 0.13016797040810138,
  },
  {
    eiaid: 16740,
    name: "Scenic Rivers Energy Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.13558345751820497,
  },
  {
    eiaid: 17011,
    name: "Shawano Municipal Utilities",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10752412400217998,
  },
  {
    eiaid: 17324,
    name: "Slinger Utilities",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11347796378544585,
  },
  {
    eiaid: 17868,
    name: "St Croix Electric Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12424853110383377,
  },
  {
    eiaid: 18312,
    name: "Sun Prairie Utilities",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10802478187550406,
  },
  {
    eiaid: 18336,
    name: "Superior Water and Light Co",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12242212491775756,
  },
  {
    eiaid: 18383,
    name: "Taylor Electric Coop - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.1453750755125973,
  },
  {
    eiaid: 19324,
    name: "Two Rivers Water & Light",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11692934782608695,
  },
  {
    eiaid: 19813,
    name: "Vernon Electric Coop",
    type: "Bundled",
    state: "WI",
    thenumber: 0.15036374400856442,
  },
  {
    eiaid: 1181,
    name: "Village of Bangor - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11972140762463343,
  },
  {
    eiaid: 1765,
    name: "Village of Black Earth - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12894433378760875,
  },
  {
    eiaid: 7665,
    name: "Village of Gresham - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.1340114431023522,
  },
  {
    eiaid: 13036,
    name: "Village of Mt. Horeb - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10968553013669524,
  },
  {
    eiaid: 14451,
    name: "Village of Pardeeville - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.1301748948417091,
  },
  {
    eiaid: 15312,
    name: "Village of Prairie Du Sac - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11057010365521004,
  },
  {
    eiaid: 16680,
    name: "Village of Sauk City - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12394225983076157,
  },
  {
    eiaid: 19114,
    name: "Village of Trempealeau - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.14410872739526,
  },
  {
    eiaid: 20211,
    name: "Village of Waunakee - (WI)",
    type: "Bundled",
    state: "WI",
    thenumber: 0.11083512114472414,
  },
  {
    eiaid: 20153,
    name: "Washington Island El Coop, Inc",
    type: "Bundled",
    state: "WI",
    thenumber: 0.15242652084757347,
  },
  {
    eiaid: 20182,
    name: "Waterloo Light & Water Comm",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10879249706916765,
  },
  {
    eiaid: 20213,
    name: "Waupun Utilities",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10876564189711108,
  },
  {
    eiaid: 20844,
    name: "Wisconsin Dells Electric Util",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10871335504885994,
  },
  {
    eiaid: 20847,
    name: "Wisconsin Electric Power Co",
    type: "Bundled",
    state: "WI",
    thenumber: 0.15448778754487671,
  },
  {
    eiaid: 20856,
    name: "Wisconsin Power & Light Co",
    type: "Bundled",
    state: "WI",
    thenumber: 0.13659313770500378,
  },
  {
    eiaid: 20860,
    name: "Wisconsin Public Service Corp",
    type: "Bundled",
    state: "WI",
    thenumber: 0.12929280043276736,
  },
  {
    eiaid: 20862,
    name: "Wisconsin Rapids W W & L Comm",
    type: "Bundled",
    state: "WI",
    thenumber: 0.10676820020222447,
  },
  {
    eiaid: 733,
    name: "Appalachian Power Co",
    type: "Bundled",
    state: "WV",
    thenumber: 0.11714292773316862,
  },
  {
    eiaid: 1764,
    name: "Black Diamond Power Co",
    type: "Bundled",
    state: "WV",
    thenumber: 0.12809428091527383,
  },
  {
    eiaid: 13471,
    name: "City of New Martinsville - (WV)",
    type: "Bundled",
    state: "WV",
    thenumber: 0.1402502606882169,
  },
  {
    eiaid: 8192,
    name: "Harrison Rural Elec Assn, Inc",
    type: "Bundled",
    state: "WV",
    thenumber: 0.17775356244761106,
  },
  {
    eiaid: 12796,
    name: "Monongahela Power Co",
    type: "Bundled",
    state: "WV",
    thenumber: 0.10988451804282483,
  },
  {
    eiaid: 14954,
    name: "Philippi Municipal Electric",
    type: "Bundled",
    state: "WV",
    thenumber: 0.08637236084452975,
  },
  {
    eiaid: 15263,
    name: "The Potomac Edison Company",
    type: "Bundled",
    state: "WV",
    thenumber: 0.10054451483460933,
  },
  {
    eiaid: 20521,
    name: "Wheeling Power Co",
    type: "Bundled",
    state: "WV",
    thenumber: 0.12265980907238089,
  },
  {
    eiaid: 1675,
    name: "Big Horn Rural Electric Co",
    type: "Bundled",
    state: "WY",
    thenumber: 0.14660130718954248,
  },
  {
    eiaid: 19545,
    name: "Black Hills Power, Inc. d/b/a",
    type: "Bundled",
    state: "WY",
    thenumber: 0.12681765796462394,
  },
  {
    eiaid: 2215,
    name: "Bridger Valley Elec Assn, Inc",
    type: "Bundled",
    state: "WY",
    thenumber: 0.10825919632147142,
  },
  {
    eiaid: 2998,
    name: "Carbon Power & Light, Inc",
    type: "Bundled",
    state: "WY",
    thenumber: 0.15784226952997624,
  },
  {
    eiaid: 3461,
    name: "Cheyenne Light Fuel & Power d/b/a Black",
    type: "Bundled",
    state: "WY",
    thenumber: 0.1570713835339942,
  },
  {
    eiaid: 3881,
    name: "City of Cody",
    type: "Bundled",
    state: "WY",
    thenumber: 0.12565957678289724,
  },
  {
    eiaid: 7222,
    name: "City of Gillette - (WY)",
    type: "Bundled",
    state: "WY",
    thenumber: 0.11277420656654463,
  },
  {
    eiaid: 15294,
    name: "City of Powell - (WY)",
    type: "Bundled",
    state: "WY",
    thenumber: 0.16086037817630167,
  },
  {
    eiaid: 19032,
    name: "City of Torrington - (WY)",
    type: "Bundled",
    state: "WY",
    thenumber: 0.08703997119193374,
  },
  {
    eiaid: 6950,
    name: "Garland Light & Power Company",
    type: "Bundled",
    state: "WY",
    thenumber: 0.1529236734141833,
  },
  {
    eiaid: 8566,
    name: "High Plains Power Inc",
    type: "Bundled",
    state: "WY",
    thenumber: 0.13878714711519444,
  },
  {
    eiaid: 27058,
    name: "High West Energy, Inc",
    type: "Bundled",
    state: "WY",
    thenumber: 0.1428690256359069,
  },
  {
    eiaid: 11273,
    name: "Lower Valley Energy Inc",
    type: "Bundled",
    state: "WY",
    thenumber: 0.0640599108530617,
  },
  {
    eiaid: 12199,
    name: "Montana-Dakota Utilities Co",
    type: "Bundled",
    state: "WY",
    thenumber: 0.10643221716630344,
  },
  {
    eiaid: 13685,
    name: "Niobrara Electric Assn, Inc",
    type: "Bundled",
    state: "WY",
    thenumber: 0.1525456477635011,
  },
  {
    eiaid: 14354,
    name: "PacifiCorp",
    type: "Bundled",
    state: "WY",
    thenumber: 0.10837064786758506,
  },
  {
    eiaid: 19156,
    name: "Powder River Energy Corp",
    type: "Bundled",
    state: "WY",
    thenumber: 0.10849237545523881,
  },
  {
    eiaid: 7759,
    name: "Town of Guernsey - (WY)",
    type: "Bundled",
    state: "WY",
    thenumber: 0.23929471032745592,
  },
  {
    eiaid: 20512,
    name: "Town of Wheatland - (WY)",
    type: "Bundled",
    state: "WY",
    thenumber: 0.11896813353566009,
  },
  {
    eiaid: 20506,
    name: "Wheatland Rural Elec Assn, Inc",
    type: "Bundled",
    state: "WY",
    thenumber: 0.15268324138425163,
  },
  {
    eiaid: 21079,
    name: "Wyrulec Company",
    type: "Bundled",
    state: "WY",
    thenumber: 0.15616,
  },
];

export const zipCodeToCounty = {
  0: "Out",
  80001: "Crockett",
  80002: "Jefferson",
  80003: "Jefferson",
  80004: "Jefferson",
  80005: "Jefferson",
  80006: "Jefferson",
  80007: "Jefferson",
  80010: "Arapahoe",
  80011: "Arapahoe",
  80012: "Arapahoe",
  80013: "Arapahoe",
  80014: "Arapahoe",
  80015: "Arapahoe",
  80016: "Arapahoe",
  80017: "Arapahoe",
  80018: "Arapahoe",
  80019: "Adams",
  80020: "Broomfield",
  80021: "Jefferson",
  80022: "Adams",
  80023: "Broomfield",
  80024: "Adams",
  80025: "Boulder",
  80026: "Boulder",
  80027: "Boulder",
  80028: "Boulder",
  80030: "Adams",
  80031: "Adams",
  80033: "Jefferson",
  80034: "Jefferson",
  80035: "Jefferson",
  80036: "Jefferson",
  80037: "Jefferson",
  80038: "Jefferson",
  80040: "Jefferson",
  80041: "Jefferson",
  80042: "Jefferson",
  80044: "Jefferson",
  80045: "Adams",
  80046: "Adams",
  80047: "Adams",
  80101: "Elbert",
  80102: "Adams",
  80103: "Arapahoe",
  80104: "Douglas",
  80105: "Arapahoe",
  80106: "El Paso",
  80107: "Elbert",
  80108: "Douglas",
  80109: "Douglas",
  80110: "Arapahoe",
  80111: "Arapahoe",
  80112: "Arapahoe",
  80113: "Arapahoe",
  80116: "Douglas",
  80117: "Elbert",
  80118: "Douglas",
  80120: "Arapahoe",
  80121: "Arapahoe",
  80122: "Arapahoe",
  80123: "Jefferson",
  80124: "Douglas",
  80125: "Douglas",
  80126: "Douglas",
  80127: "Jefferson",
  80128: "Jefferson",
  80129: "Douglas",
  80130: "Douglas",
  80131: "Douglas",
  80132: "El Paso",
  80133: "El Paso",
  80134: "Douglas",
  80135: "Douglas",
  80136: "Adams",
  80137: "Arapahoe",
  80138: "Douglas",
  80150: "Douglas",
  80151: "Douglas",
  80155: "Douglas",
  80160: "Douglas",
  80161: "Douglas",
  80162: "Douglas",
  80163: "Douglas",
  80165: "Douglas",
  80166: "Douglas",
  80201: "Douglas",
  80202: "Denver",
  80203: "Denver",
  80204: "Denver",
  80205: "Denver",
  80206: "Denver",
  80207: "Denver",
  80208: "Denver",
  80209: "Denver",
  80210: "Denver",
  80211: "Denver",
  80212: "Denver",
  80214: "Jefferson",
  80215: "Jefferson",
  80216: "Denver",
  80217: "Denver",
  80218: "Denver",
  80219: "Denver",
  80220: "Denver",
  80221: "Adams",
  80222: "Denver",
  80223: "Denver",
  80224: "Denver",
  80225: "Denver",
  80226: "Jefferson",
  80227: "Jefferson",
  80228: "Jefferson",
  80229: "Adams",
  80230: "Denver",
  80231: "Denver",
  80232: "Jefferson",
  80233: "Adams",
  80234: "Adams",
  80235: "Jefferson",
  80236: "Denver",
  80237: "Denver",
  80238: "Denver",
  80239: "Denver",
  80241: "Adams",
  80243: "Adams",
  80244: "Adams",
  80246: "Denver",
  80247: "Arapahoe",
  80248: "Arapahoe",
  80249: "Denver",
  80250: "Denver",
  80251: "Denver",
  80252: "Denver",
  80256: "Denver",
  80257: "Denver",
  80259: "Denver",
  80260: "Adams",
  80261: "Adams",
  80262: "Adams",
  80263: "Adams",
  80264: "Denver",
  80265: "Denver",
  80266: "Denver",
  80271: "Denver",
  80273: "Denver",
  80274: "Denver",
  80279: "Denver",
  80280: "Denver",
  80281: "Denver",
  80290: "Denver",
  80291: "Denver",
  80293: "Denver",
  80294: "Denver",
  80295: "Denver",
  80299: "Denver",
  80301: "Boulder",
  80302: "Boulder",
  80303: "Boulder",
  80304: "Boulder",
  80305: "Boulder",
  80306: "Boulder",
  80307: "Boulder",
  80308: "Boulder",
  80309: "Boulder",
  80310: "Boulder",
  80314: "Boulder",
  80321: "Boulder",
  80322: "Boulder",
  80323: "Boulder",
  80328: "Boulder",
  80329: "Boulder",
  80401: "Jefferson",
  80402: "Jefferson",
  80403: "Jefferson",
  80419: "Jefferson",
  80420: "Park",
  80421: "Park",
  80422: "Gilpin",
  80423: "Eagle",
  80424: "Summit",
  80425: "Jefferson",
  80426: "Eagle",
  80427: "Gilpin",
  80428: "Routt",
  80429: "Routt",
  80430: "Routt",
  80432: "Park",
  80433: "Jefferson",
  80434: "Jackson",
  80435: "Summit",
  80436: "Clear Creek",
  80437: "Clear Creek",
  80438: "Clear Creek",
  80439: "Jefferson",
  80440: "Park",
  80442: "Grand",
  80443: "Summit",
  80444: "Clear Creek",
  80446: "Grand",
  80447: "Grand",
  80448: "Park",
  80449: "Park",
  80451: "Grand",
  80452: "Clear Creek",
  80453: "Jefferson",
  80454: "Jefferson",
  80455: "Boulder",
  80456: "Park",
  80457: "Jefferson",
  80459: "Grand",
  80461: "Lake",
  80463: "Routt",
  80465: "Jefferson",
  80466: "Boulder",
  80467: "Routt",
  80468: "Grand",
  80469: "Routt",
  80470: "Jefferson",
  80471: "Boulder",
  80473: "Jackson",
  80474: "Jackson",
  80475: "Park",
  80476: "Clear Creek",
  80477: "Routt",
  80478: "Grand",
  80479: "Routt",
  80480: "Jackson",
  80481: "Boulder",
  80482: "Grand",
  80483: "Routt",
  80487: "Routt",
  80488: "Routt",
  80497: "Summit",
  80498: "Summit",
  80501: "Boulder",
  80502: "Boulder",
  80503: "Boulder",
  80504: "Boulder",
  80510: "Boulder",
  80511: "Larimer",
  80512: "Larimer",
  80513: "Larimer",
  80514: "Weld",
  80515: "Larimer",
  80516: "Weld",
  80517: "Larimer",
  80520: "Weld",
  80521: "Larimer",
  80522: "Larimer",
  80523: "Larimer",
  80524: "Larimer",
  80525: "Larimer",
  80526: "Larimer",
  80527: "Larimer",
  80528: "Larimer",
  80530: "Weld",
  80532: "Larimer",
  80533: "Larimer",
  80534: "Weld",
  80535: "Larimer",
  80536: "Larimer",
  80537: "Larimer",
  80538: "Larimer",
  80539: "Larimer",
  80540: "Boulder",
  80541: "Boulder",
  80542: "Weld",
  80543: "Weld",
  80544: "Boulder",
  80545: "Larimer",
  80546: "Weld",
  80547: "Larimer",
  80549: "Larimer",
  80550: "Weld",
  80551: "Weld",
  80553: "Weld",
  80601: "Adams",
  80602: "Adams",
  80603: "Weld",
  80610: "Weld",
  80611: "Weld",
  80612: "Weld",
  80614: "Weld",
  80615: "Weld",
  80620: "Weld",
  80621: "Weld",
  80622: "Weld",
  80623: "Weld",
  80624: "Weld",
  80631: "Weld",
  80632: "Weld",
  80633: "Weld",
  80634: "Weld",
  80638: "Weld",
  80639: "Weld",
  80640: "Adams",
  80642: "Weld",
  80643: "Weld",
  80644: "Weld",
  80645: "Weld",
  80646: "Weld",
  80648: "Weld",
  80649: "Morgan",
  80650: "Weld",
  80651: "Weld",
  80652: "Weld",
  80653: "Morgan",
  80654: "Morgan",
  80701: "Morgan",
  80705: "Morgan",
  80720: "Washington",
  80721: "Phillips",
  80722: "Logan",
  80723: "Morgan",
  80726: "Logan",
  80727: "Yuma",
  80728: "Logan",
  80729: "Weld",
  80731: "Logan",
  80732: "Logan",
  80733: "Morgan",
  80734: "Phillips",
  80735: "Yuma",
  80736: "Logan",
  80737: "Sedgwick",
  80740: "Washington",
  80741: "Logan",
  80742: "Weld",
  80743: "Washington",
  80744: "Sedgwick",
  80745: "Logan",
  80746: "Phillips",
  80747: "Logan",
  80749: "Sedgwick",
  80750: "Morgan",
  80751: "Logan",
  80754: "Weld",
  80755: "Yuma",
  80757: "Washington",
  80758: "Yuma",
  80759: "Yuma",
  80801: "Washington",
  80802: "Cheyenne",
  80804: "Lincoln",
  80805: "Kit Carson",
  80807: "Kit Carson",
  80808: "El Paso",
  80809: "El Paso",
  80810: "Cheyenne",
  80812: "Washington",
  80813: "Teller",
  80814: "Teller",
  80815: "Kit Carson",
  80816: "Teller",
  80817: "El Paso",
  80818: "Lincoln",
  80819: "El Paso",
  80820: "Park",
  80821: "Lincoln",
  80822: "Yuma",
  80823: "Lincoln",
  80824: "Yuma",
  80825: "Cheyenne",
  80826: "Cheyenne",
  80827: "Park",
  80828: "Lincoln",
  80829: "El Paso",
  80830: "Elbert",
  80831: "El Paso",
  80832: "El Paso",
  80833: "El Paso",
  80834: "Kit Carson",
  80835: "Elbert",
  80836: "Kit Carson",
  80840: "El Paso",
  80841: "El Paso",
  80860: "Teller",
  80861: "Kit Carson",
  80862: "Cheyenne",
  80863: "Teller",
  80864: "El Paso",
  80866: "El Paso",
  80901: "El Paso",
  80902: "El Paso",
  80903: "El Paso",
  80904: "El Paso",
  80905: "El Paso",
  80906: "El Paso",
  80907: "El Paso",
  80908: "El Paso",
  80909: "El Paso",
  80910: "El Paso",
  80911: "El Paso",
  80912: "El Paso",
  80913: "El Paso",
  80914: "El Paso",
  80915: "El Paso",
  80916: "El Paso",
  80917: "El Paso",
  80918: "El Paso",
  80919: "El Paso",
  80920: "El Paso",
  80921: "El Paso",
  80922: "El Paso",
  80923: "El Paso",
  80924: "El Paso",
  80925: "El Paso",
  80926: "El Paso",
  80927: "El Paso",
  80928: "El Paso",
  80929: "El Paso",
  80930: "El Paso",
  80931: "El Paso",
  80932: "El Paso",
  80933: "El Paso",
  80934: "El Paso",
  80935: "El Paso",
  80936: "El Paso",
  80937: "El Paso",
  80938: "El Paso",
  80939: "El Paso",
  80940: "El Paso",
  80941: "El Paso",
  80942: "El Paso",
  80943: "El Paso",
  80944: "El Paso",
  80945: "El Paso",
  80946: "El Paso",
  80947: "El Paso",
  80949: "El Paso",
  80950: "El Paso",
  80951: "El Paso",
  80960: "El Paso",
  80962: "El Paso",
  80970: "El Paso",
  80977: "El Paso",
  80995: "El Paso",
  80997: "El Paso",
  81001: "Pueblo",
  81002: "Pueblo",
  81003: "Pueblo",
  81004: "Pueblo",
  81005: "Pueblo",
  81006: "Pueblo",
  81007: "Pueblo",
  81008: "Pueblo",
  81009: "Pueblo",
  81010: "Pueblo",
  81011: "Pueblo",
  81012: "Pueblo",
  81019: "Pueblo",
  81020: "Las Animas",
  81021: "Kiowa",
  81022: "Pueblo",
  81023: "Pueblo",
  81024: "Las Animas",
  81025: "Pueblo",
  81027: "Las Animas",
  81029: "Baca",
  81030: "Otero",
  81033: "Crowley",
  81034: "Crowley",
  81036: "Kiowa",
  81038: "Bent",
  81039: "Otero",
  81040: "Huerfano",
  81041: "Prowers",
  81043: "Prowers",
  81044: "Bent",
  81045: "Kiowa",
  81046: "Kiowa",
  81047: "Prowers",
  81049: "Las Animas",
  81050: "Otero",
  81052: "Prowers",
  81054: "Bent",
  81055: "Huerfano",
  81057: "Bent",
  81058: "Otero",
  81059: "Las Animas",
  81062: "Crowley",
  81063: "Crowley",
  81064: "Baca",
  81067: "Otero",
  81069: "Pueblo",
  81071: "Kiowa",
  81073: "Baca",
  81075: "Baca",
  81076: "Crowley",
  81077: "Otero",
  81081: "Las Animas",
  81082: "Las Animas",
  81084: "Baca",
  81087: "Baca",
  81089: "Huerfano",
  81090: "Baca",
  81091: "Las Animas",
  81092: "Prowers",
  81101: "Alamosa",
  81102: "Alamosa",
  81120: "Conejos",
  81121: "Archuleta",
  81122: "La Plata",
  81123: "Costilla",
  81124: "Conejos",
  81125: "Saguache",
  81126: "Costilla",
  81127: "Costilla",
  81128: "Archuleta",
  81129: "Conejos",
  81130: "Mineral",
  81131: "Saguache",
  81132: "Rio Grande",
  81133: "Costilla",
  81134: "Costilla",
  81135: "Costilla",
  81136: "Alamosa",
  81137: "La Plata",
  81138: "Costilla",
  81140: "Conejos",
  81141: "Conejos",
  81143: "Saguache",
  81144: "Rio Grande",
  81146: "Alamosa",
  81147: "Archuleta",
  81148: "Conejos",
  81149: "Saguache",
  81151: "Conejos",
  81152: "Costilla",
  81153: "Costilla",
  81154: "Rio Grande",
  81155: "Saguache",
  81157: "Saguache",
  81201: "Chaffee",
  81210: "Gunnison",
  81211: "Chaffee",
  81212: "Fremont",
  81215: "Fremont",
  81220: "Gunnison",
  81221: "Fremont",
  81222: "Fremont",
  81223: "Fremont",
  81224: "Gunnison",
  81225: "Gunnison",
  81226: "Fremont",
  81227: "Chaffee",
  81228: "Chaffee",
  81230: "Gunnison",
  81231: "Gunnison",
  81232: "Fremont",
  81233: "Fremont",
  81235: "Hinsdale",
  81236: "Chaffee",
  81237: "Gunnison",
  81239: "Gunnison",
  81240: "Fremont",
  81241: "Gunnison",
  81242: "Chaffee",
  81243: "Gunnison",
  81244: "Fremont",
  81247: "Fremont",
  81248: "Saguache",
  81251: "Lake",
  81252: "Custer",
  81253: "Custer",
  81290: "Custer",
  81301: "La Plata",
  81302: "La Plata",
  81303: "La Plata",
  81320: "Dolores",
  81321: "Montezuma",
  81323: "Montezuma",
  81324: "Dolores",
  81325: "San Miguel",
  81326: "La Plata",
  81327: "Montezuma",
  81328: "Montezuma",
  81329: "Montezuma",
  81330: "Montezuma",
  81331: "Montezuma",
  81332: "Dolores",
  81334: "Montezuma",
  81335: "Montezuma",
  81401: "Montrose",
  81402: "Montrose",
  81403: "Montrose",
  81410: "Delta",
  81411: "Montrose",
  81413: "Delta",
  81414: "Delta",
  81415: "Delta",
  81416: "Delta",
  81418: "Delta",
  81419: "Delta",
  81420: "Delta",
  81422: "Montrose",
  81423: "San Miguel",
  81424: "Montrose",
  81425: "Montrose",
  81426: "San Miguel",
  81427: "Ouray",
  81428: "Delta",
  81429: "Montrose",
  81430: "San Miguel",
  81431: "Montrose",
  81432: "Ouray",
  81433: "San Juan",
  81434: "Gunnison",
  81435: "San Miguel",
  81501: "Mesa",
  81502: "Mesa",
  81503: "Mesa",
  81504: "Mesa",
  81505: "Mesa",
  81506: "Mesa",
  81507: "Mesa",
  81520: "Mesa",
  81521: "Mesa",
  81522: "Mesa",
  81523: "Mesa",
  81524: "Mesa",
  81525: "Mesa",
  81526: "Mesa",
  81527: "Mesa",
  81601: "Garfield",
  81602: "Garfield",
  81610: "Moffat",
  81611: "Pitkin",
  81612: "Pitkin",
  81615: "Pitkin",
  81620: "Eagle",
  81621: "Eagle",
  81623: "Garfield",
  81624: "Mesa",
  81625: "Moffat",
  81626: "Moffat",
  81630: "Mesa",
  81631: "Eagle",
  81632: "Eagle",
  81633: "Moffat",
  81635: "Garfield",
  81636: "Garfield",
  81637: "Eagle",
  81638: "Moffat",
  81639: "Routt",
  81640: "Moffat",
  81641: "Rio Blanco",
  81642: "Pitkin",
  81643: "Mesa",
  81645: "Eagle",
  81646: "Mesa",
  81647: "Garfield",
  81648: "Rio Blanco",
  81649: "Eagle",
  81650: "Garfield",
  81652: "Garfield",
  81653: "Moffat",
  81654: "Pitkin",
  81655: "Eagle",
  81656: "Pitkin",
  81657: "Eagle",
  81658: "Eagle",
  83201: "Bannock",
  83202: "Bannock",
  83203: "Bingham",
  83204: "Bannock",
  83205: "Bannock",
  83206: "Bannock",
  83209: "Bannock",
  83210: "Bingham",
  83211: "Power",
  83212: "Power",
  83213: "Butte",
  83214: "Bannock",
  83215: "Bingham",
  83217: "Caribou",
  83218: "Bingham",
  83220: "Bear Lake",
  83221: "Bingham",
  83223: "Bear Lake",
  83226: "Custer",
  83227: "Custer",
  83228: "Franklin",
  83229: "Franklin",
  83230: "Franklin",
  83232: "Franklin",
  83233: "Bear Lake",
  83234: "Bannock",
  83235: "Custer",
  83236: "Bingham",
  83237: "Franklin",
  83238: "Bear Lake",
  83239: "Bear Lake",
  83241: "Caribou",
  83243: "Oneida",
  83244: "Butte",
  83245: "Bannock",
  83246: "Bannock",
  83250: "Bannock",
  83251: "Custer",
  83252: "Oneida",
  83253: "Lemhi",
  83254: "Bear Lake",
  83255: "Butte",
  83256: "Butte",
  83261: "Bear Lake",
  83262: "Bingham",
  83263: "Franklin",
  83271: "Power",
  83272: "Bear Lake",
  83274: "Bingham",
  83276: "Caribou",
  83277: "Bingham",
  83278: "Custer",
  83281: "Bannock",
  83283: "Franklin",
  83285: "Bonneville",
  83286: "Franklin",
  83287: "Bear Lake",
  83301: "Twin Falls",
  83302: "Twin Falls",
  83303: "Twin Falls",
  83311: "Cassia",
  83312: "Cassia",
  83313: "Blaine",
  83314: "Gooding",
  83316: "Twin Falls",
  83318: "Cassia",
  83320: "Blaine",
  83321: "Twin Falls",
  83322: "Camas",
  83323: "Cassia",
  83324: "Lincoln",
  83325: "Jerome",
  83327: "Camas",
  83328: "Twin Falls",
  83330: "Gooding",
  83332: "Gooding",
  83333: "Blaine",
  83334: "Twin Falls",
  83335: "Jerome",
  83336: "Minidoka",
  83337: "Camas",
  83338: "Jerome",
  83340: "Blaine",
  83341: "Twin Falls",
  83342: "Cassia",
  83343: "Cassia",
  83344: "Twin Falls",
  83346: "Cassia",
  83347: "Minidoka",
  83348: "Blaine",
  83349: "Lincoln",
  83350: "Minidoka",
  83352: "Lincoln",
  83353: "Blaine",
  83354: "Blaine",
  83355: "Gooding",
  83401: "Bonneville",
  83402: "Bonneville",
  83403: "Bonneville",
  83404: "Bonneville",
  83405: "Bonneville",
  83406: "Bonneville",
  83415: "Teton",
  83420: "Fremont",
  83421: "Fremont",
  83422: "Teton",
  83423: "Clark",
  83424: "Teton",
  83425: "Jefferson",
  83427: "Bonneville",
  83428: "Bonneville",
  83429: "Fremont",
  83431: "Jefferson",
  83433: "Fremont",
  83434: "Jefferson",
  83435: "Jefferson",
  83436: "Fremont",
  83438: "Fremont",
  83440: "Madison",
  83441: "Madison",
  83442: "Jefferson",
  83443: "Jefferson",
  83444: "Jefferson",
  83445: "Fremont",
  83446: "Clark",
  83448: "Madison",
  83449: "Bonneville",
  83450: "Jefferson",
  83451: "Fremont",
  83452: "Teton",
  83454: "Bonneville",
  83455: "Teton",
  83460: "Madison",
  83462: "Lemhi",
  83463: "Lemhi",
  83464: "Lemhi",
  83465: "Lemhi",
  83466: "Lemhi",
  83467: "Lemhi",
  83468: "Lemhi",
  83469: "Lemhi",
  83501: "Nez Perce",
  83520: "Clearwater",
  83522: "Idaho",
  83523: "Lewis",
  83524: "Nez Perce",
  83525: "Idaho",
  83526: "Idaho",
  83530: "Idaho",
  83531: "Idaho",
  83533: "Idaho",
  83535: "Latah",
  83536: "Idaho",
  83537: "Clearwater",
  83539: "Idaho",
  83540: "Nez Perce",
  83541: "Clearwater",
  83542: "Idaho",
  83543: "Lewis",
  83544: "Clearwater",
  83545: "Nez Perce",
  83546: "Clearwater",
  83547: "Idaho",
  83548: "Lewis",
  83549: "Idaho",
  83552: "Idaho",
  83553: "Clearwater",
  83554: "Idaho",
  83555: "Lewis",
  83601: "Lewis",
  83602: "Boise",
  83604: "Owyhee",
  83605: "Canyon",
  83606: "Canyon",
  83607: "Canyon",
  83610: "Washington",
  83611: "Valley",
  83612: "Adams",
  83615: "Valley",
  83616: "Ada",
  83617: "Gem",
  83619: "Payette",
  83622: "Boise",
  83623: "Elmore",
  83624: "Owyhee",
  83626: "Canyon",
  83627: "Elmore",
  83628: "Owyhee",
  83629: "Boise",
  83630: "Boise",
  83631: "Boise",
  83632: "Adams",
  83633: "Elmore",
  83634: "Ada",
  83635: "Ada",
  83636: "Gem",
  83637: "Boise",
  83638: "Valley",
  83639: "Owyhee",
  83641: "Canyon",
  83642: "Ada",
  83643: "Adams",
  83644: "Canyon",
  83645: "Washington",
  83646: "Ada",
  83647: "Elmore",
  83648: "Elmore",
  83650: "Owyhee",
  83651: "Canyon",
  83652: "Canyon",
  83653: "Canyon",
  83654: "Adams",
  83655: "Payette",
  83656: "Canyon",
  83657: "Gem",
  83660: "Canyon",
  83661: "Payette",
  83666: "Boise",
  83669: "Ada",
  83670: "Gem",
  83671: "Idaho",
  83672: "Washington",
  83676: "Canyon",
  83677: "Valley",
  83680: "Valley",
  83686: "Canyon",
  83687: "Canyon",
  83701: "Canyon",
  83702: "Ada",
  83703: "Ada",
  83704: "Ada",
  83705: "Ada",
  83706: "Ada",
  83707: "Ada",
  83708: "Ada",
  83709: "Ada",
  83711: "Ada",
  83712: "Ada",
  83713: "Ada",
  83714: "Ada",
  83715: "Ada",
  83716: "Ada",
  83717: "Ada",
  83719: "Ada",
  83720: "Ada",
  83721: "Ada",
  83722: "Ada",
  83724: "Ada",
  83725: "Ada",
  83726: "Ada",
  83727: "Ada",
  83728: "Ada",
  83729: "Ada",
  83730: "Ada",
  83731: "Ada",
  83732: "Ada",
  83733: "Ada",
  83735: "Ada",
  83756: "Ada",
  83757: "Ada",
  83799: "Ada",
  83801: "Kootenai",
  83802: "Shoshone",
  83803: "Kootenai",
  83804: "Bonner",
  83805: "Boundary",
  83806: "Latah",
  83808: "Shoshone",
  83809: "Bonner",
  83810: "Kootenai",
  83811: "Bonner",
  83812: "Shoshone",
  83813: "Bonner",
  83814: "Kootenai",
  83815: "Kootenai",
  83816: "Kootenai",
  83821: "Bonner",
  83822: "Bonner",
  83823: "Latah",
  83824: "Benewah",
  83825: "Bonner",
  83826: "Boundary",
  83827: "Clearwater",
  83830: "Benewah",
  83832: "Latah",
  83833: "Kootenai",
  83834: "Latah",
  83835: "Kootenai",
  83836: "Bonner",
  83837: "Shoshone",
  83839: "Shoshone",
  83840: "Bonner",
  83841: "Bonner",
  83842: "Kootenai",
  83843: "Latah",
  83844: "Latah",
  83845: "Boundary",
  83846: "Shoshone",
  83847: "Boundary",
  83848: "Bonner",
  83849: "Shoshone",
  83850: "Shoshone",
  83851: "Benewah",
  83852: "Bonner",
  83853: "Bonner",
  83854: "Kootenai",
  83855: "Latah",
  83856: "Bonner",
  83857: "Latah",
  83858: "Kootenai",
  83860: "Bonner",
  83861: "Benewah",
  83864: "Bonner",
  83865: "Bonner",
  83866: "Benewah",
  83867: "Shoshone",
  83868: "Shoshone",
  83869: "Kootenai",
  83870: "Benewah",
  83871: "Latah",
  83872: "Latah",
  83873: "Shoshone",
  83874: "Shoshone",
  83876: "Kootenai",
  83877: "Kootenai",
  84001: "Duchesne",
  84002: "Duchesne",
  84003: "Utah",
  84004: "Utah",
  84005: "Utah",
  84006: "Salt Lake",
  84007: "Duchesne",
  84008: "Duchesne",
  84010: "Davis",
  84011: "Davis",
  84013: "Utah",
  84014: "Davis",
  84015: "Davis",
  84016: "Davis",
  84017: "Summit",
  84018: "Morgan",
  84020: "Salt Lake",
  84021: "Duchesne",
  84022: "Tooele",
  84023: "Daggett",
  84024: "Summit",
  84025: "Davis",
  84026: "Uintah",
  84027: "Duchesne",
  84028: "Rich",
  84029: "Tooele",
  84031: "Duchesne",
  84032: "Wasatch",
  84033: "Summit",
  84034: "Tooele",
  84035: "Uintah",
  84036: "Summit",
  84037: "Davis",
  84038: "Rich",
  84039: "Uintah",
  84040: "Davis",
  84041: "Davis",
  84042: "Utah",
  84043: "Utah",
  84044: "Salt Lake",
  84045: "Utah",
  84046: "Daggett",
  84047: "Salt Lake",
  84049: "Wasatch",
  84050: "Morgan",
  84051: "Duchesne",
  84052: "Duchesne",
  84053: "Duchesne",
  84054: "Davis",
  84055: "Summit",
  84056: "Davis",
  84057: "Utah",
  84058: "Utah",
  84059: "Utah",
  84060: "Summit",
  84061: "Summit",
  84062: "Utah",
  84063: "Uintah",
  84064: "Rich",
  84065: "Salt Lake",
  84066: "Duchesne",
  84067: "Weber",
  84068: "Weber",
  84069: "Tooele",
  84070: "Salt Lake",
  84071: "Tooele",
  84072: "Duchesne",
  84073: "Duchesne",
  84074: "Tooele",
  84075: "Davis",
  84076: "Uintah",
  84078: "Uintah",
  84079: "Uintah",
  84080: "Tooele",
  84081: "Salt Lake",
  84082: "Wasatch",
  84083: "Tooele",
  84084: "Salt Lake",
  84085: "Uintah",
  84086: "Rich",
  84087: "Davis",
  84088: "Salt Lake",
  84089: "Salt Lake",
  84090: "Salt Lake",
  84091: "Salt Lake",
  84092: "Salt Lake",
  84093: "Salt Lake",
  84094: "Salt Lake",
  84095: "Salt Lake",
  84096: "Salt Lake",
  84097: "Utah",
  84098: "Summit",
  84101: "Salt Lake",
  84102: "Salt Lake",
  84103: "Salt Lake",
  84104: "Salt Lake",
  84105: "Salt Lake",
  84106: "Salt Lake",
  84107: "Salt Lake",
  84108: "Salt Lake",
  84109: "Salt Lake",
  84110: "Salt Lake",
  84111: "Salt Lake",
  84112: "Salt Lake",
  84113: "Salt Lake",
  84114: "Salt Lake",
  84115: "Salt Lake",
  84116: "Salt Lake",
  84117: "Salt Lake",
  84118: "Salt Lake",
  84119: "Salt Lake",
  84120: "Salt Lake",
  84121: "Salt Lake",
  84122: "Salt Lake",
  84123: "Salt Lake",
  84124: "Salt Lake",
  84125: "Salt Lake",
  84126: "Salt Lake",
  84127: "Salt Lake",
  84128: "Salt Lake",
  84129: "Salt Lake",
  84130: "Salt Lake",
  84131: "Salt Lake",
  84132: "Salt Lake",
  84133: "Salt Lake",
  84134: "Salt Lake",
  84136: "Salt Lake",
  84138: "Salt Lake",
  84139: "Salt Lake",
  84141: "Salt Lake",
  84143: "Salt Lake",
  84144: "Salt Lake",
  84145: "Salt Lake",
  84147: "Salt Lake",
  84148: "Salt Lake",
  84150: "Salt Lake",
  84151: "Salt Lake",
  84152: "Salt Lake",
  84157: "Salt Lake",
  84158: "Salt Lake",
  84165: "Salt Lake",
  84170: "Salt Lake",
  84171: "Salt Lake",
  84180: "Salt Lake",
  84184: "Salt Lake",
  84189: "Salt Lake",
  84190: "Salt Lake",
  84199: "Salt Lake",
  84201: "Salt Lake",
  84244: "Salt Lake",
  84301: "Box Elder",
  84302: "Box Elder",
  84304: "Cache",
  84305: "Cache",
  84306: "Box Elder",
  84307: "Box Elder",
  84308: "Cache",
  84309: "Box Elder",
  84310: "Weber",
  84311: "Box Elder",
  84312: "Box Elder",
  84313: "Box Elder",
  84314: "Box Elder",
  84315: "Weber",
  84316: "Box Elder",
  84317: "Weber",
  84318: "Cache",
  84319: "Cache",
  84320: "Cache",
  84321: "Cache",
  84322: "Cache",
  84323: "Cache",
  84324: "Box Elder",
  84325: "Cache",
  84326: "Cache",
  84327: "Cache",
  84328: "Cache",
  84329: "Box Elder",
  84330: "Box Elder",
  84331: "Box Elder",
  84332: "Cache",
  84333: "Cache",
  84334: "Box Elder",
  84335: "Cache",
  84336: "Box Elder",
  84337: "Box Elder",
  84338: "Cache",
  84339: "Cache",
  84340: "Box Elder",
  84341: "Cache",
  84401: "Weber",
  84402: "Weber",
  84403: "Weber",
  84404: "Weber",
  84405: "Weber",
  84407: "Weber",
  84408: "Weber",
  84409: "Weber",
  84412: "Weber",
  84414: "Weber",
  84415: "Weber",
  84501: "Carbon",
  84510: "Carbon",
  84511: "San Juan",
  84512: "San Juan",
  84513: "Emery",
  84515: "Grand",
  84516: "Emery",
  84518: "Emery",
  84520: "Carbon",
  84521: "Emery",
  84522: "Emery",
  84523: "Emery",
  84525: "Emery",
  84526: "Carbon",
  84528: "Emery",
  84529: "Carbon",
  84530: "San Juan",
  84531: "San Juan",
  84532: "Grand",
  84533: "Kane",
  84534: "San Juan",
  84535: "San Juan",
  84536: "San Juan",
  84537: "Emery",
  84539: "Carbon",
  84540: "Grand",
  84542: "Carbon",
  84601: "Utah",
  84602: "Utah",
  84603: "Utah",
  84604: "Utah",
  84605: "Utah",
  84606: "Utah",
  84620: "Sevier",
  84621: "Sanpete",
  84622: "Sanpete",
  84623: "Sanpete",
  84624: "Millard",
  84626: "Utah",
  84627: "Sanpete",
  84628: "Juab",
  84629: "Sanpete",
  84630: "Sanpete",
  84631: "Millard",
  84632: "Sanpete",
  84633: "Utah",
  84634: "Sanpete",
  84635: "Millard",
  84636: "Millard",
  84637: "Millard",
  84638: "Millard",
  84639: "Juab",
  84640: "Millard",
  84642: "Sanpete",
  84643: "Sanpete",
  84644: "Sanpete",
  84645: "Juab",
  84646: "Sanpete",
  84647: "Sanpete",
  84648: "Juab",
  84649: "Millard",
  84651: "Utah",
  84652: "Sevier",
  84653: "Utah",
  84654: "Sevier",
  84655: "Utah",
  84656: "Millard",
  84657: "Sevier",
  84660: "Utah",
  84662: "Sanpete",
  84663: "Utah",
  84664: "Utah",
  84665: "Sanpete",
  84667: "Sanpete",
  84701: "Sevier",
  84710: "Kane",
  84711: "Sevier",
  84712: "Garfield",
  84713: "Beaver",
  84714: "Iron",
  84715: "Wayne",
  84716: "Garfield",
  84717: "Garfield",
  84718: "Garfield",
  84719: "Iron",
  84720: "Iron",
  84721: "Iron",
  84722: "Washington",
  84723: "Piute",
  84724: "Sevier",
  84725: "Washington",
  84726: "Garfield",
  84728: "Millard",
  84729: "Kane",
  84730: "Sevier",
  84731: "Beaver",
  84732: "Piute",
  84733: "Washington",
  84734: "Wayne",
  84735: "Garfield",
  84736: "Garfield",
  84737: "Washington",
  84738: "Washington",
  84739: "Sevier",
  84740: "Piute",
  84741: "Kane",
  84742: "Iron",
  84743: "Piute",
  84744: "Sevier",
  84745: "Washington",
  84746: "Washington",
  84747: "Wayne",
  84749: "Wayne",
  84750: "Piute",
  84751: "Beaver",
  84752: "Beaver",
  84753: "Iron",
  84754: "Sevier",
  84755: "Kane",
  84756: "Iron",
  84757: "Washington",
  84758: "Kane",
  84759: "Garfield",
  84760: "Iron",
  84761: "Iron",
  84762: "Kane",
  84763: "Washington",
  84764: "Garfield",
  84765: "Washington",
  84766: "Sevier",
  84767: "Washington",
  84770: "Washington",
  84771: "Washington",
  84772: "Iron",
  84773: "Wayne",
  84774: "Washington",
  84775: "Wayne",
  84776: "Garfield",
  84779: "Washington",
  84780: "Washington",
  84781: "Washington",
  84782: "Washington",
  84783: "Washington",
  84784: "Washington",
  84790: "Washington",
  84791: "Washington",
  88901: "Union",
  88905: "Union",
  89001: "Lincoln",
  89002: "Clark",
  89003: "Nye",
  89004: "Clark",
  89005: "Clark",
  89006: "Clark",
  89007: "Clark",
  89008: "Lincoln",
  89009: "Lincoln",
  89010: "Esmeralda",
  89011: "Clark",
  89012: "Clark",
  89013: "Esmeralda",
  89014: "Clark",
  89015: "Clark",
  89016: "Clark",
  89017: "Lincoln",
  89018: "Clark",
  89019: "Clark",
  89020: "Nye",
  89021: "Clark",
  89022: "Nye",
  89023: "Nye",
  89024: "Nye",
  89025: "Clark",
  89026: "Clark",
  89027: "Clark",
  89028: "Clark",
  89029: "Clark",
  89030: "Clark",
  89031: "Clark",
  89032: "Clark",
  89033: "Clark",
  89034: "Clark",
  89036: "Clark",
  89037: "Clark",
  89039: "Clark",
  89040: "Clark",
  89041: "Clark",
  89042: "Lincoln",
  89043: "Lincoln",
  89044: "Clark",
  89045: "Nye",
  89046: "Clark",
  89047: "Esmeralda",
  89048: "Nye",
  89049: "Nye",
  89052: "Clark",
  89053: "Clark",
  89054: "Clark",
  89060: "Nye",
  89061: "Nye",
  89067: "Nye",
  89070: "Nye",
  89074: "Clark",
  89077: "Clark",
  89081: "Clark",
  89084: "Clark",
  89085: "Clark",
  89086: "Clark",
  89087: "Clark",
  89101: "Clark",
  89102: "Clark",
  89103: "Clark",
  89104: "Clark",
  89105: "Clark",
  89106: "Clark",
  89107: "Clark",
  89108: "Clark",
  89109: "Clark",
  89110: "Clark",
  89111: "Clark",
  89112: "Clark",
  89113: "Clark",
  89114: "Clark",
  89115: "Clark",
  89116: "Clark",
  89117: "Clark",
  89118: "Clark",
  89119: "Clark",
  89120: "Clark",
  89121: "Clark",
  89122: "Clark",
  89123: "Clark",
  89124: "Clark",
  89125: "Clark",
  89126: "Clark",
  89127: "Clark",
  89128: "Clark",
  89129: "Clark",
  89130: "Clark",
  89131: "Clark",
  89132: "Clark",
  89133: "Clark",
  89134: "Clark",
  89135: "Clark",
  89136: "Clark",
  89137: "Clark",
  89138: "Clark",
  89139: "Clark",
  89140: "Clark",
  89141: "Clark",
  89142: "Clark",
  89143: "Clark",
  89144: "Clark",
  89145: "Clark",
  89146: "Clark",
  89147: "Clark",
  89148: "Clark",
  89149: "Clark",
  89150: "Clark",
  89151: "Clark",
  89152: "Clark",
  89153: "Clark",
  89154: "Clark",
  89155: "Clark",
  89156: "Clark",
  89157: "Clark",
  89158: "Clark",
  89159: "Clark",
  89160: "Clark",
  89161: "Clark",
  89162: "Clark",
  89163: "Clark",
  89164: "Clark",
  89165: "Clark",
  89166: "Clark",
  89169: "Clark",
  89170: "Clark",
  89173: "Clark",
  89177: "Clark",
  89178: "Clark",
  89179: "Clark",
  89180: "Clark",
  89183: "Clark",
  89185: "Clark",
  89191: "Clark",
  89193: "Clark",
  89195: "Clark",
  89199: "Clark",
  89301: "White Pine",
  89310: "Lander",
  89311: "White Pine",
  89314: "Nye",
  89315: "Nye",
  89316: "Eureka",
  89317: "White Pine",
  89318: "White Pine",
  89319: "White Pine",
  89402: "Washoe",
  89403: "Lyon",
  89404: "Humboldt",
  89405: "Washoe",
  89406: "Churchill",
  89407: "Churchill",
  89408: "Lyon",
  89409: "Nye",
  89410: "Douglas",
  89411: "Douglas",
  89412: "Washoe",
  89413: "Douglas",
  89414: "Humboldt",
  89415: "Mineral",
  89418: "Pershing",
  89419: "Pershing",
  89420: "Mineral",
  89421: "Humboldt",
  89422: "Mineral",
  89423: "Douglas",
  89424: "Washoe",
  89425: "Humboldt",
  89426: "Humboldt",
  89427: "Mineral",
  89428: "Lyon",
  89429: "Lyon",
  89430: "Lyon",
  89431: "Washoe",
  89432: "Washoe",
  89433: "Washoe",
  89434: "Washoe",
  89435: "Washoe",
  89436: "Washoe",
  89438: "Humboldt",
  89439: "Washoe",
  89440: "Storey",
  89441: "Washoe",
  89442: "Washoe",
  89444: "Douglas",
  89445: "Humboldt",
  89446: "Humboldt",
  89447: "Lyon",
  89448: "Douglas",
  89449: "Douglas",
  89450: "Washoe",
  89451: "Washoe",
  89452: "Washoe",
  89460: "Douglas",
  89496: "Douglas",
  89501: "Washoe",
  89502: "Washoe",
  89503: "Washoe",
  89504: "Washoe",
  89505: "Washoe",
  89506: "Washoe",
  89507: "Washoe",
  89508: "Washoe",
  89509: "Washoe",
  89510: "Washoe",
  89511: "Washoe",
  89512: "Washoe",
  89513: "Washoe",
  89515: "Washoe",
  89519: "Washoe",
  89520: "Washoe",
  89521: "Washoe",
  89523: "Washoe",
  89533: "Washoe",
  89555: "Washoe",
  89557: "Washoe",
  89570: "Washoe",
  89595: "Washoe",
  89599: "Washoe",
  89701: "Carson City",
  89702: "Carson City",
  89703: "Carson City",
  89704: "Washoe",
  89705: "Douglas",
  89706: "Carson City",
  89711: "Carson City",
  89712: "Carson City",
  89713: "Carson City",
  89714: "Carson City",
  89721: "Carson City",
  89801: "Elko",
  89802: "Elko",
  89803: "Elko",
  89815: "Elko",
  89820: "Lander",
  89821: "Eureka",
  89822: "Elko",
  89823: "Elko",
  89824: "Elko",
  89825: "Elko",
  89826: "Elko",
  89828: "Elko",
  89830: "Elko",
  89831: "Elko",
  89832: "Elko",
  89833: "Elko",
  89834: "Elko",
  89835: "Elko",
  89883: "Elko",
  97001: "Wasco",
  97002: "Marion",
  97004: "Clackamas",
  97005: "Washington",
  97006: "Washington",
  97007: "Washington",
  97008: "Washington",
  97009: "Clackamas",
  97010: "Clackamas",
  97011: "Clackamas",
  97013: "Clackamas",
  97014: "Hood River",
  97015: "Clackamas",
  97016: "Columbia",
  97017: "Clackamas",
  97018: "Columbia",
  97019: "Multnomah",
  97020: "Marion",
  97021: "Wasco",
  97022: "Clackamas",
  97023: "Clackamas",
  97024: "Multnomah",
  97026: "Marion",
  97027: "Clackamas",
  97028: "Clackamas",
  97029: "Sherman",
  97030: "Multnomah",
  97031: "Hood River",
  97032: "Marion",
  97033: "Sherman",
  97034: "Clackamas",
  97035: "Clackamas",
  97036: "Clackamas",
  97037: "Wasco",
  97038: "Clackamas",
  97039: "Sherman",
  97040: "Wasco",
  97041: "Hood River",
  97042: "Clackamas",
  97044: "Clackamas",
  97045: "Clackamas",
  97048: "Columbia",
  97049: "Clackamas",
  97050: "Sherman",
  97051: "Columbia",
  97053: "Columbia",
  97054: "Columbia",
  97055: "Clackamas",
  97056: "Columbia",
  97057: "Wasco",
  97058: "Wasco",
  97060: "Multnomah",
  97062: "Washington",
  97063: "Wasco",
  97064: "Columbia",
  97065: "Sherman",
  97067: "Clackamas",
  97068: "Clackamas",
  97070: "Clackamas",
  97071: "Marion",
  97075: "Marion",
  97076: "Marion",
  97077: "Marion",
  97078: "Marion",
  97080: "Multnomah",
  97086: "Clackamas",
  97089: "Clackamas",
  97101: "Yamhill",
  97102: "Clatsop",
  97103: "Clatsop",
  97106: "Washington",
  97107: "Tillamook",
  97108: "Tillamook",
  97109: "Washington",
  97110: "Clatsop",
  97111: "Yamhill",
  97112: "Tillamook",
  97113: "Washington",
  97114: "Yamhill",
  97115: "Yamhill",
  97116: "Washington",
  97117: "Washington",
  97118: "Tillamook",
  97119: "Washington",
  97121: "Clatsop",
  97122: "Tillamook",
  97123: "Washington",
  97124: "Washington",
  97125: "Washington",
  97127: "Yamhill",
  97128: "Yamhill",
  97130: "Tillamook",
  97131: "Tillamook",
  97132: "Yamhill",
  97133: "Washington",
  97134: "Tillamook",
  97135: "Tillamook",
  97136: "Tillamook",
  97137: "Marion",
  97138: "Clatsop",
  97140: "Washington",
  97141: "Tillamook",
  97143: "Tillamook",
  97144: "Washington",
  97145: "Clatsop",
  97146: "Clatsop",
  97147: "Tillamook",
  97148: "Yamhill",
  97149: "Tillamook",
  97201: "Multnomah",
  97202: "Multnomah",
  97203: "Multnomah",
  97204: "Multnomah",
  97205: "Multnomah",
  97206: "Multnomah",
  97207: "Multnomah",
  97208: "Multnomah",
  97209: "Multnomah",
  97210: "Multnomah",
  97211: "Multnomah",
  97212: "Multnomah",
  97213: "Multnomah",
  97214: "Multnomah",
  97215: "Multnomah",
  97216: "Multnomah",
  97217: "Multnomah",
  97218: "Multnomah",
  97219: "Multnomah",
  97220: "Multnomah",
  97221: "Multnomah",
  97222: "Clackamas",
  97223: "Washington",
  97224: "Washington",
  97225: "Washington",
  97227: "Multnomah",
  97228: "Multnomah",
  97229: "Washington",
  97230: "Multnomah",
  97231: "Multnomah",
  97232: "Multnomah",
  97233: "Multnomah",
  97236: "Multnomah",
  97238: "Multnomah",
  97239: "Multnomah",
  97240: "Multnomah",
  97242: "Multnomah",
  97251: "Multnomah",
  97253: "Multnomah",
  97254: "Multnomah",
  97255: "Multnomah",
  97256: "Multnomah",
  97258: "Multnomah",
  97259: "Multnomah",
  97266: "Multnomah",
  97267: "Clackamas",
  97268: "Clackamas",
  97269: "Clackamas",
  97271: "Clackamas",
  97272: "Clackamas",
  97280: "Clackamas",
  97281: "Clackamas",
  97282: "Clackamas",
  97283: "Clackamas",
  97286: "Clackamas",
  97290: "Clackamas",
  97291: "Clackamas",
  97292: "Clackamas",
  97293: "Clackamas",
  97294: "Clackamas",
  97296: "Clackamas",
  97298: "Clackamas",
  97299: "Clackamas",
  97301: "Marion",
  97302: "Marion",
  97303: "Marion",
  97304: "Polk",
  97305: "Marion",
  97306: "Marion",
  97307: "Marion",
  97308: "Marion",
  97309: "Marion",
  97310: "Marion",
  97311: "Marion",
  97312: "Marion",
  97313: "Marion",
  97314: "Marion",
  97317: "Marion",
  97321: "Linn",
  97322: "Linn",
  97324: "Lincoln",
  97325: "Marion",
  97326: "Benton",
  97327: "Linn",
  97329: "Linn",
  97330: "Benton",
  97331: "Benton",
  97333: "Benton",
  97335: "Benton",
  97336: "Benton",
  97338: "Polk",
  97339: "Polk",
  97341: "Lincoln",
  97342: "Marion",
  97343: "Lincoln",
  97344: "Polk",
  97345: "Linn",
  97346: "Marion",
  97347: "Polk",
  97348: "Linn",
  97350: "Marion",
  97351: "Polk",
  97352: "Marion",
  97355: "Linn",
  97357: "Lincoln",
  97358: "Linn",
  97360: "Linn",
  97361: "Polk",
  97362: "Marion",
  97364: "Lincoln",
  97365: "Lincoln",
  97366: "Lincoln",
  97367: "Lincoln",
  97368: "Lincoln",
  97369: "Lincoln",
  97370: "Benton",
  97371: "Polk",
  97372: "Polk",
  97373: "Marion",
  97374: "Linn",
  97375: "Marion",
  97376: "Lincoln",
  97377: "Linn",
  97378: "Yamhill",
  97380: "Lincoln",
  97381: "Marion",
  97383: "Marion",
  97384: "Marion",
  97385: "Marion",
  97386: "Linn",
  97388: "Lincoln",
  97389: "Linn",
  97390: "Lincoln",
  97391: "Lincoln",
  97392: "Marion",
  97394: "Lincoln",
  97396: "Yamhill",
  97401: "Lane",
  97402: "Lane",
  97403: "Lane",
  97404: "Lane",
  97405: "Lane",
  97406: "Curry",
  97407: "Curry",
  97408: "Lane",
  97409: "Lane",
  97410: "Douglas",
  97411: "Coos",
  97412: "Lane",
  97413: "Lane",
  97414: "Coos",
  97415: "Curry",
  97416: "Douglas",
  97417: "Douglas",
  97419: "Lane",
  97420: "Coos",
  97423: "Coos",
  97424: "Lane",
  97425: "Lane",
  97426: "Lane",
  97427: "Lane",
  97428: "Lane",
  97429: "Douglas",
  97430: "Lane",
  97431: "Lane",
  97432: "Lane",
  97434: "Lane",
  97435: "Douglas",
  97436: "Douglas",
  97437: "Lane",
  97438: "Lane",
  97439: "Lane",
  97440: "Lane",
  97441: "Douglas",
  97442: "Douglas",
  97443: "Douglas",
  97444: "Curry",
  97446: "Linn",
  97447: "Douglas",
  97448: "Lane",
  97449: "Coos",
  97450: "Curry",
  97451: "Lane",
  97452: "Lane",
  97453: "Lane",
  97454: "Lane",
  97455: "Lane",
  97456: "Benton",
  97457: "Douglas",
  97458: "Coos",
  97459: "Coos",
  97461: "Lane",
  97462: "Douglas",
  97463: "Lane",
  97464: "Lane",
  97465: "Curry",
  97466: "Coos",
  97467: "Douglas",
  97469: "Douglas",
  97470: "Douglas",
  97471: "Douglas",
  97472: "Douglas",
  97473: "Douglas",
  97475: "Douglas",
  97476: "Curry",
  97477: "Lane",
  97478: "Lane",
  97479: "Douglas",
  97480: "Lane",
  97481: "Douglas",
  97482: "Douglas",
  97484: "Douglas",
  97486: "Douglas",
  97487: "Lane",
  97488: "Lane",
  97489: "Lane",
  97490: "Lane",
  97491: "Lane",
  97492: "Lane",
  97493: "Lane",
  97494: "Douglas",
  97495: "Douglas",
  97496: "Douglas",
  97497: "Josephine",
  97498: "Lincoln",
  97499: "Douglas",
  97501: "Jackson",
  97502: "Jackson",
  97503: "Jackson",
  97504: "Jackson",
  97520: "Jackson",
  97522: "Jackson",
  97523: "Josephine",
  97524: "Jackson",
  97525: "Jackson",
  97526: "Josephine",
  97527: "Josephine",
  97528: "Josephine",
  97530: "Jackson",
  97531: "Josephine",
  97532: "Josephine",
  97533: "Josephine",
  97534: "Josephine",
  97535: "Jackson",
  97536: "Jackson",
  97537: "Jackson",
  97538: "Josephine",
  97539: "Jackson",
  97540: "Jackson",
  97541: "Jackson",
  97543: "Josephine",
  97544: "Josephine",
  97601: "Klamath",
  97602: "Klamath",
  97603: "Klamath",
  97604: "Klamath",
  97620: "Lake",
  97621: "Klamath",
  97622: "Klamath",
  97623: "Klamath",
  97624: "Klamath",
  97625: "Klamath",
  97626: "Klamath",
  97627: "Klamath",
  97630: "Lake",
  97632: "Klamath",
  97633: "Klamath",
  97634: "Klamath",
  97635: "Modoc",
  97636: "Lake",
  97637: "Lake",
  97638: "Lake",
  97639: "Klamath",
  97640: "Lake",
  97641: "Lake",
  97701: "Deschutes",
  97702: "Deschutes",
  97707: "Deschutes",
  97708: "Deschutes",
  97709: "Deschutes",
  97710: "Harney",
  97711: "Jefferson",
  97712: "Deschutes",
  97720: "Harney",
  97721: "Harney",
  97722: "Harney",
  97730: "Jefferson",
  97731: "Klamath",
  97732: "Harney",
  97733: "Klamath",
  97734: "Jefferson",
  97735: "Lake",
  97736: "Harney",
  97737: "Klamath",
  97738: "Harney",
  97739: "Deschutes",
  97741: "Jefferson",
  97750: "Wheeler",
  97751: "Crook",
  97752: "Crook",
  97753: "Crook",
  97754: "Crook",
  97756: "Deschutes",
  97758: "Harney",
  97759: "Deschutes",
  97760: "Jefferson",
  97761: "Jefferson",
  97801: "Umatilla",
  97810: "Umatilla",
  97812: "Gilliam",
  97813: "Umatilla",
  97814: "Baker",
  97817: "Grant",
  97818: "Morrow",
  97819: "Baker",
  97820: "Grant",
  97823: "Gilliam",
  97824: "Union",
  97825: "Grant",
  97826: "Umatilla",
  97827: "Union",
  97828: "Wallowa",
  97830: "Wheeler",
  97833: "Baker",
  97834: "Baker",
  97835: "Umatilla",
  97836: "Morrow",
  97837: "Baker",
  97838: "Umatilla",
  97839: "Morrow",
  97840: "Baker",
  97841: "Union",
  97842: "Wallowa",
  97843: "Morrow",
  97844: "Morrow",
  97845: "Grant",
  97846: "Wallowa",
  97848: "Grant",
  97850: "Union",
  97856: "Grant",
  97857: "Wallowa",
  97859: "Umatilla",
  97861: "Umatilla",
  97862: "Umatilla",
  97864: "Grant",
  97865: "Grant",
  97867: "Union",
  97868: "Umatilla",
  97869: "Grant",
  97870: "Baker",
  97873: "Grant",
  97874: "Wheeler",
  97875: "Umatilla",
  97876: "Union",
  97877: "Baker",
  97880: "Umatilla",
  97882: "Umatilla",
  97883: "Union",
  97884: "Baker",
  97885: "Wallowa",
  97886: "Umatilla",
  97901: "Malheur",
  97902: "Malheur",
  97903: "Malheur",
  97904: "Harney",
  97905: "Baker",
  97906: "Malheur",
  97907: "Baker",
  97908: "Malheur",
  97909: "Malheur",
  97910: "Malheur",
  97911: "Malheur",
  97913: "Malheur",
  97914: "Malheur",
  97917: "Malheur",
  97918: "Malheur",
  97920: "Malheur",
};
