import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeStep: 0,
  steps: [],
  homeowner: {},
  proposal: {},
  lat: 0,
  lng: 0,

  // Panel Placement Specific
  imageURL: "",
  pixelsPerMeter: 0,
  panel: {},
  panelWidth: 0,
  panelHeight: 0,
  panels: [],
  selectedPanel: -1,
  azimuthData: {},
  roofPitch: 0.9230769231,
  loadingData: false,
  info: {
    panelCount: 0,
    systemSize: 0,
    yearProduction: 0,
    yearProdWShading: 0,
  },

  exporting: false,
};

const proposalBuilderSlice = createSlice({
  name: "proposal-builder",
  initialState,
  reducers: {
    initializeData(state, action) {
      return { ...state, ...action.payload };
    },
    editHomeowner(state, action) {
      return { ...state, homeowner: { ...state.homeowner, ...action.payload } };
    },
    editProposal(state, action) {
      return { ...state, proposal: { ...state.proposal, ...action.payload } };
    },
    changeActiveStep(state, action) {
      return { ...state, activeStep: state.activeStep + action.payload };
    },
    setState(state, action) {
      return { ...state, ...action.payload };
    },
    cleanState() {
      return initialState;
    },

    // Now for the panel placement reducers
  },
});

export const {
  initializeData,
  editHomeowner,
  editProposal,
  changeActiveStep,
  setState,
  cleanState,
} = proposalBuilderSlice.actions;
export default proposalBuilderSlice.reducer;
