import React from "react";
import ReactDOM from "react-dom";
import { Button } from "@material-ui/core";
import { SnackbarProvider, withSnackbar } from "notistack";

const UpdateNotification = {
  send: function () {
    this.toast();
  },
  toast: function () {
    const Display = withSnackbar(({ enqueueSnackbar }) => {
      enqueueSnackbar(
        "It looks like you are using an outdated version of Check. Please refresh your page to update, then try again!",
        {
          variant: "error",
          preventDuplicate: true,
          persist: true,
          action: () => (
            <Button
              variant="contained"
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
          ),
        }
      );
      return null;
    });
    const mountPoint = document.getElementById("updatehelper");
    ReactDOM.render(
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Display />
      </SnackbarProvider>,
      mountPoint
    );
  },
};

export default UpdateNotification;
