import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import RequiresRole from "../RequiresRole";
import roles from "../../../config/roles";

import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  List,
} from "@material-ui/core";
import GridOnIcon from "@material-ui/icons/GridOn";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import BathtubOutlinedIcon from "@material-ui/icons/BathtubOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DescriptionIcon from "@material-ui/icons/Description";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}22 !important`,
  },
  selectedFaded: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}11 !important`,
  },
  highlightedText: {
    color: theme.palette.secondary.main,
  },
}));

/**
 * Colapsable nav bar element that contains multiple links for the proposals pages.
 * @todo make a nav component to clean this up.
 */
export default function ProposalsLinks({ location, toggleDrawer }) {
  const classes = useStyles();
  // This is the bool that determins whether the colapsable is open or closed.
  // Its initial state is true if the current page is contained in the dropdown
  const [proposalsOpen, setProposalsOpen] = useState(
    location.pathname.split("/")[1] === "proposals"
  );

  return (
    <>
      <Link to="/proposals/grid" className="nostyle-link">
        <ListItem
          button
          onClick={() => {
            toggleDrawer(false);
          }}
          selected={location.pathname.split("/")[1] === "proposals"}
          classes={{ selected: classes.selected }}
        >
          <ListItemIcon
            className={
              location.pathname.split("/")[1] === "proposals"
                ? classes.highlightedText
                : null
            }
          >
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Proposals" />
          <ListItemSecondaryAction>
            <IconButton
              className={
                location.pathname.split("/")[1] === "proposals"
                  ? classes.highlightedText
                  : null
              }
              onClick={(e) => {
                e.preventDefault();
                setProposalsOpen(!proposalsOpen);
              }}
            >
              {proposalsOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </Link>
      <Collapse in={proposalsOpen} timeout="auto">
        <List component="div" disablePadding>
          <Link to="/proposals/grid" className="nostyle-link">
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                toggleDrawer(false);
              }}
              selected={location.pathname.split("/")[2] === "grid"}
              classes={{ selected: classes.selectedFaded }}
            >
              <ListItemIcon
                className={
                  location.pathname.split("/")[2] === "grid"
                    ? classes.highlightedText
                    : null
                }
              >
                <GridOnIcon />
              </ListItemIcon>
              <ListItemText primary="Grid" />
            </ListItem>
          </Link>
          <Link to="/proposals/table" className="nostyle-link">
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                toggleDrawer(false);
              }}
              selected={location.pathname.split("/")[2] === "table"}
              classes={{ selected: classes.selectedFaded }}
            >
              <ListItemIcon
                className={
                  location.pathname.split("/")[2] === "table"
                    ? classes.highlightedText
                    : null
                }
              >
                <TableChartOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Table" />
            </ListItem>
          </Link>
          <RequiresRole
            roles={[
              roles.salesSupport,
              roles.supportManager,
              roles.systemAdmin,
            ]}
          >
            <Link to="/proposals/pending" className="nostyle-link">
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  toggleDrawer(false);
                }}
                selected={location.pathname.split("/")[2] === "pending"}
                classes={{ selected: classes.selectedFaded }}
              >
                <ListItemIcon
                  className={
                    location.pathname.split("/")[2] === "pending"
                      ? classes.highlightedText
                      : null
                  }
                >
                  <BathtubOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Pending" />
              </ListItem>
            </Link>
          </RequiresRole>
        </List>
      </Collapse>
    </>
  );
}

ProposalsLinks.propTypes = {
  /** Location object obtained by using react routers useLocation hook */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  /** Function to close navbar on link click */
  toggleDrawer: PropTypes.func.isRequired,
};
