const roles = {
  baseUser: "Base User",
  userManager: "User Manager",
  dealerAdmin: "Dealer Admin",
  dealerOwner: "Dealer Owner",
  salesSupport: "Sales Support",
  supportManager: "Support Manager",
  financial: "Financial",
  systemAdmin: "System Admin",
};

export default roles;
