import { createTheme } from "@material-ui/core";
import oneSolarTheme from "./config/theme";

export const getTheme = (theme) => {
  if (Object.keys(theme) !== 0) {
    return createTheme({
      ...theme,
    });
  } else {
    return createTheme(oneSolarTheme);
  }
};
