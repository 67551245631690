export default function getStateFromAddress(address) {
  if (!address) {
    return null;
  }
  const states = [
    ["ARIZONA", "AZ"],
    ["ALABAMA", "AL"],
    ["ALASKA", "AK"],
    ["ARKANSAS", "AR"],
    ["CALIFORNIA", "CA"],
    ["COLORADO", "CO"],
    ["CONNECTICUT", "CT"],
    ["DELAWARE", "DE"],
    ["FLORIDA", "FL"],
    ["GEORGIA", "GA"],
    ["HAWAII", "HI"],
    ["IDAHO", "ID"],
    ["ILLINOIS", "IL"],
    ["INDIANA", "IN"],
    ["IOWA", "IA"],
    ["KANSAS", "KS"],
    ["KENTUCKY", "KY"],
    ["LOUISIANA", "LA"],
    ["MAINE", "ME"],
    ["MARYLAND", "MD"],
    ["MASSACHUSETTS", "MA"],
    ["MICHIGAN", "MI"],
    ["MINNESOTA", "MN"],
    ["MISSISSIPPI", "MS"],
    ["MISSOURI", "MO"],
    ["MONTANA", "MT"],
    ["NEBRASKA", "NE"],
    ["NEVADA", "NV"],
    ["NEW HAMPSHIRE", "NH"],
    ["NEW JERSEY", "NJ"],
    ["NEW MEXICO", "NM"],
    ["NEW YORK", "NY"],
    ["NORTH CAROLINA", "NC"],
    ["NORTH DAKOTA", "ND"],
    ["OHIO", "OH"],
    ["OKLAHOMA", "OK"],
    ["OREGON", "OR"],
    ["PENNSYLVANIA", "PA"],
    ["RHODE ISLAND", "RI"],
    ["SOUTH CAROLINA", "SC"],
    ["SOUTH DAKOTA", "SD"],
    ["TENNESSEE", "TN"],
    ["TEXAS", "TX"],
    ["UTAH", "UT"],
    ["VERMONT", "VT"],
    ["VIRGINIA", "VA"],
    ["WASHINGTON", "WA"],
    ["WEST VIRGINIA", "WV"],
    ["WISCONSIN", "WI"],
    ["WYOMING", "WY"],
  ];

  let input = address.toUpperCase().replace(/[0-9]/g, "").split(",");
  input.pop();
  input = input.pop().trim();
  for (let i = 0; i < states.length; i++) {
    if (states[i][0] === input || states[i][1] === input) {
      return states[i][1];
    }
  }
}
