export const selectableTerms = [
  { id: 1, name: "Cash" },
  { id: 2, name: "10 Years" },
  { id: 3, name: "12 Years" },
  { id: 4, name: "15 Years" },
  { id: 5, name: "20 Years" },
  { id: 6, name: "25 Years" },
];

export const allTerms = [
  { id: 1, name: "Cash" },
  { id: 2, name: "10 Years" },
  { id: 3, name: "12 Years" },
  { id: 4, name: "15 Years" },
  { id: 5, name: "20 Years" },
  { id: 6, name: "25 Years" },
];

export const selectableRates = [
  { id: 6, name: "Credit Card" },
  { id: 1, name: "Check" },
  { id: 2, name: "2.99" },
  { id: 3, name: "3.99" },
  { id: 4, name: "4.99" },
  { id: 5, name: "5.99" },
];

export const allRates = [
  { id: 1, name: "Check" },
  { id: 2, name: "2.99" },
  { id: 3, name: "3.99" },
  { id: 4, name: "4.99" },
  { id: 5, name: "5.99" },
  { id: 6, name: "Credit Card" },
];

export const selectablePanels = [{ id: 13, name: "VSUN 370" }];

// Dimentions need to be in meters
export const allPanels = [
  { id: 1, name: "VSUN 310", watts: 310, width: 0.99, height: 1.64 },
  { id: 2, name: "Solaria 370", watts: 370, width: 1.116, height: 1.621 },
  { id: 3, name: "Heliene 300", watts: 300, width: 1.001, height: 1.666 },
  { id: 4, name: "HT-SAAE 310", watts: 310, width: 0.992, height: 1.64 },
  { id: 5, name: "Heliene 320", watts: 320, width: 0.992, height: 1.64 },
  { id: 6, name: "Solaria 400", watts: 400, width: 1.204, height: 1.644 },
  { id: 7, name: "Longi 350", watts: 350, width: 1.038098, height: 1.754886 },
  { id: 8, name: "Q Cells 380", watts: 380, width: 1.03, height: 1.84 },
  { id: 9, name: "Jinko 375", watts: 375, width: 0.99, height: 1.96 },
  { id: 10, name: "HT-SAAE 375", watts: 375, width: 1.038, height: 1.755 },
  { id: 11, name: "Aptos 365", watts: 365, width: 1.039, height: 1.756 },
  { id: 12, name: "Seraphim 400", watts: 400, width: 1.134, height: 1.722 },

  { id: 13, name: "VSUN 370", watts: 370, width: 1.048, height: 1.762 },
];

export const selectableInverters = [
  { id: 1, name: "SolarEdge" },
  { id: 2, name: "Enphase Energy" },
];

export const allInverters = [
  { id: 1, name: "SolarEdge" },
  { id: 2, name: "Enphase Energy" },
];

export const loanPaymentFactors = {
  Cash: {
    Check: [0.0, 0.0, 1],
    "Credit Card": [0.0, 0.0, 0.97],
    2.99: [0.003576, 0.004861, 0.97],
    3.99: [0.004002, 0.005425, 0.97],
    4.99: [0.004456, 0.006024, 0.97],
    5.99: [0.004936, 0.006656, 0.97],
  },
  "10 Years": {
    Check: [0.0, 0.0, 1],
    "Credit Card": [0.0, 0.0, 0.97],
    2.99: [0.007323, 0.010212, 0.8301],
    3.99: [0.007719, 0.010729, 0.8575],
    4.99: [0.00813, 0.011262, 0.8851],
    5.99: [0.008554, 0.011812, 0.9],
  },
  "12 Years": {
    Check: [0.0, 0.0, 1],
    "Credit Card": [0.0, 0.0, 0.97],
    2.99: [0.006268, 0.008675, 0.8151],
    3.99: [0.006666, 0.009195, 0.8475],
    4.99: [0.007079, 0.009734, 0.8475],
    5.99: [0.007508, 0.010292, 0.8475],
  },
  "15 Years": {
    Check: [0.0, 0.0, 1],
    "Credit Card": [0.0, 0.0, 0.97],
    2.99: [0.005222, 0.007174, 0.8151],
    3.99: [0.005623, 0.007701, 0.8325],
    4.99: [0.006044, 0.008252, 0.8675],
    5.99: [0.006483, 0.008827, 0.8675],
  },
  "20 Years": {
    Check: [0.0, 0.0, 1],
    "Credit Card": [0.0, 0.0, 0.97],
    2.99: [0.004187, 0.005713, 0.7875],
    3.99: [0.0046, 0.006258, 0.8175],
    4.99: [0.005036, 0.006832, 0.8551],
    5.99: [0.005495, 0.007436, 0.8975],
  },
  "25 Years": {
    Check: [0.0, 0.0, 1],
    "Credit Card": [0.0, 0.0, 0.97],
    2.99: [0.003576, 0.004861, 0.765],
    3.99: [0.004002, 0.005425, 0.7941],
    4.99: [0.004456, 0.006024, 0.841],
    5.99: [0.004936, 0.006656, 0.8901],
  },
};

export const cashFinancedRatios = {
  1: {
    1: 1,
    6: 0.97,
    2: 0.97,
    3: 0.97,
    4: 0.97,
    5: 0.97,
  },
  2: {
    1: 1,
    6: 0.97,
    2: 0.8301,
    3: 0.8575,
    4: 0.8851,
    5: 0.9,
  },
  3: {
    1: 1,
    6: 0.97,
    2: 0.8151,
    3: 0.8475,
    4: 0.8475,
    5: 0.8475,
  },
  4: {
    1: 1,
    6: 0.97,
    2: 0.8151,
    3: 0.8325,
    4: 0.8675,
    5: 0.8675,
  },
  5: {
    1: 1,
    6: 0.97,
    2: 0.7875,
    3: 0.8175,
    4: 0.8551,
    5: 0.8975,
  },
  6: {
    1: 1,
    6: 0.97,
    2: 0.765,
    3: 0.7941,
    4: 0.841,
    5: 0.8901,
  },
};
