const oneSolarTheme = {
  palette: {
    type: "light",
    primary: {
      main: "#FFD875",
    },
    secondary: {
      main: "#2E52B3",
    },
  },
};

export default oneSolarTheme;
