const initialState = {};
const financierReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_FINANCIER":
      return { ...payload };

    default:
      return state;
  }
};

export default financierReducer;
