const initialState = {};

const homeownerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_HOMEOWNER":
      return { ...payload };

    case "EDIT_HOMEOWNER":
      return { ...state, ...payload };

    default:
      return state;
  }
};
export default homeownerReducer;
