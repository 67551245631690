import React from "react";
import PropTypes from "prop-types";
import RequiresRole from "../RequiresRole";
import roles from "../../../config/roles";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  List,
  ListItem,
  Divider,
  Button,
  Avatar,
} from "@material-ui/core";

import ProposalsLinks from "./ProposalsLinks";
import CommissionsLinks from "./CommissionsLinks";
import RepCommissionsLinks from "./RepCommissionsLinks";
import CustomersLinks from "./CustomersLinks";
import SingleLinks from "./SingleLinks";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 275,
  },
  removeVerticalPadding: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}22 !important`,
  },
  selectedFaded: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}11 !important`,
  },
  highlightedText: {
    color: theme.palette.secondary.main,
  },
}));

/**
 * Provides the user with a way to sign out, and access every page avaliable to them
 */
export default function NavList({ toggleDrawer }) {
  const user = useSelector((state) => state.user);
  const classes = useStyles();
  const location = useLocation();

  return (
    <div className={classes.list} role="presentation">
      {/* User information an sign out */}
      <List component="nav">
        <ListItem>
          <Avatar alt="Users Avatar" src={user.profileSettings.avatar} />
        </ListItem>
        <ListItem className={classes.removeVerticalPadding}>
          <Typography variant="h6">{`${user.firstName} ${user.lastName}`}</Typography>
        </ListItem>
        <ListItem className={classes.removeVerticalPadding}>
          <Typography variant="subtitle2">{user.dealership.name}</Typography>
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            color="secondary"
            href={`${process.env.REACT_APP_API_URL}/auth/logout`}
          >
            Sign Out
          </Button>
        </ListItem>
      </List>
      <Divider />
      {/* Where the nav bar actually begins */}
      <List>
        {/* NavListItem takes in children as props, and uses it as the icon of the link */}
        <ProposalsLinks location={location} toggleDrawer={toggleDrawer} />
        <RequiresRole
          roles={[roles.dealerOwner, roles.systemAdmin, roles.financial]}
        >
          <CommissionsLinks location={location} toggleDrawer={toggleDrawer} />
        </RequiresRole>
        <RequiresRole
          roles={[roles.dealerOwner, roles.systemAdmin, roles.financial]}
        >
          <RepCommissionsLinks
            location={location}
            toggleDrawer={toggleDrawer}
          />
        </RequiresRole>
        <CustomersLinks location={location} toggleDrawer={toggleDrawer} />
        <SingleLinks location={location} toggleDrawer={toggleDrawer} />
      </List>
    </div>
  );
}

NavList.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
};
