import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  id: "",
};

const homeownerModalSlice = createSlice({
  name: "homeowner-modal",
  initialState,
  reducers: {
    openModal(state, action) {
      return { ...state, open: true, id: action.payload };
    },
    cleanState() {
      return initialState;
    },
  },
});

export const { openModal, cleanState } = homeownerModalSlice.actions;
export default homeownerModalSlice.reducer;
