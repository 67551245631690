import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  List,
} from "@material-ui/core";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import EventIcon from "@material-ui/icons/Event";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}22 !important`,
  },
  selectedFaded: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main}11 !important`,
  },
  highlightedText: {
    color: theme.palette.secondary.main,
  },
}));

/**
 * Colapsable nav bar element that contains multiple links for the commissions pages.
 * @todo make a nav component to clean this up.
 */
export default function CustomersLinks({ location, toggleDrawer }) {
  const classes = useStyles();
  // This is the bool that determins whether the colapsable is open or closed.
  // Its initial state is true if the current page is contained in the dropdown
  const [customersOpen, setCustomersOpen] = useState(
    location.pathname.split("/")[1] === "customers"
  );

  return (
    <>
      <Link to="/customers/all" className="nostyle-link">
        <ListItem
          button
          onClick={() => {
            toggleDrawer(false);
          }}
          selected={location.pathname.split("/")[1] === "customers"}
          classes={{ selected: classes.selected }}
        >
          <ListItemIcon
            className={
              location.pathname.split("/")[1] === "customers"
                ? classes.highlightedText
                : null
            }
          >
            <SupervisedUserCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Customers" />
          <ListItemSecondaryAction>
            <IconButton
              className={
                location.pathname.split("/")[1] === "customers"
                  ? classes.highlightedText
                  : null
              }
              onClick={(e) => {
                e.preventDefault();
                setCustomersOpen(!customersOpen);
              }}
            >
              {customersOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </Link>

      <Collapse in={customersOpen} timeout="auto">
        <List component="div" disablePadding>
          <Link to="/customers/all" className="nostyle-link">
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                toggleDrawer(false);
              }}
              selected={
                location.pathname.split("/")[1] === "customers" &&
                location.pathname.split("/")[2] === "all"
              }
              classes={{ selected: classes.selectedFaded }}
            >
              <ListItemIcon
                className={
                  location.pathname.split("/")[1] === "customers" &&
                  location.pathname.split("/")[2] === "all"
                    ? classes.highlightedText
                    : null
                }
              >
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText primary="All" />
            </ListItem>
          </Link>
          <Link to="/customers/perfect-packet" className="nostyle-link">
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                toggleDrawer(false);
              }}
              selected={location.pathname.split("/")[2] === "perfect-packet"}
              classes={{ selected: classes.selectedFaded }}
            >
              <ListItemIcon
                className={
                  location.pathname.split("/")[2] === "perfect-packet"
                    ? classes.highlightedText
                    : null
                }
              >
                <FolderOpenOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Perfect Packet" />
            </ListItem>
          </Link>
          <Link to="/customers/install-calendar" className="nostyle-link">
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                toggleDrawer(false);
              }}
              selected={location.pathname.split("/")[2] === "install-calendar"}
              classes={{ selected: classes.selectedFaded }}
            >
              <ListItemIcon
                className={
                  location.pathname.split("/")[2] === "install-calendar"
                    ? classes.highlightedText
                    : null
                }
              >
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary="Install Calendar" />
            </ListItem>
          </Link>
        </List>
      </Collapse>
    </>
  );
}

CustomersLinks.propTypes = {
  /** Location object obtained by using react routers useLocation hook */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  /** Function to close navbar on link click */
  toggleDrawer: PropTypes.func.isRequired,
};
