const initialState = {};

const dealershipReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_DEALERSHIP":
      return { ...payload };

    case "SET_DEALERSHIP_THEME":
      return { ...state, theme: { ...payload } };

    case "SET_DEALERSHIP_OWNER":
      return { ...state, owner: { ...payload } };

    case "SET_DEALERSHIP_ADDRESS":
      return { ...state, address: payload };

    case "SET_DEALERSHIP_PROPOSAL_DEFAULTS":
      return { ...state, proposalDefaults: payload };

    case "EDIT_DEALERSHIP":
      return { ...state, ...payload };

    default:
      return state;
  }
};
export default dealershipReducer;
