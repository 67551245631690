import { configureStore } from "@reduxjs/toolkit";
import user from "../reducers/userReducer";
import authenticated from "../reducers/authenticatedReducer";
import loading from "../reducers/loadingReducer";
import homeowner from "../reducers/homeownerReducer";
import theme from "../reducers/themeReducer";
import dealership from "../reducers/dealershipReducer";
import userDealership from "../reducers/userDealershipReducer";
import loan from "../reducers/loanReducer";
import financier from "../reducers/financierReducer";
import proposalBuilder from "../features/ProposalBuilder/proposalBuilderSlice";
import homeownerModal from "../features/HomeownerModal/homeownerModalSlice";
import proposal from "../features/Proposal/proposalSlice";

export const store = configureStore({
  reducer: {
    user,
    authenticated,
    loading,
    homeowner,
    theme,
    dealership,
    proposal,
    userDealership,
    loan,
    financier,
    proposalBuilder,
    homeownerModal,
  },
});
