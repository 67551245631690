import { setUser, setUserTheme, setUserProposalDefaults } from "./userActions";
import { setAuthenticated } from "./authenticatedActions";
import { setLoading } from "./loadingActions";
import { setTheme } from "./themeActions";
import {
  setDealership,
  setDealershipTheme,
  setDealershipOwner,
  setDealershipAddress,
  setDealershipProposalDefaults,
  editDealership,
} from "./dealershipActions";
import { setHomeowner, editHomeowner } from "./homeownerActions";
import { setProposal, editProposal } from "./proposalActions";
import { setUserDealership, editUserDealership } from "./userDealershipActions";
import { setLoan } from "./loanActions";
import { setFinancier } from "./financierActions";

const allActions = {
  setUser,
  setUserTheme,
  setUserProposalDefaults,
  setAuthenticated,
  setLoading,
  setTheme,
  setDealership,
  setDealershipTheme,
  setDealershipOwner,
  setDealershipAddress,
  setDealershipProposalDefaults,
  editDealership,
  setHomeowner,
  editHomeowner,
  setProposal,
  editProposal,
  setUserDealership,
  editUserDealership,
  setLoan,
  setFinancier,
};

export default allActions;
