export const setUser = (payload) => ({
  type: "SET_USER",
  payload,
});

export const setUserTheme = (payload) => ({
  type: "SET_USER_THEME",
  payload,
});

export const setUserProposalDefaults = (payload) => ({
  type: "SET_USER_PROPOSAL_DEFAULTS",
  payload,
});
